import React, { Component } from 'react';
import {
    Spin,
    Space,
    Badge,
    Button,
    Card,
    Descriptions,
    PageHeader,
    Tabs,
    Row,
    Col,
    Popconfirm, message
} from 'antd';
import { withTranslation } from "react-i18next";
import { Link, withRouter } from 'react-router-dom';
import DetailContainer from './detail-sections/DetailContainer';
import LoadingIndicator from '../common/LoadingIndicator';
import { getProjectYears, getProjectDetails, getProject, createProjectYear, getAccountingItems, getExaminationItems, getBreedingMethods, getLineSelections } from '../util/APIUtils';
import { capitalize, getColorToneForProjectStatus, getProjectActuals } from '../util/Helpers';
import './ProjectDetail.css';
import ProjectCharts from './project_charts/ProjectCharts';
import TinyAreaChart from './project_charts/TinyAreaChart';
const { TabPane } = Tabs;
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';

class ProjectDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            years: [],
            projectDetails: null,
            projectInfo: null,
            accountingItems: [],
            examinationItems: [],
            breedingMethods: [],
            lineSelections: [],
            defaultActiveYear: null,
            activeYear: null,
            activeTab: "1",
        };
    }

    loadAccountingItems(type) {
        let promise;
        promise = getAccountingItems(type);

        if (!promise) {
            return;
        }
        this.setState({ isLoading: true });

        promise.then(response => {
            this.setState({
                accountingItems: response,
                isLoading: false
            })
            this.loadExaminationItems(type);

        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    loadExaminationItems() {
        let promise;
        promise = getExaminationItems();

        if (!promise) {
            return;
        }
        this.setState({ isLoading: true });

        promise.then(response => {
            this.setState({
                examinationItems: response,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    loadBreedingMethods(type) {
        this.setState({ isLoading: true });
        getBreedingMethods(type).then(response => {
            this.setState({
                breedingMethods: response, isLoading: false
            });
        }).catch(error => {
            this.setState({ isLoading: false });
        });
    }


    loadProject() {
        const { id } = this.props.match.params;
        let promise;
        promise = getProject(id);

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response)
            this.setState({
                years: response.projectYears,
                projectInfo: response,
                projectDetails: response.projectDetails,
                isLoading: false,
                defaultActiveYear: response.projectYears[0]
            })
            this.setState(prevState => ({
                years: ["Überblick", ...prevState.years],
                // activeYear: prevState.years.length > 0 ? prevState.years[0] : 0
            }))
            console.log(this.state);
            this.loadAccountingItems(response.projectType);
            this.loadBreedingMethods(response.projectType);
            // this.loadLineSelections(response.cropId);
        }).catch(error => {
            console.log(error)
            this.setState({ isLoading: false })
        });
    }

    addProjectYear = () => {
        const { id } = this.props.match.params;
        let promise;
        promise = createProjectYear(id);

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });
        promise.then(response => {
            // this.loadProject();
            // this.loadExaminationItems();
            // this.loadAccountingItems();
            // this.loadBreedingMethods();
            // this.loadLineSelections();
            this.setState({
                isLoading: false
            })
            if (response == 1) {
                message.success('Jahr erstellt und Daten aktualisiert!');
            } else if (response == 2) {
                message.warn('Jahr kann nicht erstellt werden! Nicht in der richtigen Phase');
            } else if (response == 3) {
                message.warn('Jahr kann nicht erstellt werden!');
            }

            // this.props.history.push(`/project/detail/${id}`);
            this.reloadProject({ 'year': "", "tab": 1 })

        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }
    cancelPop = () => {
        this.setState({
            loading: false,
        })
    }

    reloadProject = (stObj) => {
        this.loadProject();
        this.setState({
            loading: false,
            activeYear: stObj['year'],
            activeTab: stObj['tab']
        })

        console.log(this.state)
    }

    handleBack = e => {
        this
            .props
            .history
            .goBack();
        // this.props.history.push('/projects');
    };

    componentDidMount() {
        this.loadProject();
        // this.loadExaminationItems();
        // this.loadAccountingItems();
        // this.loadLineSelections();
    }

    componentDidUpdate(nextProps) {
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                years: [],
                projectDetails: [],
                // page: 0, size: 10, totalElements: 0, totalPages: 0, last: true,
                isLoading: false
            });
            this.loadProject();

        }
    }

    onChange = activeKey => {
        console.log(activeKey);
        this.setState({ activeKey });
    };

    getColorToneForStatus(statusText) {
        let status;
        switch (statusText) {
            case "ok":
                status = "green";
                break;
            case "zurückgestellt":
                status = "#a0c4ff";
                break;
            case "abgelehnt":
                status = "#ffadad";
                break;
            default:
                status = "";

        }
        return status
    }

    render() {
        const { t, currentUser } = this.props;
        console.log(this.state.activeYear)


        return (
            <div>
                {!this.state.isLoading && this.state.projectInfo
                    ? (
                        <div>
                            <PageHeader
                                className="site-page-header"
                                onBack={() => this.handleBack()}
                                title={this.state.projectInfo["projectNumber"] + " / " + this.state.projectInfo["breederName"] + " / " + this.state.projectInfo["description"]}
                            />
                            <Card colSpan="calc(100% - 200px)" bordered={true} loading={this.state.isLoading} bodyStyle={{ background: getColorToneForProjectStatus(this.state.projectInfo["status"])['color'], paddingTop: 10, paddingBottom: 10, borderTopWidth: 1 }}>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Descriptions
                                            size="small"
                                            column={2}
                                            style={{
                                                marginBottom: 0
                                            }}>
                                            <Descriptions.Item label={t("crops.crop")} span={1}>
                                                <b>{this.state.projectInfo["cropName"]}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={t("projects.project_type")} >{this.state.projectInfo["projectType"]}</Descriptions.Item>
                                            {/* <Descriptions.Item label={t("general.status")}><Badge
                                                className="site-badge-count-109"
                                                count={capitalize(this.state.projectInfo["status"])}
                                                style={{
                                                    backgroundColor: this.getColorToneForStatus(this.state.projectInfo["status"])
                                                }} />
                                            </Descriptions.Item> */}
                                            <Descriptions.Item label={t("project_details.start_date")} >{this.state.projectInfo["startYear"]}</Descriptions.Item>
                                            <Descriptions.Item label={t("project_details.end_date")} >{this.state.projectInfo["endYear"]}</Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                    <Col span={12} >
                                        <Row gutter={[16, 16]} justify="end" title='Gesamtkosten'>
                                            <Col>
                                                <ProjectCharts projectDetails={this.state.projectDetails} />
                                            </Col><Col>
                                                <TinyAreaChart data={getProjectActuals(this.state.projectDetails)} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>


                            <Card bordered={true} loading={this.state.isLoading} bodyStyle={{ paddingLeft: '8px', paddingRight: '8px' }}>
                                {this.state.projectDetails.length > 0 ?
                                    <Tabs defaultActiveKey={this.state.activeYear ? this.state.activeYear.toString() : this.state.defaultActiveYear.toString()} tabPosition="left" onChange={this.onChange} tabBarStyle={{ alignContent: "flex-start", width: '100px' }}>
                                        {this.state.years.map(
                                            item => (
                                                <TabPane tab={`${item}`} key={item} >
                                                    <DetailContainer
                                                        activeTab={this.state.activeTab}
                                                        year={item}
                                                        projectInfo={this.state.projectInfo}
                                                        projectDetails={this.state.projectDetails}
                                                        accountingItems={this.state.accountingItems}
                                                        examinationItems={this.state.examinationItems}
                                                        lineSelections={this.state.lineSelections}
                                                        breedingMethods={this.state.breedingMethods}
                                                        reloadProject={this.reloadProject}
                                                        currentUser={currentUser}
                                                    />
                                                </TabPane>
                                            )
                                        )}
                                        {
                                            (currentUser.projectYear.currentPhase === 1 ||
                                                currentUser.projectYear.currentPhase === 3 ||
                                                currentUser.accessOverride?.planWrite) &&
                                                this.state.projectInfo.breederId === currentUser.breederInfo.id &&
                                                currentUser.projectYear.projectYear !== this.state.defaultActiveYear ?
                                                <TabPane tab={
                                                    <Popconfirm
                                                        title={"Sind Sie sicher, dass Sie ein neues Projektjahr erstellen für " + this.state.projectInfo.projectNumber + "?"}
                                                        onConfirm={this.addProjectYear}
                                                        onCancel={this.cancelPop}
                                                    >
                                                        <Space>
                                                            <Button type="primary" shape="round" icon={<PlusOutlined />} size={'default'} />
                                                        </Space>
                                                    </Popconfirm>
                                                } key={"sss"}>
                                                </TabPane>
                                                :
                                                ""
                                        }

                                    </Tabs> :
                                    (currentUser.projectYear.currentPhase == 1 || currentUser.accessOverride?.planWrite) && this.state.projectInfo.breederId == currentUser.breederInfo.id ?
                                        <Popconfirm
                                            title={"Sind Sie sicher, dass Sie ein neues Projektjahr erstellen für " + this.state.projectInfo.projectNumber + "?"}
                                            onConfirm={this.addProjectYear}
                                            onCancel={this.cancelPop}
                                            okText="Yes"
                                            cancelText="No">
                                            <Button icon={<PlusOutlined />} style={{ alignItems: 'baseline' }} />
                                        </Popconfirm> : ""
                                }

                            </Card>
                        </div>
                    )
                    : <LoadingIndicator />}

                <div></div>
            </div>
        );
    }
}
export default withTranslation()(withRouter(ProjectDetail));
