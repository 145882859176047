import React from 'react'
import { Table, Button, Menu, Dropdown, Icon, Space, Spin, Card, Row, Col, Typography, Divider } from "antd";
import { getBreeders, getContractDetails, getContractSummary } from '../../util/APIUtils';
import AccountingSummary from './AccountingSummary';
import ExaminationSummary from './ExaminationSummary';
import PickBreeder from '../../farmprofile/locations/PickBreeder';
import BreederSelect from '../../common/BreederSelect';
import YearSelect from '../../common/YearSelect';
import { formatCurrency } from '../../util/Helpers';
import FgSummary from './FgSummary';

import "../../finance/Finance.css"

const { Title } = Typography;

function CalculationContainer(props) {
    const [loading, setLoading] = React.useState(false);
    const [nestedData, setNestedData] = React.useState({});
    const [isLoading, setIsLoading] = React.useState({});
    const [breeders, setBreeders] = React.useState([]);
    const [pdm, setPdm] = React.useState([]);
    const [summary, setSummary] = React.useState([]);
    const [breederId, setBreederId] = React.useState(props.breederId);
    const [year, setYear] = React.useState(null);

    const fetchPdm = () => {
        let promise;
        promise = getContractDetails(breederId, year);
        if (!promise) {
            return;
        }
        promise.then(response => {
            console.log(response)
            setPdm(response);
            fetchSummary()
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }

    const fetchSummary = () => {
        let promise;
        promise = getContractSummary(breederId, year);
        if (!promise) {
            return;
        }
        promise.then(response => {
            console.log(response)
            setSummary(response);
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }


    const expandedRowRender = record => {
        console.log(record)
        return (<div>
            {record.itemName == "FG" ? <FgSummary fgBudgetId={record.itemId}/> :
            <div>
            <AccountingSummary projectDetailId={record.itemId} projectNumber={record.itemName} />
            <ExaminationSummary projectDetailId={record.itemId} projectNumber={record.itemName}/>
            </div>
        }

            {/* <ExaminationSummary projectDetailId={record.projectDetailId} projectNumber={record.projectNumber}/> */}
        </div>
            // <Table
            //     loading={isLoading[record.id] && !data}
            //     columns={columns}
            //     dataSource={nestedData[record.id]}
            //     pagination={false}
            // />
        );
    };

    const columns = [
        { title: "No#", dataIndex: "itemName", key: "itemName", render: (text) =>  <b>{text}</b>},
        { title: "Titel", dataIndex: "title", key: "title" },
        { title: "Betrag", dataIndex: "amount", key: "amount", align: 'right', render: ((amount) => <b>{formatCurrency(amount)}</b>) },
        { title: "Betrag FG", dataIndex: "fgAmount", key: "fgAmount", align: 'right', render: ((fgAmount) => <b>{formatCurrency(fgAmount)}</b>) },
        { title: "Auszahlbetrag", dataIndex: "payoutAmount", key: "payoutAmount", align: 'right', render: ((payoutAmount) => <b>{formatCurrency(payoutAmount)}</b>) },
        { title: "Abgerechnet", dataIndex: "actualAmount", key: "actualAmount", align: 'right', render: ((actualAmount) => <b>{formatCurrency(actualAmount)}</b>) },
        { title: "Eigenanteil", dataIndex: "breederShare", key: "breederShare", align: 'right', render: ((breederShare) => <b>{formatCurrency(breederShare)}</b>) },
    ];

    const summaryColumns = [
        { title: "Typ", dataIndex: "projectType", key: "projectType", render: (text) =>  <b>{text}</b>},
        { title: "Anz", dataIndex: "itemCount", key: "itemCount", align: 'right' , render: (text) =>  <b>{text}</b>},
        { title: "Betrag", dataIndex: "amount", key: "amount", align: 'right', render: ((amount) => <b>{formatCurrency(amount)}</b>) },
        { title: "Auszahlbetrag", dataIndex: "payoutAmount", key: "payoutAmount", align: 'right', render: ((payoutAmount) => <b>{formatCurrency(payoutAmount)}</b>) },
        { title: "Abgerechnet", dataIndex: "actualAmount", key: "actualAmount", align: 'right', render: ((actualAmount) => <b>{formatCurrency(actualAmount)}</b>) },
        { title: "Eigenanteil", dataIndex: "breederShare", key: "breederShare", align: 'right', render: ((breederShare) => <b>{formatCurrency(breederShare)}</b>) },
    ];

    const calculateSum = (array, property) => {
        const total = array.reduce((accumulator, object) => {
          return accumulator + object[property];
        }, 0);
      
        return total;
      }

    const summaryData = [
        {
          projectType: "Gesamt",
          itemCount: calculateSum(summary, 'itemCount'),
          amount: calculateSum(summary, 'amount'),
          payoutAmount: calculateSum(summary, 'payoutAmount'),
          actualAmount: calculateSum(summary, 'actualAmount'),
          breederShare: calculateSum(summary, 'breederShare'),
        }
      ];

    const handleExpand = (expanded, record) => {
        // setIsLoading({
        //     [record.id]: true
        // });

        // setTimeout(() => {
        //     setNestedData(state => ({
        //         ...state,
        //         [record.key]: [
        //             {
        //                 id: record.id,
        //                 key: record.id,
        //                 name: "Screem",
        //                 platform: "iOS",
        //                 version: "10.3.4.5654",
        //                 upgradeNum: 500,
        //                 creator: "Jack",
        //                 createdAt: "2014-12-24 23:12:00"
        //             }
        //         ]
        //     }));
        //     setIsLoading({
        //         [record.id]: false
        //     });
        // }, 1000);
    };

    return (
        <div>
        <Card><Row gutter={16}><Col span={6}>

            <BreederSelect
                setItem={setBreederId}
                selectedItem={breederId} />
        </Col><Col span={3}>
                <YearSelect
                    setItem={setYear}
                    selectedItem={year} />
            </Col><Col span={2}>
                <Button type='primary' disabled={!(year && breederId)} onClick={fetchPdm}>Suche</Button>
            </Col>
        </Row>
        <Divider></Divider>
            {pdm.length > 0 &&
                <Table
                bordered
                    size='small'
                    rowKey="itemId"
                    loading={loading}
                    className="components-table-demo-nested"
                    columns={columns}
                    expandedRowRender={expandedRowRender}
                    onExpand={handleExpand}
                    dataSource={pdm}
                    pagination={false}
                />}


        </Card>
        <Card>
        {summary.length > 0 &&
                <Table
                bordered
                    size='small'
                    rowKey="itemId"
                    loading={loading}
                    columns={summaryColumns}
                    dataSource={summary.concat(summaryData)}
                    pagination={false}
                    rowClassName={(record, index) => {
                        if (record.projectType == 'Gesamt') {
                            return 'table-row-dark'
                        } else if (record.type == 'subtotal_breeder') {
                            return 'table-row-dark'
                        }
                    }}
                  
                />}
        </Card>
        </div>
    );
}

export default CalculationContainer