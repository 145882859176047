import React from 'react'
import { useTranslation, Trans } from 'react-i18next';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { getCropDetail, getAllCrops, updateCrop } from '../util/APIUtils';
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';

import {
    Button,
    Card,
    Descriptions,
    Row,
    Col,
    Typography,
    PageHeader,
    Space,
    Input,
    InputNumber,
    Select

} from 'antd';
import openNotification from '../common/openNotification';

function CropDetailFn(props) {
    const [loading, setLoading] = React.useState(false);
    const [editing, setEditing] = React.useState(false);
    const [cropGroups, setCropGroups] = React.useState([]);
    const [state, setState] = React.useState(null)
    const { t } = useTranslation();
    const { id } = props.match.params

    const getCropDetailsById = React.useCallback(async () => {
        console.log('Loading Crop detail')
        let promise;
        promise = getCropDetail(id);

        if (!promise) {
            return;
        }
        setLoading(true);
        promise.then(response => {
            console.log(response)
            setState(response)
        }).catch(error => {
            setLoading(false);
        });
    }, [])

    const loadCropGroups = React.useCallback(async () => {
        let promise;
        promise = getAllCrops();

        if (!promise) {
            return;
        }

        setLoading(true);
        promise.then(response => {
            setCropGroups(
                response.filter(x => {
                    return x.isGroup == 1
                }))
            setLoading(false)

        }).catch(error => {
            setLoading(false)
        });
    }, [])

    React.useEffect(() => {
        getCropDetailsById();
        loadCropGroups();
    }, [getCropDetailsById, loadCropGroups]);

    
    const handleChangeOther = name => value => {
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
        console.log(state)
    };

    const handleChangeText = name => e => {
        setState(prevState => ({
            ...prevState,
            [name]: e.target.value
        }));
        console.log(state)
    };

    const saveDetail = () => {
        let promise;
        promise = updateCrop(state);

        if (!promise) {
            return;
        }

        setLoading(true);
        promise.then(response => {
            setState(response)
            setLoading(false)
            setEditing(false)
            openNotification("error", "Aktualisierung erfolgreich" )

        }).catch(error => {
            setLoading(false)
            setEditing(false)
    openNotification("error", "Update fehlgeschlagen" )
        });
    }



    const handleBack = e => {
        props
            .history
            .goBack();
    };

    let officeUser = props.currentUser.roles.find(x => x.name === 'ROLE_OFFICE');
    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={() => handleBack()}
                extra={[officeUser ?
                    <Space>
                        {!editing ?
                            <Button size='small' key="edit1" type="primary" onClick={() => setEditing(true)}>{t("general.edit")}</Button> :
                            <Space><Button key="save1" size='small' type="primary" onClick={saveDetail} >{t("general.save")}</Button>
                                <Button key="cancel1" size='small' onClick={() => setEditing(false)}>{t("general.cancel")}</Button>
                            </Space>
                        }
                        <Button key="34" size='small' type="primary" danger>{t("general.delete")}</Button>
                    </Space> : ""]
                }
                title={t("crops.crop_details")}
                subTitle={state ? state.shortName : ""} />

            <Card bordered={true} loading={loading} headStyle={{ padding: "0" }}>
                {state != null  &&
                <Descriptions
                    size="small"
                    column={2}
                    bordered="true"
                    style={{
                        marginBottom: 20
                    }}>
                    <Descriptions.Item label={t("crops.crop_name")} >
                        {editing ? <Input name='name' defaultValue={state.name} onChange={handleChangeText("name")} />
                            : <b>{state.name}</b>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label={t("crops.crop_group")}>
                        {editing ? <Select style={{ width: "250px" }} defaultValue={state.groupId} onChange={handleChangeOther("groupId")}>
                            {cropGroups.map(fbb =>
                                <option key={fbb.id} value={fbb.id}>{fbb.name}</option>
                            )}
                        </Select> :
                            state.groupName}
                    </Descriptions.Item>
                    {editing ? "" : <>
                        <Descriptions.Item label={t("crops.has_fg")}>
                            {state.fgId === 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="red" />}
                        </Descriptions.Item>

                        <Descriptions.Item label={t("fachgroup.fg_name")}><Link
                            to={{
                                pathname: `/fachgroup/detail/${state.fachGroupId}`,
                                state: {
                                    id: state.fachGroupId
                                }
                            }}><b>{state.fachGroupName}</b></Link></Descriptions.Item>

                        <Descriptions.Item label={t("fachgroup.fg_leader_short")}>{state.fgLeaderShortName}</Descriptions.Item>
                        <Descriptions.Item label={t("fachgroup.fg_leader_name")}>{state.fgLeaderName}</Descriptions.Item>
                    </>
                    }
                    <Descriptions.Item label={t("crops.harvest_interval")}>
                        {editing ? <InputNumber defaultValue={state.anniversary} onChange={handleChangeText("anniversary")} /> :
                            state.anniversary}</Descriptions.Item>
                    <Descriptions.Item label={t("crops.family")}>
                        {editing ? <Input defaultValue={state.family} onChange={handleChangeText("family")} /> :
                            state.family}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("crops.latin_name")}>
                        {editing ? <Input defaultValue={state.latinName} onChange={handleChangeText("latinName")} /> :
                            state.latinName}
                    </Descriptions.Item>



                </Descriptions>
}
                {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={24}>
                        <Card bordered={true}  >
                           
                        </Card>
                    </Col>
                   
                </Row> */}
            </Card>

        </div>
    )

}

export default CropDetailFn