
import { useQuery } from 'react-query';
import fileDownload from 'js-file-download';
import { API_BASE_URL, POLL_LIST_SIZE, ACCESS_TOKEN } from '../constants';

function useReactQuery(queryFn, queryKey, method, url, payload, options) {
    const token = localStorage.getItem(ACCESS_TOKEN);

    if (!queryKey) {
        queryKey = `${method}-${url}`;
    }

    return useQuery(
        queryKey,
        () => {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };

            let fetchOptions = {
                method: method,
                headers: headers,
            };

            if (method === 'POST' || method === 'PUT') {
                fetchOptions = {
                    ...fetchOptions,
                    body: JSON.stringify(payload),
                };
            }

            return fetch(`${API_BASE_URL}/${url}`, fetchOptions)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error(`Network response was not ok (${res.status})`);
                    }
                    return res.json();
                })
                .then((res) => queryFn(res));
        },
        options
    );
}


const request = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    });

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN));
    }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response => {
            if (response.status === 401) {
                return Promise.reject({ message: "Unautorisierter Zugriff" });
            }

            return response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            });
        })
        .catch(error => {
            // Handle other errors
            console.error("An error occurred:", error);
            return Promise.reject(error);
        });
};


const requestFile = (options) => {
    fetch(options.url)
        .then(res => res.blob()) // Gets the response and returns it as a blob
        .then(blob => {
            fileDownload(blob, options.fileName);
        }
        )
}

export function getAllPolls(page, size) {
    page = page || 0;
    size = size || POLL_LIST_SIZE;

    return request({
        url: API_BASE_URL + "/polls?page=" + page + "&size=" + size,
        method: 'GET'
    });
}

// Workflow
export function getUserTasks() {
    return request({
        url: API_BASE_URL + "/user/tasks/",
        method: 'GET'
    });
}

export function submitProject(projectDetailId, description, review) {
    let commentObj = { "comment": description }
    return request({
        url: API_BASE_URL + "/user/submitproject/" + projectDetailId.toString() + "/" + review,
        method: 'POST',
        body: JSON.stringify(commentObj)
    });
}

export function getGroupTasks(fgId) {
    return request({
        url: API_BASE_URL + "/tasks/group/" + fgId.toString(),
        method: 'GET'
    });
}

export function getVariableTasks(key, val) {
    return request({
        url: API_BASE_URL + "/tasks/variable/" + key + "/" + val,
        method: 'GET'
    });
}

export function getProcessTasks(processId) {
    return request({
        url: API_BASE_URL + "/processinfo/" + processId,
        method: 'GET'
    });
}

export function approveProjectFg(projectDetailId, val, comment, amount, review) {
    let commentObj = { "comment": comment, "approvedAmount": amount }
    return request({
        url: API_BASE_URL + "/fg/approve/tasks/" + projectDetailId.toString() + "/" + val.toString() + "/" + review.toString(),
        method: 'POST',
        body: JSON.stringify(commentObj)
    });
}

export function approveProjectBoard(projectDetailId, val, comment, amount) {
    let commentObj = { "comment": comment, "approvedAmount": amount }
    return request({
        url: API_BASE_URL + "/board/approve/tasks/" + projectDetailId.toString() + "/" + val.toString(),
        method: 'POST',
        body: JSON.stringify(commentObj)
    });
}

export function approveFunding(projectDetailId, val, comment, amount) {
    let commentObj = { "comment": comment, "fgApprovedAmount": amount }
    return request({
        url: API_BASE_URL + "/funding/approve/" + projectDetailId.toString() + "/" + val.toString(),
        method: 'POST',
        body: JSON.stringify(commentObj)
    });
}

export function submitReport(projectDetailId, val, comment, amount) {
    let commentObj = { "comment": comment }
    return request({
        url: API_BASE_URL + "/funding/approve/" + projectDetailId.toString() + "/" + val.toString(),
        method: 'POST',
        body: JSON.stringify(commentObj)
    });
}

export function getProcessComments(processId) {
    return request({
        url: API_BASE_URL + "/process/comments/" + processId,
        method: 'GET'
    });
}

export function addProcessComment(processId, taskId, comment) {
    let commentObj = { "comment": comment }
    return request({
        url: API_BASE_URL + "/process/comment/add/" + processId + "/" + taskId,
        method: 'POST',
        body: JSON.stringify(commentObj)
    });
}

export function getMySubmissions() {
    return request({
        url: API_BASE_URL + "/tasks/mysubmissions/",
        method: 'GET'
    });
}


//End Workflow

// Funding
export function getCurrentYearProjects(year) {
    return request({
        url: API_BASE_URL + "/project_funders/" + year.toString(),
        method: 'GET'
    });
}

export function getPdmForYear(year) {
    let url = "pdm/year/" + year.toString()
    let method = 'GET'
    let payload = null
    return (queryFn, options) => useReactQuery(queryFn, null, method, url, payload, options);
}

export function saveFunder(projectDetailId, funderId) {
    return request({
        url: API_BASE_URL + "/project_funders/add/" + projectDetailId.toString() + "/" + funderId.toString(),
        method: 'GET'
    });
}

// End Funding

// Breeders
export function getAllBreeders(page, size) {
    page = page || 0;
    size = size || POLL_LIST_SIZE;

    return request({
        url: API_BASE_URL + "/breeders/all",
        method: 'GET'
    });
}

export function getAllBreedersWithFarmProfile() {
    return request({
        url: API_BASE_URL + "/breederswithprofile/all",
        method: 'GET'
    });
}

export function getBreederInfo(id, profile) {
    return request({
        url: API_BASE_URL + "/breeders/" + id.toString() + "/" + profile.toString(),
        method: 'GET'
    });
}

export function createBreeder(breederData) {
    return request({
        url: API_BASE_URL + "/breeder/create",
        method: 'POST',
        body: JSON.stringify(breederData)
    });
}

export function editBreeder(breederData) {
    return request({
        url: API_BASE_URL + "/breeder/edit",
        method: 'POST',
        body: JSON.stringify(breederData)
    });
}

export function getBreeders() {

    return request({
        url: API_BASE_URL + "/breeders",
        method: 'GET'
    });
}

// Projects

export function createProjectYear(id) {
    return request({
        url: API_BASE_URL + "/projects/year/create/" + id.toString(),
        method: 'GET',
    });
}


export function deleteProject(id) {
    return request({
        url: API_BASE_URL + "/project/delete/" + id.toString(),
        method: 'GET',
    });
}


export function deleteProjectYear(id) {
    return request({
        url: API_BASE_URL + "/project/year/delete/" + id.toString(),
        method: 'GET',
    });
}

export function saveProjectDetail(projectDetailInfo) {
    return request({
        url: API_BASE_URL + "/projectdetail/add",
        method: 'POST',
        body: JSON.stringify(projectDetailInfo)
    });
}


export function createProject(project) {
    return request({
        url: API_BASE_URL + "/project/add",
        method: 'POST',
        body: JSON.stringify(project)
    });
}

export function updateParentProject(id, projectNumber) {
    return request({
        url: API_BASE_URL + "/projects/updateparent/" + id.toString() + "/" + projectNumber.toString(),
        method: 'GET'
    });
}

export function getAllProjects() {
    return request({
        url: API_BASE_URL + "/projects/all/",
        method: 'GET'
    });
}

export function getAllProjectTypes() {
    return request({
        url: API_BASE_URL + "/projects/types/",
        method: 'GET'
    });
}

export function getAllProjectsByUser(id) {
    return request({
        url: API_BASE_URL + "/projects/user/" + id.toString(),
        method: 'GET'
    });
}


export function getAProjectsByBreeder(id) {
    return request({
        url: API_BASE_URL + "/projects/breeder/" + id.toString(),
        method: 'GET'
    });
}

export function getProjectYears(id) {
    return request({
        url: API_BASE_URL + "/projectdetails/years/" + id.toString(),
        method: 'GET'
    });
}

export function getProjectDetails(id) {
    return request({
        url: API_BASE_URL + "/projectdetails/" + id.toString(),
        method: 'GET'
    });
}

export function getProject(id) {
    return request({
        url: API_BASE_URL + "/projectdetails/" + id.toString(),
        method: 'GET'
    });
}

export function getBreedingMethods(type) {
    return request({
        url: API_BASE_URL + "/breedingmethods/" + type.toString(),
        method: 'GET'
    });
}

export function getLineSelections(id) {
    return request({
        url: API_BASE_URL + "/lineselections/" + id.toString(),
        method: 'GET'
    });
}

export function createLineSelection(lineSelection) {
    return request({
        url: API_BASE_URL + "/lineselections/add",
        method: 'POST',
        body: JSON.stringify(lineSelection)
    });
}

export function getFilteredProjects(filter) {
    return request({
        url: API_BASE_URL + "/projects/filter",
        method: 'POST',
        body: JSON.stringify(filter)
    });
}


export function updatePayoutInfo(id, payout, comments) {
    return request({
        url: API_BASE_URL + "/projectdetails/updatepayout/" + id.toString() + "/" + payout.toString() + "/" + comments,
        method: 'GET'
    });
}

export function getPdmForBreederYear(breederId, year) {
    return request({
        url: API_BASE_URL + "/pdm/breeder_summary/" + breederId.toString() + "/" + year.toString(),
        method: 'GET'
    });
}

export function getContractDetails(breederId, year) {
    return request({
        url: API_BASE_URL + "/pdm/contract_detail/" + breederId.toString() + "/" + year.toString(),
        method: 'GET'
    });
}

export function getContractSummary(breederId, year) {
    return request({
        url: API_BASE_URL + "/pdm/contract_summary/" + breederId.toString() + "/" + year.toString(),
        method: 'GET'
    });
}

// export function getProjectAmountSummary(projectDetailId) {
//     return request({
//         url: API_BASE_URL + "/calculations/projectamountsummary/" + projectDetailId.toString(),
//         method: 'GET'
//     });
// }

export function getProjectAmountSummary(projectDetailId) {
    let url = "calculations/projectamountsummary/" + projectDetailId.toString()
    let method = 'GET'
    let payload = null
    return (queryFn, options) => useReactQuery(queryFn, null, method, url, payload, options);
}

// Projects

// VA Extern

export function getVaExterns(year) {
    return request({
        url: API_BASE_URL + "/vaextern/" + year.toString(),
        method: 'GET'
    });
}

export function getVaExternReportXls(year) {
    return API_BASE_URL + "/vaexternreport/" + year.toString()

}

// export function getVaExterns(year) {
//     console.log("API")
//     let url = "vaextern/" + year.toString()
//     let method = 'GET'
//     let payload = null

//     return (queryFn, options) => useReactQuery(queryFn, null, method, url, payload, {enabled: false});
// }


export function updateVaExtern(vaExtern) {
    return request({
        url: API_BASE_URL + "/vaextern/update",
        method: 'POST',
        body: JSON.stringify(vaExtern)
    });
}


// VA Extern


export function getFgCrops() {
    return request({
        url: API_BASE_URL + "/fg_budgets/crops",
        method: 'GET'
    });
}

export function getProjectsByType(type) {
    return request({
        url: API_BASE_URL + "/projects/type/" + type,
        method: 'GET'
    });
}

export function getFgCropDetails(id) {
    return request({
        url: API_BASE_URL + "/fg_budgets/breeder/" + id.toString(),
        method: 'GET'
    });
}

export function getFgCropDetailsForFgAndBreeder(cropId, breederId) {
    return request({
        url: API_BASE_URL + "/fg_budgets/crop/breeder/" + cropId.toString() + "/" + breederId.toString(),
        method: 'GET'
    });
}


export function createFgBudgetYear() {
    return request({
        url: API_BASE_URL + "/fg_budgets/create/",
        method: 'GET',
    });
}

export function getFgBudgetCalculations(id) {
    return request({
        url: API_BASE_URL + "/fg_budgets/calculations/" + id.toString(),
        method: 'GET',
    });
}

export function deleteFgCalculationById(id) {
    return request({
        url: API_BASE_URL + "/fg_budgets/calculations/delete/" + id.toString(),
        method: 'GET',
    });
}

export function updateFgCalculationItem(fgCalculation) {
    return request({
        url: API_BASE_URL + "/fg_budgets/calculation/update",
        method: 'POST',
        body: JSON.stringify(fgCalculation)
    });
}


export function updateFgApproval(workFlowResponse) {
    return request({
        url: API_BASE_URL + "/fg_budgets/approval/update",
        method: 'POST',
        body: JSON.stringify(workFlowResponse)
    });
}



export function createFgBudgetYearCrop(cropId) {
    return request({
        url: API_BASE_URL + "/fg_budget/create/" + cropId.toString(),
        method: 'GET',
    });
}

export function getFgBudgets(year) {
    return request({
        url: API_BASE_URL + "/fg_budgets/year/" + year.toString(),
        method: 'GET',
    });
}

export function getFgBudgetsBreeder(year) {
    return request({
        url: API_BASE_URL + "/fg_budgets/breeder/year/" + year.toString(),
        method: 'GET',
    });
}

export function getFgBudgetsforFg(id) {
    return request({
        url: API_BASE_URL + "/fg_budgets/fg/" + id.toString(),
        method: 'GET',
    });
}

export function getFgAccountingItems() {
    return request({
        url: API_BASE_URL + "/fg_budgets/accountingitems/",
        method: 'GET',
    });
}

export function getFgAccountSummary(id) {
    return request({
        url: API_BASE_URL + "/fg_budgets/accountsummary/" + id.toString(),
        method: 'GET',
    });
}

export function addFgAccountingItems(fgBudgetInfo) {
    return request({
        url: API_BASE_URL + "/fg_budgets/calculation/update",
        method: 'POST',
        body: JSON.stringify(fgBudgetInfo)
    });
}

export function updateFgBudget(fgBudget) {
    return request({
        url: API_BASE_URL + "/fg_budgets/update",
        method: 'POST',
        body: JSON.stringify(fgBudget)
    });
}

export function deleteFgBudget(fgBudget) {
    return request({
        url: API_BASE_URL + "/fg_budgets/delete",
        method: 'POST',
        body: JSON.stringify(fgBudget)
    });
}


// Reports
export function getProjectReport(id) {
    return request({
        url: API_BASE_URL + "/project_report/" + id.toString(),
        method: 'GET'
    });
}

export function createReportYear(id) {
    return request({
        url: API_BASE_URL + "/project_reports/create/" + id.toString(),
        method: 'GET'
    });
}

export function saveProjectReport(projectReportInfo) {
    return request({
        url: API_BASE_URL + "/project_reports/save",
        method: 'POST',
        body: JSON.stringify(projectReportInfo)
    });
}

export function deleteReportYear(id) {
    return request({
        url: API_BASE_URL + "/project_reports/delete/" + id.toString(),
        method: 'GET'
    });
}

// End Reports

//Funders
export function getFunders() {
    return request({
        url: API_BASE_URL + "/funders",
        method: 'GET'
    });
}

// End funder

// Lines
export function getProjectDetailLines(projectDetailId) {
    return request({
        url: API_BASE_URL + "/lines/" + projectDetailId.toString(),
        method: 'GET'
    });
}

export function getLastYearLines(projectId, year) {
    return request({
        url: API_BASE_URL + "/lines/prevyear/" + projectId.toString() + "/" + year.toString(),
        method: 'GET'
    });
}

export function editLine(lineObj) {
    return request({
        url: API_BASE_URL + "/lines/edit/",
        method: 'POST',
        body: JSON.stringify(lineObj)
    });
}

export function copyLines(projectId, projectDetailId, projectReportId, lines) {
    return request({
        url: API_BASE_URL + "/lines/copy/" + projectId.toString() + "/" + projectDetailId.toString() + "/" + projectReportId.toString(),
        method: 'POST',
        body: JSON.stringify(lines)
    });
}

export function createLine(lineObj) {
    return request({
        url: API_BASE_URL + "/lines/create/",
        method: 'POST',
        body: JSON.stringify(lineObj)
    });
}

export function deleteLine(id) {
    return request({
        url: API_BASE_URL + "/lines/delete/" + id.toString(),
        method: 'GET'
    });
}

export function getBsagLines(year) {
    return request({
        url: API_BASE_URL + "/bsaginfo/" + year.toString(),
        method: 'GET'
    });
}

//End lines

// Calc

export function addAccountingItem(obj) {
    return request({
        url: API_BASE_URL + "/project/addaccountingitem/",
        method: 'POST',
        body: JSON.stringify(obj)
    });
}

export function updateAccountingItemActual(obj) {
    return request({
        url: API_BASE_URL + "/project/updateaccountingitemactual/",
        method: 'POST',
        body: JSON.stringify(obj)
    });
}

export function updateAccountingItem(obj) {
    return request({
        url: API_BASE_URL + "/project/updateaccountingitem/",
        method: 'POST',
        body: JSON.stringify(obj)
    });
}

export function updateExaminationItem(obj) {
    return request({
        url: API_BASE_URL + "/project/updateexaminationitem/",
        method: 'POST',
        body: JSON.stringify(obj)
    });
}


export function addExaminationItem(obj) {
    return request({
        url: API_BASE_URL + "/project/addexaminationitem/",
        method: 'POST',
        body: JSON.stringify(obj)
    });
}


export function updateExaminationItemActual(obj) {
    return request({
        url: API_BASE_URL + "/project/updateexaminationitemactual/",
        method: 'POST',
        body: JSON.stringify(obj)
    });
}

export function deleteAccountingItem(id) {
    return request({
        url: API_BASE_URL + "/project/deleteaccountingitem/" + id.toString(),
        method: 'GET'
    });
}

export function deleteExaminationItem(id) {
    return request({
        url: API_BASE_URL + "/project/deleteexaminationitem/" + id.toString(),
        method: 'GET'
    });
}

export function getProjectDetailForId(id) {
    return request({
        url: API_BASE_URL + "/project/detail/" + id.toString(),
        method: 'GET'
    });
}

export function getProjectDetailAccountingItems(id) {
    return request({
        url: API_BASE_URL + "/project/accountingitems/" + id.toString(),
        method: 'GET'
    });
}

export function getProjectDetailExaminationItems(id) {
    return request({
        url: API_BASE_URL + "/project/examinationitems/" + id.toString(),
        method: 'GET'
    });
}

export function addAccountingItems(projectDetailId, accItems) {
    return request({
        url: API_BASE_URL + "/project/addaccountingitems/" + projectDetailId.toString(),
        method: 'POST',
        body: JSON.stringify(accItems)
    });
}

export function addExaminationItems(projectDetailId, exmItems) {
    return request({
        url: API_BASE_URL + "/project/addexaminationitems/" + projectDetailId.toString(),
        method: 'POST',
        body: JSON.stringify(exmItems)
    });
}


export function getAccountingItems(type) {
    return request({
        url: API_BASE_URL + "/calculations/accountingitems/" + type.toString(),
        method: 'GET'
    });
}

export function getExaminationItems() {
    return request({
        url: API_BASE_URL + "/calculations/examinationitems",
        method: 'GET'
    });
}

// Farm Locations
export function getFarmLocations(id) {
    return request({
        url: API_BASE_URL + "/farmlocations/",
        method: 'GET'
    });
}

export function createFarmLocation(farmLocationData) {
    return request({
        url: API_BASE_URL + "/farmlocation/add",
        method: 'POST',
        body: JSON.stringify(farmLocationData)
    });
}

export function getFarmLocation(id) {
    return request({
        url: API_BASE_URL + "/farmlocation/" + id.toString(),
        method: 'GET'
    });
}

// Fach Groups
export function getAllFachgroups() {
    return request({
        url: API_BASE_URL + "/fachgroups",
        method: 'GET'
    });
}

export function getFachGroupDetail(id) {
    return request({
        url: API_BASE_URL + "/fach_group/" + id.toString(),
        method: 'GET'
    });
}

export function deleteFachGroup(id, force) {
    return request({
        url: API_BASE_URL + "/fach_group/delete/" + id.toString() + "/" + force.toString(),
        method: 'GET'
    });
}

export function createFg(fgInfo) {
    return request({
        url: API_BASE_URL + "/fach_group/add/",
        method: 'POST',
        body: JSON.stringify(fgInfo)
    });
}



export function updateFg(fgInfo) {
    return request({
        url: API_BASE_URL + "/fach_group/update/",
        method: 'POST',
        body: JSON.stringify(fgInfo)
    });
}

export function updateFgBreeders(fgId, fgBreeders) {
    return request({
        url: API_BASE_URL + "/fach_group/updatebreeders/" + fgId.toString(),
        method: 'POST',
        body: JSON.stringify(fgBreeders)
    });
}

export function updateFgCrops(fgId, fgCrops) {
    return request({
        url: API_BASE_URL + "/fach_group/updatecrops/" + fgId.toString(),
        method: 'POST',
        body: JSON.stringify(fgCrops)
    });
}

export function isFgLeader(cropId) {
    return request({
        url: API_BASE_URL + "/fach_group/leader/" + cropId.toString(),
        method: 'GET'
    });
}

export function isFgMember(cropId) {
    return request({
        url: API_BASE_URL + "/fach_group/member/" + cropId.toString(),
        method: 'GET'
    });
}

// Fach group

export function getFullUserProfile(id) {
    return request({
        url: API_BASE_URL + "/userprofile/" + id.toString(),
        method: 'GET'
    });
}

export function getRoles() {
    return request({
        url: API_BASE_URL + "/roles",
        method: 'GET'
    });
}

export function isBoardMember() {
    return request({
        url: API_BASE_URL + "/user/isboardmember",
        method: 'GET'
    });
}

export function isOfficeMember() {
    return request({
        url: API_BASE_URL + "/user/isofficemember",
        method: 'GET'
    });
}

export function getUserForEmail(email) {
    return request({
        url: API_BASE_URL + "/user/" + email,
        method: 'GET'
    });
}

export function updateUserRoles(roleObj) {
    return request({
        url: API_BASE_URL + "/user/roles/add",
        method: 'POST',
        body: JSON.stringify(roleObj)
    });
}

export function sendInviteEmail(id) {
    return request({
        url: API_BASE_URL + "/email/" + id.toString(),
        method: 'GET'
    });
}

export function updateFinancialInfo(finObj) {
    return request({
        url: API_BASE_URL + "/financialinfo/update",
        method: 'POST',
        body: JSON.stringify(finObj)
    });
}

export function updateUserInfo(userObj) {
    return request({
        url: API_BASE_URL + "/user/update",
        method: 'POST',
        body: JSON.stringify(userObj)
    });
}

export function resetPasswordForgot(userObj) {
    return request({
        url: API_BASE_URL + "/forgot-password/update-password",
        method: 'POST',
        body: JSON.stringify(userObj)
    });
}
export function verifyPasswordToken(token) {
    return request({
        url: API_BASE_URL + "/verify-reset-token/" + token,
        method: 'GET'
    });
}

export function forgotPassword(email) {
    return request({
        url: API_BASE_URL + "/forgot-password/" + email,
        method: 'GET'
    });
}

export function changePassword(passwordObj) {
    return request({
        url: API_BASE_URL + "/change-password",
        method: 'POST',
        body: JSON.stringify(passwordObj)
    });
}

// Satz
export function getAllSatzPlan(projectDetailId) {
    return request({
        url: API_BASE_URL + "/satz/plan/list/" + projectDetailId.toString(),
        method: 'GET'
    });
}

export function getAllSatzReport(projectDetailId) {
    return request({
        url: API_BASE_URL + "/satz/report/list/" + projectDetailId.toString(),
        method: 'GET'
    });
}

export function createSatz(satzObj) {
    return request({
        url: API_BASE_URL + "/satz/create",
        method: 'POST',
        body: JSON.stringify(satzObj)
    });
}

export function updateSatz(satzObj, type) {
    return request({
        url: API_BASE_URL + "/satz/update/" + type,
        method: 'POST',
        body: JSON.stringify(satzObj)
    });
}

export function copySatz(projectDetailId) {
    return request({
        url: API_BASE_URL + "/satz/copy/" + projectDetailId.toString(),
        method: 'GET'
    });
}

export function deleteSatz(satzId) {
    return request({
        url: API_BASE_URL + "/satz/delete/" + satzId.toString(),
        method: 'GET'
    });
}



// End Satz




//Crops
export function getAllCrops() {
    return request({
        url: API_BASE_URL + "/crops",
        method: 'GET'
    });
}

export function getCropDetail(id) {
    return request({
        url: API_BASE_URL + "/crop/" + id.toString(),
        method: 'GET'
    });
}

export function createCrop(cropData) {
    return request({
        url: API_BASE_URL + "/crop/add",
        method: 'POST',
        body: JSON.stringify(cropData)
    });
}


export function updateCrop(cropData) {
    return request({
        url: API_BASE_URL + "/crop/update",
        method: 'POST',
        body: JSON.stringify(cropData)
    });
}

// Farm Profile
export function createFarmProfile(farmProfileData) {
    return request({
        url: API_BASE_URL + "/farmprofile/create",
        method: 'POST',
        body: JSON.stringify(farmProfileData)
    });
}

export function updateFarmProfile(farmProfileData) {
    return request({
        url: API_BASE_URL + "/farmprofile/update",
        method: 'POST',
        body: JSON.stringify(farmProfileData)
    });
}
export function getAllFarmProfiles() {
    return request({
        url: API_BASE_URL + "/farmprofiles/",
        method: 'GET'
    });
}

export function getFarmLocationsForId(id) {
    return request({
        url: API_BASE_URL + "/farmprofile/locations/" + id.toString(),
        method: 'GET'
    });
}

export function deleteFarmProfileById(id) {
    return request({
        url: API_BASE_URL + "/farmprofile/delete/" + id.toString(),
        method: 'GET'
    });
}


export function updateFarmBreeder(id, farmBreederList) {
    return request({
        url: API_BASE_URL + "/farmbreeder/update/" + id.toString(),
        method: 'POST',
        body: JSON.stringify(farmBreederList)
    });
}

export function getFarmProfileById(id) {
    return request({
        url: API_BASE_URL + "/farmprofile/" + id.toString(),
        method: 'GET'
    });
}


// Farm profile End

// Project years
export function getYears() {
    return request({
        url: API_BASE_URL + "/projectyears",
        method: 'GET'
    });
}

export function getCurrentProjectYear() {
    return request({
        url: API_BASE_URL + "/currentprojectyear",
        method: 'GET'
    });
}

export function getPreviousProjectYear() {
    return request({
        url: API_BASE_URL + "/previousprojectyear",
        method: 'GET'
    });
}


export function increment() {
    return request({
        url: API_BASE_URL + "/increment",
        method: 'GET'
    });
}

export function saveCurrentYearSettings(projectYear) {
    return request({
        url: API_BASE_URL + "/saveprojectyear",
        method: 'POST',
        body: JSON.stringify(projectYear)
    });
}

export function saveLastYearSettings(projectYear) {
    return request({
        url: API_BASE_URL + "/savelastprojectyear",
        method: 'POST',
        body: JSON.stringify(projectYear)
    });
}

// End Project years


// Finance
export function getBudgetOverViews(year) {
    return request({
        url: API_BASE_URL + "/budget_overview/" + year.toString(),
        method: 'GET'
    });
}

export function getBudgetOverViewSummary(year) {
    return request({
        url: API_BASE_URL + "/budget_summary/" + year.toString(),
        method: 'GET'
    });
}

// Breeder - Type - Crop
export function getBreederCropSummary(year) {
    return request({
        url: API_BASE_URL + "/project_crop_summary/" + year.toString(),
        method: 'GET'
    });
}

// Type - Crop
export function getProjectTypeCropSummary(year) {
    return request({
        url: API_BASE_URL + "/project_crop/" + year.toString(),
        method: 'GET'
    });
}

export function getBreederCropSummaryReportXls(year) {
    return API_BASE_URL + "/reports/breedercropreport/" + year.toString()

}


export function getBreederCropSummaryReportPdf(year) {
    return API_BASE_URL + "/reports/breedercropreportpdf/" + year.toString()

}


export function getZslReportLink(year, format) {
    return API_BASE_URL + "/reports/zsl/" + year.toString() + "/" + format.toString()
}

export function getZslActualReportLink(year) {
    return API_BASE_URL + "/reports/zsl-antrag/" + year.toString()
}

// End Finance

//Contract
export function getContractLink(year, breederId) {
    return API_BASE_URL + "/reports/contract/" + year.toString() + "/" + breederId.toString()
}

export function getContractActualLink(year, breederId) {
    return API_BASE_URL + "/reports/contract-actual/" + year.toString() + "/" + breederId.toString()
}

//End contract

// Payouts

// export function getAllPayouts(year) {
//     return request({
//         url: API_BASE_URL + "/payouts/" + year.toString(),
//         method: 'GET'
//     });
// }


export function getPayoutInstallmentReport(breederId, year, rate) {
    return API_BASE_URL + "/reports/payout/installment_invoice/" + breederId.toString() + "/" +  year.toString() + "/" + rate.toString();
       
}

export function getInstallmentSummaryLink(year, serial) {
    return API_BASE_URL + "/reports/payout/installment_summary/" + year.toString() + "/" + serial.toString()
}

export function getAllPayouts(year) {
    let url = "payouts/" + year.toString();
    let method = 'GET'
    let payload = null
    return (queryFn, options) => useReactQuery(queryFn, null, method, url, payload, options);
}

export function getPayoutsForBreederYear(breederId, year) {
    return request({
        url: API_BASE_URL + "/payout/" + breederId.toString() + "/" + year.toString(),
        method: 'GET'
    });
}

export function getPayoutsForBreeder(breederId) {
    return request({
        url: API_BASE_URL + "/payout/breeder/" + breederId.toString(),
        method: 'GET'
    });
}

export function updatePayout(payoutInfo) {
    return request({
        url: API_BASE_URL + "/payouts/update",
        method: 'POST',
        body: JSON.stringify(payoutInfo)
    });
}

export function editPayout(payoutInfo) {
    return request({
        url: API_BASE_URL + "/payout/edit",
        method: 'POST',
        body: JSON.stringify(payoutInfo)
    });
}

export function generatePayout(year, breederId) {
    return request({
        url: API_BASE_URL + "/payout/generate/" + year.toString() + "/" + breederId.toString(),
        method: 'GET'
    });
}

export function deletePayout(payoutId) {
    return request({
        url: API_BASE_URL + "/payout/delete/" + payoutId.toString(),
        method: 'GET'
    });
}


export function updateContractStatus(payoutId, status) {
    return request({
        url: API_BASE_URL + "/payout/updatecontract/" + payoutId.toString() + "/" + status,
        method: 'GET'
    });
}


export function refreshPayoutDetail(id) {
    return request({
        url: API_BASE_URL + "/payout/payoutrate/refresh/" + id.toString(),
        method: 'GET'
    });
}

export function updateSettledStatus(payoutId, status) {
    return request({
        url: API_BASE_URL + "/payout/lastyearsettled/" + payoutId.toString() + "/" + status,
        method: 'GET'
    });
}

export function updatePayoutDetail(payoutDetail) {
    return request({
        url: API_BASE_URL + "/payoutdetail/update",
        method: 'POST',
        body: JSON.stringify(payoutDetail)
    });
}

export function getInstallmentSummary(year,serial) {
    let url = "payout/installmentsummary/" + year + "/" + serial.toString()
    let method = 'GET'
    let payload = null
    return (queryFn, options) => useReactQuery(queryFn, null, method, url, payload, options);
}

// Payouts End

// Breeder crop forecast
export function getForecastForBreederCrop(breederId, cropId) {
    return request({
        url: API_BASE_URL + "/breeder_crop_forecast/" + breederId.toString() + "/" + cropId.toString(),
        method: 'GET'
    });
}

export function getCropsForBreeder(breederId) {
    return request({
        url: API_BASE_URL + "/breeder_crop_forecast/crops/" + breederId.toString(),
        method: 'GET'
    });
}

export function updateBreederCropForecast(breederCropForecastInfo) {
    return request({
        url: API_BASE_URL + "/breeder_crop_forecast/update/",
        method: 'POST',
        body: JSON.stringify(breederCropForecastInfo)
    });
}

// Breeder crop forecast End

// ZSL
export function getZslData(year) {
    return request({
        url: API_BASE_URL + "/zsl/" + year.toString(),
        method: 'GET'
    });
}

// ZSL end

export function getUserProfileByType(type) {
    return request({
        url: API_BASE_URL + "/userprofiles/" + type.toString(),
        method: 'GET'
    });
}

export function createUserProfile(userProfile) {
    return request({
        url: API_BASE_URL + "/userprofiles/add",
        method: 'POST',
        body: JSON.stringify(userProfile)
    });
}

export function createPoll(pollData) {
    return request({
        url: API_BASE_URL + "/polls",
        method: 'POST',
        body: JSON.stringify(pollData)
    });
}

export function castVote(voteData) {
    return request({
        url: API_BASE_URL + "/polls/" + voteData.pollId + "/votes",
        method: 'POST',
        body: JSON.stringify(voteData)
    });
}

export function login(loginRequest) {
    return request({
        url: API_BASE_URL + "/auth/signin",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}

export function signup(signupRequest) {
    return request({
        url: API_BASE_URL + "/auth/signup",
        method: 'POST',
        body: JSON.stringify(signupRequest)
    });
}

export function checkUsernameAvailability(username) {
    return request({
        url: API_BASE_URL + "/user/checkUsernameAvailability?username=" + username,
        method: 'GET'
    });
}

export function checkEmailAvailability(email) {
    return request({
        url: API_BASE_URL + "/user/checkEmailAvailability?email=" + email,
        method: 'GET'
    });
}

export function getSettings() {
    return request({
        url: API_BASE_URL + "/settings",
        method: 'GET'
    });
}

export function getCurrentUser() {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: API_BASE_URL + "/user/me",
        method: 'GET'
    });
}

export function getUserProfile(username) {
    return request({
        url: API_BASE_URL + "/users/" + username,
        method: 'GET'
    });
}

export function getUserCreatedPolls(username, page, size) {
    page = page || 0;
    size = size || POLL_LIST_SIZE;

    return request({
        url: API_BASE_URL + "/users/" + username + "/polls?page=" + page + "&size=" + size,
        method: 'GET'
    });
}

export function getUserVotedPolls(username, page, size) {
    page = page || 0;
    size = size || POLL_LIST_SIZE;

    return request({
        url: API_BASE_URL + "/users/" + username + "/votes?page=" + page + "&size=" + size,
        method: 'GET'
    });
}

//Project Resources

export function getFileListsForProjectId(projectId) {
    return request({
        url: API_BASE_URL + "/projects/media/" + projectId.toString(),
        method: 'GET'
    });
}

export function getMediaPath(year, projectId) {
    return request({
        url: API_BASE_URL + "/projects/media/path/" + year.toString() + "/" + projectId.toString(),
        method: 'GET'
    });
}

//Project Resources End

//Sorten Bio

export function getSortenBio(projectId) {
    return request({
        url: API_BASE_URL + "/sortenbio/" + projectId.toString(),
        method: 'GET'
    });
}

export function saveSortenBio(sortenBio) {
    return request({
        url: API_BASE_URL + "/sortenbio/add",
        method: 'POST',
        body: JSON.stringify(sortenBio)
    });
}

//Sorten Bio End

// PDF request
export function getSpecialReport(year, farmProfileId, format) {
    return requestFile({
        url: API_BASE_URL + "/reports/contractnew/" + year.toString() + "/" + farmProfileId.toString() + "/" + format,
        method: 'GET',
        fileName: "Standortprofil-auftrag." + format
    });
}

export function getBudgetValidationReport(year, breederId, format) {
    return requestFile({
        url: API_BASE_URL + "/reports/budget/validation/" + breederId.toString() + "/" + year.toString() + "/" + format,
        method: 'GET',
        fileName: "Auswertung-nach-Züchter." + format
    });
}

export function getZslSummary(year, format) {
    return requestFile({
        url: API_BASE_URL + "/reports/budget/zsl_summary/" + year.toString() + "/" + format,
        method: 'GET',
        fileName: "Saatgutfonds." + format
    });
}

//Upload file
export function uploadFile(formData) {
    return fetch(API_BASE_URL + '/media/file/upload', {
        method: 'POST',
        body: formData
    })
}

export function uploadForumFile(formData) {
    return fetch(API_BASE_URL + '/media/forum/file/upload', {
        method: 'POST',
        body: formData
    })
}

export function deleteFile(fileName) {
    let cropInfo = {
        name: fileName
    }
    return request({
        url: API_BASE_URL + "/media/file/delete/",
        method: 'POST',
        body: JSON.stringify(cropInfo)
    });
}

// Validation
export function getValidationResults(projectDetailId) {
    return request({
        url: API_BASE_URL + "/validations/projectdetail/" + projectDetailId.toString(),
        method: 'GET',
    });
}

export function getValidationResultsForProject(projectId) {
    return request({
        url: API_BASE_URL + "/validations/project/" + projectId.toString(),
        method: 'GET',
    });
}

export function getFgValidationResultsForBreeder(breederId) {
    return request({
        url: API_BASE_URL + "/validations/fg/" + breederId.toString(),
        method: 'GET',
    });
}
export function getValidationResultsForAllProjects(breederId) {
    return request({
        url: API_BASE_URL + "/validations/projects/all/" + breederId.toString(),
        method: 'GET',
    });
}


// End Validation

// Status
export function submitProjectStatus(projectId) {

    return request({
        url: API_BASE_URL + "/status/submit/project/" + projectId.toString(),
        method: 'GET',
    });

}

// End Status

// React Query

// FG Review list
export function getFgReviewProjectList(breederId) {
    let url = "fach_group/projects/" + breederId.toString()
    let method = 'GET'
    let payload = null
    return (queryFn, options) => useReactQuery(queryFn, null, method, url, payload, options);
}

export function getFgsForBreeder(breederId) {
    let url = "fach_groups/breeder/" + breederId.toString()
    let method = 'GET'
    let payload = null
    return (queryFn, options) => useReactQuery(queryFn, null, method, url, payload, options);
}

export function updateFgReview(fgReview) {
    return request({
        url: API_BASE_URL + '/review/fg',
        method: 'POST',
        body: JSON.stringify(fgReview)
    })
}

export function updateBoardReview(boardReview) {
    return request({
        url: API_BASE_URL + '/review/board',
        method: 'POST',
        body: JSON.stringify(boardReview)
    })
}

export function updateFgBoardReview(boardReview) {
    return request({
        url: API_BASE_URL + '/review/fg-board',
        method: 'POST',
        body: JSON.stringify(boardReview)
    })
}



//
// Comments
// export function getCommentsForItem(itemId) {
//     let url = "comments/item/" + itemId.toString()
//     let method = 'GET'
//     let payload = null
//     let qk = url + itemId.toString()
//     return (queryFn, options) => useReactQuery(queryFn, qk, method, url, payload, options);
//   }

export function getCommentsForItem(itemId) {
    return request({
        url: API_BASE_URL + "/comments/item/" + itemId.toString(),
        method: 'GET',
    });
}

export function postComment(comment) {
    return request({
        url: API_BASE_URL + "/comments/add",
        method: 'POST',
        body: JSON.stringify(comment)
    });
}

export function editComment(comment) {
    return request({
        url: API_BASE_URL + "/comments/edit",
        method: 'PUT',
        body: JSON.stringify(comment)
    });
}

export function deleteComment(id) {
    return request({
        url: API_BASE_URL + "/comments/delete/" + id.toString(),
        method: 'GET',
    });
}
// End comments

// FG Budget Review
export function getFgBudgetsForCropId(cropId) {
    let url = "fg_budgets/review/" + cropId.toString()
    let method = 'GET'
    let payload = null
    return (queryFn, options) => useReactQuery(queryFn, null, method, url, payload, options);
}

export function getFgBudgetsForYear(year) {
    let url = "fg_budgets/year/" + year.toString()
    let method = 'GET'
    let payload = null
    return (queryFn, options) => useReactQuery(queryFn, null, method, url, payload, options);
}

// End Fg Budget Review

// Sorten

export function getSortenById(id) {
    let url = "sorten/" + id.toString()
    let method = 'GET'
    let payload = null
    return (queryFn, options) => useReactQuery(queryFn, null, method, url, payload, options);
}


export function getSortens() {
    let url = "sorten/list/"
    let method = 'GET'
    let payload = null
    return (queryFn, options) => useReactQuery(queryFn, null, method, url, payload, options);
}

export function addSorten(sorten) {
    return request({
        url: API_BASE_URL + "/sorten/add",
        method: 'POST',
        body: JSON.stringify(sorten)
    });
}
export function editSorten(sorten) {
    return request({
        url: API_BASE_URL + "/sorten/edit",
        method: 'POST',
        body: JSON.stringify(sorten)
    });
}
export function deleteSorten(id) {
    return request({
        url: API_BASE_URL + "/sorten/delete/" + id.toString(),
        method: 'GET',
    });
}

// End Sorten

// MISC
export function getCropList() {
    let url = "crops/"
    let method = 'GET'
    let payload = null
    return (queryFn, options) => useReactQuery(queryFn, null, method, url, payload, options);
}

// Resources

export function getResourceList(mediaType) {
    let url = "resources/mediatype/" + mediaType.toString()
    let method = 'GET'
    let payload = null
    return (queryFn, options) => useReactQuery(queryFn, null, method, url, payload, options);
}

export function getResourceListByItemIdAndMediaType(itemId, mediaType) {
    let url = "resources/items/" + itemId.toString() + "/" + mediaType.toString()
    let method = 'GET'
    let payload = null
    return (queryFn, options) => useReactQuery(queryFn, null, method, url, payload, options);
}

export function postResource(resource) {
    return request({
        url: API_BASE_URL + "/resources/add",
        method: 'POST',
        body: JSON.stringify(resource)
    });
}

export function downloadFile (id,filename, mediaType)  {
    let url = API_BASE_URL + "/resources/download/" + id.toString() + "/" + filename.toString() + "/" + mediaType.toString()
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      })
      .catch(error => {
        console.error('Error downloading the file:', error);
      });
  };

export function deleteForumFile(id) {
    return request({
        url: API_BASE_URL + "/media/forum/file/delete/" + id.toString(),
        method: 'GET',
    });
}

export function deleteGeneralFile(id) {
    return request({
        url: API_BASE_URL + "/media/file/general/delete/" + id.toString(),
        method: 'GET',
    });
}

// Function to get a list of AccessOverrides
export function createAccessOverride(payload) {
    return request({
        url: API_BASE_URL + "/access-overrides",
        method: 'POST',
        body: JSON.stringify(payload)
    });
}

export function getAccessOverrides() {
    let url = "access-overrides";
    let method = 'GET';
    let payload = null;
    return (queryFn, options) => useReactQuery(queryFn, null, method, url, payload, options);
}

export function deleteAccessOverride(id) {
    let url = `access-overrides/delete/${id}`;
    return request({
        url: API_BASE_URL + "/access-overrides/delete/" + id.toString(),
        method: 'GET',
    });
}

export function updateAccessOverride(id, payload) {
    return request({
        url: API_BASE_URL + "/access-overrides/" + id.toString(),
        method: 'PUT',
        body: JSON.stringify(payload)
    });
}

export function getAccessOverrideById(id) {
    return request({
        url: API_BASE_URL + "/access-overrides/" + id.toString(),
        method: 'GET',
    });
}

// End Resources

// Impersonate
export function impersonateUser(impersonationRequest) {
    return request({
        url: API_BASE_URL + "/impersonate",
        method: 'POST',
        body: JSON.stringify(impersonationRequest)
    });
}

// End Impersonate

export function deleteUser(userId) {
    return request({
        url: API_BASE_URL + "/user/delete/" + userId.toString(),
        method: 'GET',
    });
}



