import React, { Component } from 'react';
import './App.css';
import { Link, Route, withRouter, Switch } from 'react-router-dom';

import { getCurrentUser } from '../util/APIUtils';
import { ACCESS_TOKEN } from '../constants';
import { withTranslation } from "react-i18next";

import NewPoll from '../poll/NewPoll';
import Login from '../user/login/Login';
import Signup from '../user/signup/Signup';
import Profile from '../user/profile/Profile';
import AppHeader from '../common/AppHeader';
import NotFound from '../common/NotFound';
import LoadingIndicator from '../common/LoadingIndicator';
import PrivateRoute from '../common/PrivateRoute';
import Projects from '../projects/Projects';
import BreederProfile from '../breeders/BreederProfile';
import NewProject from '../projects/NewProject';
import Account from '../user/profile/Account';
import { Layout, notification, Menu, Breadcrumb, Alert } from 'antd';
import Breeders from '../breeders/Breeders';
import ProjectDetail from '../projects/ProjectDetail';
import FachGroupBudget from '../fg_budget/FachGroupBudget';
import FachGroupBudgetDetail from '../fg_budget/FachGroupBudgetDetail';
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

import FarmProfile from '../farmprofile/FarmProfile';
import NewFarmProfile from '../farmprofile/NewFarmProfile';
import FarmProfileDetail from '../farmprofile/FarmProfileDetail';
import FarmLocations from '../farmprofile/locations/FarmLocations';
import NewFarmLocation from '../farmprofile/locations/NewFarmLocation';
import LocationDetail from '../farmprofile/locations/LocationDetail';
import Crops from '../crops/Crops';
import CropDetail from '../crops/CropDetail';
import NewCrop from '../crops/NewCrop';
import FachGroups from '../fachgroups/FachGroups';
import FachGroupDetail from '../fachgroups/FachGroupDetail';
import NewBreeder from '../breeders/NewBreeder';
import UserProfiles from '../user/user_profiles/UserProfiles';
import { observer, inject } from 'mobx-react';
import NewFachGroup from '../fachgroups/NewFachGroup';
import Payout from '../payouts/Payout';
import BudgetOverview from '../finance/BudgetOverview';
import BreederCropSummary from '../finance/BreederCropSummary';
import ProjectTypeCropSummary from '../finance/ProjectTypeCropSummary';
import CropForecast from '../crop-forecast/CropForecast';
import AddCropForecast from '../crop-forecast/AddCropForecast';
import { ZslReport } from '../zsl/ZslReport';
import AllPayouts from '../payouts/AllPayouts';
import AppMenu from './AppMenu';
import ProjectSettings from '../settings/ProjectSettings';
import Funders from './funders/Funders';
import ProjectFunding from '../finance/ProjectFunding';
import ZslAntrag from '../zsl/ZslAntrag';
import Contract from '../contract/Contract';
import ContractActual from '../contract/ContractActual';
import FachGroupBudgetYears from '../fg_budget/FachGroupBudgetYears';
import ForumLanding from '../forum/ForumLanding';
import TaskList from '../workflow/TaskList';
import UserManagement from '../user/management/UserManagement';
import UserProfile from '../user/management/UserProfile';
import VaExtern from '../finance/VaExtern';
import BsagReport from '../bsag/BsagReport';
import SpecialContract from '../contract/SpecialContract';
import CalculationContainer from '../projects/project_calculation_summary/CalculationContainer';
import CropDetailFn from '../crops/CropDetailFn';
import ValidationAll from '../validations/ValidationAll';
import BudgetValidation from '../contract/BudgetValidation';
import ZslSummary from '../contract/ZslSummary';
import FgReviewContainer from '../fg_review/FgReviewContainer';
import ReviewStatusProjects from '../fg_review/ReviewStatusProjects';
import ReviewFgList from '../fg_review/ReviewFgList';
import SortenList from '../sorten/SortenList';
import SortenDetail from '../sorten/SortenDetail';
import BreederCalculationContainer from '../projects/project_calculation_summary/BreederCalculationContainer';
import InstallmentSummary from '../payouts/InstallmentSummary';
import FarmProfileDocuments from '../farmprofile/documents/FarmProfileDocuments';
import AccessOverrides from '../access-override/AccessOverrides';
import PasswordRetrievalForm from '../user/management/PasswordRetrieval';
import ResetPasswordForm from '../user/management/ResetPassword';

@inject('projectsStore')
@observer
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      isAuthenticated: false,
      isLoading: false,
      collapsed: false,
      breedingMethods: [],
      lineSelections: [],
      isResetPasswordRoute : props.location.pathname.startsWith('/reset-password/token/') || false,
    }
    this.handleLogout = this
      .handleLogout
      .bind(this);
    this.loadCurrentUser = this
      .loadCurrentUser
      .bind(this);
    this.handleLogin = this
      .handleLogin
      .bind(this);

    this.onCollapse = collapsed => {
      this.setState({ collapsed });
    }

    notification.config({ placement: 'topRight', top: 70, duration: 3 });
  }

  loadCurrentUser() {
    this.setState({ isLoading: true });
    getCurrentUser().then(response => {
      this.setState({ currentUser: response, isAuthenticated: true, isLoading: false });
      localStorage.setItem('currentUser', JSON.stringify(response));
      // getSettings().then(setResponse => {
      //   ProjectStoreObj.generalSettings = setResponse;
      //   console.log(ProjectStoreObj.generalSettings);    
      // })
    }).catch(error => {
      this.setState({ isLoading: false });
      if (error.status == 403) {

        notification.error({ message: 'Kultursaat', description: "Keine Rollen zugewiesen, wenden Sie sich an den Administrator" });
        // this.handleLogout();
      }
    });
  }

  componentDidMount() {
    this.loadCurrentUser();
  }

  resetLogin() {
    localStorage.removeItem(ACCESS_TOKEN);
    // this.setState({ currentUser: null, isAuthenticated: false });
  }

  handleLogout(redirectTo = "/", notificationType = "success", description = "Sie wurden erfolgreich abgemeldet.") {
    localStorage.removeItem(ACCESS_TOKEN);

    this.setState({ currentUser: null, isAuthenticated: false });
    console.log('logging out')
    this.props.projectsStore.clearAllStore();

    this
      .props
      .history
      .push(redirectTo);
    notification[notificationType]({ message: 'Kultursaat', description: description });
  }

  handleLogin() {
    notification.success({ message: 'Kultursaat', description: "Sie haben sich erfolgreich angemeldet." });
    this.loadCurrentUser();
    this.props.projectsStore.clearAllStore();
    this
      .props
      .history
      .push("/");
  }

  render() {

    const { collapsed } = this.state;

    const { t } = this.props;
    if (this.state.isLoading) {
      return <LoadingIndicator />
    }
    return (

      <Layout className="app-container">
        <AppHeader
          isAuthenticated={this.state.isAuthenticated}
          currentUser={this.state.currentUser}
          handleLogout={this.handleLogout} /> {/* <div className="logo" /> */}
        {/* <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
        <Menu.Item key="1">nav 1</Menu.Item>
        <Menu.Item key="2">nav 2</Menu.Item>
        <Menu.Item key="3">nav 3</Menu.Item>
      </Menu> */}

        <Content className="app-content">
          {/* <Alert type="error" message="Noch in der Testphase. Bitte bis zum 23.10.2022 auf ernsthaften Dateneingabe warten!" showIcon
            style={{ fontWeight: 700, backgroundColor: '#e9c46a' }} /> */}
          <Layout
            className="site-layout-background"
            style={{
              padding: '0px 0',
              margin: '0px 0',
              minHeight: "100vh"
            }}>
            {this.state.isAuthenticated && <Sider className="ant-layout-sider-trigger-or1" width={200} collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
              <AppMenu handleLogout={this.handleLogout} currentUser={this.state.currentUser} />
            </Sider>}
            <Content
              style={{
                padding: '0 24px',
                minHeight: 280
              }}>
              <Switch>

                <Route
                  exact
                  path="/"
                  render={(props) => <Projects
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    handleLogout={this.handleLogout}
                    {...props} />}></Route>
                <Route
                  path="/login"
                  render={(props) => <Login onLogin={this.handleLogin} {...props} />}></Route>
                <Route
                  path="/users/:username"
                  render={(props) => <Account
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>
                {/* <Route
                  path="/users/:username"
                  render={(props) => <Profile
                  isAuthenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  {...props}/>}></Route> */}
                <Route
                  path="/projects"
                  render={(props) => <Projects
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    handleLogout={this.handleLogout}
                    // store={projectsStore}
                    {...props} />}></Route>
                <Route
                  path="/projects/user"
                  render={(props) => <Projects
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    handleLogout={this.handleLogout}
                    {...props} />}></Route>

                <Route
                  path="/project/new"
                  render={(props) => <NewProject
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    handleLogout={this.handleLogout}
                    {...props} />}></Route>
                <Route
                  path="/breeders/all"
                  render={(props) => <Breeders
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    handleLogout={this.handleLogout}
                    {...props} />}></Route>

                <Route
                  path="/breeder/add"
                  render={(props) => <NewBreeder
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    handleLogout={this.handleLogout}
                    {...props} />}></Route>
                <Route
                  path="/breeders/profile/:id/:profile"
                  render={(props) => <BreederProfile
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>
                {/* <PrivateRoute
                  path="/project/detail/:id"
                  render={(props) => <ProjectDetail
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                     />}></PrivateRoute> */}

                <PrivateRoute
                  path="/project/detail/:id"
                  component={ProjectDetail}
                  isAuthenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                />
                <Route
                  path="/farmprofile/list"
                  render={(props) => <FarmProfile
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>
                <Route
                  path="/farmprofile/detail/:id"
                  render={(props) => <FarmProfileDetail
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route
                  path="/farmprofile/new"
                  render={(props) => <NewFarmProfile
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>
                <Route
                  path="/farmprofile/documents"
                  render={(props) => <FarmProfileDocuments
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>
                <Route
                  path="/farmprofile/locations"
                  render={(props) => <FarmLocations
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>
                <Route
                  path="/farmlocation/add"
                  render={(props) => <NewFarmLocation
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>
                <Route
                  path="/farmlocation/detail/:id"
                  render={(props) => <LocationDetail
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>
                <Route
                  path="/crops"
                  render={(props) => <Crops
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>
                <Route
                  path="/crop/detail/:id"
                  render={(props) => <CropDetailFn
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>
                <Route
                  path="/crop/add"
                  render={(props) => <NewCrop
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route
                  path="/fachgroups"
                  render={(props) => <FachGroups
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route
                  path="/fachgroup/add"
                  render={(props) => <NewFachGroup
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route
                  path="/fachgroup/detail/:id"
                  render={(props) => <FachGroupDetail
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route
                  path="/fg_budget"
                  render={(props) => <FachGroupBudget
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route
                  path="/fg_budget_years"
                  render={(props) => <FachGroupBudgetYears
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route
                  path="/fg_budget_detail/:cropId/:breederId"
                  render={(props) => <FachGroupBudgetDetail
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route
                  path="/userprofiles/:id"
                  render={(props) => <UserProfiles
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route
                  path="/userprofiles/detail/:id"
                  render={(props) => <FachGroupDetail
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route
                  path="/personas/detail/:id"
                  render={(props) => <FachGroupDetail
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route
                  path="/personas/add"
                  render={(props) => <FachGroupDetail
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route
                  path="/payouts/:id/:year"
                  render={(props) => <Payout
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>

                <Route
                  path="/payout-breeder/:id/:year"
                  render={(props) => <Payout
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    breederView={1019}


                    {...props} />}></Route>

                <Route
                  path="/installment-summary"
                  render={(props) => <InstallmentSummary
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>
                <Route
                  path="/payout/list"
                  render={(props) => <Payout
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>


                <Route
                  path="/payouts"
                  render={(props) => <AllPayouts
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>

                <Route
                  path="/payout/list/:year"
                  render={(props) => <AllPayouts
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>

                <Route
                  path="/budget_overview"
                  render={(props) => <BudgetOverview
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>

                <Route
                  path="/project_type_crop_summary"
                  render={(props) => <ProjectTypeCropSummary
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>

                <Route
                  path="/breeder_crop_summary"
                  render={(props) => <BreederCropSummary
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>

                <Route
                  path="/crop-forecast"
                  render={(props) => <CropForecast
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>
                <Route
                  path="/add-crop-forecast"
                  render={(props) => <AddCropForecast
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>
                <Route
                  path="/crop-forecast/user/:id"
                  render={(props) => <AddCropForecast
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>
                <Route
                  path="/zsl"
                  render={(props) => <ZslReport
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>
                <Route
                  path="/zsl-antrag"
                  render={(props) => <ZslAntrag
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>
                <Route
                  path="/project-settings"
                  render={(props) => <ProjectSettings
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>
                <Route
                  path="/project-funding"
                  render={(props) => <ProjectFunding
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route
                  path="/funders"
                  render={(props) => <Funders
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route
                  path="/contract"
                  render={(props) => <Contract
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route
                  path="/contract-actual"
                  render={(props) => <ContractActual
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route
                  path="/forum"
                  render={(props) => <ForumLanding
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route
                  path="/fg-tasks/:id"
                  render={(props) => <TaskList
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>

                <Route
                  path="/user-management"
                  render={(props) => <UserManagement
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>
                <Route
                  path="/user-profile/:id"
                  render={(props) => <UserProfile
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    handleLogin={this.handleLogin}
                    resetLogin={this.resetLogin}

                    {...props} />}></Route>

                <Route
                  path="/va-extern"
                  render={(props) => <VaExtern
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>

                <Route
                  path="/fp-report"
                  render={(props) => <SpecialContract
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>

                <Route
                  path="/budget-validation"
                  render={(props) => <BudgetValidation
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>

                <Route
                  path="/zsl-summary"
                  render={(props) => <ZslSummary
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>


                <Route
                  path="/proj-calculation"
                  render={(props) => <CalculationContainer
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>

                <Route
                  path="/breeder-budget-calculation"
                  render={(props) => <BreederCalculationContainer
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>


                <Route
                  path="/validations"
                  render={(props) => <ValidationAll
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>


                <Route
                  path="/fg-review"
                  render={(props) => <FgReviewContainer
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>


                <Route
                  path="/project-review"
                  render={(props) => <ReviewStatusProjects
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>

                <Route
                  path="/fg-review-list"
                  render={(props) => <ReviewFgList
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>

                <Route
                  path="/sorten"
                  render={(props) => <SortenList
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}

                    {...props} />}></Route>

                <Route
                  path="/sortendetail/:id"
                  render={(props) => <SortenDetail
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route
                  path="/access-override/"
                  render={(props) => <AccessOverrides
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route
                  path="/bsag-report"
                  render={(props) => <BsagReport
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route>

                <Route path="/forgot-password" component={PasswordRetrievalForm} />
                <Route path="/reset-password/token/:token" component={ResetPasswordForm} />


                {/* <Route
                  path="/reset-password/token/:token"
                  render={(props) => <ResetPasswordForm
                    isAuthenticated={this.state.isAuthenticated}
                    currentUser={this.state.currentUser}
                    {...props} />}></Route> */}
                {/* 
                <Route
                  path="/reset-password/token/:token"
                  render={(props) => <ResetPasswordForm  {...props} />}></Route> */}

                
                <Route component={NotFound}></Route>
              </Switch>
              {!this.state.isAuthenticated && !this.state.isResetPasswordRoute && <Login onLogin={this.handleLogin} />}

            </Content>
          </Layout>

          {/* <div className="container">
            <Switch>
              {/* <Route
                exact
                path="/"
                render={(props) => <PollList
                isAuthenticated={this.state.isAuthenticated}
                currentUser={this.state.currentUser}
                handleLogout={this.handleLogout}
                {...props}/>}></Route> */}
          {/* <Route
                path="/login"
                render={(props) => <Login onLogin={this.handleLogin} {...props}/>}></Route>
              <Route path="/signup" component={Signup}></Route>
              <Route
                path="/users/:username"
                render={(props) => <Profile
                isAuthenticated={this.state.isAuthenticated}
                currentUser={this.state.currentUser}
                {...props}/>}></Route>
              <PrivateRoute
                authenticated={this.state.isAuthenticated}
                path="/poll/new"
                component={NewPoll}
                handleLogout={this.handleLogout}></PrivateRoute>
              <Route component={NotFound}></Route>
            </Switch>
          </div> */}


        </Content>
      </Layout>

    );
  }
}

export default withTranslation()(withRouter(App));
