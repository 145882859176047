import React, { useState, useEffect, useRef } from 'react';
import { getBreeders } from '../util/APIUtils';
import {
    Select,
} from 'antd';

function BreederSelect({setItem, setFormattedItems,  selectedItem, placeholder, disabled=false, value=null, onChange }) {
    const [itemList, setItemList] = useState([])
    const [val, setVal] = useState(null)
    const [loading, setLoading] = React.useState(false);

    const fetchBreeders = React.useCallback(async () => {
        let promise;
        promise = getBreeders();
        setLoading(true)
        if (!promise) {
            return;
        }

        promise.then(response => {
            setItemList(response);
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, [])

    useEffect(() => {
        fetchBreeders()
    }, [fetchBreeders]);


    const handleChange = selectedItem => {
        setVal(selectedItem)
        setItem(selectedItem);
        onChange(selectedItem);
    };

    const filteredOptions = itemList
    return (
        <>
            <Select
            showSearch
                mode="single"
                loading={loading}
                disabled={disabled}
                placeholder= {placeholder ? placeholder : "Gegenstände auswählen"}
                defaultValue={selectedItem}
                onChange={handleChange}
                // filterOption={(input, option) =>
                //     (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                //   }
                style={{ width: '100%' }}
            >
                {filteredOptions.map(item => (
                    <Select.Option key={item.id} value={item.id} label={item.lastName + ", " + item.firstName}>
                        {item.lastName}, {item.firstName}  
                    </Select.Option>
                ))}
            </Select>

        </>
    );
}

export default BreederSelect