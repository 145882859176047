import React from 'react'
import { Table, Typography, Tag } from 'antd'

const { Text, Title } = Typography;

function TableSummaryCell(props) {
    let val = props.val;
    let count = props.count;
    let color = props.color;

    return (
        <Table.Summary.Cell align={count ? 'left' : 'right'} >
            <div style={{background: color}}>
            <Title level={5}>   
                {val ? !count? val.toFixed(2) : <Tag color="gold">{val}</Tag> : !count? "0.00" : <Tag color="gold">{0}</Tag>}
            </Title>
            </div>
        </Table.Summary.Cell>
    )
}

export default TableSummaryCell
