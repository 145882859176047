import React, { Component } from 'react'
import { Card, PageHeader, Select, Table, Typography, Divider, Checkbox, Space, Tag } from 'antd'
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { getBudgetOverViews, getProjectTypeCropSummary, getYears } from '../util/APIUtils';
import { formatCurrency } from '../util/Helpers';
import TableSummaryCell from './TableSummaryCell';
import './Finance.css'
import YearSelect from '../common/YearSelect';

const { Text, Title } = Typography;

const fg_style = "ccd5ae"
const prio_style = "e9edc9"

class ProjectTypeCropSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            year: null,
            projectYears: [],
            isLoading: false,
            visible: false,
            priorityChecked: true,
            fgChecked: true,
            payoutChecked: false,
            projectTypeCropSummary: [],
            fullList: []
        };
    }


    loadBudgetOverviews(val) {
        let promise;
        // if (!this.state.year) {
        //     return;
        // }

        console.log("Loading")
        promise = getProjectTypeCropSummary(val);

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });
        promise.then(response => {
            // console.log(response);
            this.setState({
                projectTypeCropSummary: response,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    componentDidMount() {
        if (!this.props.isAuthenticated) {
            this
                .props
                .history
                .push("/login");
        }
    }

    handleYearInput = (val) => {
        console.log(val);
        this.setState({
            year: val
        })

        console.log(this.state.year);
        this.loadBudgetOverviews(val);
    }

    onFgChange = (val) => {
        console.log(val)
        this.setState({ fgChecked: !this.state.fgChecked });
        console.log(this.state.fgChecked)
    }


    onPayoutChange = (val) => {
        console.log(val)
        this.setState({ payoutChecked: !this.state.payoutChecked });
        console.log(this.state.payoutChecked)
    }

    onPrioChange = (val) => {
        console.log(val)
        this.setState({ priorityChecked: !this.state.priorityChecked });
        console.log(this.state.priorityChecked)
    }


    render() {
        const { t } = this.props;

        let columns = [
            {
                title: "Typ",
                dataIndex: 'projectType',
                key: 'projectType',
                width: 20,
                sortDirections: ['descend', 'ascend'],
            },
            
            {
                title: "Kulturart",
                dataIndex: 'cropName',
                key: 'cropName',
                align: "left",
                width: 70,
                
            },

            // ####### PLAN
            {
                title: "Gesamt Kalkulation",
                type: "prio",
                dataIndex: 'totalItemBudget',
                key: 'totalItemBudget',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#bfd8bd" }
                        },
                        children: <span><b>
                           <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                }
                // width: 300,
            },
            {
                title: "#",
                type: "prio",
                dataIndex: 'totalItemCount',
                key: 'totalItemCount',
                align: "left",
                width: 40,
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#bfd8bd" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                }
            },
            {
                title: "Tätigkeiten",
                type: "prio",
                dataIndex: 'totalAccountingBudget',
                key: 'totalAccountingBudget',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#dde7c7" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                }
                // width: 300,
            },
            
            {
                title: "Analysen",
                type: "prio",
                dataIndex: 'totalExamBudget',
                key: 'totalExamBudget',
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#edeec9" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
                align: "right",
                // width: 300,
            },
           

            // #### PLAN END

            // #########  Betrag FG 
            {
                title: "FG OK",
                dataIndex: 'totalFgOkAmount',
                type: "betrag_fg",
                key: 'totalFgOkAmount',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#d5cdbb" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
                align: "right",
            },
            {
                title: "#",
                type: "betrag_fg",
                dataIndex: 'totalFgOkCount',
                key: 'totalFgOkCount',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#d5cdbb" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                width: 40,
            },
            {
                title: "FG Zurückgestellt",
                type: "betrag_fg",
                dataIndex: 'totalFgOnHoldAmount',
                key: 'totalFgOnHoldAmount',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#e8d1be" }
                        },
                        children: <span><b>
                           <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
            },
            {
                title: "#",
                type: "betrag_fg",
                dataIndex: 'totalFgOnHoldCount',
                key: 'totalFgOnHoldCount',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#e8d1be" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                width: 40,
                // width: 260
            },
            {
                title: "FG Abgelehnt",
                type: "betrag_fg",
                dataIndex: 'totalFgRejectAmount',
                key: 'totalFgRejectAmount',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#f1d3bf" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
            },
            {
                title: "#",
                type: "betrag_fg",
                dataIndex: 'totalFgRejectCount',
                key: 'totalFgRejectCount',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#f1d3bf" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                width: 40,
                // width: 260
            },

            // #########  Betrag FG END
            // #########  Payout
            {
                title: "Auszahl OK",
                type: "payout",
                dataIndex: 'totalPayoutOkAmount',
                key: 'totalPayoutOkAmount',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#fcde73" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
            },
            {
                title: "#",
                type: "payout",
                dataIndex: 'totalPayoutOkCount',
                key: 'totalPayoutOkCount',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#fcde73" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                width: 40,
                // width: 260
            },
            {
                title: "Auszahl Zurückgestellt",
                type: "payout",
                dataIndex: 'totalPayoutOnHoldAmount',
                key: 'totalPayoutOnHoldAmount',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#fde89b" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
            },
            {
                title: "#",
                type: "payout",
                dataIndex: 'totalPayoutOnHoldCount',
                key: 'totalPayoutOnHoldCount',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#fde89b" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                width: 40,
                // width: 260
            },
            {
                title: "Auszahl Abgelehnt",
                type: "payout",
                dataIndex: 'totalPayoutRejectAmount',
                key: 'totalPayoutRejectAmount',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#fdecaf" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
            },
            {
                title: "#",
                type: "payout",
                dataIndex: 'totalPayoutRejectCount',
                key: 'totalPayoutRejectCount',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#fdecaf" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                width: 40,
                // width: 260
            },
            // ########### FG FIXED
           


        ];

        if (!this.state.fgChecked) {
            columns = columns.filter(c => c.type != "betrag_fg")
        }
        if (!this.state.payoutChecked) {
            columns = columns.filter(c => c.type != "payout")
        }

        if (!this.state.priorityChecked) {
            columns = columns.filter(c => c.type != "prio")
        }


        // const fullList = this.state.cropSummaryList.push(...this.state.projectTypeSummaryList)
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    title="Budgetübersicht nach Kulturart"
                    subTitle=""
                />
               
                <Card bordered={true} loading={this.state.isLoading}>
                    <Space>
                        <YearSelect setItem={this.handleYearInput} selectedItem={this.state.year} />
                        <Checkbox   
                            checked={this.state.priorityChecked}
                            onChange={this.onPrioChange}
                        >
                            Plan
                        </Checkbox>
                        <Checkbox
                            checked={this.state.fgChecked}
                            onChange={this.onFgChange}
                        >
                            Betrag FG
                        </Checkbox>
                        <Checkbox
                            checked={this.state.payoutChecked}
                            onChange={this.onPayoutChange}
                        >
                            Auszahlbetrag
                        </Checkbox>
                    </Space>
                    <Divider dashed />
                    {this.state.projectTypeCropSummary.length > 0 &&
                    <Table
                        size='small'
                        className='finance-table'
                        columns={columns}
                        style={{ fontSize: '10px' }}
                        scroll={{ x: 'max-content' }}
                        dataSource={this.state.projectTypeCropSummary}
                        loading={this.state.isLoading}
                        pagination={false}
                        rowClassName={(record, index) =>  { 
                            if (record.type == 'subtotal_ptype') {
                                return 'table-row-pt'
                            } else if (record.type == 'subtotal_breeder') {
                             return  'table-row-dark'
                            }}}
                        summary={pageData => {
                            return (
                                <>

                                {/* {this.state.projectTypeSummaryList.map(item => (
                                      <Table.Summary.Row align="right">
                                      <Table.Summary.Cell align="left"><Title level={5}>{item.cropName}</Title></Table.Summary.Cell>

                                      <TableSummaryCell val={item.budgetTotalAmount} />
                                      <TableSummaryCell val={item.totalNumberOfProjects} count={true} />
                                   
                                      {this.state.priorityChecked && <TableSummaryCell val={item.totalPriority1Projects} color={"#bfd8bd"} />}
                                      {this.state.priorityChecked &&<TableSummaryCell val={item.noPriority1Projects} count={true} color={"#bfd8bd"}/>}
                                      {this.state.priorityChecked &&<TableSummaryCell val={item.totalPriority2Projects} color={"#bfd8bd"}/>}
                                      {this.state.priorityChecked &&<TableSummaryCell val={item.noPriority2Projects} count={true} color={"#bfd8bd"}/>}
                                      {this.state.priorityChecked &&<TableSummaryCell val={item.totalPriority3Projects} color={"#bfd8bd"} />}
                                      {this.state.priorityChecked &&<TableSummaryCell val={item.noPriority3Projects} count={true} color={"#bfd8bd"} />}
                                     
                                      {this.state.fgChecked && <TableSummaryCell val={item.projectFgOkTotalAmount} color={"#bfd8bd"} />}
                                      {this.state.fgChecked &&<TableSummaryCell val={item.projectFgOkCount} count={true} color={"#bfd8bd"}/>}
                                      {this.state.fgChecked &&<TableSummaryCell val={item.projectFgOnHoldTotalAmount} color={"#bfd8bd"}/>}
                                      {this.state.fgChecked &&<TableSummaryCell val={item.projectFgOnHoldCount} count={true} color={"#bfd8bd"}/>}
                                      {this.state.fgChecked &&<TableSummaryCell val={item.projectFgRejectedTotalAmount} color={"#bfd8bd"}/>}
                                      {this.state.fgChecked &&<TableSummaryCell val={item.projectFgRejectedCount} count={true} color={"#bfd8bd"}/>}

                                      {this.state.payoutChecked && <TableSummaryCell val={item.projectPayoutOkTotalAmount} color={"#bfd8bd"}/>}
                                      {this.state.payoutChecked &&<TableSummaryCell val={item.projectPayoutOkCount} count={true} color={"#bfd8bd"}/>}
                                      {this.state.payoutChecked &&<TableSummaryCell val={item.projectPayoutOnHoldTotalAmount} color={"#bfd8bd"}/>}
                                      {this.state.payoutChecked &&<TableSummaryCell val={item.projectPayoutOnHoldCount} count={true} color={"#bfd8bd"}/>}
                                      {this.state.payoutChecked &&<TableSummaryCell val={item.projectPayoutRejectedTotalAmount} color={"#bfd8bd"}/>}
                                      {this.state.payoutChecked &&<TableSummaryCell val={item.projectPayoutRejectedCount} count={true} color={"#bfd8bd"}/>}

                                      <TableSummaryCell val={item.fgBudgetTotal} />
                                      <TableSummaryCell val={item.noFgBudgets} count={true}/>


                                  </Table.Summary.Row >
                                ))} */}
    


                                </>
                            );
                        }}
                    />}



                </Card>

            </div>
        )
    }
}

export default withTranslation()(withRouter(ProjectTypeCropSummary));
