import React from 'react'
import moment from 'moment'
import { Descriptions, Tag, Form, Empty, Input, Checkbox, Select, InputNumber, Button, DatePicker, Space, notification, Divider, Row, Col, List, Badge, Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next';
import { getProjectReport, getLineSelections, saveProjectReport, getBreedingMethods, createReportYear } from '../../util/APIUtils';
import { useEffect, useState } from 'react'
import _ from "lodash";
import './Report.css'
import { getTextArtva } from '../../util/Helpers';
import LineSelectionLookup from '../../common/LineSelectionLookup';
import GlLineSelectionTable from '../../common/GlLineSelectionTable';
import GlLineSelectionsReport from '../../common/GlLineSelectionsReport';

function ReportGl(props) {
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = React.useState(false);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [canceled, setCanceled] = useState(props.projectDetailInfo.projectReportInfo.canceled ? 1 : 0)
    const [state, setState] = useState(props.projectDetailInfo.projectReportInfo)
    const projectInfo = props.projectInfo;
    const projectDetailInfo = props.projectDetailInfo;
    const cropId = props.projectInfo.cropId;
    const editable = props.isReportEditable
    const projectType = props.projectInfo.projectType;
    const { t } = useTranslation();
    const [form] = Form.useForm();

    let variantTreatment = state.variantTreatment ? state.variantTreatment.split(';') : [];
    let varietyLines = state.varietyLines ? state.varietyLines.split(';') : [];

    const setEditingLocal = (val) => {
        setEditing(val);
        props.setEditingMaster(val);
        if (!val) {
            props.reloadProject(screenState)
        }
    }

    const screenState = {
        "year": props.year,
        "tab": '3'
    }

    const onChange = e => {
        setCanceled(e);
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeOther = name => value => {
        if (name === 'investigations') {
            value = Array.isArray(value) ? value.join(";") : [];
        } 
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const getCheckedStatus = (val) => {
        const investigationsArray = state.investigations != null ? state.investigations.split(";") : null;
        return investigationsArray;
    }

    const setGlI1tems = (vals) => {
        setState(prevState => ({
            ...prevState,
            ["variantTreatment"]: vals
        }));
    }

    const setGlI2tems = (vals) => {
        setState(prevState => ({
            ...prevState,
            ["varietyLines"]: vals
        }));
    }

    const mapObjToReport = (values, type) => {

        console.log(values)
        let renewal = null;

        if(type == "form") {
            renewal = values.renewal != null || values.renewal != 0 ? moment(values.renewal, 'YYYY').year() : null
            
        } else {
            renewal = values.renewal != null ? moment(values.renewal, 'YYYY') : null;
        }

        let planObj = {}
        planObj = {
            canceled: canceled,

            description: state.description,
            descExperienceImplementation: state.descExperienceImplementation,
            variantTreatment: state.variantTreatment,
            varietyLines: state.varietyLines,
            noOfLines: state.noOfLines,
            numberOfRepetitions: state.numberOfRepetitions,
            cultivationScope: state.cultivationScope,
            cultivationScopeUnit: state.cultivationScopeUnit,
            investigations: state.investigations,
            culturalTour: state.culturalTour,
            deviationPlan: state.deviationPlan,
            results: state.results,
            renewal: renewal,
            descExtension: state.descExtension,

            id: state.id,
            projectId: props.projectInfo.projectId,
            projectDetailId: props.projectDetailInfo.id,
            projectNumber: props.projectInfo.projectNumber
        }
        return planObj;
    }

    const onFinish = (values) => {
        setLoading(true);

        console.log(mapObjToReport(values, "form"))


        let promise;
        promise = saveProjectReport(mapObjToReport(values, "form"));
        if (!promise) {
            return;
        }

        promise.then(response => {
            setLoading(false)

            props.reloadProject(screenState);
            notification.success({ message: "Kultursaat", description: "Bericht aktualisiert!", placement: 'topLeft' })
        }).catch(error => {
            console.log(error)
            setLoading(false)
            notification.error({ message: "Kultursaat", description: "Error saving report", placement: 'topLeft' })
        });
    };

    const initVals = mapObjToReport(state, "obj");

    const getCanceledBlock = () => {
        return (
            <>
                <Descriptions.Item label={t("project_report.the_project_was")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start', width: '300px' }} >
                    {editing && editable ?
                        <Form.Item name="canceled">
                            <Space>
                                <Select style={{ width: 250 }} onChange={onChange} defaultValue={canceled}>
                                    <Option value={0}>{t("project_report.completed")}</Option>
                                    <Option value={1}>{t("project_report.not_completed")}</Option>
                                </Select>
                            </Space>
                        </Form.Item> :

                        !state.canceled ?
                            <Tag color="#87d068">{t("project_report.completed")}</Tag> :
                            <Tag color="#f50">{t("project_report.not_completed")}</Tag>}
                </Descriptions.Item>
            </>
        )
    }

    const getReportBlock = () => {
        return (
            <>
                <Descriptions.Item label={t("project_details.description")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                    {editing && editable ?
                        <Input.TextArea name="description" value={state["description"]} onChange={handleChange} autoSize/>
                        : state["description"]}
                </Descriptions.Item>
                
                <Descriptions.Item span={3} label="Erläuterung Versuchsdurchf" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Input.TextArea name="descExperienceImplementation" value={state.descExperienceImplementation} onChange={handleChange} autoSize/>
                        : state.descExperienceImplementation}
                </Descriptions.Item>

                <Descriptions.Item label="Sorte(n)/Linei(n)" labelStyle={{ fontWeight: 'bold' }} span={3}>
                    {editing && editable ?
                        <Form.Item
                            name="variantTreatment"
                            hasFeedback
                        >
                            <GlLineSelectionsReport itemList={state.variantTreatment} setItems={setGlI1tems} />
                        </Form.Item>
                        :
                        <List
                            size="small"
                            dataSource={variantTreatment}
                            renderItem={item => <List.Item style={{ padding: 2 }}><Badge color={'geekblue'} text={item} /></List.Item>}
                        />}
                </Descriptions.Item>

                <Descriptions.Item label="Behandlungsvarianten" labelStyle={{ fontWeight: 'bold' }} span={3}>
                    {editing && editable ?
                        <Form.Item
                            name="varietyLines"
                            hasFeedback
                        >
                            <GlLineSelectionsReport itemList={state.varietyLines} setItems={setGlI2tems} />
                        </Form.Item>
                        :
                        <List
                            size="small"
                            dataSource={varietyLines}
                            renderItem={item => <List.Item style={{ padding: 2 }}><Badge color={'geekblue'} text={item} /></List.Item>}
                        />}

                </Descriptions.Item>
                <Descriptions.Item label="Anzahl Varianten" labelStyle={{ fontWeight: 'bold' }} span={3} >
                    {editing && editable ?

                        <Form.Item
                            name="noOfLines"
                        >
                            <InputNumber onChange={handleChangeOther("noOfLines")}/>
                        </Form.Item>
                        : state.noOfLines}
                </Descriptions.Item>

                <Descriptions.Item label="Wiederholungen" labelStyle={{ fontWeight: 'bold' }} span={3} >
                    {editing && editable ?

                        <Form.Item
                            name="numberOfRepetitions"
                        >
                            <Select
                                style={{ width: '150px' }}
                                name="numberOfRepetitions"
                                defaultValue={state.numberOfRepetitions}
                                onChange={handleChangeOther("numberOfRepetitions")}
                            >
                                <Option value={0}>nein</Option>
                                <Option value={2}>2</Option>
                                <Option value={3}>3</Option>
                                <Option value={4}>4</Option>
                            </Select>
                        </Form.Item>
                        : state.numberOfRepetitions == 0 ? "nein" : state.numberOfRepetitions}
                </Descriptions.Item>

                <Descriptions.Item label={t("project_details.cultivation_scope")} labelStyle={{ fontWeight: 'bold' }} span={3} >
                    {editing && editable ?

                        <div>
                            <Space>
                                <InputNumber name="cultivationScope" value={state["cultivationScope"]} onChange={handleChangeOther("cultivationScope")} />

                                <Select
                                    style={{ width: '150px' }}
                                    name="cultivationScopeUnit"
                                    defaultValue={state.cultivationScopeUnit}
                                    onChange={handleChangeOther("cultivationScopeUnit")}
                                >

                                    <Option value={"qm"}>qm</Option>
                                    <Option value={"ha"}>ha</Option>
                                    <Option value={"stück"}>stück</Option>
                                </Select>
                            </Space>

                        </div>
                        : state.cultivationScope + " " + state.cultivationScopeUnit}
                </Descriptions.Item>

                <Descriptions.Item label="Geplante Untersuchungen" labelStyle={{ fontWeight: 'bold' }} span={3}>
                    {editing && editable ?
                        <Form.Item
                            name="investigations"
                        >
                            <Checkbox.Group style={{ width: '100%' }} onChange={handleChangeOther("investigations")} >
                                <Row>
                                    <Col span={8}>
                                        <Checkbox value="B" >Bonituren</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value="S" >Sensorik</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value="BM">Bildschaffende Methoden</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value="A" >Analysen</Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox value="BK">Bildekrafteuntersuchungen</Checkbox>
                                    </Col>

                                </Row>
                            </Checkbox.Group>
                        </Form.Item>

                        : <Checkbox.Group style={{ width: '100%' }} defaultValue={getCheckedStatus()} disabled >
                            <Row>
                                <Col span={8}>
                                    <Checkbox value="B" >Bonituren</Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="S" >Sensorik</Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="BM">Bildschaffende Methoden</Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="A" >Analysen</Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="BK">Bildekrafteuntersuchungen</Checkbox>
                                </Col>

                            </Row>
                        </Checkbox.Group>}
                </Descriptions.Item>
                <Descriptions.Item label={"Bemerkungen"} labelStyle={{ fontWeight: 'bold' }} span={3}>
                    {editing && editable ?
                        <Form.Item
                            name="culturalTour"
                            
                        >
                        <Input.TextArea name="culturalTour" onChange={handleChange} />
                         </Form.Item>
                        : state.culturalTour}
                </Descriptions.Item>

                <Descriptions.Item label={"Abweichung vom plan"} labelStyle={{ fontWeight: 'bold' }} span={3} >
                    {editing && editable ?
                        <Form.Item
                            name="deviationPlan"
                        >
                           <Input.TextArea name="deviationPlan" onChange={handleChange} autoSize/>
                        </Form.Item>
                        : state.deviationPlan}
                </Descriptions.Item>

                <Descriptions.Item label={"Resultate"} labelStyle={{ fontWeight: 'bold' }} span={3} >
                    {editing && editable ?
                        <Form.Item
                            name="results"
                        >
                           <Input.TextArea name="results" onChange={handleChange} />
                        </Form.Item>
                        : state.results}
                </Descriptions.Item>
                <Descriptions.Item label={t("project_report.project_extention")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                {editing && editable ?
                            <Form.Item
                                name="renewal"
                            >
                                <DatePicker picker="year" />
                            </Form.Item> 
                            : state.renewal
                            
                            }

                            {/* {projectReportInfo.renewal ? projectReportInfo.renewal : "Nein"} */}
                        </Descriptions.Item>


                <Descriptions.Item label={"Begründung Projektverlängerung"} labelStyle={{ fontWeight: 'bold' }} span={3} >
                    {editing && editable ?
                        <Form.Item
                            name="descExtension"
                        >
                           <Input.TextArea name="descExtension" value={state["descExtension"]} onChange={handleChange} />
                        </Form.Item>
                        : state.descExtension}
                </Descriptions.Item>

            </>
        )
    }


    return (
        <div>
            <Form name="reports-form" form={form} onFinish={onFinish} initialValues={initVals}>
                <Descriptions
                    size="small"
                    extra=
                    {editable ?
                        !editing ?
                            <Space>
                                <Popconfirm
                                    title={"Möchten Sie den Bericht wirklich löschen??"}
                                    onConfirm={props.deleteReport}
                                >
                                    <Button type="danger" >Bericht löschen</Button>
                                </Popconfirm>

                                <Button onClick={() => setEditingLocal(true)}>Bearbeiten</Button> </Space> :
                            <Space>
                                <Button onClick={() => form.submit()} type='primary'>Speichern</Button>
                                <Button onClick={() => setEditingLocal(false)}>Abbrechen</Button>
                            </Space> : ""

                    }>
                </Descriptions>


                <Descriptions
                    size="small"
                    bordered
                >
                    {getCanceledBlock()}
                    {!canceled && getReportBlock()}
                </Descriptions>
            </Form>
        </div>
    )
}

export default ReportGl
