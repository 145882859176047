import React from 'react';
import { Select } from 'antd';

export default function SingleSelect({ setItems, existingItems = [], itemList = [], disabled = false}) {
    const [selectedItems, setSelectedItems] = React.useState(existingItems);

    const handleChange = selectedItems => {
        setSelectedItems(selectedItems);
        setItems(selectedItems);
    };

    return (
        <Select
            disabled={disabled}
            showSearch
            mode="single"
            placeholder="Select Items"
            value={selectedItems}
            onChange={handleChange}
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: '100%' }}
        >
            {itemList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                    {item.value}
                </Select.Option>
            ))}
        </Select>
    );
}
