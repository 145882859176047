import React, { Component } from 'react';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { getCropDetail, getAllCrops } from '../util/APIUtils';
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import { withTranslation } from "react-i18next";

import {
    Button,
    Card,
    Descriptions,
    Row,
    Col,
    Typography,
    PageHeader,
    Space,
    Input,
    InputNumber,
    Select

} from 'antd';

class CropDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            editing: false,
            cropDetail: {},
            cropGroups: [],
            currentUser: props.currentUser
        };
    }

    loadCropGroups() {
        let promise;
        promise = getAllCrops();

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response)

            this.setState({
                cropGroups: response.filter(x => {
                    return x.isGroup == 1
                }),
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    geCropDetailsById(id) {
        console.log('Loading Crop detail')
        console.log(id)
        let promise;
        promise = getCropDetail(id);

        if (!promise) {
            return;
        }
        this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response)
            this.setState({
                cropDetail: response,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    handleChangeOther = (name, val) => {
        console.log(name)
        console.log(val)
        let value =  null 
        if(name === "groupId") {
            value = val
        } else {
            value = val.target.value; 
        }
        this.setState({
            ...this.state.cropDetail,
            [name]: value
    });
    console.log(this.state)
    console.log(this.state.cropDetail)
    };

    handleChangeSelect = (name, val) => {
        console.log(e)
        this.setState({
            ...this.state.cropDetail,
            [name] : e
    });
    console.log(this.state.cropDetail)
    };


    setEditing = (val) => {
        this.setState({
            editing: val
        })
    }

    componentDidMount() {
        const { id } = this.props.match.params
        console.log(this.props);
        this.geCropDetailsById(id);
        this.loadCropGroups();
    }


    componentDidUpdate(nextProps) {
        const { id } = nextProps.match.params
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                isLoading: false,
                farmProfileId: id
            });
            // this.loadLocations(id);
            this.geCropDetailsById(id);

        }
    }

    handleBack = e => {
        this
            .props
            .history
            .goBack();
    };

    saveDetail = () => {
        console.log(this.state.cropDetail)
    }
    render() {
        const { t } = this.props;
        let officeUser = this.state.currentUser.roles.find(x => x.name === 'ROLE_OFFICE');
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    onBack={() => this.handleBack()}
                    extra={[officeUser ?
                        <Space>
                            {!this.state.editing ?
                                <Button size='small' key="edit1" type="primary" onClick={() => this.setEditing(true)}>{t("general.edit")}</Button> :
                                <Space><Button key="save1" size='small' type="primary" onClick={this.saveDetail} >{t("general.save")}</Button>
                                    <Button key="cancel1" size='small' onClick={() => this.setEditing(false)}>{t("general.cancel")}</Button>
                                </Space>
                            }
                            <Button key="34" size='small' type="primary" danger>{t("general.delete")}</Button>
                        </Space> : ""]
                    }
                    title={t("crops.crop_details")}
                    subTitle={this.state.cropDetail.shortName} />

                <Card bordered={true} loading={this.state.isLoading} headStyle={{ padding: "0" }}>
                    <Descriptions
                        size="small"
                        column={2}
                        bordered="true"
                        style={{
                            marginBottom: 20
                        }}>
                        <Descriptions.Item label={t("crops.crop_name")} >
                            {this.state.editing ? <Input name='name' defaultValue={this.state.cropDetail.name} onChange={(val) => this.handleChangeOther("name", val)} />
                                : <b>{this.state.cropDetail.name}</b>
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label={t("crops.crop_group")}>
                            {this.state.editing ? <Select style={{ width: "250px" }} defaultValue={this.state.cropDetail.groupId} onChange={(val) => this.handleChangeOther("groupId", val)}>
                                {this.state.cropGroups.map(fbb =>
                                    <option key={fbb.id} value={fbb.id}>{fbb.name}</option>
                                )}
                            </Select> :
                                this.state.cropDetail.groupName}
                        </Descriptions.Item>
                        {this.state.editing ? "" : <>
                            <Descriptions.Item label={t("crops.has_fg")}>
                                {this.state.cropDetail.fgId === 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="red" />}
                            </Descriptions.Item>

                            <Descriptions.Item label={t("fachgroup.fg_name")}><Link
                                to={{
                                    pathname: `/fachgroup/detail/${this.state.cropDetail.fachGroupId}`,
                                    state: {
                                        id: this.state.cropDetail.fachGroupId
                                    }
                                }}><b>{this.state.cropDetail.fachGroupName}</b></Link></Descriptions.Item>
                        
                        <Descriptions.Item label={t("fachgroup.fg_leader_short")}>{this.state.cropDetail.fgLeaderShortName}</Descriptions.Item>
                        <Descriptions.Item label={t("fachgroup.fg_leader_name")}>{this.state.cropDetail.fgLeaderName}</Descriptions.Item>
                        </>
                        }
                        <Descriptions.Item label={t("crops.harvest_interval")}>
                        {this.state.editing ? <InputNumber defaultValue={this.state.cropDetail.anniversary} onChange={(val) => this.handleChangeOther("anniversary", val)}/> : 
                            this.state.cropDetail.anniversary}</Descriptions.Item>
                        <Descriptions.Item label={t("crops.family")}>
                        {this.state.editing ? <Input defaultValue={this.state.cropDetail.family} onChange={(val) => this.handleChangeOther("family", val)}/> : 
                            this.state.cropDetail.family}
                            </Descriptions.Item>
                        <Descriptions.Item label={t("crops.latin_name")}> 
                        {this.state.editing ? <Input defaultValue={this.state.cropDetail.latinName} onChange={(val) => this.handleChangeOther("latinName", val)}/> : 
                            this.state.cropDetail.latinName}
                            </Descriptions.Item>



                    </Descriptions>
                    {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={24}>
                            <Card bordered={true}  >
                               
                            </Card>
                        </Col>
                       
                    </Row> */}
                </Card>

            </div>
        )
    }
}

export default withTranslation()(withRouter(CropDetail));
