import React from 'react';
import ReactDOM from 'react-dom';
import './i18nextConf';
import './index.css';
import App from './app/App';
import { ConfigProvider } from 'antd';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import deDE from 'antd/lib/locale/de_DE';
import { LocaleProvider } from 'antd';
import { Provider } from 'mobx-react';
import  ProjectsStore  from './store/ProjectsStore';
import { QueryClient, QueryClientProvider } from 'react-query';


const queryClient = new QueryClient();

const stores = {
    projectsStore: new ProjectsStore()
}

ReactDOM.render(
    <LocaleProvider locale={deDE}>
    <Router>
        <Provider {...stores}>
        <QueryClientProvider client={queryClient}>
        <App />
        </QueryClientProvider>
        </Provider>
    </Router>
    </LocaleProvider>
    , 
    document.getElementById('root')
);

registerServiceWorker();
