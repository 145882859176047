import { Card, Descriptions, Tag, Button } from 'antd'
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { capitalize, formatCurrency, getColorToneForSummary } from '../util/Helpers';
import { ReloadOutlined } from '@ant-design/icons'
import { getFgAccountSummary } from '../util/APIUtils';
import { SmileTwoTone, CloseCircleTwoTone, CheckCircleOutlined } from '@ant-design/icons';


const FgAmountSummary = (props) => {
    const { t } = useTranslation();
    const id = props.id;
    const [loading, setLoading] = React.useState(false);
    const [accountSummary, setAccountSummary] = React.useState({});

    const loadAccountSummary = React.useCallback(async () => {
        console.log("calling API")
        let promise;
        promise = getFgAccountSummary(id);

        if (!promise) {
            return;
        }

        setLoading(true);
        promise.then(response => {
            setAccountSummary(response);
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false);
        });
    }, [])

    React.useEffect(() => {
        loadAccountSummary();
    }, [loadAccountSummary]);

    return (
        <Card bordered={true} loading={loading}>
            {<Descriptions loading={loading} bordered bodyStyle={{ background: "#fefee3", paddingTop: 25 }} 
            size={"small"}
            >
                <Descriptions.Item label={t("project_details.bewillig_status")} labelStyle={{ fontWeight: 'bold' }} span={2}>
                    <Tag style={{fontWeight: 600, minWidth: "75px"}}  color={getColorToneForSummary(accountSummary.status)}>
                        {capitalize(accountSummary.status)}
                    </Tag>
                </Descriptions.Item>

                <Descriptions.Item label={t("project_details.fg_status")} labelStyle={{ fontWeight: 'bold' }}>
                    <Tag style={{fontWeight: 600 , minWidth: "75px"}}  color={getColorToneForSummary(accountSummary.statusFg)}>
                        {capitalize(accountSummary.statusFg)}
                    </Tag>
                </Descriptions.Item>

                <Descriptions.Item label="Betrag" labelStyle={{ fontWeight: 'bold' }} span={2}>
                    <Tag color="grey" style={{fontWeight: 600 , minWidth: "75px"}}>{formatCurrency(accountSummary.amount)}</Tag>
                </Descriptions.Item>

                <Descriptions.Item label={t("project_details.fg_amount")} labelStyle={{ fontWeight: 'bold' }}>
                    <Tag color="grey"  style={{fontWeight: 600, minWidth: "75px"}}>{formatCurrency(accountSummary.amountFg)}</Tag>
                </Descriptions.Item>

                <Descriptions.Item label={t("project_details.payout_amount")} labelStyle={{ fontWeight: 'bold' }} span={2}>
                    <Tag color="grey" style={{fontWeight: 600, minWidth: "75px"}}>{formatCurrency(accountSummary.payoutAmount)}</Tag>
                </Descriptions.Item>

                <Descriptions.Item label={t("project_details.breeder_share")} labelStyle={{ fontWeight: 'bold' }}>
                    <Tag color="grey" style={{fontWeight: 600, minWidth: "75px"}}>{formatCurrency(accountSummary.breederShare)}</Tag>
                </Descriptions.Item>

            </Descriptions>}
        </Card>
    )
}

export default FgAmountSummary