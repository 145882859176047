import React, { Component } from 'react'
import {
    Badge,
    Card,
    Descriptions,
    Divider,
    Table,
    Layout,
    Input,
    Row,
    Col,
    Typography,
    PageHeader,
    Select,
    List,
    Avatar,
    Tag,
    Space,
    Button,

} from 'antd';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { getAllBreeders, getAllCrops, getCropsForBreeder, getForecastForBreederCrop } from '../util/APIUtils';

import { dynamicSort } from '../util/Helpers';

export class CropForecast extends Component {

    constructor(props) {
        super(props);
        this.state = {
            year: null,
            breederId: null,
            cropId: null,
            breeders: [],
            crops: [],
            forecasts: [],
            isLoading: false,
        };
    }

    fetchBreeders() {
        this.setState({ isLoading: true });
        let promise;
        promise = getAllBreeders();
        if (!promise) {
            return;
        }

        promise.then(response => {
            this.setState({
                breeders: response.sort(dynamicSort("firstName")),
                isLoading: false
            })

        }).catch(error => {
            this.setState({ isLoading: false });
        });
    }

    fetchCrops(breederId) {
        this.setState({ isLoading: true });
        let promise;
        promise = getCropsForBreeder(breederId);
        // promise = getAllCrops();
        if (!promise) {
            return;
        }

        promise.then(response => {

            this.setState({
                crops: response.sort(dynamicSort("shortName")),
                isLoading: false
            })

        }).catch(error => {
            this.setState({ isLoading: false });
        });
    }


    fetchForecasts(cropId) {
        console.log("Fetching forecasts")
        this.setState({ isLoading: true });
        let promise;
        promise = getForecastForBreederCrop(this.state.breederId, cropId);
        if (!promise) {
            return;
        }

        promise.then(response => {
            this.setState({
                forecasts: response.sort(dynamicSort("year")).reverse(),
                isLoading: false
            })

        }).catch(error => {
            this.setState({ isLoading: false });
        });
    }

    handleBack = e => {
        this
            .props
            .history
            .goBack();
    };

    handleBreederSelection = (val) => {

        this.setState({
            crops: [],
            forecasts: [],
            breederId: val
        });

        this.fetchCrops(val);

    }

    handlCropSelection = (val) => {
        this.setState({
            cropId: val,
            forecasts: []
        })

        console.log(this.state.cropId)
        console.log(this.state.breederId)
        if (this.state.breederId && val) {
            this.fetchForecasts(val);
        }
    }


    componentDidMount() {
        this.fetchBreeders();
        // this.fetchCrops();
    }

    componentDidUpdate(nextProps) {
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                breeders: [],
                // page: 0, size: 10, totalElements: 0, totalPages: 0, last: true,
                isLoading: false
            });
            this.fetchBreeders();
            // this.fetchCrops();
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    onBack={() => this.handleBack()}
                    title={"Kulturart Ausblick"}
                    // extra={<Button type='primary'>Add</Button>}
                // subTitle={this.state.farmProfile.name}
                />
                <Card bordered={true} headStyle={{ padding: "0" }} >

                    <Space>
                        <Select style={{ width: '300px' }} onChange={this.handleBreederSelection} >
                            {this.state.breeders.map(item => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.shortName + " : " + item.firstName + " " + item.lastName}
                                </Select.Option>
                            ))}
                        </Select>
                        {this.state.crops.length > 0 &&
                            <Select style={{ width: '300px' }} onChange={this.handlCropSelection}  placeholder={"Select Crop"} >
                                {this.state.crops.map(item => (
                                    <Select.Option key={0} value={0}>
                                    Select Crop
                                </Select.Option>,
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.shortName + " : " + item.name}
                                    </Select.Option>
                                ))}
                            </Select>}
                    </Space><Divider></Divider>
                    <List
                        itemLayout="horizontal"
                        dataSource={this.state.forecasts}
                        loading={this.state.isLoading}
                        renderItem={item => (

                            <List.Item>

                                <List.Item.Meta
                                    avatar={<Tag color="#2db7f5">{item.year}</Tag>}
                                    title={<Card title="Hintergrund"  size='small' >
                                    {item.background}
                                </Card>}
                                    description={<span>
                                        <Card title="Tätigkeit" size='small'>
                                            {item.activity}
                                        </Card>
                                        <Card title="Ausblick"  size='small'>
                                        {item.outlook}
                                    </Card></span>
                                    }
                                />

                            </List.Item>

                        )}
                    />
                </Card>
            </div>
        )
    }
}

export default withTranslation()(withRouter(CropForecast));
