import React, { Component } from 'react';
import { login } from '../../util/APIUtils';
import './Login.css';
import { Link } from 'react-router-dom';
import { ACCESS_TOKEN } from '../../constants';
import logo from '../../assets/ks-logo.png';

import { Form, Input, Button, Checkbox, notification, Card, Row, Col, Image } from 'antd';
import PasswordRetrievalModal from '../management/PasswordRetrieval';


const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const Login = ({ onLogin, isAuthenticated }) => {

  const onFinish = (values) => {
    console
    const loginRequest = Object.assign({}, values);
    login(loginRequest)
      .then(response => {
        console.log(response);
        localStorage.setItem(ACCESS_TOKEN, response.accessToken);
        onLogin();
      }).catch(error => {
        if (error.status === 401) {
          notification.error({
            message: 'Kultursaat',
            description: 'Ihr Benutzername oder Passwort ist falsch. Bitte versuchen Sie es erneut!'
          });
        } else {
          notification.error({
            message: 'Kultursaat',
            description: error.message || 'Entschuldigung! Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut!'
          });
        }
      });

  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <Row style={{
        padding: '100px 0 10px 0',
      }}
        justify={'center'}>
        <Col >
          <img
            width={150}
            src={logo}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 12, offset: 6 }} lg={{ span: 8, offset: 8 }}>
          <Card bordered={true} >
            <Form
              {...layout}
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Benutzername"
                name="usernameOrEmail"
                rules={[
                  {
                    required: true,
                    message: 'Bitte geben Sie Ihren Benutzernamen ein!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Passwort"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Bitte geben Sie Ihr Passwort ein!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
        <Checkbox>Remember me</Checkbox>
      </Form.Item> */}

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  Anmelden
                </Button>
                <PasswordRetrievalModal />
              </Form.Item>
            </Form>
          </Card>
        </Col>

      </Row>
    </div>

  );
};



export default Login;