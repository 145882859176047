import React from 'react'
import { Collapse, List, Spin, Divider } from 'antd';
function ReportValidation(props) {
    const loading = props.loading;
    console.log(props)

    return (
        <div>
            <List
                size="small"
                loading={loading}
                dataSource={props.reportValidationInfo.reportValidationFields}
                renderItem={(item) => <List.Item actions={[props.getIcon(item.status)]}>{item.displayName}</List.Item>}
            />

            {props.type == "VA" &&
                <List
                    size="small"
                    header={<b>{"Satze"}</b>}
                    loading={loading}
                    dataSource={props.reportValidationInfo.satzReportValidations || []}
                    renderItem={(item) => (
                        <List.Item actions={[]}>
                            <List.Item.Meta
                                title={<b>{item.itemName} {props.getIcon(item.itemStatus)}</b>}
                                description={<List
                                    size="small"
                                    loading={loading}
                                    dataSource={item.validationInfos}
                                    renderItem={(item) => <List.Item actions={[props.getIcon(item.status)]}>{item.displayName}</List.Item>}
                                />}
                            />
                        </List.Item>
                    )

                    }
                />

            }

            {props.type == "NZ" && <>

            {   (props.reportValidationInfo.lineValidations == null || !props.reportValidationInfo.lineCountOk) ?
                <List
                    size="small"
                    header={<b>Linie</b>}
                    loading={loading}
                    dataSource={props.getEmptyItem("lines")}
                    renderItem={(item) => <List.Item actions={[props.getIcon(item.status)]}>{item.displayName}</List.Item>}
                />
                :
                
                <List
                    size="small"
                    header={<b>{"Linie"}</b>}
                    loading={loading}
                    dataSource={props.reportValidationInfo.lineValidations}
                    // renderItem={(item) => <List.Item actions={[getIcon(item.status)]}>{item.itemName}</List.Item>}
                    renderItem={(item) => (
                        <List.Item actions={[]}>
                            <List.Item.Meta
                                title={<b>{item.itemName} {props.getIcon(item.itemStatus)}</b>}

                                description={<List
                                    size="small"
                                    loading={loading}
                                    dataSource={item.validationInfos}
                                    renderItem={(item) => <List.Item actions={[props.getIcon(item.status)]}>{item.displayName}</List.Item>}
                                />}
                            />
                        </List.Item>
                    )

                    }
                />
            }
            </>}
        </div>
    )
}

export default ReportValidation