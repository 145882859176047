import React from 'react'
import { TinyColumn, TinyArea } from '@ant-design/charts';
import { Statistic, Row, Col, Card } from 'antd';
import { getBudgetVsActual, formatCurrency } from '../../util/Helpers'

export default function ProjectCharts(props) {
    const finData = getBudgetVsActual(props.projectDetails);
    return (    
        <Statistic bodyStyle={{margin: "0px", padding: "0px"}} valueStyle={{ color: '#3f8600', fontSize: "16px" }} title={<div style={{ fontSize :"13px"}}>Gesamtkosten - Kalkuliert / Abgerechnet</div>} value={formatCurrency(finData.totalBudgets)} suffix= {"/" + formatCurrency(finData.totalActuals)} titleStyle={{margin: "0px", padding: "0px"}} />
            // <TinyColumn width={100} height={100} {...config} />
    )
}
