import React, { useState, useEffect, useRef } from 'react';
import { getBreedingMethods } from '../util/APIUtils';
import {
    Select,
} from 'antd';

function BreedingMethodLookup({projectType, setItems, setFormattedItems,  existingItems = [] }) {
    const [itemList, setItemList] = useState([])
    const [loading, setLoading] = React.useState(false);
    const [selectedItems, setSelectedItems] = useState(existingItems ? existingItems.map(a => a.id) : []);

    const fetchBreedingMethods = React.useCallback(async () => {
        let promise;
        promise = getBreedingMethods(projectType);
        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response)
            setItemList(response);
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, [])

    useEffect(() => {
        fetchBreedingMethods()
    }, [fetchBreedingMethods]);


    const handleChange = selectedItems => {
        let formattedItems = selectedItems.length > 0 ? selectedItems.map(elem => ({ id: parseInt(elem) })) : [];
        setSelectedItems(selectedItems);
        setItems(selectedItems);
        setFormattedItems(formattedItems)
    };

    const filteredOptions = itemList.filter(o => !selectedItems.includes(o))
    return (
        <>
            <Select
                mode="multiple"
                loading={loading}
                placeholder="Gegenstände auswählen"
                value={[...new Set(selectedItems)]}
                onChange={handleChange}
                style={{ width: '100%' }}
            >
                {filteredOptions.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                        {item.name} - {item.projectType}
                    </Select.Option>
                ))}
            </Select>

        </>
    );
}

export default BreedingMethodLookup