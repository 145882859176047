import React, { useState } from 'react';
import { Modal, Form, Input, Button, notification } from 'antd';
import { changePassword } from '../../util/APIUtils'; 

const PasswordChangeForm = (props) => {
  const [visible, setVisible] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      notification.error({
        message: 'Fehler',
        description: 'Die neuen Passwörter stimmen nicht überein.',
      });
      return;
    }

    setIsLoading(true);

    let passwordObj = {
        oldPassword: currentPassword,
        password : newPassword,
        };

    try {
        let promise = changePassword(passwordObj);
        if (!promise) {
            return;
        }

        promise.then(response => {
            setIsLoading(false);
            notification.success({
                message: 'Kultursaat',
                description: "Passwort erfolgreich geändert"
            });
            // props.history.push("/login")

        }).catch(error => {
            console.log(error)
            setIsLoading(false);
            notification.error({
                message: 'Fehler',
                description: "Fehler beim Zurücksetzen des Passworts."
            });
        });
    } catch (error) {
      notification.error({
        message: 'Fehler',
        description: 'Fehler beim Ändern des Passworts.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Passwort ändern
      </Button>
      <Modal
        title="Passwort ändern"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        centered
      >
      
    <Form onFinish={handleSubmit} layout="vertical">
      <Form.Item
        label="Aktuelles Passwort"
        name="currentPassword"
        rules={[{ required: true, message: 'Bitte geben Sie Ihr aktuelles Passwort ein!' }]}
      >
        <Input.Password value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} />
      </Form.Item>

      <Form.Item
        label="Neues Passwort"
        name="newPassword"
        rules={[{ required: true, message: 'Bitte geben Sie Ihr neues Passwort ein!' }]}
      >
        <Input.Password value={newPassword} onChange={e => setNewPassword(e.target.value)} />
      </Form.Item>

      <Form.Item
        label="Neues Passwort bestätigen"
        name="confirmPassword"
        rules={[{ required: true, message: 'Bitte bestätigen Sie Ihr neues Passwort!' }]}
      >
        <Input.Password value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Passwort ändern
        </Button>
      </Form.Item>
    </Form>
    </Modal>
    </>
  );
};

export default PasswordChangeForm;
