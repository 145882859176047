import React from 'react'
import { Collapse, List, Spin, Divider } from 'antd';
function PlanValidation(props) {
    console.log(props)
    const loading = props.loading;
    return (
        <div>

            <List
                size="small"    
                loading={loading}
                dataSource={props.planValidationInfo.planValidationFields}
                renderItem={(item) => <List.Item actions={[props.getIcon(item.status)]}>{item.displayName}</List.Item>}
            />
            {/* <Divider></Divider> */}
            {props.planValidationInfo.accountingValidations?.length == 0 ?
                <List
                    size="small"
                    header={<b>Kalkulation - Tätigkeiten</b>}
                    loading={loading}
                    dataSource={props.getEmptyItem("accounting")}
                    renderItem={(item) => <List.Item actions={[props.getIcon(item.status)]}>{item.displayName}</List.Item>}
                />
                :
                <List
                    size="small"
                    loading={loading}
                    header={<b>Kalkulation - Tätigkeiten</b>}
                    dataSource={props.planValidationInfo.accountingValidations}
                    renderItem={(item) => <List.Item actions={[props.getIcon(item.status)]}>{item.displayName}</List.Item>}
                />}
            {props.planValidationInfo.examinationValidations?.length == 0 ?
                <List
                    size="small"
                    header={<b>Kalkulation - Untersuchungen</b>}
                    loading={loading}
                    dataSource={props.getEmptyItem("examination")}
                    renderItem={(item) => <List.Item actions={[props.getIcon(item.status)]}>{item.displayName}</List.Item>}
                />
                :
                <List
                    size="small"
                    header={<b>Kalkulation - Untersuchungen</b>}
                    loading={loading}
                    dataSource={props.planValidationInfo.examinationValidations}
                    renderItem={(item) => <List.Item actions={[props.getIcon(item.status)]}>{item.displayName}</List.Item>}
                />}

            {props.type == "VA" &&
                <List
                    size="small"
                    header={<b>{"Satze"}</b>}
                    loading={loading}
                    dataSource={props.planValidationInfo.satzPlanValidations}
                    renderItem={(item) => (
                        <List.Item actions={[]}>
                            <List.Item.Meta
                                title={<b>{item.itemName} {props.getIcon(item.itemStatus)}</b>}
                                description={<List
                                    size="small"
                                    loading={loading}
                                    dataSource={item.validationInfos}
                                    renderItem={(item) => <List.Item actions={[props.getIcon(item.status)]}>{item.displayName}</List.Item>}
                                />}
                            />
                        </List.Item>
                    )

                    }
                />

            }
        </div>
    )
}

export default PlanValidation