import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getFarmLocation } from '../../util/APIUtils';
import { SmileTwoTone, CloseCircleTwoTone , CheckCircleTwoTone } from '@ant-design/icons';
import { withTranslation  } from "react-i18next";

import {
    Button,
    Card,
    Descriptions,
    Divider,
    Table,
    Layout,
    AutoComplete,
    Row,
    Col,
    Typography,
    PageHeader,
    List,
    Avatar,
    Space,

} from 'antd';

class LocationDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            location: {}
        };
    }

    getFarmLocationById(id) {
        console.log('Loading Locations')
        let promise;
        promise = getFarmLocation(id);

        if (!promise) {
            return;
        }
        this.setState({ isLoading: true });
        promise.then(response => {
            this.setState({
                location: response,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    componentDidMount() {
        const { id } = this.props.match.params
        this.getFarmLocationById(id);
    }

    
    componentDidUpdate(nextProps) {
        const { id } = nextProps.match.params
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                isLoading: false,
                farmProfileId: id
            });
            // this.loadLocations(id);
            this.getFarmLocationById(id);
            
        }
    }

    handleBack = e => {
        this
            .props
            .history
            .goBack();
    };
    render() {
        const { t } = this.props;
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    onBack={() => this.handleBack()}
                    extra={[<Button key="334" type="primary">{t("general.edit")}</Button>, <Button key="34" type="primary" danger>{t("general.delete")}</Button>]}
                    title={t("farmprofile.farm_plots")}
                    subTitle="" />
                     
                <Card bordered={true} loading={this.state.isLoading} headStyle={{ padding: "0" }}>
                <Descriptions
                        size="small"
                        column={2}
                        bordered="true"
                        style={{
                            marginBottom: 20
                        }}>
                        <Descriptions.Item label={t("general.name")} ><b>{this.state.location.locationName}</b></Descriptions.Item>
                        <Descriptions.Item label={t("farmprofile.coords")}>{this.state.location.lat + " " + this.state.location.lon}</Descriptions.Item>
                        <Descriptions.Item label={t("farmprofile.farm_profile")}>{this.state.location.farmProfileName}</Descriptions.Item>
                        <Descriptions.Item label={t("farmprofile.primary_location")}>{this.state.location.primaryLocation === 1?<CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="red" />}</Descriptions.Item>

                </Descriptions>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={24}>
                            <Card bordered={true}  >
                               
                            </Card>
                        </Col>
                       
                    </Row>
                </Card>

            </div>
        )
    }
}

export default withTranslation()(withRouter(LocationDetail));
