import React, { useState, useEffect } from 'react';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  PageHeader,
  Divider,
  Row,
  Col,
  Card,
  Descriptions,
  InputNumber,
  message,
  Select,
  Modal,
  Table,
  Button,
  Space,
  Input,
  Form,
  DatePicker,
  Popconfirm,
  Result
} from 'antd';
import { formatCurrency } from '../util/Helpers';
import { editPayout, refreshPayoutDetail } from '../util/APIUtils';
import RateCard from './RateCard';

function PayoutDetail1(props) {
  const [loading, setLoading] = useState(false);
  const [validating, setValidating] = useState(false);
  const [currentPayoutInfo, setCurrentPayoutInfo] = useState(props.payoutInfo);
  const [payoutArray, setPayoutArray] = useState(props.payoutInfo.payoutDetailList);
  const [visible, setVisible] = useState(false);
  const [validated, setValidated] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editable, setEditable] = useState(false);
  const [record, setRecord] = useState({});
  const [formRecord, setFormRecord] = useState({});
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [specialPayoutAmount, setSpecialPayoutAmount] = useState(props.payoutInfo.specialPayoutAmount);
  const [totalPayoutAmount, setTotalPayoutAmount] = useState(props.payoutInfo.totalPayoutAmount);
  const [difference, setDifference] = useState(0.0);
  const [mainRemarks, setMainRemarks] = useState(props.payoutInfo.remarks);
  const breederView = props.breederView

  const handleOk = (values) => {
    console.log(values);

    let payoutDetailObj = {
      "id": currentPayoutInfo.id,
      "specialPayoutAmount": specialPayoutAmount,
      "remarks": mainRemarks
    }

    let promise;
    promise = editPayout(payoutDetailObj);

    if (!promise) {
      return;
    }
    setLoading(true)

    promise.then(response => {
      console.log(response)
      setCurrentPayoutInfo(response)
    }).catch(error => {
      console.log(error);
    });

    console.log("Saved")
    setVisible(false);
    setConfirmLoading(false);
    setEditing(false);
    setEditable(false);
  };

  const refreshRates = () => {
    let promise;
    promise = refreshPayoutDetail(currentPayoutInfo.id);

    if (!promise) {
      return;
    }
    setLoading(true)

    promise.then(response => {
      console.log("Submitted")
      console.log(response);
      setPayoutArray(response);
    }).catch(error => {
      console.log(error);
    });
  };

  const handleSpecialAmountChange = (e) => {
    if (!e) { e = 0 }
    console.log(e)
    console.log(currentPayoutInfo.calculatedPayoutAmount)
    setSpecialPayoutAmount(e);
    if (!currentPayoutInfo.calculatedPayoutAmount) { currentPayoutInfo.calculatedPayoutAmount = 0 }
    setTotalPayoutAmount(parseFloat(currentPayoutInfo.calculatedPayoutAmount) + parseFloat(e));
  }

  const handleRemarksChange = (e) => {
    setMainRemarks(e.target.value);
  }

  console.log(currentPayoutInfo)
  const { t } = props;

  return (
    <div>

      <Card bordered={true} loading={props.loading} >
        <Descriptions
          extra={
            !breederView ?
              <Space style={{ width: '100%', align: 'right' }}>
                {!editing &&
                  <Space>
                    <Button type="primary" size="small" onClick={() => setEditing(true)} disabled={!currentPayoutInfo.id}>
                      Bearbeiten
                    </Button>
                    <Popconfirm title="Löschen?" onConfirm={() => props.deletePay(currentPayoutInfo.id)}>
                      <Button type="danger" size="small"  disabled={!currentPayoutInfo.id}>
                        Löschen
                      </Button>
                                          </Popconfirm>
                  </Space>
                }
                {editing &&
                  <Space><Button size="small" onClick={() => setEditing(false)}>
                    Abbrechen
                  </Button><Button color='green' type="primary" size="small" onClick={handleOk}>
                      Speichern
                    </Button></Space>}
              </Space> : ""}
          bordered
          size='small'
          labelStyle={{
            fontWeight: 'bold'
          }}
          style={{ marginBottom: '15px' }}
        // title={currentPayoutInfo.breederName + " - " + currentPayoutInfo.year}
        >
          <Descriptions.Item label={"Bewilligt"} span={3} labelStyle={{ width: '20%' }}>
            <b>{currentPayoutInfo.calculatedPayoutAmount != null || currentPayoutInfo.calculatedPayoutAmount == 0
              ? formatCurrency(currentPayoutInfo.calculatedPayoutAmount.toFixed(2))
              : "0.00"}
            </b>
          </Descriptions.Item>
          <Descriptions.Item label={"Sonderzahlung"} span={3}>
            <b>
              {editing ?
                <InputNumber name="specialPayoutAmount" value={specialPayoutAmount} onChange={handleSpecialAmountChange} />
                : specialPayoutAmount != null || specialPayoutAmount == 0
                  ? formatCurrency(specialPayoutAmount.toFixed(2))
                  : "0.00"}

            </b>
          </Descriptions.Item>
          <Descriptions.Item label={"Gesamtbetrag"} span={3}>
            <b>
              {totalPayoutAmount != null || totalPayoutAmount == 0
                ? formatCurrency(totalPayoutAmount.toFixed(2))
                : "0.00"}</b>
          </Descriptions.Item>
          <Descriptions.Item label={"Bemerkung"} span={4}>
            {editing ?
              <Input.TextArea autoSize name="remarks" value={mainRemarks} onChange={handleRemarksChange} />
              : mainRemarks}
          </Descriptions.Item>
        </Descriptions>

        {/* <Space>
                        <Table
                            columns={columns}
                            dataSource={currentPayoutInfo.payoutDetailList}
                            pagination={false}></Table>
                    </Space> */}
        <Space size={'large'} direction='vertical'>

          {!breederView ?

            <Space> {!editing && currentPayoutInfo.id && <Button color='green' type="primary" size="small" onClick={refreshRates}>Raten Berechnen</Button>}
              {!currentPayoutInfo.id && <Button color='green' type="primary" size="small" onClick={props.generate}>Raten generieren</Button>}
            </Space> : ""}

        </Space>
        {payoutArray != null && <RateCard payoutInfo={currentPayoutInfo} payoutArray={payoutArray} totalPayoutAmount={totalPayoutAmount} />}
      </Card>
    </div>
  )
}

export default PayoutDetail1

