import React, { useState, useEffect } from 'react';
import {
    PageHeader,
    Card,
    Descriptions,
    Select,
    Empty,
    Button,
    Result,
    Space,
    notification
} from 'antd';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { getPayoutsForBreeder, getYears, generatePayout, deletePayout, getAllBreeders, getPayoutsForBreederYear } from '../util/APIUtils';
import { v4 as uuid } from "uuid";
import PayoutDetail1 from './PayoutDetail1';
import BreederSelect from '../common/BreederSelect';
import YearSelect from '../common/YearSelect';
import BreederSelectForm from '../common/BreederSelectForm';

function Payout(props) {
    
    const [year, setYear] = useState(props.location.state?.year || null);
    const [loading, setLoading] = useState(false);
    const [breederId, setBreederId] = useState(props.match.params?.id || null);
    const [breeders, setBreeders] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [projectYears, setProjectYears] = useState([]);
    const [payoutInfo, setPayoutInfo] = useState([]);
    const [currentPayoutInfo, setCurrentPayoutInfo] = useState(null);

    const breederView = props.breederView == 1019

    console.log(props)

    const fetchPayoutInfo = (breederId, year) => {
        console.log(breederId);
        console.log(year);

        if (breederId && year) {
            console.log("Both set");
            setLoading(true);
            setYear(year);
            setBreederId(breederId);
            let promise = getPayoutsForBreederYear(breederId, year);
            if (!promise) {
                return;
            }

            promise.then(response => {
                console.log("Loaded Payoutinfo");
                console.log(response);
                setPayoutInfo(response);
                setCurrentPayoutInfo(response);
                setLoading(false);
                console.log(currentPayoutInfo);
            }).catch(error => {
                setLoading(false);
            });
        } else {
            setPayoutInfo([]);
            setCurrentPayoutInfo(null);
            setLoading(false);
        }
    };

    const generate = () => {
        setLoading(true);
        let promise = generatePayout(year, breederId);
        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response);
            setPayoutInfo(response);
            console.log(breederId);
            fetchPayoutInfo(breederId, year);
            console.log(currentPayoutInfo);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        });
    };

    const deletePay = (payoutId) => {
        setLoading(true);
        let promise = deletePayout(payoutId);
        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response);
            setLoading(false);
            if(response) {
                notification.success({ message: 'Kultursaat', description: "Erfolgreich gelöscht" });
                props.history.goBack();
            }
            
            
        }).catch(error => {
            console.log(error);
            setLoading(false);
        });
    };

    const handleBreederInput = (e) => {
        console.log(e);
        setLoading(true);
        setBreederId(e);
        setTimeout(() => {
            fetchPayoutInfo(e, year);
        }, 200);
    };

    const handleYearInput = (e) => {
        setLoading(true);
        setYear(e);
        setTimeout(() => {
            fetchPayoutInfo(breederId, e);
        }, 200);
    };

    const handleBack = e => {
        props.history.goBack();
    };

    useEffect(() => {
        const { id, year } = props.match.params;
        console.log(id);
        console.log(year);
      
        if (id && year) {
          fetchPayoutInfo(id, year);
        }
      }, [props.match.params.id, props.match.params.year]);

    

        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    onBack={() => handleBack()}
                    title={"Auszahlung"}
                    subTitle="" />
                <Card loading={loading}>
                    <Descriptions >
                    {!breederView ?<Descriptions.Item label={"Züchter"} labelStyle={{ width: '30%' }}>
                            
                            <BreederSelectForm onChange={handleBreederInput}
                                value={breederId} />
                           
                        {/* <BreederSelect
                                setItem={handleBreederInput}
                                selectedItem={breederId} />  */}
                                
                        </Descriptions.Item>: ""}
                        <Descriptions.Item >
                            <YearSelect setItem={handleYearInput} selectedItem={year} />
                            {/* <Select
                                placeholder={"Jahr"}
                                style={{
                                    width: '100px'
                                }}
                                onChange={handleYearInput}
                                defaultValue={year}>
                                {projectYears
                                    .map(item => (
                                        <Select.Option key={item.projectYear} value={item.projectYear}>
                                            {item.projectYear}
                                        </Select.Option>
                                    ))}
                            </Select> */}
                        </Descriptions.Item>
                        {/* <Descriptions.Item><Button onClick={fetchPayoutInfo}>{t("general.submit")}</Button></Descriptions.Item> */}
                    </Descriptions>
                </Card >
                {currentPayoutInfo
                    ?
                    <PayoutDetail1
                        breederView={breederView}
                        payoutInfo={currentPayoutInfo}
                        key={uuid()}
                        loading={loading} 
                        generate={generate}
                        deletePay={deletePay}
                        />
                    :
                    <span > 
                    </span>
                }
            </div>
        );
    
}

export default Payout;