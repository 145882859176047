import React from 'react'
import { Button, Divider, PageHeader, Card } from 'antd';
import { getValidationResultsForAllProjects } from '../util/APIUtils';
import ProjectValidation from './ProjectValidation';
import ValidationAllItem from './ValidationAllItem';
import FgBudgetValidation from './FgBudgetValidation';

function ValidationAll(props) {
    const [loading, setLoading] = React.useState(false)
    const [allProjectValidations, setAllProjectValidations] = React.useState([])
    const currentUser = props.currentUser;

    const breederId = currentUser?.breederInfo.id;

    const validateAllProjects = React.useCallback(async () => {
        let promise;
        setLoading(true)
        promise = getValidationResultsForAllProjects(breederId);
        if (!promise) {
            return;
        }

        promise.then(response => {
            setAllProjectValidations(response);
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, [])

    React.useEffect(() => {
        validateAllProjects()
    }, [validateAllProjects]);
    return (
        <div>
            <PageHeader
                className="site-page-header"
                title={"Prüfen"}
                subTitle=""
                extra={<Button loading={loading} type='primary' size='small' style={{backgroundColor: 'green'}} onClick={validateAllProjects}>Prüfen</Button>}
            />
            <Card bordered={true} loading={loading}
            >
                <Divider orientation="left">Projekte</Divider>
                {allProjectValidations.map(item => <div><ValidationAllItem validationResults={item} /><br></br></div>)}
                <Divider orientation="left">FG Kosten</Divider>
                <FgBudgetValidation breederId={breederId} />
            </Card>


        </div>
    )
}

export default ValidationAll