import React, { useState, useEffect, useRef } from 'react';
import {
    Card,
    List,
    Badge,
    Input,
    Tag,
    Form,
    Button,
    InputNumber,
    DatePicker,
    Select,
    Descriptions,
    message,
    Checkbox,
    Space,
} from 'antd';
import _ from "lodash";
import { CheckCircleOutlined } from '@ant-design/icons';
import './Plan.css';
import { useTranslation } from 'react-i18next';
import { saveProjectDetail, getLineSelections } from '../../util/APIUtils';
import { capitalize, formatCurrency } from '../../util/Helpers';
import MultiSelect from '../../common/MultiSelect';
import AmountSummary from './AmountSummary';
import { isPlanEditable } from '../../util/ProjectStatusHelper';
import shortid from 'shortid';
import LineSelectionLookupWithAdd from '../../common/LineSelectionLookupWithAdd';


function getColorToneForStatus(statusText) {
    let status;
    switch (statusText) {
        case "ok":
            status = "#55a630";
            break;
        case "zurückgestellt":
            status = "#ff6d00";
            break;
        case "abgelehnt":
            status = "#ff6b6b";
            break;
        default:
            status = "";
    }
    return status
}


function processLineSelections(arr, cropId) {
    const bList = arr
        .filter(function (el) {
            return el.cropId == cropId
        })
    return bList;
}

function onReset() {
    this.formRef.current.resetFields();
};


function Plan(props) {
    const { t } = useTranslation();
    const [editing, setEditing] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const workFlowState = props.workFlowState;

    const [allLineSelections, setAllLineSelections] = useState([])
    const lineSelections = props.lineSelections;
    const [state, setState] = useState(props.projectDetail);
    const editable = props.isPlanEditable

    const projectType = "NZ";
    let screenState = {
        "year": state.year,
        "tab": '1'
    }
    const cropId = props.projectInfo.cropId;
    console.log(props)

    let fetchLines = React.useCallback(async () => {
        setLoading(true)
        let promise;
        promise = getLineSelections(cropId);
        if (!promise) {
            return;
        }

        promise.then(response => {
            setAllLineSelections(response);
            setLoading(false)

        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, [])

    // const fetchBreedingMethods = React.useCallback(async () => {
    //     let promise;
    //     promise = getBreedingMethods(projectType);
    //     if (!promise) {
    //         return;
    //     }

    //     promise.then(response => {
    //         setBreedingMethods(response);
    //         // console.log(response)
    //         setLoading(false)
    //     }).catch(error => {
    //         console.log(error)
    //         setLoading(false)
    //     });
    // }, [])

    useEffect(() => {
        fetchLines();
        // fetchBreedingMethods();
    }, [fetchLines, 
        // fetchBreedingMethods
    ]);


    const lineSelectionChildren = [];
    allLineSelections.forEach(element => {
        lineSelectionChildren.push(<Option label={element.name} value={element.id} key={element.id}>{element.name}</Option>);
    });


    // const breedingMethodsChildren = [];
    // breedingMethods.forEach(element => {
    //     breedingMethodsChildren.push(<Option label={element.name} value={element.id} key={element.id}>{element.name}</Option>);
    // });


    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeOther = name => value => {
        console.log(value)
        if (name === 'lineSelections') {
            value = value.length > 0 ? value.map(elem => ({ id: parseInt(elem) })) : [];
        } else if (name === 'breedingMethodList') {
            value = value.length > 0 ? value.map(elem => ({ id: parseInt(elem) })) : [];
        } else if (name === "ks_plan") {
            value = value.target.checked ? 1 : 0;
        }
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const onFinish = (values) => {

        var subsetObj = _.pick(state, [
            "id",
            "projectNumber",
            "description",
            "plannedActivity",
            "lineSelections",
            "breedingMethodList",
            "numberOfPlannedLines",
            "projectExtension",
            "generationStart",
            "generationEnd",
            "descBreedingMethod",
            "cultivationScope",
            "cultivationScopeUnit",
            "priority",
            "ks_plan"
        ]);

        let screenState = {
            "year": state.year,
            "tab": '1'
        }

        let promise;

        console.log(subsetObj)
        promise = saveProjectDetail(subsetObj);

        if (!promise) {
            return;
        }

        promise.then(response => {
            message.success("Projektplan aktualisiert")
            props.reloadProject(screenState);

        }).catch(error => {
            message.error("Aktualisierung des Projektplans fehlgeschlagen")
        });
    };

    const [form] = Form.useForm();

    const allBreedingMethods = props.breedingMethods.filter(function (el) {
        return el.projectType == props.projectInfo.projectType
    });

    let breedingMethods = state.breedingMethodList.map(a => a.name);
    let existingBreedingMethods = state.breedingMethodList.map(a => a.id);

    form.setFieldsValue({
        description: state.description,
        planned_activity: state.plannedActivity,
        youngest: state.generationStart,
        oldest: state.generationEnd,
        breeding_method_description: state.descBreedingMethod,
        priority: state.priority,
        no_planned_lines: state.numberOfPlannedLines,
        cultivation_scope: state.cultivationScope ? parseInt(state.cultivationScope) : 0,
        cultivation_scope_unit: state.cultivationScopeUnit,
        ks_plan: state.ks_plan,
        lineSelections: state.lineSelections.map(a => a.id)
    });

    const setEditingLocal = (val) => {
        setEditing(val);
        props.setEditingMaster(val);
    }

    return (
        <div>
            <Card bordered={true} loading={loading}
             extra={
                editable ?
                !editing ?
                    <Button onClick={() => setEditingLocal(true)}>Bearbeiten</Button> :
                    <Space>
                        <Button onClick={() => form.submit()} type='primary'>Speichern</Button>
                        <Button onClick={() => setEditingLocal(false)}>Abbrechen</Button>
                    </Space>: ""
                    
                }
                    >
                <Form
                    // ref={this.formRef}
                    name="plan-x"
                    span={3}
                    form={form}
                    onFinish={onFinish}
                    scrollToFirstError
                >
                    <Descriptions
                        size="small"
                        bordered
                        style={{
                            marginBottom: 2
                        }}>
                        <Descriptions.Item label={t("project_details.description")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} style={{ width: '400px' }}>
                            {editing && editable ?
                                <Input.TextArea name="description" value={state["description"]} onChange={handleChange} />
                                : state["description"]}
                        </Descriptions.Item>
                        <Descriptions.Item span={3} label={t("project_details.planned_activity")} labelStyle={{ fontWeight: 'bold', width: 250 }}>
                            {editing && editable ?
                                <Input.TextArea name="plannedActivity" value={state["plannedActivity"]} onChange={handleChange} />
                                : state["plannedActivity"]}
                        </Descriptions.Item>

                        <Descriptions.Item label={t("project_details.starting_points")} labelStyle={{ fontWeight: 'bold' }} span={3}>
                            {editing && editable ?
                                <Form.Item
                                    name="lineSelections"
                                    hasFeedback
                                >
                                    {/* <Select mode="multiple" style={{ width: '100%' }} onChange={handleChange}>
                                    {childrenB}
                                </Select> */}

                                    <LineSelectionLookupWithAdd
                                        cropId={cropId}
                                        setItems={handleChangeOther("lineSelections")}
                                        existingItems={state.lineSelections}
                                        setFormattedItems={() => console.log("val")}
                                        projectInfo={props.projectInfo}
                                    />

                                    {/* <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Bitte auswählen"
                                    onChange={handleChangeOther("lineSelections")}
                                >
                                    {lineSelectionChildren}
                                </Select> */}



                                </Form.Item>
                                : 
                                <List
                                    size="small"
                                    // bordered
                                    dataSource={state.lineSelections}
                                    renderItem={item => <List.Item style={{ padding: 2 }}><Badge color={'geekblue'} text={item.name} /></List.Item>}
                                />
                                }

                        </Descriptions.Item>
                        <Descriptions.Item label={t("project_details.planned_lines")} labelStyle={{ fontWeight: 'bold' }} span={3} >
                            {editing && editable ?

                                // <Form.Item
                                //     name="no_planned_lines"
                                //     rules={[
                                //         {
                                //             required: true,
                                //             message: 'Needs to be filled',
                                //         },
                                //     ]}
                                // >
                                <InputNumber style={{ width: '100%' }} name="numberOfPlannedLines" value={state["numberOfPlannedLines"]} onChange={handleChangeOther("numberOfPlannedLines")} />
                                // </Form.Item>
                                : state.numberOfPlannedLines}
                        </Descriptions.Item>
                        {/* <Descriptions.Item label={t("project_details.project_delivery")} labelStyle={{ fontWeight: 'bold' }} span={2} span={3}>
                            {editing && editable ?
                                <Form.Item name="project_delivery" rules={[{ required: false }]}>
                                    <DatePicker picker="year" style={{ width: 360 }} />
                                </Form.Item> : props.projectInfo.generationStart}
                        </Descriptions.Item> */}
                        <Descriptions.Item label={t("project_details.age_of_lines_used")} labelStyle={{ fontWeight: 'bold' }} span={3}></Descriptions.Item>
                        <Descriptions.Item label={t("project_details.age_of_lines_used_youngest")} labelStyle={{ fontWeight: 'bold' }} style={{ width: '350px' }}>
                            {editing && editable ?
                                // <Form.Item
                                //     name='youngest'
                                //     noStyle
                                //     rules={[{ required: true, message: 'Needs to be filled' }]}
                                // >
                                <InputNumber placeholder={t("project_details.age_of_lines_used_youngest")} name="generationStart" value={state["generationStart"]} onChange={handleChangeOther("generationStart")} style={{ width: '350px' }} />
                                // </Form.Item>
                                : state.generationStart}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("project_details.age_of_lines_used_oldest")} labelStyle={{ fontWeight: 'bold' }} span={3}>
                            {editing && editable ?
                                // <Form.Item
                                //     name='oldest'
                                //     noStyle
                                //     rules={[{ required: true, message: 'Needs to be filled' }]}
                                // >
                                <InputNumber placeholder={t("project_details.age_of_lines_used_oldest")} name="generationEnd" value={state["generationEnd"]} onChange={handleChangeOther("generationEnd")} />
                                // </Form.Item>
                                : state.generationEnd}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("project_details.breeding_method")} labelStyle={{ fontWeight: 'bold' }} span={3}>
                            {editing && editable ?
                                // <Form.Item
                                //     name="breeding_method"
                                //     hasFeedback
                                // // initialValue={childrenA}
                                // // initialValue={[...new Set(breedingMethods)]}
                                // // rules={[
                                // //     {
                                // //         required: true,
                                // //         message: 'Needs to be filled',
                                // //     },
                                // // ]}
                                // >
                                <MultiSelect
                                    setItems={handleChangeOther("breedingMethodList")}
                                    existingItems={existingBreedingMethods}
                                    itemList={allBreedingMethods} />

                                // </Form.Item> 
                                : <List
                                    size="small"
                                    // bordered
                                    dataSource={breedingMethods}
                                    renderItem={item => <List.Item style={{ padding: 2 }}><Badge color={'blue'} text={item} /></List.Item>}
                                />}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("project_details.breeding_method_description")} labelStyle={{ fontWeight: 'bold' }} span={3}>
                            {editing && editable ?
                                // <Form.Item
                                //     name="breeding_method_description"
                                //     rules={[
                                //         {
                                //             required: true,
                                //             message: 'Please input',
                                //         },
                                //     ]}
                                // >
                                <Input.TextArea name="descBreedingMethod" value={state["descBreedingMethod"]} onChange={handleChange} />
                                // </Form.Item>
                                : state.descBreedingMethod}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("project_details.cultivation_scope")} labelStyle={{ fontWeight: 'bold' }} >
                            {editing && editable ?

                                <div>
                                    {/* <Form.Item
                                        name="cultivation_scope"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input',
                                            },
                                        ]}
                                    > */}
                                    <InputNumber name="cultivationScope" value={state["cultivationScope"]} onChange={handleChangeOther("cultivationScope")} />
                                    {/* </Form.Item> */}
                                    {/* <Form.Item
                                        name="cultivation_scope_unit"> */}
                                    <Select
                                        // value={value.currency || currency}
                                        style={{ width: '25%' }}
                                        name="cultivationScopeUnit"
                                        defaultValue={state.cultivationScopeUnit}
                                        onChange={handleChangeOther("cultivationScopeUnit")}
                                    >

                                        <Option value={"qm"}>qm</Option>
                                        <Option value={"ha"}>ha</Option>
                                        <Option value={"stück"}>stück</Option>
                                    </Select>

                                    {/* </Form.Item> */}
                                </div>
                                : state.cultivationScope + " " + state.cultivationScopeUnit}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("project_details.priority")} labelStyle={{ fontWeight: 'bold' }} span={4} >
                            {editing && editable ?
                                // <Form.Item
                                //     name="priority"
                                //     rules={[
                                //         {
                                //             required: true,
                                //             message: 'Please input ',
                                //         },
                                //     ]}
                                // >
                                <Select
                                    // value={value.currency || currency}
                                    style={{ width: '100%', margin: '0 0px' }}
                                    name="priority"
                                    defaultValue={state.priority}
                                    onChange={handleChangeOther("priority")}
                                // onChange={onCurrencyChange}
                                >
                                    <Option value={1}>Hoch</Option>
                                    <Option value={2}>Mittel</Option>
                                    <Option value={3}>Neidrig</Option>
                                </Select>
                                // </Form.Item>
                                : state.priority}
                        </Descriptions.Item>

                        <Descriptions.Item label={t("project_details.reason_for_extension")} labelStyle={{ fontWeight: 'bold' }} span={3} >
                            {state.planDeviation}</Descriptions.Item>
                        <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label={"bei Kultursaat einreichen"} span={3}>
                            <Checkbox disabled={!(editing && editable)} defaultChecked={state.ks_plan == 1 ? true : false} label={"bei Kultursaat einreichen"} onChange={handleChangeOther("ks_plan")}></Checkbox>
                        </Descriptions.Item>
                        {/* {editing && editable ?
                            <Form.Item >
                                <Space>
                                    <Button type="primary"
                                        htmlType="submit" >

                                        {t("general.submit")}
                                    </Button>
                                    <Button htmlType="button"
                                    // onClick={this.onReset}
                                    >
                                        {t("general.reset")}
                                    </Button>
                                </Space>
                            </Form.Item> : ""} */}
                    </Descriptions>
                    {editing && editable ? "" :
                        // <Badge.Ribbon text={state.ks_plan == 1 ? t("project_details.submitted_to_ks") : t("project_details.not_submitted")} color={state.ks_plan == 1 ? "green" : "volcano"}>
                        <AmountSummary key={shortid.generate()} projectDetailId={state.id} type={"plan"} workFlowState={workFlowState} reloadProject={() => props.reloadProject(screenState)} currentUser={props.currentUser} />
                        // </Badge.Ribbon>
                    }
                </Form>
            </Card>
        </div>
    );
}

export default Plan;