
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { Card, PageHeader, Select, Table, Typography, Divider, Checkbox, Space, Tag } from 'antd'
import {
    DataGrid, useGridApiContext, deDE, GridToolbarContainer, GridRowModes, GridToolbar,
    gridVisibleColumnDefinitionsSelector,
    gridVisibleSortedRowIdsSelector,
    GridActionsCellItem, GRID_CHECKBOX_SELECTION_COL_DEF
} from '@mui/x-data-grid';

import { getBsagLines, getVaExterns, getYears, updateVaExtern } from '../util/APIUtils';
import { formatCurrency } from '../util/Helpers';
import { CheckCircleOutline, HourglassBottomOutlined, WarningOutlined } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { blue, green, red } from '@mui/material/colors';
import moment from 'moment'
import YearSelect from '../common/YearSelect';

const BsagReport = (props) => {
    const [rows, setRows] = React.useState([]);
    const [years, setYears] = React.useState([]);
    const [selectedYear, setSelectedYear] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [filterModel, setFilterModel] = React.useState({
        items: [
            {
                columnField: 'varietyLevel',
                operatorValue: '>=',
                value: 3,
            }
        ],
    });

    const fetchYears = React.useCallback(async () => {
        console.log("Fetching years")
        setLoading(true)
        let promise;
        promise = getYears();
        if (!promise) {
            return;
        }

        promise.then(response => {
            setYears(response.reverse());
            setLoading(false)

        }).catch(error => {
            setLoading(false);
        });
    }, [])

    React.useEffect(() => {
        fetchYears();
    }, [fetchYears]);


    const loadBsagLines = (year) => {
        let promise;
        promise = getBsagLines(year);
        if (!promise) {
            return;
        }

        // setLoading(true)
        promise.then(response => {
            console.log(response)
            setRows(response)
            // setLoading(false)
        }).catch(error => {
            console.log(error)
            // setLoading(false)
        });
    }

    const handleYearInput = (val) => {
        setSelectedYear(val)
        console.log(val);
        loadBsagLines(val);
    }

    const stripDate = (dateString) => {

        let dtStr = ""
        if (!dateString) {
            return ""
        } else {
            dtStr = dateString.toString();
        }

        var date = moment(new Date(dtStr.substr(0, 16)));
        if (date.isValid) {
            return date.format("DD-MMM-YYYY")
        }

        if (moment.isMoment(dateString)) {
            return moment(dateString).format('YYYY-MM-DD');
        }

        if (dtStr.includes("00:00:00")) {
            return dtStr.replace(' 00:00:00', '');
        } else {
            return dtStr;
        }


    }

    const handleProcessRowUpdateError = React.useCallback((error) => {
        // setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const columns = [
        { field: 'projectId', headerName: 'projectId',  hide: true, flex: 1 },
        { field: 'id', headerName: 'projectDetailId', hide: true, flex: 1 },
        { field: 'projectNumber', headerName: 'Projektnummer', minWidth: 30, flex: 1 },
        { field: 'breederName', headerName: 'Züchter', minWidth: 70, flex: 1 },
        { field: 'cropName', headerName: 'Kulturart', minWidth: 45, flex: 1 },
        { field: 'varietyLevel', headerName: 'Sortenstufe', minWidth: 40, flex: 1, type: 'number' },
        { field: 'lineName', headerName: 'Linie', minWidth: 500, flex: 1, },
        { field: 'varietyDescription', headerName: 'Sortenbeschreibung', minWidth: 500, flex: 1, hide: true, },

    ];

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        border: 1,
        color:
            theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        fontSize: '14px',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            // backgroundColor: '#ccc',
            border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
        },
        '& .MuiDataGrid-columnHeader': {
            fontSize: '14px',
            height: 22,
            backgroundColor: '#f1f7ee',
        },
        '& .MuiDataGrid-cell': {
            wordWrap: "break-word !important",
            // paddingTop: "4px",
            // paddingBottom: "4px",
            color:
                theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
        },

        '&.MuiDataGrid-root .MuiDataGrid-cell ': {
            whiteSpace: "normal !important",
            wordWrap: "break-word !important",
        },
        '& .MuiPaginationItem-root': {
            borderRadius: 0,
        },
        '& .sum-row-sum': {
            fontSize: '8pt',
            bgcolor: (theme) =>
                getBackgroundColor(theme.palette.success.main, theme.palette.mode),
            '&:hover': {
                bgcolor: (theme) =>
                    getHoverBackgroundColor(
                        theme.palette.success.main,
                        theme.palette.mode,
                    ),
            },
        },

    }));

    const handleCellEditStopEvent = (e) => {
        console.log(e)
        e.preventDefault();
    }

    const setUpdatedState = (state) => {
        // console.log(state)
    }

       const csvOptions ={
            fileName: 'bsag-report-' + selectedYear,
            utf8WithBom: true,
        }

    return (
        <div>
            <PageHeader
                className="site-page-header"
                title="Sortenstufe Bericht"
                subTitle=""
            />
            <Card bordered={true} >
                <YearSelect selectedItem={selectedYear} setItem={handleYearInput}
                />
                
                <div style={{ display: 'flex', height: '100%', marginTop: '15px' }}>
                    <div style={{ flexGrow: 1 }}>
                        <Box
                            sx={{
                                width: '100%',
                                '& .editcol': {
                                    backgroundColor: '#f1f7ee',
                                    color: '#1a3e72',
                                },
                                '& .date': {
                                    fontSize: '12px',
                                    backgroundColor: '#f1f7ee',
                                    color: '#1a3e72',
                                },
                                '& .small-text': {
                                    // backgroundColor: '#f1f7ee',
                                    // color: '#1a3e72',
                                    fontSize: '12px'
                                },
                            }}
                        >
                            <StyledDataGrid
                                loading={loading}
                                autoHeight
                                components={{ Toolbar: GridToolbar }}
                                headerRowHeight={27}
                                rowHeight={35}
                                disableColumnMenu
                                disableClickEventBubbling
                                disableSelectionOnClick
                                hideFooter
                                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                                rows={rows}
                                columns={columns}
                                filterModel={filterModel}
                                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                                componentsProps={{
                                    toolbar: {
                                        setRows, setRowModesModel, csvOptions
                                    },
                                }}
                                experimentalFeatures={{ newEditingApi: true }}
                            />
                        </Box>
                    </div></div>
            </Card>
        </div>
    );
}

export default BsagReport