import React, { useState, useEffect, useRef } from 'react';
import {
    Card,
    List,
    Badge,
    Input,
    Tag,
    Form,
    Button,
    InputNumber,
    DatePicker,
    Empty,
    Descriptions,
    message,
    Space,
    Modal,
    Popconfirm,
    notification
} from 'antd';
import _ from "lodash";
import { CheckCircleOutlined } from '@ant-design/icons';
import './Plan.css';
import { useTranslation } from 'react-i18next';
import { saveProjectDetail, getProjectReport, createReportYear, deleteReportYear } from '../../util/APIUtils';
import { capitalize, formatCurrency } from '../../util/Helpers';
import MultiSelect from '../../common/MultiSelect';
import CreateReport from '../project_report/CreateReport';
import ReportBody from '../project_report/ReportBody';


function processLineSelections(arr, cropId) {
    const bList = arr
        .filter(function (el) {
            return el.cropId == cropId
        })
    return bList;
}

function onReset() {
    this.formRef.current.resetFields();
};

function ProjectReport(props) {
    const { t } = useTranslation();
    const [editing, _setEditing] = useState(false);
    const [state, setState] = useState(props.projectDetail);
    const projectDetailId = props.projectDetail.id;
    const projectInfo = props.projectInfo;
    const currentUser = props.currentUser;

    const screenState = {
        "year": state.year,
        "tab": '3'
    }


    console.log(projectInfo);
    console.log(editing)

    const setEditing = () => {
        _setEditing(!editing)
    }

    const getReport = () => {
        let promise;
        promise = getProjectReport(projectDetailId);

        if (!promise) {
            return;
        }

        promise.then(response => {
            message.success("Projektbericht aktualisiert")
            props.reloadProject(screenState);

        }).catch(error => {
            message.error("Update fehlgeschlagen")
        });
    }

    const createReportNew = () => {
        let promise;
        promise = createReportYear(projectDetailId);

        if (!promise) {
            return;
        }

        promise.then(response => {
            message.success("Projektbericht aktualisiert")
            props.reloadProject(screenState);

        }).catch(error => {
            message.error("Update fehlgeschlagen")
        });
    }

    const onClose = () => {
        _setEditing(false);
        notification.success({
            message: 'Kultursaat',
            description: "Projektbericht aktualisiert"
        });
        props.reloadProject(screenState);
        // props.fetchLines();
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const deleteReport = () => {
        console.log(projectDetailId)
        let promise;
        promise = deleteReportYear(projectDetailId);

        if (!promise) {
            return;
        }

        promise.then(response => {
            message.success("Projektbericht gelöscht")
            props.reloadProject(screenState);

        }).catch(error => {
            console.log(error)
            message.error("Report delete failed")
        });
    }

    const handleChangeOther = name => value => {
        if (name === 'lineSelections') {
            value = value.length > 0 ? value.map(elem => ({ id: parseInt(elem) })) : state.lineSelections;
        } else if (name === 'breedingMethodList') {
            value = value.length > 0 ? value.map(elem => ({ id: parseInt(elem) })) : state.breedingMethods;
        }
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const returnBodyForType = (type) => {
        let output = null;
        switch (type) {
            case "NZ":
                output = getReportBody();
                break;

            case "EZ":
                output = getReportBodyEz();
                break;
        
            default:
                break;
        }
        return output;
    }

    const getReportBody = () => {
        return <>
            <Descriptions.Item label={t("project_report.description")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                {state.projectReportInfo.description}
            </Descriptions.Item>

            <Descriptions.Item label={t("project_report.starting_variety")} labelStyle={{ fontWeight: 'bold' }} span={3}>

                <List
                    size="small"
                    dataSource={state.projectReportInfo.lineSelections}
                    renderItem={item => <List.Item style={{ padding: 2 }}><Badge color={'green'} text={item.name} /></List.Item>}
                />

            </Descriptions.Item>

            <Descriptions.Item label={t("project_report.project_status")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                {state.projectReportInfo.projectStatus}
            </Descriptions.Item>

          

            <Descriptions.Item label={t("project_report.breeding_method")} labelStyle={{ fontWeight: 'bold' }} span={3}>
                <List
                    size="small"
                    dataSource={state.projectReportInfo.breedingMethods}
                    renderItem={item => <List.Item style={{ padding: 2 }}><Badge color={'blue'} text={item.name} /></List.Item>}
                />
            </Descriptions.Item>

            <Descriptions.Item label={t("project_report.breeding_method_description")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                {state.projectReportInfo.descBreedingMethod}
            </Descriptions.Item>

            <Descriptions.Item label={t("project_report.cultivation_scope")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                {state.projectReportInfo.cultivationScope + " " + state.projectReportInfo.cultivationScopeUnit}
            </Descriptions.Item>

            <Descriptions.Item label={t("project_report.cultural_tour")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                {state.projectReportInfo.culturalTour}
            </Descriptions.Item>

            <Descriptions.Item label={t("project_report.plan_deviation")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                {state.projectReportInfo.deviationPlan}
            </Descriptions.Item>

            <Descriptions.Item label={t("project_report.results")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                {state.projectReportInfo.results}
            </Descriptions.Item>

            <Descriptions.Item label={t("project_report.project_extention")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                {state.projectReportInfo.renewal ? state.projectReportInfo.renewal : "Nein"}
            </Descriptions.Item>

            <Descriptions.Item label={t("project_report.reason_project_extension")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                {state.projectReportInfo.descExtension}
            </Descriptions.Item>
        </>
    }

    const getReportBodyEz = () => {
        return <>
            <Descriptions.Item label="Enstanden aus Proj" span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                {state.projectReportInfo.parentNo}
            </Descriptions.Item>

            <Descriptions.Item label={t("project_report.starting_variety")} labelStyle={{ fontWeight: 'bold' }} span={3}>

                <List
                    size="small"
                    dataSource={state.projectReportInfo.lineSelections}
                    renderItem={item => <List.Item style={{ padding: 2 }}><Badge color={'green'} text={item.name} /></List.Item>}
                />

            </Descriptions.Item>

            <Descriptions.Item label={t("project_report.project_status")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                {state.projectReportInfo.projectStatus}
            </Descriptions.Item>

          

            <Descriptions.Item label={t("project_report.breeding_method")} labelStyle={{ fontWeight: 'bold' }} span={3}>
                <List
                    size="small"
                    dataSource={state.projectReportInfo.breedingMethods}
                    renderItem={item => <List.Item style={{ padding: 2 }}><Badge color={'blue'} text={item.name} /></List.Item>}
                />
            </Descriptions.Item>

            <Descriptions.Item label={t("project_report.breeding_method_description")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                {state.projectReportInfo.descBreedingMethod}
            </Descriptions.Item>

            <Descriptions.Item label={t("project_report.cultivation_scope")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                {state.projectReportInfo.cultivationScope + " " + state.projectReportInfo.cultivationScopeUnit}
            </Descriptions.Item>

            <Descriptions.Item label={t("project_report.cultural_tour")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                {state.projectReportInfo.culturalTour}
            </Descriptions.Item>

            <Descriptions.Item label={t("project_report.plan_deviation")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                {state.projectReportInfo.deviationPlan}
            </Descriptions.Item>

            <Descriptions.Item label={t("project_report.results")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                {state.projectReportInfo.results}
            </Descriptions.Item>

            <Descriptions.Item label={t("project_report.project_extention")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                {state.projectReportInfo.renewal ? state.projectReportInfo.renewal : "Nein"}
            </Descriptions.Item>

            <Descriptions.Item label={t("project_report.reason_project_extension")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                {state.projectReportInfo.descExtension}
            </Descriptions.Item>
        </>
    }

    // const onFinish = (values) => {
    //     var subsetObj = _.pick(state, [
    //         "id",
    //         "description",
    //         "plannedActivity",
    //         "lineSelections",
    //         "breedingMethodList",
    //         "numberOfPlannedLines",
    //         "projectExtension",
    //         "generationStart",
    //         "generationEnd",
    //         "descBreedingMethod",
    //         "cultivationScope",
    //         "cultivationScopeUnit",
    //         "priority",
    //     ]);

    //     let screenState = {
    //         "year": state.year,
    //         "tab": '3'
    //     }

    //     let promise;
    //     promise = saveProjectDetail(subsetObj);

    //     if (!promise) {
    //         return;
    //     }

    //     promise.then(response => {
    //         message.success("Project plan updated")
    //         props.reloadProject(screenState);

    //     }).catch(error => {
    //         message.error("project update failed")
    //     });
    // };

    const [form] = Form.useForm();

    // const allBreedingMethods = props.breedingMethods.filter(function (el) {
    //     return el.projectType == props.projectInfo.projectType
    // });
    // const allLineSelections = props.lineSelections;

    // let lineSelection = state.lineSelections.map(a => a.name);
    // let existingLineSelections = state.lineSelections.map(a => a.id);

    // let breedingMethods = state.breedingMethodList.map(a => a.name);
    // let existingBreedingMethods = state.breedingMethodList.map(a => a.id);

    // const cropId = props.projectInfo.cropId;

    form.setFieldsValue({
        description: state.description,
        planned_activity: state.plannedActivity,
        youngest: state.generationStart,
        oldest: state.generationEnd,
        breeding_method_description: state.descBreedingMethod,
        priority: state.priority,
        no_planned_lines: state.numberOfPlannedLines,
        cultivation_scope: state.cultivationScope ? parseInt(state.cultivationScope) : 0,
        cultivation_scope_unit: state.cultivationScopeUnit,
    });

    console.log(state.projectReportInfo)



    return (

        <Card bordered={true} >
            {state.projectReportInfo != null ?


                <div>
                    {!editing ?
                        <Descriptions
                            size="small"
                            bordered
                            style={{
                                marginBottom: 2
                            }}
                            extra={
                                props.isReportEditable?
                                !editing ? <Space><Button type="primary" onClick={setEditing}>Bericht bearbeiten</Button>
                                <Popconfirm
                                    title={"Möchten Sie den Bericht wirklich löschen??"}
                                    onConfirm={deleteReport}
                                >
                                    <Button type="danger" >Bericht löschen</Button>
                                </Popconfirm>
                            </Space> : <div><Button onClick={setEditing}>Save Report</Button></div>: null}
                        >
                            <Descriptions.Item label={t("project_report.the_project_was")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start', width: '300px' }} >

                                {!state.projectReportInfo.canceled ? <Tag color="#87d068">{t("project_report.completed")}</Tag> : <Tag color="#f50">{t("project_report.not_completed")}</Tag>}
                            </Descriptions.Item>

                            {/* Show rest of report data if not cancelled */}
                            {!state.projectReportInfo.canceled ? returnBodyForType(projectInfo.projectType)

                                : ""}
                        </Descriptions>

                        : <div>
                            {props.isReportEditable ?
                                <CreateReport
                                    cropId={projectInfo.cropId}
                                    projectInfo={projectInfo}
                                    projectType={projectInfo.projectType}
                                    projectDetailId={projectDetailId}
                                    projectId={projectInfo.projectId}
                                    projectReportInfo={state.projectReportInfo}
                                    onClose={onClose} setEditing={setEditing}
                                    reloadProject={props.reloadProject}
                                    year={state.year}
                                /> : ""}</div>}

                </div>

                : <Empty description={<Space direction="vertical">{t("project_report.report_not_available")}
                    {props.isReportEditable ? <Button onClick={createReportNew} type="primary">Bericht erstellen</Button> : ""} </Space>} />}


        </Card>

    );

    

}

export default ProjectReport;