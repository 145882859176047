import React from 'react'
import { useTranslation } from 'react-i18next';
import {
    Badge,
    Typography,
    Form,
    Card,
    Descriptions,
    Divider,
    Row,
    Table,

  
  } from 'antd';
  import { formatCurrency } from '../util/Helpers'
  const { Text, Title } = Typography;

function FgCalculation(props) {
    const { t } = useTranslation();
    const fgDetails = props.fgDetails;
    console.log(fgDetails)

    const columnAccountingItems = [
        {
          title: t("calculation.accounting_item"),
          dataIndex: 'accountingItemName',
          key: 'accountingItemName',
          editable: true,
          width: 500,
        },
        {
          title: t("calculation.researcher"),
          children: [
            {
              title: t("calculation.budget"),
              align: "right",
              editable: true,
              dataIndex: 'daysWorkerBudget',
              fieldProps: { placeholder: 0 },
              valueType: 'inputnumber',
              key: 'daysWorkerBudget',
              render: (text, row, index) => {
                return (
                  <span><b>
                    {text != null || text == 0 ? text.toFixed(2) : "0.00"}
                  </b>
                  </span>
                )
              },
            },
            {
              title: t("calculation.actual"),
              align: "right",
              dataIndex: 'daysWorkerActual',
              editable: true,
              key: 'daysWorkerActual',
              render: (text, row, index) => {
                let textColor = row.daysWorkerActual > row.daysWorkerBudget ? "warning" : "default";
                return (
                  <span><b>
                    <Text type={textColor}>  {text != null || text == 0 ? text.toFixed(2) : "0.00"}</Text>
                  </b>
                  </span>
                )
              },
            },
          ]
        },
        
        {
          title: t("calculation.total"),
          children: [
            {
              title: t("calculation.budget"),
              align: "right",
              dataIndex: 'budgetRowTotal',
              key: 'budgetRowTotal',
              render: (text, row, index) => {
                return (
                  <span><b>
                    {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}
                  </b>
                  </span>
                )
              },
            },
            {
              title: t("calculation.actual"),
              align: "right",
              dataIndex: 'actualRowTotal',
              key: 'actualRowTotal',
              render: (text, row, index) => {
                let textColor = row.actualRowTotal != row.budgetRowTotal ? "warning" : "default";
                let actualSelected = row.selected ? "" : "#f2d577"
                return {
                  props: {
                    style: { background: actualSelected },
                  },
                  children:
                    <span><b>
                      <Text type={textColor}>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                    </b>
                    </span>
                }
              },
            }]
        }
      ];
      let locale = {
        emptyText: (
          <span>
            <p>
              No Data
            </p>
  
          </span>
        )
      };
    return (
        <div>
            <Table
              size={"small"}
              locale={locale}
              bordered
              dataSource={fgDetails.fachGroupCalculationList}
              columns={columnAccountingItems}
              pagination={false}
              summary={pageData => {
                let textColor = fgDetails.actualTotal > fgDetails.budgetTotal ? "warning" : "default"
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell><Title level={5}>Gesamt</Title></Table.Summary.Cell>
                      <Table.Summary.Cell>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                      </Table.Summary.Cell>
          
                      <Table.Summary.Cell align="right" >
                        <Title level={5}>{formatCurrency(fgDetails.budgetTotal.toFixed(2))}</Title>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right" >
                        <Title type={textColor} level={5}>{formatCurrency(fgDetails.actualTotal.toFixed(2))}</Title>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
        </div>
    )
}

export default FgCalculation
