import React, { useState, useRef } from 'react'
import { Card, Button, Menu, Modal, notification, Space, Divider, Row, Col, Radio, Input, InputNumber, Select, DatePicker, Form, Descriptions } from 'antd';
import { createSatz, updateSatz } from '../util/APIUtils';
import moment from 'moment';
function AddSatz(props) {
    const [editing, _setEditing] = useState(props.editing);
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState(props.satz? "edit" : "new")
    const dateFormat = 'MMM-DD-YYYY';
    const [form] = Form.useForm();
    const satz = props.satz;
    
    if(satz) {
        console.log(satz)
        satz.sowingDate = moment(satz.sowingDate)
        satz.plantingDate = moment(satz.plantingDate)
        satz.visitMonth = moment(satz.visitMonth)
    }
    
    const onFinish = (values) => {
        values["projectDetailId"] = props.projectDetailId
        values["satzType"] = props.satzType
        

        let promise;
        if(mode == "new") {
            promise = createSatz(values);
        } else {
            values["id"] = satz.id
            promise = updateSatz(values, props.satzType);
        }
        
        if (!promise) {
            return;
        }

        // this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response)
            setLoading(false)
            form.resetFields();
            props.onClose();
            notification.success({
                message: 'Kultursaat',
                description: "Erfolgreich geupdated"
            });

        }).catch(error => {
            console.log(error)
            // this.setState({ isLoading: false })
        });


        console.log(values);
    }
    return (
        <div>
            <Form name="satz-form" form={form} onFinish={onFinish} initialValues={satz}>

                <Descriptions
                    bordered
                    title="Dokumentation Sätze"
                    size={"small"}

                >
                    <Descriptions.Item span={3} label="Bezeichnung">
                        <Form.Item name="description">
                            <Input /> 
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="Anbauumfang">
                        <Space>
                            <Form.Item name="cultivationScope">
                                <InputNumber name="cultivationScope" />
                            </Form.Item>
                            {/* </Form.Item> */}
                            {/* <Form.Item
                                        name="cultivation_scope_unit"> */}
                            <Form.Item name="cultivationScopeUnit">

                                <Select
                                    // value={value.currency || currency}
                                    style={{ width: '90px' }}
                                    name="cultivationScopeUnit"
                                >

                                    <Option value={"qm"}>qm</Option>
                                    <Option value={"ha"}>ha</Option>
                                    <Option value={"stück"}>stück</Option>
                                </Select>

                            </Form.Item>
                        </Space>

                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="geplate Aussaat">
                        <Form.Item name="sowingDate" >
                            <DatePicker format={dateFormat} />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="geplate Pflanzung">
                        <Form.Item name="plantingDate" initialValue={""} >
                            <DatePicker format={dateFormat} />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label="Vorfrucht">
                        <Form.Item name="previousCrop">
                            <Input />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label="Düngung">
                        <Form.Item name="fertilization">
                            <Input />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="Bewässerung">
                        <Form.Item name="irrigation">
                            <Input.TextArea />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="Anbaubesonderheiten">
                        <Form.Item name="particularities">
                            <Input.TextArea />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label="Mitbetr. BSAG sinnvoll">
                        <Form.Item name="betrBsag">
                            <Select
                                style={{ width: '100px' }}
                            >
                                <Option value={"ja"}>ja</Option>
                                <Option value={"nein"}>nein</Option>
                                <Option value={"vielleicht"}>vielleicht</Option>
                            </Select>
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label="Vor Besuchstermin">
                        <Space>
                            <Form.Item name="visitDate">
                                <Select
                                    style={{ width: '100px' }}
                                >
                                    <Option value={"01-10"}>01-10</Option>
                                    <Option value={"11-20"}>11-20</Option>
                                    <Option value={"21-30"}>21-30</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="visitMonth" initialValue={""}>
                                <DatePicker picker="month" format={"MMM"} />
                            </Form.Item>
                        </Space>
                    </Descriptions.Item>
                    <Descriptions.Item span={1} >
                        <Form.Item
                            name="submit"
                        >
                            <Space><Button type="primary" htmlType="submit" loading={loading}>
                                Speichern
                            </Button> <Button type="secondary" onClick={props.onClose}>
                                    Abbrechen
                                </Button></Space>
                        </Form.Item>
                    </Descriptions.Item>
                </Descriptions>
            </Form>

           
        </div>
    )
}

export default AddSatz