import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { CheckCircleTwoTone, CloseCircleTwoTone, StarTwoTone } from '@ant-design/icons';
import {
    PageHeader,
    Card,
    Descriptions,
    Select,
    Modal,
    Table,
    Button,
    Space,
    Input,
    Checkbox,
    Form,
    DatePicker,
    Divider
} from 'antd';
import { getAllPayouts, updateContractStatus, updateSettledStatus, getYears } from '../util/APIUtils';
import { formatCurrency } from '../util/Helpers';
import YearSelect from '../common/YearSelect';

function AllPayouts(props) {
    const { t } = props;
    const [year, setYear] = useState("2023");
    const [loading, setLoading] = useState(false);
    const [inputValues, setInputValues] = useState({});
    const [projectYears, setProjectYears] = useState([]);
    const [payoutInfos, setPayoutInfo] = useState([]);
    const [currentPayoutInfo, setCurrentPayoutInfo] = useState(null);
    const [filteredInfo, setFilteredInfo] = useState(false);
    const [sortedInfo, setSortedInfo] = useState({});

    const updateContract = (id, status) => {
        console.log(id)

        let promise;
        if (!id) {
            return;
        }
        promise = updateContractStatus(id, status);
        if (!promise) {
            return;
        }

        promise
            .then((response) => {
                refetch()
                // fetchAllPayouts(year);
            })
            .catch((error) => {
                console.log(error)
                setLoading(false);
            });
    };

    const updateSettled = (id, status) => {
        let promise;
        if (!id) {
            return;
        }
        promise = updateSettledStatus(id, status);
        if (!promise) {
            return;
        }

        promise
            .then((response) => {
                refetch()
            })
            .catch((error) => {
                console.log(error)
                setLoading(false);
            });
    };

    let useMyData = getAllPayouts(year);

    const { isLoading, error, data, refetch } = useMyData(
        (jsonData) => jsonData,
    );

    if (isLoading) {
        return <p>Wird geladen .....</p>;
    }

    if (error) {
        return <p>Error Wird geladen ..: {error.message}</p>;
    }

    const updateYear = (year) => {
        setYear(year);
        // refetch()
        // fetchAllPayouts(year);
    }


    // const fetchAllPayouts = (year) => {
    //     console.log(year);

    //     if (year) {
    //         setLoading(true);
    //         let promise = getAllPayouts(year);
    //         if (!promise) {
    //             return;
    //         }

    //         promise
    //             .then((response) => {
    //                 console.log('Loaded ...');
    //                 console.log(response);
    //                 setPayoutInfo(response);
    //                 setYear(year);
    //                 setLoading(false);
    //             })
    //             .catch((error) => {
    //                 setLoading(false);
    //             });
    //     } else {
    //         setPayoutInfo([]);
    //         setCurrentPayoutInfo({});
    //         setLoading(false);
    //     }
    // };



    const handleChange = (pagination, filters, sorter) => {
        console.log(filters);

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const onContractChecked = (id, e) => {
        console.log("contract checked")
        console.log(id)
        updateContract(id, e.target.checked);
    };


    const onSettledChecked = (id, e) => {
        updateSettled(id, e.target.checked);
    }

    const title = "Auszahlungen " + (year != null ? year : "")
    const columns = [
        {
            title: "Züchter",
            dataIndex: 'breederName',
            key: 'breederName',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => {
                if (a.breederName < b.breederName) {
                    return -1;
                }
                if (a.breederName > b.breederName) {
                    return 1;
                }
                return 0;
            },
            width: 650,
            render: (text, record) => {
                let linkText = `/payouts/${record.breederId}/${year}`
                return (
                    <span><b>
                        <Link
                            to={{
                                pathname: linkText,
                                state: {
                                    id: record.breederId,
                                    year: year
                                }
                            }}>{text} </Link>
                    </b>
                    </span>
                )
            },
        },
        {
            title: "Bewilligt",
            dataIndex: 'calculatedPayoutAmount',
            key: 'calculatedPayoutAmount',
            align: 'right',
            width: 550,
            sortDirections: ['descend', 'ascend'],
            render: (text, record) => {

                return (
                    <span><b>
                        {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : formatCurrency(0)}
                    </b>
                    </span>
                )
            },
        },
        {
            title: "Sonderzahlung",
            dataIndex: 'specialPayoutAmount',
            align: 'right',
            key: 'id',
            width: 300,
            render: (text, record) => {

                return (
                    <span>
                        {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : formatCurrency(0)}

                    </span>
                )
            },
        },
        {
            title: "Gesamtbetrag",
            dataIndex: 'totalPayoutAmount',
            align: 'right',
            key: 'totalPayoutAmount',
            width: 300,
            render: (text, record) => {

                return (
                    <span><b>
                        {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : formatCurrency(0)}
                    </b>
                    </span>
                )
            },
        },
        {
            title: "Rate 1",
            dataIndex: 'totalPayoutAmount',
            key: 'totalPayoutAmount',
            align: 'right',
            width: 300,
            render: (text, record) => {
                let rate = record.payoutDetailList != null ? record.payoutDetailList[0].plannedPayout : null;
                return (
                    <span>
                        {rate != null || rate == 0 ? formatCurrency(rate.toFixed(2)) : formatCurrency(0)}

                    </span>
                )
            },
        },
        {
            title: "Rate 2",
            dataIndex: 'totalPayoutAmount',
            key: 'totalPayoutAmount',
            align: 'right',
            width: 300,
            render: (text, record) => {
                let rate = record.payoutDetailList != null ? record.payoutDetailList[1].plannedPayout : null;
                return (
                    <span>
                        {rate != null || rate == 0 ? formatCurrency(rate.toFixed(2)) : formatCurrency(0)}

                    </span>
                )
            },
        },
        {
            title: "Rate 3",
            dataIndex: 'totalPayoutAmount',
            key: 'totalPayoutAmount',
            align: 'right',
            width: 300,
            render: (text, record) => {
                let rate = record.payoutDetailList != null ? record.payoutDetailList[2].plannedPayout : null;
                return (
                    <span>
                        {rate != null || rate == 0 ? formatCurrency(rate.toFixed(2)) : "-"}

                    </span>
                )
            },
        },
        {
            title: "Vertrag eingereicht",
            dataIndex: 'contractSubmitted',
            key: 'id',
            align: 'right',
            width: 300,
            render: (text, record) => {
                return (
                    <span><Checkbox disabled={record.id == null} checked={text} onChange={(val) => {
                        if (record.id && val) {
                            onContractChecked(record.id, val)
                        }

                    }}></Checkbox>
                    </span>
                )
            },
        },
        {
            title: "Abrechnung Vorjahr",
            dataIndex: 'lastYearSettled',
            key: 'id',
            align: 'right',
            width: 300,
            render: (text, record) => {
                return (
                    <span><Checkbox disabled={record.id == null} checked={text} onChange={(val) => {
                        if (record.id && val) {
                            onSettledChecked(record.id, val)
                        }
                    }}></Checkbox>
                    </span>
                )
            },
        },
        {
            title: "Status",
            dataIndex: 'payoutGenerated',
            key: 'payoutGenerated',
            align: 'right',
            width: 100,
            render: (text, record) => {
                return (
                    <span>{text ? <CheckCircleTwoTone style={{ "fontSize": "20px" }} twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor={'red'} style={{ "fontSize": "20px" }} />}
                    </span>
                )
            },
            filters: [
                {
                    text: 'Ja',
                    value: true,
                },
                {
                    text: 'Nein',
                    value: false,
                },
            ],
            filteredValue: filteredInfo.payoutGenerated || null,
            onFilter: (value, record) => record.payoutGenerated == value,
            sorter: (a, b) => a.payoutGenerated - b.payoutGenerated,
            sortOrder: sortedInfo.columnKey === 'payoutGenerated' ? sortedInfo.order : null,
        },
    ]
    return (
        <div>
            <PageHeader
                className="site-page-header"
                title={title}
                extra={<Button type='primary' onClick={() => props
                    .history
                    .push("/installment-summary")}>Liste Überweisungen</Button>}
                subTitle="" />
            <Card loading={isLoading} >
                <YearSelect
                    setItem={updateYear}
                    selectedItem={year} />

                <Divider></Divider>
                {data.length > 0 &&
                    <Table
                        size='small'
                        columns={columns}
                        dataSource={data}
                        loading={isLoading}
                        pagination={false}
                        onChange={handleChange}
                    />
                }
            </Card>
        </div>
    )
}

export default AllPayouts;
