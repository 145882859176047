import {
    DataGrid, useGridApiContext, deDE, useGridApiRef, GridToolbarContainer, GridRowModes,
    GridActionsCellItem,
    renderBooleanCell,
    renderEditBooleanCell,
} from '@mui/x-data-grid';
import { NumericFormat } from 'react-number-format';
import { styled } from '@mui/material/styles';
import uuid from "uuid";
import Snackbar from '@mui/material/Snackbar';

import { Button, Select, Card, Descriptions, Input, Space, message, InputNumber, Divider, Popconfirm } from 'antd';
import React from 'react'
import { useEffect, useState, useMemo, useRef } from 'react'
import { currencyFormatter, currencyParser, formatCurrency } from '../../util/Helpers';
import { DeleteOutlined, PlusCircleOutlined, SaveOutlined, PlusOutlined } from '@ant-design/icons';
import { deleteAccountingItem, getProjectDetailAccountingItems, updateAccountingItem } from '../../util/APIUtils';
import { Box } from '@mui/system';
import { Cancel, CancelOutlined, CheckBox, CheckBoxOutlineBlank, CheckBoxOutlineBlankTwoTone, CheckBoxOutlined, CheckCircle, CheckCircleOutline, Done } from '@mui/icons-material';


const AccountingSummary = (props) => {
    const [loading, setLoading] = useState(false);
    const projectDetailId = props.projectDetailId;
    const isPlanEditable = props.isPlanEditable;
    const isReportEditable = props.isReportEditable;
    const [data, setData] = useState([]);
    const [snackbar, setSnackbar] = React.useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [budgetTotal, setBudgetTotal] = useState(0);
    const [actualTotal, setActualTotal] = useState(0);
    const projectNumber =  props.projectNumber;
    const loadAccountingItems = React.useCallback(async () => {
        let promise;
        promise = getProjectDetailAccountingItems(projectDetailId);

        if (!promise) {
            return;
        }
        setLoading(true);
        promise.then(response => {
            response.push(createSummaryData(response));
            setData(response);
        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, [])

    useEffect(() => {
        loadAccountingItems();
    }, [loadAccountingItems]);

    const createSummaryData = (response) => {
        let summaryRow = createNewRow("sum")
        summaryRow.accountingItem = projectNumber + " - Tätigkeiten Gesamt";
        // summaryRow.actualTotalAmount = response.reduce((a, b) => a + (b['actualTotalAmount'] || 0), 0).toFixed(2)
        summaryRow.budgetTotalAmount = response.reduce((a, b) => a + (b['budgetTotalAmount'] || 0), 0).toFixed(2)
        summaryRow.daysResearcherBudget = response.reduce((a, b) => a + (b['daysResearcherBudget'] || 0), 0).toFixed(2)
        summaryRow.daysResearcherActual = response.reduce((a, b) => a + (b['daysResearcherActual'] || 0), 0).toFixed(2)
        summaryRow.daysHelperBudget = response.reduce((a, b) => a + (b['daysHelperBudget'] || 0), 0).toFixed(2)
        summaryRow.daysHelperActual = response.reduce((a, b) => a + (b['daysHelperActual'] || 0), 0).toFixed(2)
        summaryRow.actualTotalAmount = response.filter(a => a.actualSelected == true).reduce((a, b) => a + (b['actualTotalAmount'] || 0), 0).toFixed(2)
        summaryRow.actualSelected = "-"
        return summaryRow;
    }

    const createNewRow = (type) => {
        return {
            id: type == "sum" ? 108080 : 0,
            accountingItem: "",
            actualTotalAmount: 0,
            budgetTotalAmount: 0,
            daysHelperActual: 0,
            daysHelperBudget: 0,
            daysResearcherActual: 0,
            daysResearcherBudget: 0,
            isNew: true,
            projectDetailId: projectDetailId,
            budget: isPlanEditable,
            actual: isReportEditable,
            rowType: type
        }
    }

    // const columnGroupingModel = [
    //     {
    //         groupId: 'Züchter',
    //         description: '',
    //         headerAlign: 'center',
    //         children: [{ field: 'daysResearcherBudget' }, { field: 'daysResearcherActual' }],
    //     },
    //     {
    //         groupId: 'Hilfskraft',
    //         description: '',
    //         headerAlign: 'center',
    //         children: [{ field: 'daysHelperBudget' }, { field: 'daysHelperActual' }],
    //     },
    //     {
    //         groupId: 'Gesamt',
    //         description: '',
    //         headerAlign: 'center',
    //         children: [{ field: 'budgetTotalAmount' },{ field: 'actualSelected' }, { field: 'actualTotalAmount' }],
    //     },
    // ];

    const renderActualSelected = (row) => {
        if(row.row.id === 108080) {
            return "";
        } else {
            return row.row.actualSelected ? <CheckCircleOutline style={{ fill: "green" }} /> : <CancelOutlined style={{ fill: 'orangered' }} />
        }
    }

    const columns = [
        
        { field: 'accountingItemId', headerName: 'Id', hide: true, minWidth: 600, editable: true },
        
        { field: 'accountingItem', headerName: 'Tätigkeiten', minWidth: 400,  flex: 1 },
        {
            field: 'daysResearcherBudget',
            headerName: 'Kalkulation',
            valueFormatter: ({ value }) => currencyFormatter(value),
            // valueGetter: ({ value }) => currencyParser(value),
            headerAlign: 'right',
            align: 'right',
            minWidth: 50,
            flex: 1,
            headerClassName: 'header-style'
        },
        {
            field: 'daysResearcherActual',
            headerName: 'Abrechnung',
            valueFormatter: ({ value }) => currencyFormatter(value),
            // type: 'number',
            minWidth: 50,
            flex: 1,
            headerAlign: 'right',
            align: 'right'
        },
        {
            field: 'daysHelperBudget',
            headerName: 'Kalkulation',
            valueFormatter: ({ value }) => currencyFormatter(value),
            // type: 'number',
            minWidth: 50,
            flex: 1,
            headerAlign: 'right',
            align: 'right'
        },
        {
            field: 'daysHelperActual',
            headerName: 'Abrechnung',
            headerClassName: 'table-header',
            valueFormatter: ({ value }) => currencyFormatter(value),
            // type: 'number',
            minWidth: 50,
            flex: 1,
            headerAlign: 'right',
            align: 'right',

        },
        {
            field: 'budgetTotalAmount',
            headerName: 'Kalkulation',
            valueFormatter: ({ value }) => formatCurrency(value),
            type: 'number',
            minWidth: 100,
            flex: 1,
            headerAlign: 'right',
            align: 'right',
        },
        { field: 'actualSelected', headerName: '-', minWidth: 20, type: 'boolean', flex: 1, renderCell: renderActualSelected},
        {
            field: 'actualTotalAmount',
            headerName: 'Abrechnungsbetrag',
            valueFormatter: ({ value }) => formatCurrency(value),
            type: 'number',
            minWidth: 100,
            flex: 1,
            headerAlign: 'right',
            align: 'right',
        },
        
    ];

    

    
    const checkEditable = (param) => {

        if (param.row.rowType === "sum") {
            return false;
        }
        if (param.colDef.field === "accountingItem" && param.row.budget && isPlanEditable) {
            return true;
        }

        if (param.colDef.field === "accountingItem" && param.row.actual && isReportEditable) {
            return true;
        }

        if (isPlanEditable && ['daysResearcherBudget', 'daysHelperBudget'].includes(param.colDef.field)) {
            return true;
        }

        if (isReportEditable && ['actualSelected', 'daysResearcherActual', 'daysHelperActual'].includes(param.colDef.field)) {
            return true;
        }

        return false;

    }

    function CustomFooter(props) {
        return (
            <Box sx={{ fontWeight: "600", border: `1px solid #f0f0f0`, backgroundColor: "#f1f7ee", padding: "0px", display: "flex", justifyContent: "right", alignItems: "right" }}>
            </Box>)
    }

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        border: 1,
        color:
            theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        // "& .MuiDataGrid-columnHeaders": { rowHeight: "35!important" },
        // "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            // backgroundColor: '#ccc',
            border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnHeader': {
            height: "22!important",
            backgroundColor: '#edede9',
        },
        '& .MuiDataGrid-cell': {

            color:
                theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
        },
        '& .MuiPaginationItem-root': {
            borderRadius: 0,
        },
        '& .sum-row-sum': {
            bgcolor: (theme) =>
                getBackgroundColor(theme.palette.success.main, theme.palette.mode),
            '&:hover': {
                bgcolor: (theme) =>
                    getHoverBackgroundColor(
                        theme.palette.success.main,
                        theme.palette.mode,
                    ),
            },
        },

        

    }));

    return (
        <div style={{ width: "100%", marginBottom: "2px" }}>
            <br></br>
            <Box
                sx={{
                    width: '100%',
                    '& .budget': {
                        backgroundColor: '#dde5b6',
                        color: 'e8e8e4',
                    },
                    '& .actual': {
                        backgroundColor: '#fae1ca',
                        color: '#1a3e72',
                    },
                    '& .sum-row': {
                        backgroundColor: '#edede9',
                        fontWeight: 600
                    },
                }}
            >
                <StyledDataGrid
                    disableColumnFilter
                    disableClickEventBubbling
                    disableSelectionOnClick
                    autoHeight
                    rowHeight={27}
                    headerRowHeight={27}
                    rows={data}
                    columns={columns}
                    experimentalFeatures={{ newEditingApi: true, columnGrouping: true }}
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                    // columnGroupingModel={columnGroupingModel}
                    disableColumnMenu
                    isCellEditable={checkEditable}
                    getRowClassName={(params) => {
                        if (params.row.rowType === "sum") {
                            return 'sum-row'
                        }
                    }}
                    getCellClassName={(params) => {
                        if (['daysResearcherBudget', 'daysHelperBudget'].includes(params.field)) {
                            return 'budget';
                        } else if (['daysResearcherActual', 'daysHelperActual'].includes(params.field)) {
                            return 'actual'
                        }

                    }}
                    components={{
                        Footer: CustomFooter
                    }}
                    componentsProps={{
                        footer: { budgetTotal, actualTotal }
                    }}
                />
            </Box>
        </div>

    )
}

export default AccountingSummary