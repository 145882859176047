import React from 'react'

import {notification} from 'antd'
const key = 'error';
const openNotification = (status, desc) => {
    notification.info({
      key,
      message: 'Kultursaat',
      description: desc,
    });
}

export default openNotification;