import React, { useState, useEffect } from 'react';
import { PageHeader, Button, Select, Modal, notification, Space, Divider, Row, Col, Radio, Badge, Table, Text } from 'antd';
import { getSortens } from '../util/APIUtils';
import AddSorten from './AddSorten';
const { Option } = Select;
import { Link } from 'react-router-dom';
function SortenList(props) {
    const [visible, setVisible] = useState(false);
    const [selectedSorten, setSelectedSorten] = useState({});
    const [selectedSortenId, setSelectedSortenId] = useState(null);
    const [editingSorten, setEditingSorten] = useState({});



    let useMyData = getSortens();

    const { isLoading, error, data, refetch } = useMyData(
        (jsonData) => jsonData,
    );

    if (isLoading) {
        return <p>Wird geladen .....</p>;
    }

    if (error) {
        return <p>Error Wird geladen ..: {error.message}</p>;
    }


    const showModal = (sorten) => {
        setTimeout(() => {
        }, 5000);
        setSelectedSorten(sorten);
        setVisible(true)
        console.log(sorten)
        setSelectedSortenId(sorten.id)

    };

    const handleCancel = () => {
        setVisible(false);
        setSelectedSorten({});
    };

    const handleEdit = (sorten) => {
        setEditingSorten(sorten);
        setVisible(true);
    };

    const columns = [

        {
            title: 'Bezeichnung',
            dataIndex: 'designation',
            key: 'designation',
            render: (text, record) => {
                let linkText = `/sortendetail/${record.id}`
                return (
                    <span><b>
                        <Link
                            to={{
                                pathname: linkText,
                                state: {
                                    id: record.id,
                                }
                            }}> {text}</Link>
                    </b>
                    </span>
                )
            },
        },
        {
            title: 'Kulturart',
            dataIndex: 'cropName',
            key: 'cropName',

        },
        {
            title: 'Zulassung',
            dataIndex: 'permit',
            key: 'permit',
        },
        {
            title: 'Verlaengert bis',
            dataIndex: 'extendedUntil',
            key: 'extendedUntil',
        },
        

    ];

    return (
        <div>
            <PageHeader title="Sorten"
                className="site-page-header"
                extra={<Button size='small' type="primary" onClick={showModal}>
                    Hinzufügen
                </Button>}
            />
            <div style={{ marginTop: '10px', marginBottom: '10px' }}>

            </div>
            <AddSorten visible={visible} onCancel={handleCancel} sorten={selectedSorten} setVisible={setVisible} refetch={refetch} />
            <Table
                size='small'
                columns={columns}
                dataSource={data}
                pagination={false}
            >

            </Table>
        </div>
    )
}

export default SortenList