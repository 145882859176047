import { PageHeader, Card, Table } from 'antd';
import React, { Component } from 'react'
import { getFunders } from '../../util/APIUtils';

export class Funders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            funders: [],

        };
    }

    loadFunders() {
        let promise;
        promise = getFunders();

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response)
            this.setState({
                funders: response,
                        isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }
    componentDidMount() {
        const { id } = this.props.match.params
        if (!this.props.isAuthenticated) {
            this.props.history.push("/login");
            notification.info({
                message: 'Kultursaat',
                description: "Please login to continue.",
            });
            return;
        }
        this.loadFunders();
    }

    componentDidUpdate(nextProps) {
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                farmprofiles: [],
                // page: 0, size: 10, totalElements: 0, totalPages: 0, last: true,
                isLoading: false
            });
            this.loadFunders();
        }
    }

    
  render() {
    const columns = [
        {
            title: "Name",
            dataIndex: 'name',
            key: 'name',
            width: 300,
           
            
        },
        {
            title: "Typ",
            dataIndex: 'funder_type',
            key: 'id',
            width: 150 //<CheckCircleTwoTone twoToneColor="#52c41a" />
        }]
    return (
        <div>
        <PageHeader
            className="site-page-header"
            title={"Funders"}
            subTitle=""
            // extra={[officeUser? <Link to="/crop/add"><Button key="3" type="primary" >{t("general.add")}</Button></Link>: ""]}
        />
        <Card bordered={true} loading={this.state.isLoading}>
            <Table
                columns={columns}
                dataSource={this.state.funders}
                loading={this.state.isLoading}
            />
        </Card>
    </div>
    )
  }
}

export default Funders