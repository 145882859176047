import React from 'react'
import moment from 'moment'
import { Descriptions, Tag, Form, Empty, Input, Checkbox, Select, InputNumber, Button, DatePicker, Space, notification, Divider, Row, Col, List, Badge, Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next';
import { getProjectReport, saveProjectReport, getBreedingMethods, createReportYear } from '../../util/APIUtils';
import { useEffect, useState } from 'react'

import './Report.css'
import BreedingMethodLookup from '../../common/BreedingMethodLookup';

function ReportEz(props) {
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = React.useState(false);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [canceled, setCanceled] = useState(props.projectDetailInfo.projectReportInfo.canceled ? 1 : 0)
    const [state, setState] = useState(props.projectDetailInfo.projectReportInfo)
    const projectInfo = props.projectInfo;
    const projectDetailInfo = props.projectDetailInfo;
    const cropId = props.cropId;
    const editable = props.isReportEditable
    // const editing = false
    const projectType = props.projectInfo.projectType;
    const { t } = useTranslation();
    const [form] = Form.useForm();

    let breedingMethods = state.breedingMethods

    const setEditingLocal = (val) => {
        setEditing(val);
        props.setEditingMaster(val);
        if (!val) {
            props.reloadProject(screenState)
        }
    }

    const getCheckedStatus = () => {
        const tArray = [];
        if (state.bleedingSeeds) {
            tArray.push('A');
        }
        if (state.pltaetauslese) {
            tArray.push('P');
        }
        return tArray;
    }

    const screenState = {
        "year": props.year,
        "tab": '3'
    }

    const onChange = e => {
        setCanceled(e);
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeOther = name => value => {
        if (name === "ks_plan") {
            value = value.target.checked ? 1 : 0;
        }else if (name === "bleedingSeeds") {
            value = value.target.checked ? 1 : 0;
        }
        else if (name === "pltaetauslese") {
            value = value.target.checked ? 1 : 0;
        }
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const mapObjToReport = (values, type) => {
        let yearOfRegistration = null;
        let checkBSA = null;
        let nextPlace = null;
        let testPatternCreation = null;
        let plannedActivity = state.plannedActivity ? state.plannedActivity.length > 0 ? state.plannedActivity : [] : []
        let bleedingSeeds = null;
        let pltaetauslese = null;
        let plantDate = null;
        let sowingDate = null;
        if (type == 'form') {
            yearOfRegistration = props.projectDetailInfo.annualRegistration != null || props.projectDetailInfo.annualRegistration != 0 ? moment(props.projectDetailInfo.annualRegistration, 'YYYY').year() : null
            checkBSA = values.nextPlace != null || values.nextPlace != 0 ? moment(values.nextCheckBsa, 'YYYY').year() : null
            plantDate = values.plantDate != null || values.plantDate != 0 ? moment(values.plantDate, 'YYYY-MM-DD') : null
            nextPlace = values.nextPlace != null || values.nextPlace != 0 ? moment(values.nextPlace, 'YYYY').year() : null
            testPatternCreation = values.testPatternCreation != null || values.testPatternCreation != 0 ? moment(values.testPatternCreation, 'YYYY').year() : null
            sowingDate = values.sowingDate != null || values.sowingDate != 0 ? moment(values.sowingDate, 'YYYY-MM-DD') : null
            bleedingSeeds = plannedActivity.includes('A') ? 1 : 0;
            pltaetauslese = plannedActivity.includes('P') ? 1 : 0
        }
        else {
            yearOfRegistration = props.projectDetailInfo.annualRegistration != null || props.projectDetailInfo.annualRegistration != 0 ? moment(props.projectDetailInfo.annualRegistration, 'YYYY') : null
            checkBSA = values.nextCheckBsa != null ? moment(values.nextCheckBsa, 'YYYY') : null
            nextPlace = values.nextPlace != null ? moment(values.nextPlace, 'YYYY') : null
            plantDate = values.plantDate != null ? moment(values.plantDate, 'YYYY-MM-DD') : null
            sowingDate = values.sowingDate != null ? moment(values.sowingDate, 'YYYY-MM-DD') : null
            testPatternCreation = values.testPatternCreation != null ? moment(values.testPatternCreation, 'YYYY') : null
            bleedingSeeds = state.bleedingSeeds
            pltaetauslese = state.pltaetauslese
        }

        let planObj = {}

        planObj = {
            canceled: canceled,
            breedingMethods: state.breedingMethods,
            parentProjectNumber: props.projectDetailInfo.parentProjectNumber,
            lastLineDescription: props.projectDetailInfo.lastLineDescription,
            yearOfRegistration: yearOfRegistration,
            bsaExtension: values.bsaExtension,
            bleedingSeeds: state.bleedingSeeds,
            pltaetauslese: state.pltaetauslese,
            plannedActivity: type === "form" ? null : state.plannedActivity,
            nextCheckBsa: checkBSA,
            cultivationScope: values.cultivationScope,
            cultivationScopeUnit: values.cultivationScopeUnit,
            testPatternCreation: testPatternCreation,
            nextPlace: nextPlace,
            ezMultiplication: values.ezMultiplication,
            projectStatus: values.projectStatus,
            descBreedingMethod: values.descBreedingMethod,
            sowingDate: sowingDate,
            plantDate: plantDate,
            numberCarrier: values.numberCarrier,
            amountOfEliteSeeds: values.amountOfEliteSeeds,
            culturalTour: values.culturalTour,
            deviationPlan: values.deviationPlan,
            commentVariety: values.commentVariety,
            id: state.id,
            projectId: props.projectInfo.projectId,
            projectDetailId: props.projectDetailInfo.id,
            projectNumber: props.projectInfo.projectNumber
        }
        return planObj;
    }

    const onFinish = (values) => {
        setLoading(true);

        let promise;
        promise = saveProjectReport(mapObjToReport(values, "form"));
        if (!promise) {
            return;
        }

        promise.then(response => {
            setLoading(false)

            props.reloadProject(screenState);
            notification.success({ message: "Kultursaat", description: "Bericht aktualisiert!", placement: 'topLeft' })
        }).catch(error => {
            console.log(error)
            setLoading(false)
            notification.error({ message: "Kultursaat", description: "Error saving report", placement: 'topLeft' })
        });
    };

    form.setFieldsValue({
        bleedingSeeds: state.bleedingSeeds,
        pltaetauslese: state.pltaetauslese

    });


    // const allBreedingMethods = props.breedingMethods.filter(function (el) {
    //     return el.projectType == props.projectInfo.projectType
    // });


    // let existingBreedingMethods = state.breedingMethods.map(a => a.id);

    // useEffect(() => form.resetFields(), [props.projectReportInfo]);

    const initVals = mapObjToReport(state, "obj");

    const getCanceledBlock = () => {
        return (
            <>
                <Descriptions.Item label={t("project_report.the_project_was")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start', width: '300px' }} >
                    {editing && editable ?
                        <Form.Item name="canceled">
                            <Space>
                                <Select style={{ width: 250 }} onChange={onChange} defaultValue={canceled}>
                                    <Option value={0}>{t("project_report.completed")}</Option>
                                    <Option value={1}>{t("project_report.not_completed")}</Option>
                                </Select>
                            </Space>
                        </Form.Item> :

                        !state.canceled ?
                            <Tag color="#87d068">{t("project_report.completed")}</Tag> :
                            <Tag color="#f50">{t("project_report.not_completed")}</Tag>}
                </Descriptions.Item>
            </>
        )
    }

    const getReportBlock = () => {
        return (
            <>
                <Descriptions.Item label="Enstanden aus projekt" span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                    {editing && editable ?
                        <Form.Item
                            name="parentProjectNumber"
                        >
                            <Input.TextArea style={{ width: 480 }} disabled />
                        </Form.Item>
                        : props.projectDetailInfo.parentProjectNumber}
                </Descriptions.Item>

                <Descriptions.Item label="Letzte Sorten/Linienbezeichnung" labelStyle={{ fontWeight: 'bold' }} span={3}>
                    {editing && editable ?
                        <Form.Item
                            name="lastLineDescription"
                        >
                            <Input.TextArea name="lastLineDescription" disabled />
                        </Form.Item>
                        : props.projectDetailInfo.lastLineDescription}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Zulassungsjahr" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="yearOfRegistration"
                            hasFeedback
                        >
                            <DatePicker picker="year" disabled />
                        </Form.Item>
                        : props.projectDetailInfo.annualRegistration}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Zulassung soil verlängert werden" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="bsaExtension"
                            hasFeedback
                        >
                            <Select valuePropName="option" style={{ width: '100px' }}>
                                <Option value="ja">Ja</Option>
                                <Option value="nein">Nein</Option>
                            </Select>
                        </Form.Item>
                        : state["bsaExtension"]}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Gepl Tätigkeit" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <div>
                            <Checkbox.Group style={{ width: '100%' }}  defaultValue={getCheckedStatus()} >
                                <Row>
                                    <Form.Item
                                        name="bleedingSeeds"
                                    >
                                        <Col span={24}>
                                            <Checkbox name="bleedingSeeds" value="A" onChange={handleChangeOther("bleedingSeeds")} >Anbau Auslesebestand</Checkbox>
                                        </Col>
                                    </Form.Item>
                                    <Form.Item
                                        name="pltaetauslese"
                                    >
                                        <Col span={24}>
                                            <Checkbox name="pltaetauslese" value="P" onChange={handleChangeOther("pltaetauslese")}>Pflege Samentrager/Samemernte</Checkbox>
                                        </Col>
                                    </Form.Item>
                                </Row>
                            </Checkbox.Group>
                        </div>
                        : <Checkbox.Group style={{ width: '100%' }} defaultValue={getCheckedStatus()} disabled >
                            <Row>
                                <Col span={8}>
                                    <Checkbox value="A"  >Anbau Auslesebestand</Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="P"  >Pflege Samentrager/Samemernte</Checkbox>
                                </Col>
                            </Row>
                        </Checkbox.Group>}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Nächster prufanbau BSA" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="nextCheckBsa"
                            hasFeedback
                        >
                            <DatePicker picker="year" />
                        </Form.Item>
                        : state["nextCheckBsa"]}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Anbaummfang" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?

                        <Space>
                            <Form.Item
                                name="cultivationScope"
                                hasFeedback
                            >
                                <InputNumber onChange={handleChangeOther("cultivationScope")} />
                            </Form.Item>
                            <Form.Item
                                name="cultivationScopeUnit">
                                <Select
                                    valuePropName="option"
                                    // value={value.currency || currency}
                                    style={{ width: '100px' }}
                                    onChange={handleChangeOther("cultivationScopeUnit")}
                                    defaultValue={state["cultivationScopeUnit"]}
                                >

                                    <Option value={"qm"}>qm</Option>
                                    <Option value={"ha"}>ha</Option>
                                    <Option value={"stück"}>stück</Option>
                                </Select>
                            </Form.Item>
                        </Space>

                        : state["cultivationScope"] + " " + state["cultivationScopeUnit"]}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Prüfmuster für BSA fertig" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="testPatternCreation"
                            hasFeedback
                        >
                            <DatePicker picker="year" />
                        </Form.Item>
                        : state["testPatternCreation"]}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="nächstes basissaatgut für SGV fertig" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="nextPlace"
                            hasFeedback
                        >
                            <DatePicker picker="year" />
                        </Form.Item>
                        : state["nextPlace"] == 0 ? "noch unklar" : state["nextPlace"]}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Erhaltungszucht innerh Vermehrung" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="ezMultiplication"
                            hasFeedback
                        >
                            <Select valuePropName="option" style={{ width: 140 }}>
                                <Option value="Ja">Ja</Option>
                                <Option value="Nein">Nein</Option>
                            </Select>
                        </Form.Item>
                        : state["ezMultiplication"]}
                </Descriptions.Item>

                <Descriptions.Item label={t("project_report.project_status")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                    {editing && editable ? <Form.Item
                        name="projectStatus"

                    >
                        <Select >
                            <Option value="weiterhin aktuell">weiterhin aktuell</Option>
                            <Option value="erfolgreich abgeschlossen">erfolgreich abgeschlossen</Option>
                            <Option value="abgebrochen">abgebrochen</Option>

                        </Select>
                    </Form.Item> :
                        state.projectStatus}
                </Descriptions.Item>

                <Descriptions.Item label={t("project_details.breeding_method")} labelStyle={{ fontWeight: 'bold' }} span={3}>
                    {editing && editable ?
                        <BreedingMethodLookup
                            projectType={'EZ'}
                            setItems={handleChangeOther("breedingMethods")}
                            existingItems={breedingMethods}
                            setFormattedItems={handleChangeOther("breedingMethods")}
                        />
                        : <List
                            size="small"
                            // bordered
                            dataSource={breedingMethods}
                            renderItem={item => <List.Item style={{ padding: 2 }}><Badge color={'blue'} text={item.name} /></List.Item>}
                        />}
                </Descriptions.Item>

                <Descriptions.Item label={t("project_details.breeding_method_description")} labelStyle={{ fontWeight: 'bold' }} span={3}>
                    {editing && editable ?
                        <Form.Item
                            name="descBreedingMethod"

                        >
                            <Input.TextArea name="descBreedingMethod" onChange={handleChange} />
                        </Form.Item>
                        : state.descBreedingMethod}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Aussaat" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="sowingDate"
                            hasFeedback
                        >
                            <DatePicker />
                        </Form.Item>
                        : moment(state.sowingDate).format('YYYY-MM-DD')}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Pflanzung" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="plantDate"
                            hasFeedback
                        >
                            <DatePicker />
                        </Form.Item>
                        : moment(state.plantDate).format('YYYY-MM-DD')}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Anz Samentrager" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="numberCarrier"
                            hasFeedback
                        >
                            <InputNumber />
                        </Form.Item>
                        : state["numberCarrier"]}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Erntemenge Basissaatgut" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="amountOfEliteSeeds"
                            hasFeedback
                        >
                            <InputNumber />
                        </Form.Item>
                        : state["amountOfEliteSeeds"]}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Bemerkungen" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="culturalTour"
                            hasFeedback
                        >
                            <Input.TextArea />
                        </Form.Item>
                        : state["culturalTour"]}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Abweichung von plan" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="deviationPlan"
                            hasFeedback
                        >
                            <Input.TextArea />
                        </Form.Item>
                        : state["deviationPlan"]}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Kommentar zur sorte" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="commentVariety"
                            hasFeedback
                        >
                            <Input.TextArea />
                        </Form.Item>
                        : state["commentVariety"]}
                </Descriptions.Item>
            </>)
    }

    return (
        <div>
            <Form name="reports-form" form={form} onFinish={onFinish} initialValues={initVals}>
                <Descriptions
                    size="small"
                    extra=
                    {editable ?
                        !editing ?
                            <Space>
                                <Popconfirm
                                    title={"Möchten Sie den Bericht wirklich löschen??"}
                                    onConfirm={props.deleteReport}
                                >
                                    <Button type="danger" >Bericht löschen</Button>
                                </Popconfirm>

                                <Button onClick={() => setEditingLocal(true)}>Bearbeiten</Button> </Space> :
                            <Space>
                                <Button onClick={() => form.submit()} type='primary'>Speichern</Button>
                                <Button onClick={() => setEditingLocal(false)}>Abbrechen</Button>
                            </Space> : ""

                    }>
                </Descriptions>


                <Descriptions
                    size="small"
                    bordered
                >
                    {getCanceledBlock()}
                    {!canceled && getReportBlock()}
                </Descriptions>
            </Form>
        </div>
    )
}

export default ReportEz
