import React from 'react'
import {
    Badge,
    Descriptions,
    Divider,
    Table,
    Layout,
    Button,
    Input,
    List,
    Select,
    Row,
    Col,
    Form,
    Skeleton,
    PageHeader,
    Space,
    notification
} from 'antd';
import MultiSelect from '../../common/MultiSelect';
import { updateUserRoles } from '../../util/APIUtils';

function UserRoles(props) {
    console.log(props)

    const [loading, setLoading] = React.useState(false)
    const [editing, setEditing] = React.useState(false)
    const [roles, setRoles] = React.useState(props.roles)
    const [selectedItems, setSelectedItems] = React.useState(props.roles);

    const saveRoles = () => {
        let userProfileObj = {
            "id": props.id,
            "roles": selectedItems.map(a => ({  "id": a }))
        }

        console.log(userProfileObj);    

        let promise;

        promise = updateUserRoles(userProfileObj);

        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response);
            setEditing(false);
            props.reload();
            

        }).catch(error => {
            console.log(error)
        });
    }


    const handleChange = selectedItems => {
        setSelectedItems(selectedItems);
        // setItems(selectedItems);
    };

    const handleChangeOther = name => value => {
        console.log(name)
        console.log(value)
        setSelectedItems(value)
    };

    let existingItems = props.roles.map(a => a.id);


    return (    
        <div><Divider
            style={{
                marginBottom: 20
            }} />
            <Descriptions
                title={"Roles and FG memberships"}
                bordered
                size="small"
                style={{
                    marginBottom: 20
                }}
                extra={
                    !editing ?
                        <Button onClick={() => setEditing(true)}>Bearbeiten</Button> :
                        <Space>
                            <Button key="35" type='primary' onClick={saveRoles}>Speichern</Button>
                            <Button onClick={() => setEditing(false)}>Abbrechen</Button>
                        </Space>
                }
            >
                <Descriptions.Item label="Roles" labelStyle={{width: "200px"}}>
                    {editing ?
                        <MultiSelect
                            setItems={handleChangeOther("roles")}
                            existingItems={existingItems}
                            itemList={props.allRoles} />
                        :
                        <List
                            size="small"
                            // bordered
                            dataSource={props.roles}
                            renderItem={item => <List.Item style={{ padding: 2 }}><Badge color={'blue'} text={item.name} /></List.Item>}
                        />}
                </Descriptions.Item>
            </Descriptions></div>
    )
}

export default UserRoles