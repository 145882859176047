import React, { useState, useRef } from 'react';
import { Modal, Button, Form, Select, InputNumber, Input, Descriptions } from 'antd';
import {
    PlusSquareFilled,
  
  } from '@ant-design/icons';
import ResourceInfoForm from './ResourceInfoForm';
const { Option } = Select;

function AddFile(props) {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const formRef = useRef(null);

    const showModal = () => setVisible(true);
    const handleCancel = () => setVisible(false);

    const currentUser = props.currentUser;
    console.log(currentUser)

    const handleOk = () => {
        formRef.current.submit();
    };

    const handleLoadingChange = (isLoading) => {
        setLoading(isLoading);
    };

    const handleVisibleChange = (isVisible) => {
        setVisible(isVisible);
    };

    return (
        <>
            <Button icon={<PlusSquareFilled />} type='primary' onClick={showModal}>Dokument hinzufügen</Button>
            <Modal
                forceRender
                style={{ top: 20 }}
                title={"Datei hinzufügen"}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={loading}
                onLoadingChange={handleLoadingChange} 
            >
                <ResourceInfoForm ref={formRef} currentUser={currentUser} handleLoadingChange={handleLoadingChange} handleVisibleChange={handleVisibleChange} refetch={props.refetch}/>
            </Modal>
        </>
    );

}

export default AddFile;
