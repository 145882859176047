import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { TinyArea, measureTextWidth } from '@ant-design/charts';
export default function TinyAreaChart(props) {

  const lData = props.data;
  const config = {
    height: 40,
    width: 160,
    autoFit: false,
    data: lData,
    smooth: true,
    color: '#E5EDFE',
    pattern: {
      type: 'line',
      cfg: {
        stroke: '#5B8FF9',
      },
    },
  };
  return <TinyArea {...config} />;
};