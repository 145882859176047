import { Table, Tag, Button, Modal, Form, Select, Input, Typography, Descriptions, InputNumber } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import { getFgBudgetsForCropId, updateFgApproval, isFgLeader } from '../util/APIUtils';
import { currencyFormatter, currencyParser, formatCurrency } from '../util/Helpers';
import { Link } from 'react-router-dom';
import CurrencyInputNumber from '../common/CurrencyInputNumber';
const { Option } = Select;
const { Title } = Typography;

const FgBudgetReview = (props) => {
  const [fgLeader, setFgLeader] = useState(false)
  const [modalVisible, setModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState(props.comment)
  const [approvalStatus, setApprovalStatus] = useState(null)
  const [approvedAmount, setApprovedAmount] = useState(null)
  const [form] = Form.useForm();
  const useMyData = getFgBudgetsForCropId(props.cropId);
  const currentUser = props.currentUser;

  const isUserFgLeader = () => {
    let promise;
    promise = isFgLeader(props.cropId);

    if (!promise) {
      return;
    }

    promise.then(response => {
      setFgLeader(response)

    }).catch(error => {
      console.log(error)
    });
  }
  React.useEffect(() => {
    isUserFgLeader();
  }, [isUserFgLeader]);


  const { isLoading, error, data, refetch } = useMyData(
    (jsonData) => jsonData,
  );

  if (isLoading) {
    return <p>Wird geladen .....</p>;
  }

  if (error) {
    return <p>Error Wird geladen ..: {error.message}</p>;
  }

  const handleOk = () => {
    console.log("Submitting form with values: ", form.getFieldsValue());
    let values = form.getFieldsValue()
    // Perform any necessary actions to save the form data here
    setModalVisible(false);
    setCurrentRecord({});
    setConfirmLoading(true);
    let workFlowResponse = {
      itemId: currentRecord.id,
      comment: values.comments || currentRecord.commentsFg,
      approvedAmount: values.approvedAmount || currentRecord.amountFg,
      fgStatus: approvalStatus,
      approverType: "FG"
    }

    console.log(workFlowResponse)
    let promise;
    promise = updateFgApproval(workFlowResponse);

    if (!promise) {
      return;
    }

    promise.then(response => {
      setConfirmLoading(false);
      setModalVisible(false);
      refetch()
    }).catch(error => {
      console.log(error)
    });
  };

  const getStatusValue = (val) => {
    console.log(val)
    let st = null;
    switch (val) {
      case "abgelehnt":
        st = 0;
        break;
      case "ok":
        st = 1;
        break;
      case "zurückgestellt":
        st = -1;
        break;
      default:
        break;
    }

    return st
  }

  const columns = [
    {
      title: 'Züchter',
      dataIndex: 'breederName',
      key: 'breederName',
      render: (text, record) => {
        return (
          <Link
            to={{
              pathname: `/fg_budget_detail/${record.cropId}/${record.breederId}`,

            }}> {text} </Link>
        )
      }
    },
    {
      title: 'Kulturart',
      dataIndex: 'cropName',
      key: 'cropName',
    },
    {
      title: 'Plan',
      dataIndex: 'activityPlan',
      key: 'activityPlan',
    },
    {
      title: 'Betrag',
      dataIndex: 'budgetTotal',
      key: 'budgetTotal',
      align: 'right',
      render: (text) => {
        return (
          <b>{formatCurrency(text)}</b>
        )
      }
    },
    {
      title: 'Betrag FG',
      dataIndex: 'fgOkAmount',
      key: 'fgOkAmount',
      align: 'right',
      render: (text) => {
        return (
          <b>{formatCurrency(text)}</b>
        )
      }
    },
    {
      title: 'Status',
      key: 'statusFg',
      dataIndex: 'statusFg',
      render: status => {
        let color = 'green';
        if (status == 'zurückgestellt') {
          color = 'yellow';
        } else if (status == 'abgelehnt') {
          color = 'red';
        }
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button disabled={!fgLeader} size='small' style={{ float: 'right' }} type="primary" onClick={() => handleApprove(record)}>
          Evaluieren
        </Button>
        // <Button size='small' onClick={() => handleApprove(record)}>Approve</Button>
      ),
    },
  ];

  const handleApprove = (record) => {
    console.log("Approving: ", record);
    setModalVisible(true);
    setCurrentRecord(record);
    setApprovalStatus(getStatusValue(record.statusFg))
    setApprovedAmount(record.fgOkAmount)
    setComment(record.commentsFg)
    form.setFieldsValue({
      approvedAmount: record.fgOkAmount,
      approvalStatus: record.statusFg,
      comments: record.commentsFg,
    });
    // Perform any necessary actions to approve the record here
  }

  const handleCancel = () => {
    setModalVisible(false);
    setCurrentRecord({});
  };

  //   let initVals = {
  //     comments: comment,
  //     approvalStatus: getStatusValue(approvalStatus),
  //     approvedAmount: approvedAmount
  // }

  return (
    <>
      <Title level={5} style={{ backgroundColor: '#ccc', color: 'black', padding: '10px' }}>
        FG Evaluation
      </Title>
      {data.length > 0 &&
        <div>
          <Table columns={columns} dataSource={data} size={'small'} pagination={false} bordered />
          <Modal
            title="Evaluation"
            visible={modalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form form={form}
              onValuesChange={(changedValues, allValues) => {
                if (changedValues.hasOwnProperty('approvalStatus') && changedValues.approvalStatus != '1') {
                  form.setFieldsValue({ approvedAmount: 0 });
                }
              }}>
              <Descriptions size='small' bordered>
                <Descriptions.Item span={3} label="Status" >
                  <Form.Item
                    name="approvalStatus"
                    rules={[{ required: true, message: 'Pflichtfeld' }]}
                  >
                    <Select onChange={setApprovalStatus}>
                      <Option value={99}> </Option>
                      <Option value={1}>ok</Option>
                      <Option value={0}>abgelehnt</Option>
                      <Option value={-1}>zurückgestellt</Option>
                    </Select>
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Bemerkungen" span={3}  >
                  <Form.Item
                    name="comments"
                    rules={[{ required: true, message: 'Pflichtfeld' }]}
                  >
                    <Input.TextArea rows={4} onChange={setComment} />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Betrag" span={3} >
                  <Form.Item
                    name="approvedAmount"
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        message: 'Pflichtfeld',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (getFieldValue('approvalStatus') === 1 && (!value || value <= 0)) {
                            return Promise.reject('Der genehmigte Betrag muss größer als Null sein, wenn der Genehmigungsstatus "ok" ist');
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      formatter={currencyFormatter}
                      parser={currencyParser}
                      onChange={setApprovedAmount}
                    />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </Form>
            {/* <Form form={form} initialValues={currentRecord}>
          <Form.Item label="Status" name="status" rules={[{ required: true }]}>
            <Select >
              <Option value={99}> </Option>
              <Option value={1}>ok</Option>
              <Option value={0}>abgelehnt</Option>
              <Option value={-1}>zurückgestellt</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Amount" name="amount" rules={[{ required: true }]}>
            <Input type="number" placeholder="Enter Amount" />
          </Form.Item>
          <Form.Item label="Comments" name="comments">
            <Input placeholder="Enter comments" />
          </Form.Item>
        </Form> */}
          </Modal>
        </div>}

    </>
  );
};

export default FgBudgetReview
