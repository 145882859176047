import React from 'react'
import CreatableSelect from 'react-select/creatable';

function SelectEditInputCell(props) {
    const [eventState, setEventState] = React.useState("")
    const { id, value, field, itemName } = props;
    
    const procesedItems = props.items.map((item) => ({ label: item[itemName], value: item.id }))
        
    const handleParentClick = (e) => { 
         if(eventState === "select-option") {
            e.stopPropagation();
            e.preventDefault();
         }
      }
    
    const handleChange = (event, selectedItem) => {
        setEventState(selectedItem.action)
        props.apiRef.current.setEditCellValue({ id: id, field, value: event.label });
        props.apiRef.current.stopCellEditMode({ id: id, field });
    };

    const styles = {
        menu: base => ({
            ...base,
            marginTop: 0
        })
    };

    return (
        <div style={{ minWidth: "400px", borderWidth: "0px" }} onClick={handleParentClick}>
            <CreatableSelect options={procesedItems} width={300}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                onChange={handleChange}
                openMenuOnClick={true}
                placeholder={<div style={{ color :'#ccc'}}>Auswählen oder eingeben</div>}
                openAfterFocus={true}
                formatCreateLabel={userInput => `hinzufügen ${userInput}`}
                styles={{
                    ...styles, option: (base) => ({
                        ...base,
                        height: '100%',
                        padding: '5px 10px 5px 10px'
                    }),
                }}
            />
        </div>)
}

export default SelectEditInputCell