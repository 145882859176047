import React from 'react'
import GlLineSelectionTable from './GlLineSelectionTable'

function GlLineSelectionsReport(props) {
    console.log(props.itemList)
    let itemList = props.itemList ? props.itemList.split(';').map(elem => ({ id: 0, "name": elem  })): [];
    console.log(itemList);

    const appendedString = (arr) => {
        let appendedStr = arr.map(elem => elem.name).join(';')
        console.log(appendedStr)
        props.setItems(appendedStr)
    }

  return (
    <div>
        <GlLineSelectionTable dataSource={itemList} setItems={appendedString} />
    </div>
  )
}

export default GlLineSelectionsReport