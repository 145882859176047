import React from 'react'
import { addSorten, deleteSorten, getSortenById } from '../util/APIUtils';
import { Form, Input, Select, DatePicker, Modal, Descriptions, notification, PageHeader, Button, Space, Popconfirm } from 'antd';
import CropSelect from '../common/CropSelect';
import moment from 'moment'
import {Link, useHistory} from 'react-router-dom';
import DeleteWithConfirm from '../common/DeleteWithConfirm';

function SortenDetail(props) {
    const [editing, setEditing] =  React.useState(props.editing)
    const [startOpen, setStartOpen] = React.useState(false);
    const [endOpen, setEndOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false)
    const [cropId, setCropId] = React.useState(null)
    const [form] = Form.useForm();
    const handleBack = e => {
            props
            .history
            .goBack();
    };

    
    const { id } = props.match.params;
    let useMyData = getSortenById(id);

    const { isLoading, error, data, refetch } = useMyData(
        (jsonData) => jsonData,
    );

    if (isLoading) {
        return <p>Wird geladen .....</p>;
    }

    if (error) {
        return <p>Error Wird geladen ..: {error.message}</p>;
    }

    const sorten = data;
    let initVals = !editing ? {} : {
        designation: sorten?.designation,
        cropId: sorten.cropId,
        permit: sorten.permit != null ? moment(sorten.permit, 'YYYY') : null,
        extendedUntil: sorten.extendedUntil != null ? moment(sorten.extendedUntil, 'YYYY') : null,
    }

    console.log(initVals)

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        setLoading(true)
        let sortenObj = {
            id: sorten? sorten.id: null,
            designation: values.designation,
            cropId: cropId? cropId: sorten.cropId,
            permit: moment.isMoment(values.permit) ? moment(values.permit).year() : sorten.permit,
            extendedUntil: moment.isMoment(values.extendedUntil) ? moment(values.extendedUntil).year() : sorten.extendedUntil,
        }
       
        let promise;
        console.log(sortenObj)
        promise = addSorten(sortenObj);
        if (!promise) {
            return;
        }
        promise.then(response => {
            setLoading(false)
            setEditing(false)
           notification.success({
                message: 'Kultursaat',
                description: "Erfolgreich gelöscht"
            });
            refetch()
        }).catch(error => {
            console.log(error)
        });

    };

    form.setFieldsValue({
        designation: initVals?.designation,
        cropName: initVals?.cropId,
        permit: initVals?.permit,
        extendedUntil: initVals?.extendedUntil

    });

    const deleteSortenDetail = () => {
        let promise;
        promise = deleteSorten(id)

        if (!promise) {
            return;
        }
        setLoading(true)

        promise.then(response => {
            setLoading(false)
            handleBack();
        }).catch(error => {
            console.log(error);
            this.setState({ isLoading: false });
        });
    }

    return (
        <>
        <PageHeader title="Sorten"
        className="site-page-header"
        onBack={() => handleBack()}
        extra={<Space><Button  type="primary" onClick={() => setEditing(true)}>
            Hinzufügen
        </Button>
        <DeleteWithConfirm confirm={deleteSortenDetail}/></Space>}
    />
        <Form form={form} onFinish={onFinish} initialValues={initVals}>
            <Descriptions
                bordered
                size={"small"}
            >
                <Descriptions.Item span={3} label="Bezeichnung" labelStyle={{width: '30%'}}>
                    {editing ?
                    <Form.Item
                        name="designation"
                        rules={[{ required: true, message: 'Please input designation!' }]}
                    >
                        <Input />
                    </Form.Item> : sorten.designation}
                </Descriptions.Item>
                <Descriptions.Item span={3} label="Kulturart">
                {editing ?
                    <Form.Item
                        name="cropName"
                        // rules={[{ required: true, message: 'Please input crop name!' }]}
                    >
                        <CropSelect selectedItem={initVals?.cropId} setItem={setCropId} />
                    </Form.Item>: sorten.cropName}
                </Descriptions.Item>
                <Descriptions.Item span={3} label="Zulassung">
                {editing ?
                    <Form.Item name="permit" >
                        <DatePicker
                            picker="year"
                        />
                    </Form.Item> : sorten.permit}
                </Descriptions.Item>
                <Descriptions.Item span={3} label="Verlaengert bis">
                {editing ?
                    <Form.Item name="extendedUntil" >
                        <DatePicker
                            picker="year"
                        />
                    </Form.Item> : sorten.extendedUntil}
                </Descriptions.Item>
                {editing && 
                <Form.Item
                name="submit"

            >
                <Space><Button type="primary" htmlType="submit" loading={props.loading}>
                Speichern
                </Button> <Button type="secondary" onClick={() => setEditing(false)}>
                Abbrechen
                    </Button></Space>
            </Form.Item>
}

            </Descriptions>
        </Form>
        </>
    )
}

export default SortenDetail