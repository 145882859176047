import React, { Component } from 'react'
import { getFgBudgetsforFg, getGroupTasks, getUserTasks } from '../util/APIUtils';
import { CommentOutlined, TabletTwoTone } from '@ant-design/icons';

import FgComments from '../fachgroups/FgComments';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';



import {
    Card,
    Space,
    Badge,
    List,
    Tabs,
    Tag,
    PageHeader,
    Modal,
    Table
} from 'antd';
import ProjectFgTasks from './ProjectFgTasks';
import FgFgTasks from './FgFgTasks';
const { TabPane } = Tabs;
export class TaskList extends Component {
    constructor(props) {
        console.log(props)
        super(props);
        this.state = {
            isLoading: false,
            tasks: [],
            comments: [],
            groupId: props.match.params.id,
            commentModalVisible: false,
            fgId: props.match.params.id,
            fgBudgets: [],
            currentUser: props.currentUser,
            activeTab: (props.location && props.location.state && props.location.state) ? props.location.state.tab : "1"
        };
        console.log(this.state)
        console.log(props.match.params.id)
    }

    

    showModal = (item) => {
        console.log("Show modal")
        this.setState({
            commentModalVisible: true,
            comments: item.comments
        });
    }

    handleOk = () => {
        this.setState({
            commentModalVisible: false
        });
    };

    handleCancel = () => {
        this.setState({
            commentModalVisible: false
        });
    };

    handleBack = e => {
        this
            .props
            .history
            .goBack();
    };


    loadTasksForGroup(groupId) {
        console.log('Loading Tasks')
        let promise;
        promise = getGroupTasks(this.props.match.params.id);
        if (!promise) {
            return;
        }
        this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response);
            this.setState({
                tasks: response.filter(x => !!x),
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    loadFgBudgetsForGroup(groupId) {
        console.log('Loading Tasks')
        let promise;
        this.setState({
            isLoading: true
        })
        promise = getFgBudgetsforFg(this.props.match.params.id);

        if (!promise) {
            return;
        }
        this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response);
            this.setState({
                fgBudgets: response,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    componentDidMount() {
        this.loadTasksForGroup(this.state.groupId);
        this.loadFgBudgetsForGroup()
    }

    onChange = (key) => {
        console.log(key);
    };

    render() {
        const { t } = this.props;

        return (
            <div>
            <PageHeader
                    className="site-page-header"
                    onBack={() => this.handleBack()}
                    title={"FG Aufgaben"}
                // extra={<Button type='primary'>Add</Button>}
                // subTitle={this.state.farmProfile.name}
                />
            <Card bordered={true} loading={this.state.isLoading} headStyle={{ padding: "0" }}>
               
                    {/* <Modal visible={this.state.commentModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
                    <FgComments comments={this.state.comments} />
                </Modal> */}
                    <Tabs type="card"

                        defaultActiveKey={this.state.activeTab}
                        onChange={this.onChange}
                    >
                        <TabPane tab={"Projekte"} key="1">
                            <ProjectFgTasks projectTasks={this.state.tasks} loading={this.state.loading} />
                        </TabPane>
                        <TabPane tab={"FG Tasks"} key="2">
                            <FgFgTasks fgBudgets={this.state.fgBudgets} loading={this.state.loading} currentUser={this.props.currentUser} fgId={this.state.groupId}/>
                        </TabPane>

                    </Tabs>


                    {/* <List
                    dataSource={tasks}
                    renderItem={item => {
                        let linkText = `/project/detail/${item.taskData.projectId}`;
                        return (<List.Item key={"br" + item.taskId}
                            extra={<Space>


                                <CommentOutlined onClick={() => this.showModal(item)} /> <Tag color="green">Pending FG Approval</Tag> </Space>}>
                            <List.Item.Meta
                                avatar={
                                    <TabletTwoTone />
                                }
                                // title={<a href={linkText}>{item.taskData.projectNo}</a>}
                                title={<Link
                                    to={{
                                        pathname: linkText,
                                        state: {
                                            id: item.taskData.projectId,

                                        }
                                    }}>{item.taskData.projectNo}</Link>}
                                description={<div>{item.taskData.userName}{item.taskData.year}</div>}
                            />
                        </List.Item>)

                    }} /> */}
                </Card>
                </div>
        )
    }
}

export default withTranslation()(withRouter(TaskList));
