import {
    DataGrid, useGridApiContext, deDE, useGridApiRef, GridToolbarContainer, GridRowModes,
    GridActionsCellItem,
} from '@mui/x-data-grid';
import { NumericFormat } from 'react-number-format';
import { styled } from '@mui/material/styles';


import { Button, Select, Card, Descriptions, Input, Space, message, InputNumber, Divider, Popconfirm } from 'antd';
import React from 'react'
import { useEffect, useState, useMemo, useRef } from 'react'
import { currencyFormatter, currencyParser, formatCurrency } from '../../util/Helpers';
import { DeleteOutlined, PlusCircleOutlined, SaveOutlined, PlusOutlined } from '@ant-design/icons';
import { deleteFgCalculationById, getFgAccountingItems, getFgBudgetCalculations, getProjectDetailAccountingItems, updateFgCalculationItem } from '../../util/APIUtils';
import { Box } from '@mui/system';
import { Cancel, CancelOutlined, CheckBox, CheckBoxOutlineBlank, CheckBoxOutlineBlankTwoTone, CheckBoxOutlined, CheckCircle, CheckCircleOutline, Done } from '@mui/icons-material';


const FgSummary = (props) => {
    const [loading, setLoading] = useState(false);
    const isPlanEditable = props.isPlanEditable;
    const isReportEditable = props.isReportEditable;
    const [data, setData] = useState(props.data || []);
    const [budgetTotal, setBudgetTotal] = useState(0);
    const [actualTotal, setActualTotal] = useState(0);
    const fgBudgetId = props.fgBudgetId;
    const accountingItems = props.accountingItems;


    const loadFgCalculations = React.useCallback(async () => {
        let promise;
        promise = getFgBudgetCalculations(fgBudgetId);

        if (!promise) {
            return;
        }
        setLoading(true);
        promise.then(response => {
            response.push(createSummaryData(response));
            setData(response);
            console.log(response)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, [])

    useEffect(() => {
        loadFgCalculations();
    }, [loadFgCalculations]);

    const createSummaryData = (response) => {
        let summaryRow = createNewRow("sum")
        summaryRow.accountingItemName = "Gesamt"
        summaryRow.budgetRowTotal = response.reduce((a, b) => a + (b['budgetRowTotal'] || 0), 0).toFixed(2)
        summaryRow.actualRowTotal = response.filter(a => a.selected == true).reduce((a, b) => a + (b['actualRowTotal'] || 0), 0).toFixed(2)
        summaryRow.daysWorkerBudget = response.reduce((a, b) => a + (b['daysWorkerBudget'] || 0), 0).toFixed(2)
        summaryRow.daysWorkerActual = response.reduce((a, b) => a + (b['daysWorkerActual'] || 0), 0).toFixed(2)
        return summaryRow;
    }

    const createNewRow = (type) => {
        return {
            id: type == "sum" ? 108080: 0,
            accountingItemName: "",
            budgetRowTotal: 0,
            actualRowTotal: 0,
            daysWorkerActual: 0,
            daysWorkerBudget: 0,
            isNew: true,
            fgBudgetId: fgBudgetId,
            budget: isPlanEditable,
            actual: isReportEditable,
            rowType: type
        }
    }

    const columnGroupingModel = [
        {
            groupId: 'Züchter',
            description: '',
            headerAlign: 'center',
            children: [{ field: 'daysWorkerBudget' }, { field: 'daysWorkerActual' }],
        },
        {
            groupId: 'Gesamt',
            description: '',
            headerAlign: 'center',
            children: [{ field: 'budgetRowTotal' }, { field: 'actualRowTotal' }],
        },
    ];
    const renderActualSelected = (row) => {
        if(row.row.id === 108080) {
            return "";
        } else {
            return row.row.selected ? <CheckCircleOutline style={{ fill: "green" }} /> : <CancelOutlined style={{ fill: 'orangered' }} />
        }
    }


    const columns = [
        { field: 'accountingItemId', headerName: 'Id', hide: true },
        // { field: 'selected', headerName: 'Abrechnung', minWidth: 20, editable: true, type: 'boolean', flex: 1,  },
        { field: 'accountingItemName', headerName: 'Tätigkeiten', minWidth: 400, flex : 1 },
        {
            field: 'daysWorkerBudget',
            headerName: 'Kalkulation',
            valueFormatter: ({ value }) => currencyFormatter(value),
            // valueGetter: ({ value }) => currencyParser(value),
            headerAlign: 'right',
            align: 'right',
            minWidth: 50,
            flex : 1
        },
        {
            field: 'daysWorkerActual',
            headerName: 'Abrechnung',
            valueFormatter: ({ value }) => currencyFormatter(value),
            minWidth: 50,
            flex : 1,
            headerAlign: 'right',
            align: 'right'
        },
        {
            field: 'budgetRowTotal',
            headerName: 'Kalkulation',
            valueFormatter: ({ value }) => formatCurrency(value),
            type: 'number',
            minWidth: 70,
            flex : 1,
            headerAlign: 'right',
            align: 'right',
        },
        { field: 'selected', headerName: '-', minWidth: 20, type: 'boolean', flex: 1, renderCell: renderActualSelected},
        {
            field: 'actualRowTotal',
            headerName: 'Abrechnung',
            valueFormatter: ({ value }) => formatCurrency(value),
            type: 'number',
            minWidth: 70,
            flex : 1,
            headerAlign: 'right',
            align: 'right',
        },
       
    ];

    
    const handleProcessRowUpdateError = React.useCallback((error) => {
        // setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const checkEditable = (param) => {

        if (param.row.rowType === "sum") {
            return false;
        }
        if (param.colDef.field === "accountingItemName" && param.row.budget && isPlanEditable) {
            return true;
        }

        if (param.colDef.field === "accountingItemName" && param.row.actual && isReportEditable) {
            return true;
        }

        if (isPlanEditable && ['daysWorkerBudget'].includes(param.colDef.field)) {
            return true;
        }

        if (isReportEditable && ['selected','daysWorkerActual'].includes(param.colDef.field)) {
            return true;
        }

        return false;

    }

    function CustomFooter(props) {
        return (
        <Box sx={{ fontWeight: "600", border: `1px solid #f0f0f0`, backgroundColor: "#f1f7ee", padding: "0px", display: "flex", justifyContent: "right", alignItems: "right" }}>
      </Box> )
       
    }

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        border: 1,
        color:
            theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            // backgroundColor: '#ccc',
            border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnHeader': {
            height: 22,
            backgroundColor: '#f1f7ee',
        },
        '& .MuiDataGrid-cell': {

            color:
                theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
        },
        '& .MuiPaginationItem-root': {
            borderRadius: 0,
        },
        '& .sum-row-sum': {
            bgcolor: (theme) =>
              getBackgroundColor(theme.palette.success.main, theme.palette.mode),
            '&:hover': {
              bgcolor: (theme) =>
                getHoverBackgroundColor(
                  theme.palette.success.main,
                  theme.palette.mode,
                ),
            },
          },

    }));

    return (

        <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
            {/* <Space direction='vertical'> */}

            <br></br>

            <Box
                sx={{
                    width: '100%',
                    '& .budget': {
                        backgroundColor: '#dde5b6',
                        color: 'e8e8e4',
                    },
                    '& .actual': {
                        backgroundColor: '#fae1ca',
                        color: '#1a3e72',
                    },
                    '& .sum-row': {
                        backgroundColor: '#f1f7ee',
                        color: '#1a3e72',
                        fontWeight: 800
                    },
                }}
            >
            <StyledDataGrid
                disableColumnFilter
                autoHeight
                rowHeight={27}
                headerRowHeight={27}
                rows={data}
                columns={columns}
                experimentalFeatures={{ newEditingApi: true, columnGrouping: true }}
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                // hideFooter
                // columnGroupingModel={columnGroupingModel}
                disableColumnMenu
                getRowClassName={(params) => {
                    if(params.row.rowType === "sum") {
                        return 'sum-row'
                    }
                }}
                getCellClassName={(params) => {
                    if (['daysWorkerBudget'].includes(params.field)) {
                        return 'budget';
                    } else if (['daysWorkerActual'].includes(params.field)){
                        return 'actual'
                    }
                   
                }}
                components={{
                    Footer: CustomFooter
                }}
                componentsProps={{
                    footer: { budgetTotal, actualTotal }
                }}
            />
            </Box>

            {/* </Space> */}
        </div>
        </div>

    )
}

export default FgSummary