import React, { useState, useRef } from 'react'
import { GridContent } from '@ant-design/pro-layout';
import { Button, Menu, Modal, notification, Space, Divider, Row, Col } from 'antd';
import LineDetail from './LineDetail';
import { getProjectDetailLines } from '../../util/APIUtils';
import AddLine from './AddLine';
import CopyLinesPopup from './CopyLinesPopup';
const { Item } = Menu;

function Lines(props) {
    const [adding, _setAdding] = useState();
    const projectDetailId = props.projectDetailId;
    const projectId = props.projectId;

    console.log(props)

    const [initConfig, setInitConfig] = useState({
        mode: 'inline',
        selectKey: 'myTasks',
    });
    const [lines, setLines] = useState([]);
    const [selectedLine, setSelectedLine] = useState(0);
    const dom = useRef();

    const setAdding = () => {
        _setAdding(!adding)
    }
    const onClose = () => {
        _setAdding(false);
        fetchLines();
    }

    const onCloseAdd = () => {
        _setAdding(false);

        fetchLines();
    }

    let fetchLines = React.useCallback(async () => {
        let promise;
        promise = getProjectDetailLines(projectDetailId);
        if (!promise) {
            return;
        }

        // this.setState({ isLoading: true });
        promise.then(response => {
            setLines(response);
            if (lines.length > 0) {
                setSelectedLine(lines[0])
                setInitConfig({ ...initConfig, selectKey: lines[0].id });
            }

        }).catch(error => {
            console.log(error)

            // this.setState({ isLoading: false })
        });
    }, [])

    React.useEffect(() => {
        fetchLines();
    }, [fetchLines]);


    const getMenu = () => {
        return lines.map((item) => <Item style={{ borderBottomColor: "red", borderBottomWidth: "2" }} key={item.id}>{item.description}</Item>)
    };

    const renderChildren = () => {
        return <LineDetail lines={lines.find(x => x.id == selectedLine)} fetchLines={fetchLines} isReportEditable={props.isReportEditable} />;
    };

    return (
        <div>
            <GridContent>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        paddingTop: '16px',
                        paddingBottom: '16px'
                    }}
                    ref={(ref) => {
                        if (ref) {
                            dom.current = ref;
                        }
                    }}
                >
                    <div style={{ width: '275px' }}>
                        <Menu
                            mode={initConfig.mode}
                            defaultSelectedKeys={[initConfig.selectKey]}
                            // selectedKeys={[initConfig.selectKey]}
                            onClick={({ key }) => {
                                console.log(key)
                                setSelectedLine(key)
                                setInitConfig({ ...initConfig, selectKey: key });
                            }}
                        >
                            {getMenu()}
                            <Divider></Divider>
                            {props.isReportEditable ? 
                            <Row justify="center">
                                <Col span={8}><Button onClick={setAdding}>+ Linie</Button></Col>
                                <Col span={8}><CopyLinesPopup projectDetailId={projectDetailId} projectId={projectId} year={props.year} fetchLines={fetchLines} /></Col>
                            </Row> : ""}
                            {/* {props.isReportEditable ? <Space align='center'><Button onClick={setAdding}>+ Linie</Button>
                                <CopyLinesPopup projectDetailId={projectDetailId} projectId={projectId} year={props.year} fetchLines={fetchLines} />
                            </Space> : ""} */}
                            <Modal width={800} forceRender visible={adding} onClose={onClose} onCancel={onClose} footer={[]}>
                                <AddLine onClose={onCloseAdd} projectDetailId={projectDetailId} projectId={projectId} projectInfo={props.projectInfo} fetchLines={fetchLines} />
                            </Modal>
                        </Menu>

                    </div>

                    <div style={{
                        flex: 1,
                        padding: '8px 40px'
                    }}>

                        {renderChildren()}

                    </div>
                </div>
            </GridContent>
        </div>
    );
}

export default Lines;
