import React from 'react'
import { Collapse, List, Spin, Divider,Space, Tag } from 'antd';
import { CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import { getValidationResults, getValidationResultsForProject } from '../util/APIUtils';
import PlanValidation from './PlanValidation';
import ReportValidation from './ReportValidation';
import FgBudgetValidation from './FgBudgetValidation';
const { Panel } = Collapse;

function ValidationAllItem(props) {
    const [loading, setLoading] = React.useState(false);
    const validationResults = props.validationResults;
    const planValidation = props.validationResults.planValidationInfo;
    const reportValidation = props.validationResults.reportValidationInfo;

    const onChange = (key) => {
        // console.log(key);
    };

    const getIconWithSubmit = (val) => {
        console.log(validationResults)
        const submitStatus = validationResults.planSubmitStatus || validationResults.reportSubmitStatus  ? <Tag color="green">Eingereicht</Tag> : <Tag color="orange">Nicht eingereicht</Tag>
        let iconNode= val ? <CheckCircleFilled style={{ fontSize: '16px', color: "#52c41a", alignContent: 'start' }} /> :
            < WarningFilled style={{ fontSize: '16px', color: "orange" }} />
        return <Space>{submitStatus} {iconNode}</Space>
    }

   
    const getIcon = (val) => {
        return val ? <CheckCircleFilled style={{ fontSize: '16px', color: "#52c41a", alignContent: 'start' }} /> :
            < WarningFilled style={{ fontSize: '16px', color: "orange" }} />
    }

    const getEmptyItem = (type) => {
        if (type == "accounting") {
            return [{ displayName: "Kalkulation - Tätigkeiten", status: planValidation?.accountingValidationStatus, message: "At least one item required" }]
        } else if (type == "examination") {
            return [{ displayName: "Kalkulation - Untersuchungen", status: planValidation?.examinationValidationStatus, message: "At least one item required" }]
        } else if (type == "lines") {
            return [{ displayName: "mindestens 1 Position muss angegeben werden", status: planValidation?.lineValidationStatus, message: "At least one item required" }]
        }

    }

    const header =(st) => {
        return <b>st</b>
    }

    return (
        <div>
            {validationResults ?
            <div>
                <Collapse defaultActiveKey={['1']} onChange={onChange}>
                    {planValidation &&
                        <Panel header={validationResults.projectNumber + " Plan - " + planValidation?.planYear} key="plan"  extra={getIconWithSubmit(planValidation?.currentYearPlanValidationStatus)} >
                            <PlanValidation planValidationInfo={planValidation} getEmptyItem={getEmptyItem} getIcon={getIcon} loading={loading} type={validationResults.projectType}/>
                        </Panel>
                    }

                    {reportValidation &&
                        <Panel header={validationResults.projectNumber + " Bericht - " + reportValidation?.reportYear} key="2" extra={getIconWithSubmit(reportValidation?.lastYearReportValidationStatus)}>
                            <ReportValidation reportValidationInfo={reportValidation} getEmptyItem={getEmptyItem} getIcon={getIcon} loading={loading} type={validationResults.projectType} />
                        </Panel>}

                </Collapse>
                </div>
                : <Spin></Spin>
            }
        </div>
    )
}

export default ValidationAllItem