import React, { useState, useEffect } from 'react';
import {
    PageHeader,
    Card,
    Button,
    Table,
    Space,
    Row,
    Col,
    notification,
    Tooltip,
    Popconfirm
} from 'antd'
import {
    DownloadOutlined,
    DeleteOutlined
  } from '@ant-design/icons';
  
import { deleteForumFile, downloadFile, getResourceListByItemIdAndMediaType } from '../../util/APIUtils'
import FarmProfileSelect from '../../common/FarmProfileSelect';
import AddFile from './AddFile';

function FarmProfileDocuments(props) {
    const [loading, setLoading] = React.useState(false)

    const [fpId, setFpId] = useState(props.fpId)
    const currentUser = props.currentUser

    let useMyData = getResourceListByItemIdAndMediaType(fpId, 3);

    const { isLoading, error, data, refetch } = useMyData(
        (jsonData) => jsonData,
    );

    if (isLoading) {
        return <p>Wird geladen .....</p>;
    }

    if (error) {
        return <p>Error Wird geladen ..: {error.message}</p>;
    }
    console.log(data)

    const handleDownload = (id, fileName) => {
        downloadFile(id, fileName);
    };

    const onDelete = (id) => {
        console.log(id)
        let promise;
        promise = deleteForumFile(id);

        if (!promise) {
            return;
        }
        setLoading(true)
        promise.then(response => {
            setLoading(false);
            console.log(response)
            refetch();
            notification.success({ message: "Kultursaat", description: "Erfolgreich gelöscht", placement: 'topLeft' })

        }).catch(error => {
            console.log(error)
            setLoading(false)
            notification.error({ message: "Kultursaat", description: error, placement: 'topLeft' })
        });
    }

    const columns = [
        {
            width: '100',
            title: 'Jahr',
            dataIndex: 'year',
            key: 'year',
        },
        {
            width: '300',
            title: 'Züchter',
            dataIndex: 'ownerName',
            key: 'ownerName',
        },
        {
            title: 'Bezeichnung',
            dataIndex: 'designation',
            key: 'designation',
        },
        {
            title: 'Beschreibung',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Schlágwort',
            dataIndex: 'keywords',
            key: 'keywords',
        },
        {
            title: ' ',
            key: 'download',
            render: (_, item) => (
                item.filename && (
                    <Space><Tooltip title={item.filename}>
                        <Button type="primary" onClick={() => downloadFile(item.id, item.filename, 3)} icon={<DownloadOutlined />}>
                        </Button></Tooltip>

                    </Space>
                )
            ),
        },
        {
            title: ' ',
            key: 'delete',
            render: (_, item) => (
                item.editable && (
                    <Space>
                        <Popconfirm title="Löschen?" onConfirm={() => onDelete(item.id)}>
                            <Button type="danger" icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Space>
                )
            ),
        }
    ];

    return (
        <div>
            {/* <PageHeader
                className="site-page-header"
                title={"Standortprofil - Unterlagen"}
                subTitle="" /> */}
                <br></br>
            <Row gutter={16}>
                <Col span={16}>
                    <Space>Sonstige dokumente für Standortprofil - {props.fpName}
                    {/* <FarmProfileSelect setItem={setFpId} selectedItem={fpId} width={300} /> */}
                    </Space>
                </Col>
                <Col xs={{ span: 4, offset: 1 }} lg={{ span: 6, offset: 2 }} align='end'>
                    <AddFile currentUser={currentUser} refetch={refetch} fpId={props.fpId} />
                </Col>
            </Row>
            <br></br>
            <Table
                size='small'
                bordered
                dataSource={data}
                loading={loading || isLoading}
                columns={columns}
                rowKey="id"
                pagination={false}
            />
        </div>
    )
}

export default FarmProfileDocuments