import shortid from 'shortid';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jwtDecode from 'jsonwebtoken/decode';


export function formatDate(dateString) {
  const date = new Date(dateString);

  const monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];

  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return monthNames[monthIndex] + ' ' + year;
}

export function formatDateTime(dateTimeString) {
  const date = new Date(dateTimeString);

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr",
    "May", "Jun", "Jul", "Aug",
    "Sep", "Oct", "Nov", "Dec"
  ];

  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return date.getDate() + ' ' + monthNames[monthIndex] + ' ' + year + ' - ' + date.getHours() + ':' + date.getMinutes();
}

// export function formatCurrency(num) {
//   return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
// }

export function formatCurrency(num) {
  var formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  });

  return formatter.format(num); /* $2,500.00 */
}

export function formatCurrencyNoSign(num) {
  var formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  });

  return formatter.format(num); /* $2,500.00 */
}

export function capitalize(string) {
  if (!string) {
    return null;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}


export function getColorToneForProjectStatus(statusText) {
  let status;
  switch (statusText) {
    case "weiterhin aktuell":
      status = { "status_text": "uptodate", "color": "#d0f4de", "border": "2px" };
      break;
    case "abgebrochen":
      status = { "status_text": "cancelled", "color": "#f9dcc4" ,"border": "2px" };
      break;
    case "erfolgreich abgeschlossen":
      status = { "status_text": "completed", "color": "#dfe7fd", "border": "2px" };
      break;
    default:
      status = "";

  }
  return status
}

export function getColorToneForStatus(statusText) {
  let status;
  switch (statusText) {
    case "weiterhin aktuell":
      status = "uptodate";
      break;
    case "abgebrochen":
      status = "cancelled";
      break;
    case "erfolgreich abgeschlossen":
      status = "#d0f4de";
      break;
    default:
      status = "";

  }
  return status
}

export function getColorToneForSummary(statusText) {
  let status;
  switch (statusText) {
    case "ok":
      status = "green";
      break;
    case "abgelehnt":
      status = "orange";
      break;
    case "zurückgestellt":
      status = "yellow";
      break;
    default:
      status = "grey";

  }
  return status
}

export function filterPlainArray(array, filters) {
  const getValue = value => (typeof value === 'string' ? value.toUpperCase() : value);
  const filterKeys = Object.keys(filters);
  return array.filter(item => {
    // validates all filter criteria
    return filterKeys.every(key => {
      // ignores an empty filter
      if (!filters[key].length) return true;
      return filters[key].find(filter => getValue(filter) === getValue(item[key]));
    });
  });
}

export function getCurrentStatusFromTasks(tasks) {

  console.log(tasks)
  if(tasks){
    if (tasks.length > 0) {
      var result = tasks.find(obj => {
        return obj.endTime === null
      })
  
      if(result != null) {
        return { "name": result.name, "status": "Pending" };
      } else {
        return { "name": "TASK", "status": "Completed" };
      }
    }else {
      return {"name": null};
    }
  }else {
    return {"name": null};
  }
  
}

export function getCurrentTask(tasks) {
  if(tasks) {
    if (tasks.length > 0) {
      return tasks[0];
    } else {
      return null;
    }
  }else {
    return null;
  }
  
}

export function getShortNameForFull(fullName) {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

  let initials = [...fullName.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase();

  return initials;
}

// Project Calculation helpers

export function getProjectActuals(projectDetails) {
   return projectDetails.map(item => item.accountingActualTotal + item.examinationActualTotal)
}

export function getBudgetVsActual(projectDetails) {

  const projectAcountingActuals = projectDetails.reduce((n, {accountingActualTotal}) => n + accountingActualTotal, 0);
  const projectExaminationActuals = projectDetails.reduce((n, {examinationActualTotal}) => n + examinationActualTotal, 0);
  let totalActuals = projectAcountingActuals + projectExaminationActuals;

  const projectAcountingBudgets = projectDetails.reduce((n, {accountingBudgetTotal}) => n + accountingBudgetTotal, 0);
  const projectExaminationBudgets = projectDetails.reduce((n, {examinationBudgetTotal}) => n + examinationBudgetTotal, 0);
  let totalBudgets = projectAcountingBudgets + projectExaminationBudgets;

  return {
    totalBudgets: totalBudgets,
    totalActuals: totalActuals
  }



}

export function dynamicSort(property) {
  var sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a,b) {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
  }
}

export function isOfficeUser(currentUser) {
  if (currentUser) {
    return currentUser.roles.find(x => x.name === 'ROLE_OFFICE');
  }
  return false;
}

export function getProjectStatusBar(projectDetail) {
  let status = {}
  if (projectDetail.status === "ok") {
    status.fgStatus = true;
  }
  if (projectDetail.status === "ok") {
    status.boardStatus = true;
  }

}

export const currencyFormatter = (val) => {
  if (!val) return 0;
  return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace(/\.(?=\d{0,2}$)/g, ",");
}

export const currencyParser = (val) => {
  
  if (!val) {
    return 0;
  } 
  val = val.toString()
  return Number.parseFloat(val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")).toFixed(2)
}

export const getTextArtva = (val) => {
  switch (val) {
      case 1:
          return "interner Versuchsanbau nur auf eigenem Betrieb"
          break;
      case 2:
          return "übergeordnetes Versuchsanbau Projekt mit Tochterprojekten auf extern Standorten";
          break;
      case 3:
          return "Tochterprojekt zu folgendem übergeordneten projekt"
          break;

      default:
          return "interner Versuchsanbau nur auf eigenem Betrieb"
          break;
  }
}

export const  addUniqueKey = (array) => {
  return array.map((item) => ({
    ...item,
    key: shortid.generate(),
  }));
}

export const  addLeaderKey = (array, breederId) => {
  return array.map((item) => ({
    ...item,
    fgLeader: item.fachGroupLeaderId == breederId
  }));
}

export const exportToExcel = (tableData) => {
  const worksheet = XLSX.utils.json_to_sheet(tableData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const fileData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  saveAs(fileData, 'table-data.xlsx');
}

export const decodeToken = (token) => {
  try {
      return jwtDecode(token);
  } catch (error) {
      console.error('Failed to decode JWT:', error);
      return null;
  }
}



