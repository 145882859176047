import React, { Component } from 'react';
import { Table, Layout, AutoComplete, Button, Col, PageHeader, Card, Drawer } from 'antd';
const { Header, Content, Footer, Sider } = Layout;
import { Link } from 'react-router-dom';
import { getFarmLocations } from '../../util/APIUtils';
import { withRouter } from 'react-router-dom';
import { withTranslation  } from "react-i18next";
import {POLL_LIST_SIZE} from '../../constants'
import { SmileTwoTone, CloseCircleTwoTone , CheckCircleTwoTone } from '@ant-design/icons';

import '../FarmProfile.css';
const sleep = (ms) => new Promise((r) => setTimeout(r, ms));


class FarmLocations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            farmLocations: [],
            page: 0,
            size: 10,
            totalElements: 0,
            totalPages: 0,
            last: true,
            isLoading: false,
            visible: false
        };
    }

    loadFarmLocations(page = 0, size = POLL_LIST_SIZE) {
        let promise;
        promise = getFarmLocations(page, size);

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });

        promise.then(response => {
            const breeders = [];
            this.setState({
                farmLocations: response,
                // page: response.page, size: response.size, totalElements:
                // response.totalElements, totalPages: response.totalPages, last: response.last,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    componentDidMount() {
        this.loadFarmLocations();
    }

    componentDidUpdate(nextProps) {
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                farmLocations: [],
                // page: 0, size: 10, totalElements: 0, totalPages: 0, last: true,
                isLoading: false
            });
            this.loadFarmLocations();
        }
    }
    render() {
        const { t } = this.props;
        const columns = [
            {
                title: t("general.farm_plot"),
                dataIndex: 'locationName',
                key: 'locationName',
                // render: text => <Link to="/breeders/profile">{text}</Link>,
                render: (text, record) => {
                    let linkText = `/farmlocation/detail/${record.locationId}`
                    return (
                        <span><b>
                            <Link
                                to={{
                                    pathname: linkText,
                                    state: {
                                        id: record.id
                                    }
                                }}>{text}</Link>
                        </b>
                        </span>
                    )
                },
                width: 350
            },
            {
                title: t("farmprofile.farm_profile"),
                dataIndex: 'farmProfileName',
                key: 'farmProfileName',
                render: (text, record) => {
                    return (
                        <span>
                            {text}
                        </span>
                    )
                },
                width: 450
            }, {
                title: 'Lat/Lng',
                dataIndex: 'lat',
                key: 'lat',
                width: 250,
                render: (text, record) => {
                    let loc = `${record.lat}, ${record.lon} `
                    return (
                        <span>
                            {loc}
                        </span>
                    )
                },
                sortDirections: ['descend', 'ascend']
            }, {
                title: t("farmprofile.primary"),
                dataIndex: 'primaryLocation',
                key: 'primaryLocation',
                ellipsis: true,
                render: (text, record) => {
                    let loc = `${record.lat}, ${record.lon} `
                    return (
                        <span>
                            {text === 1?<CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="red" />}
                        </span>
                    )
                },
            }
        ];
        let bList = this
            .state
            .farmLocations
            .map(a => a.name);
        let placeHolder = `${bList.length} items available`
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    title={t("general.farm_plots")}
                    subTitle=""
                    extra={[
                        <Link to="/farmlocation/add"><Button key="36" type="primary" >Add</Button></Link>
                    ,
                    ]
                    }

                />
                <Card bordered={true} loading={this.state.isLoading}>
                    {/* <Search dataList={bList} dataCount={bList.length}/> */}
                    <Table
                        columns={columns}
                        dataSource={this.state.farmLocations}
                        loading={this.state.isLoading} />
                </Card>
            </div>

        );
    }
}
export default  withTranslation()(withRouter(FarmLocations));
