import React from 'react'
import {
    List,
    Tag,
    Table
} from 'antd';
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';

function ProjectFgTasks(props) {

    
    const projectTasks = props.projectTasks;
    const loading = props.loading;
    const { t } = props;

    const columns = [
        {
            title: t("projects.project_number"),
            dataIndex: 'projectNumber',
            key: 'projectNumber',
            width: 150,

            render: (text, record) => {
                let linkText = `/project/detail/${record.projectId}`
                return (
                    <span><b>
                        <Link
                            to={{
                                pathname: linkText,
                                state: {
                                    id: record.projectId
                                }
                            }}>{text} </Link>
                    </b>
                    </span>
                )
            },
            sortDirections: ['descend', 'ascend']
        },
        {
            title: "Typ",
            dataIndex: 'projectType',
            key: 'projectType',
            width: 70 //<CheckCircleTwoTone twoToneColor="#52c41a" />
        },
        {
            title: t("general.description"),
            dataIndex: 'projectDescription',
            key: 'projectDescription',
            width: 150,
        },
        {
            title: t("farmprofile.breeder_name"),
            dataIndex: 'breederName',
            key: 'breederName',
            width: 120,
        },
        {
            title: t("general.crop"),
            dataIndex: 'cropName',
            key: 'cropName',
            width: 120,
        }, {
            title: "Status",
            dataIndex: 'status',
            key: 'status',
            width: 80,
            render: (text, record) => {
                return <Tag color={"green"}>{text}</Tag>
            }
        },
        , {
            title: "Kommentar",
            dataIndex: 'status',
            key: 'status',
            width: 400,
            render: (text, record) => {
                let comments = record.comments.map(a => a.fullMessage).join(<br></br>);
                return (
                    <span>
                        {record.comments.length > 0 ?
                        <List
                            size="small"
                            // bordered
                            dataSource={record.comments}
                            renderItem={item => <List.Item style={{ padding: 2 }}><b>{item.userId} </b><br></br> {item.fullMessage}</List.Item>}
                        /> : "-"}
                    </span>
                )
            },
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={projectTasks}
            loading={loading}
        />
    )
}

export default withTranslation()(withRouter(ProjectFgTasks));