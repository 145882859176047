import React, { useState } from 'react';
import { Input, Radio, Space, Form, Select, Divider, Button } from 'antd';


function AddLine(props) {
    const [value, setValue] = useState(1);
    const [disabledOption, setDisabledOption] = useState(2);
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
        setDisabledOption(e.target.value === 1 ? 2 : 1)

    };
    const [form] = Form.useForm();

    const children = [];

    for (let i = 0; i < 100; i++) {
        children.push(<Option key={i}>{i}</Option>);
    }

    const onFinish = (vals) => {
        console.log(vals)
        vals["selection"] = value
        props.handleOk(vals)

    }

    return (
        <div>
            <Form name="add-line-form" form={form} onFinish={onFinish} 
            initialValues={{
                name_1: "KSZ",
                name_2: props.projectInfo.cropShortName + "-" + props.projectInfo.breederShortName,
                name_3: "",
                name_4: 0
              }}>
            <Radio.Group onChange={onChange} value={value}>
                <Space direction="vertical">
                    <Radio value={1} >Kultursaatlinie</Radio>
                    <Space >
                        <Form.Item
                            name="name_1"
                        >
                            <Select defaultValue="KSZ" style={{ width: 80 }} disabled={disabledOption == 1}>
                                <Option value="KSZ">KSZ</Option>
                                <Option value="KS">KS</Option>
                                <Option value="KSV">KSV</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="name_2"
                        >
                            <Input disabled style={{ width: 90 }} defaultValue={props.projectInfo.cropShortName + "-" + props.projectInfo.breederShortName}  />
                        </Form.Item>
                        <Form.Item
                            name="name_3"
                            rules={[
                                {
                                    required: disabledOption == 2,
                                },
                            ]}
                        >
                            <Input style={{ width: 220 }}  disabled={disabledOption == 1}/>
                        </Form.Item>
                        <Form.Item
                            name="name_4"
                        >
                            <Select defaultValue={0} style={{ width: 80 }} disabled={disabledOption == 1}>
                                {children}
                            </Select>
                        </Form.Item>
                    </Space>
                    <Divider width={"100%"}/>

                    <Radio value={2} >Linie/Sorte anderer zuchterhauser/Initiativen und zugelassene kulturart sorten </Radio>
                    <Form.Item
                            name="name_5"
                            rules={[
                                {
                                    required: disabledOption == 1,
                                },
                            ]}
                        >
                            <Input style={{ width: 380 }}  disabled={disabledOption == 2}/>
                        </Form.Item>
                        <Form.Item
                            name="submit"

                        >
                            <Space><Button type="primary" htmlType="submit" loading={props.loading}>
                            Speichern
                            </Button> <Button type="secondary" onClick={props.onClose}>
                            Abbrechen
                                </Button></Space>
                        </Form.Item>
                    {/* <Radio value={4}>
          More...
          {value === 4 ? <Input style={{ width: 100, marginLeft: 10 }} /> : null}
        </Radio> */}
                </Space>
            </Radio.Group>
            </Form>
        </div>
    )
}

export default AddLine