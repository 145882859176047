import React from 'react'
import { useTranslation } from 'react-i18next';

import {
    Badge,
    Card,
    Descriptions,
    Divider,
    Table,
    Layout,
    Button,
    Input,
    List,
    Row,
    Col,
    Form,
    Skeleton,
    PageHeader,
    Space,
    notification
} from 'antd';
import { updateFinancialInfo } from '../../util/APIUtils';

function FinancialInfo(props) {
    const financialInfo = props.financialInfo;
    const breederId = props.breederId;
    const [loading, setLoading] = React.useState(false)
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [editing, setEditing] = React.useState(false)

    let initVals = {}
    if (financialInfo) {
        initVals = {
            bank: financialInfo ? financialInfo.bank : "",
            bic: financialInfo ? financialInfo.bic : "",
            blz: financialInfo ? financialInfo.blz : "",
            iban: financialInfo ? financialInfo.iban : "",
            ktonr: financialInfo ? financialInfo.ktonr : "",
            taxNumber: financialInfo ? financialInfo.taxNumber : "",
            ust: financialInfo ? financialInfo.ust : "",
            ustHint: financialInfo ? financialInfo.ustHint : "",
            ustId: financialInfo ? financialInfo.ustId : "",
        }
    }

    const onFinish = (obj) => {
        console.log(obj);
        let finObj = {
            breederId: breederId,
            bank: obj.bank,
            bic: obj.bic,
            blz: obj.blz,
            iban: obj.iban,
            ktonr: obj.ktonr,
            taxNumber: obj.taxNumber,
            ust: obj.ust,
            ustHint: obj.ustHint,
            ustId: obj.ustId,
        }


        let promise;
        promise = updateFinancialInfo(finObj);

        if (!promise) {
            return;
        }

        setLoading(true)

        promise.then(response => {
            setLoading(false);
            setEditing(false)
            notification.success({ message: "Kultursaat", description: "Saved", placement: 'topLeft' })
            props.loadBreederInfo()

        }).catch(error => {
            console.log(error)
            setLoading(false)
            notification.error({ message: "Kultursaat", description: "Failed", placement: 'topLeft' })
        });
    }

    return (
        <div>
           
                <Form name="fin-form" form={form} onFinish={onFinish} initialValues={initVals} >
                    <Descriptions
                        title={t("breeders.financial_info")}
                        bordered
                        size="small"
                        loading={loading}
                        extra={
                            !editing ?
                                <Button onClick={() => setEditing(true)}>Bearbeiten</Button> :
                                <Space>
                                    <Button type='primary' key="35" onClick={() => form.submit()}>Speichern</Button>
                                    <Button onClick={() => setEditing(false)}>Abbrechen</Button>
                                </Space>
                        }
                        style={{
                            marginBottom: 20
                        }}>
                        <Descriptions.Item label="Bank" >
                            {editing ?
                                <Form.Item
                                    name="bank"
                                >
                                    <Input />
                                </Form.Item> :

                                financialInfo ? financialInfo.bank : ""
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="bic">
                            {editing ?
                                <Form.Item
                                    name="bic"
                                >
                                    <Input />
                                </Form.Item> :

                                financialInfo ? financialInfo.bic : ""
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="blz">
                            {editing ?
                                <Form.Item
                                    name="blz"
                                >
                                    <Input />
                                </Form.Item> :

                                financialInfo ? financialInfo.blz : ""
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="iban">
                            {editing ?
                                <Form.Item
                                    name="iban"
                                >
                                    <Input />
                                </Form.Item> :

                                financialInfo ? financialInfo.iban : ""
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="ktonr">
                            {editing ?
                                <Form.Item
                                    name="ktonr"
                                >
                                    <Input />
                                </Form.Item> :

                                financialInfo ? financialInfo.ktonr : ""
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="Steuernummer">
                            {editing ?
                                <Form.Item
                                    name="taxNumber"
                                >
                                    <Input />
                                </Form.Item> :

                                financialInfo ? financialInfo.taxNumber : ""
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="ust">
                            {editing ?
                                <Form.Item
                                    name="ust"
                                >
                                    <Input />
                                </Form.Item> :

                                financialInfo ? financialInfo.ust : ""
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="ustHint">
                            {editing ?
                                <Form.Item
                                    name="ustHint"
                                >
                                    <Input />
                                </Form.Item> :

                                financialInfo ? financialInfo.ustHint : ""
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="ustId">
                            {editing ?
                                <Form.Item
                                    name="ustId"
                                >
                                    <Input />
                                </Form.Item> :

                                financialInfo ? financialInfo.ustId : ""
                            }
                        </Descriptions.Item>
                    </Descriptions>
                </Form>
        </div>
    )
}

export default FinancialInfo