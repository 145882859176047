import React, { Component } from 'react';
import { Menu } from 'antd';
import { createFromIconfontCN } from '@ant-design/icons';
import { withTranslation } from "react-i18next";
import { Link, Route, withRouter, Switch } from 'react-router-dom';
import {
    SolutionOutlined,
    TableOutlined,
    ApiOutlined,
    AuditOutlined,
    SafetyCertificateOutlined,
    ScheduleTwoTone,
    LogoutOutlined,
    ExperimentOutlined,
    FileProtectOutlined,
    CloudSyncOutlined,
    TeamOutlined,
    OneToOneOutlined,
    ContainerOutlined,
    ControlOutlined,
    ScheduleOutlined,
    GlobalOutlined,
    RadarChartOutlined,
    BorderOuterOutlined,
    EuroOutlined,
    PicCenterOutlined,
    UserOutlined,
    BankOutlined,
    DollarCircleOutlined,
    SwapOutlined,
    SettingOutlined,
    RightOutlined,
    CaretRightFilled
} from '@ant-design/icons';
import { AccountCircle, AccountCircleOutlined, Description, DescriptionTwoTone } from '@mui/icons-material';
import { orange } from '@mui/material/colors';


export class AppMenu extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { SubMenu } = Menu;
        const { t } = this.props;
        const currentPhase = this.props.currentUser?.projectYear.currentPhase;
        const breederId = this.props.currentUser?.breederInfo.id;
        const currentActiveYear = this.props.currentUser?.projectYear.projectYear
        return (
            <Menu
                mode="vertical"
                defaultSelectedKeys={['pr12']}
                defaultOpenKeys={['sub1']}
                theme='light'
                style={{
                    height: '100%'
                }}>
                <SubMenu key="org" title={t("general.organizations")} icon={<GlobalOutlined />}>
                    <Menu.Item key="fp1" icon={<CaretRightFilled />}>
                        <Link to="/farmprofile/list">{t("farmprofile.farm_profiles")}</Link>
                    </Menu.Item>
                    {/* <Menu.Item key="fpl" icon={<CaretRightFilled />}>
                        <Link to="/farmprofile/locations">{t("general.farm_plots")}</Link>
                      </Menu.Item> */}
                    <Menu.Item key="fg1" icon={<CaretRightFilled />}>
                        <Link to="/fachgroups">{t("general.fachgroups")}</Link>
                    </Menu.Item>
                    {/* <Menu.Item key="sc1" icon={<CaretRightFilled />}>
                        <Link to="/fachgroups">{t("general.seed_companies")}</Link>
                      </Menu.Item> */}
                </SubMenu>

                <Menu.Item key="pr12" icon={<BorderOuterOutlined />}>
                    <Link to="/projects">{t("general.projects")}</Link>
                </Menu.Item>

                <Menu.Item key="valid-1" icon={<SafetyCertificateOutlined />}>
                    <Link to="/validations">Prüfen</Link>
                </Menu.Item>


                <Menu.Item key="12" icon={<RadarChartOutlined />}>
                    <Link to="/crops">{t("general.crops")}</Link>
                </Menu.Item>


                {/* <SubMenu key="pay0" title={t("general.payout")} icon={<FileDoneOutlined />}>

                    <Menu.Item key="pay01" icon={<CaretRightFilled />} >
                        <Link to="/payouts">{t("general.payout_all")}</Link>
                    </Menu.Item>

                    <Menu.Item key="pay02" icon={<CaretRightFilled />} >
                        <Link to="/payout/list">{t("general.payout_by_breeder")}</Link>
                    </Menu.Item>
                </SubMenu> */}

                <SubMenu key="fin" title={t("general.finance")} icon={<BankOutlined />}>

                    <Menu.Item key="fin02" icon={<CaretRightFilled />} >
                        <Link to="/budget_overview">{t("general.overall_view")}</Link>
                    </Menu.Item>
                    <Menu.Item key="fin03" icon={<CaretRightFilled />} >
                        <Link to="/project_type_crop_summary">{t("general.overview_crop")}</Link>
                    </Menu.Item>
                    <Menu.Item key="fin04" icon={<CaretRightFilled />} >
                        <Link to="/breeder_crop_summary">{t("general.overview_breeder")}</Link>
                    </Menu.Item>

                    <Menu.Item key="fin05" icon={<CaretRightFilled />} >
                        <Link to="/budget-validation"> Auswertung nach Züchter</Link>
                    </Menu.Item>
                    <Menu.Item key="breeder-budget-calculation" icon={<SwapOutlined />}>
                        <Link to="/breeder-budget-calculation">Budget verifizieren</Link>
                    </Menu.Item>
                    <Menu.Item key="payout-breeder" icon={<SwapOutlined />}>
                        <Link to={`/payout-breeder/${breederId}/${currentActiveYear-1}`}>Gutschrift erstellen</Link>
                    </Menu.Item>
                </SubMenu>


                <Menu.Item key="123" icon={<PicCenterOutlined />}>
                    <Link to="/fg_budget">FG Kosten</Link>
                </Menu.Item>

                {[2, 4].includes(currentPhase) &&

                    <Menu.Item key="fg=review" icon={<AuditOutlined />} >
                        <Link to="/fg-review">FG Evaluation</Link>
                    </Menu.Item>
                }


                <Menu.Item key="bsag-report" icon={<OneToOneOutlined />}>
                    <Link to="/bsag-report">BSAG Report</Link>
                </Menu.Item>


                <SubMenu key="con" title="Auftrag" icon={<TableOutlined />}>
                    <Menu.Item key="con-1">
                        <Link to="/contract">Auftrag drucken</Link>
                    </Menu.Item>
                    <Menu.Item key="con-2">
                        <Link to="/contract-actual">Abrechnung drucken</Link>
                    </Menu.Item>
                </SubMenu>

                <SubMenu key="sub2" title="ZSL" icon={<ScheduleOutlined />}>
                    <Menu.Item key="fore-add1" >
                        <Link to="/add-crop-forecast">ZSL Kurzberichte</Link>
                    </Menu.Item>
                    {/* <Menu.Item key="rep-2">
                        <Link to="/zsl">ZSL Bericht</Link>

                    </Menu.Item>
                    <Menu.Item key="rep-3">
                        <Link to="/zsl-antrag">ZSL Antrag</Link>

                    </Menu.Item> */}

                </SubMenu>


                {this.props.currentUser.roles.find(x => x.name === 'ROLE_OFFICE') &&


                    <SubMenu key="office" title={"Administrator"} icon={<ControlOutlined />}>

                        {/* <SubMenu key="2ds" title={t("general.user_profiles")} icon={<UserOutlined />}>
                            <Menu.Item key="11" icon={<CaretRightFilled />} >
                                <Link to="/breeders/all">{t("general.breeders")}</Link>
                            </Menu.Item>
                            <Menu.Item key="fps1" icon={<CaretRightFilled />}>
                                <Link to="/funders">{t("general.funders")}</Link>
                            </Menu.Item>

                        </SubMenu> */}

                        <Menu.Item key="12-1" icon={<SettingOutlined />}>
                            <Link to="/project-settings">Projekt Einstellungen</Link>
                        </Menu.Item>
                        <Menu.Item key="12-3" icon={<EuroOutlined />}>
                            <Link to="/project-funding">Projektfinanzierung</Link>
                        </Menu.Item>

                        <Menu.Item key="prj-review" icon={<EuroOutlined />}>
                            <Link to="/project-review">Projekt Review</Link>
                        </Menu.Item>


                        <Menu.Item key="fg-review" icon={<EuroOutlined />}>
                            <Link to="/fg-review-list">FG Review</Link>
                        </Menu.Item>

                        <Menu.Item key="user-management" icon={<TeamOutlined />}>
                            <Link to="/user-management">Benutzerverwaltung</Link>
                        </Menu.Item>

                        <Menu.Item key="12-4" icon={<ApiOutlined />}>
                            <Link to="/fg_budget_years/">FG Budget</Link>
                        </Menu.Item>

                        <Menu.Item key="proj-calculation" icon={<SwapOutlined />}>
                            <Link to="/proj-calculation">Projekt verifizieren</Link>
                        </Menu.Item>

                        <Menu.Item key="va-extern" icon={<ContainerOutlined />}>
                            <Link to="/va-extern">VA-Extern</Link>
                        </Menu.Item>

                        <Menu.Item key="sorten" icon={<ContainerOutlined />}>
                            <Link to="/sorten">EZ-Sorten</Link>
                        </Menu.Item>

                        <Menu.Item key="fp-report" icon={<FileProtectOutlined />}>
                            <Link to="/fp-report">Standortprofil-auftrag</Link>
                        </Menu.Item>

                        <Menu.Item key="zsl-summary" icon={<FileProtectOutlined />}>
                            <Link to="/zsl-summary">Saatgutfonds</Link>
                        </Menu.Item>

                        <Menu.Item key="12-1-2" icon={<CloudSyncOutlined />}>
                            <Link to="/crop-forecast">Kulturart Ausblick</Link>
                        </Menu.Item>

                        <Menu.Item key="office-5" icon={<CaretRightFilled />}>
                            <Link to="/zsl">ZSL Bericht</Link>

                        </Menu.Item>

                        <Menu.Item key="51" icon={<CaretRightFilled />}>
                            <Link to="/zsl-antrag">ZSL Antrag</Link>

                        </Menu.Item>

                        <Menu.Item key="pay01" icon={<CaretRightFilled />} >
                            <Link to="/payouts">{t("general.payout_all")}</Link>
                        </Menu.Item>

                        <Menu.Item key="pay02" icon={<CaretRightFilled />} >
                            <Link to="/payout/list">{t("general.payout_by_breeder")}</Link>
                        </Menu.Item>
                        {/* <Menu.Item key="off1" icon={<CaretRightFilled />}>
                            <Link to="/breeder/add">Züchter erstellen</Link>
                        </Menu.Item>
                        <Menu.Item key="off2" icon={<CaretRightFilled />}>
                            <Link to="/crop/add">Kultur erstellen</Link>
                        </Menu.Item>
                        <Menu.Item key="off3" icon={<CaretRightFilled />}>
                            <Link to="/farmprofile/new">Standortprofil erstellen</Link>
                        </Menu.Item>
                        <Menu.Item key="off4" icon={<CaretRightFilled />}>
                            <Link to="/fachgroup/add">Fach group erstellen</Link>
                        </Menu.Item> */}

                    </SubMenu>

                }
                <Menu.Item key="3" icon={<LogoutOutlined />}>
                    <Link to="/"
                        onClick={this.props.handleLogout}
                    >{t("general.sign_out")}
                    </Link>
                </Menu.Item>
            </Menu>
        )
    }
}

export default withTranslation()(withRouter(AppMenu));


