import { makeObservable, observable, computed, action } from "mobx";

const initFilters = {
    activeProjects: true,
    status: [],
    projectTypes: [],
    breederIds: [],
    cropIds: [],
    userProjectsOnly: true
}

export default class ProjectsStore {

    @observable filters;
    @observable pagination;
    @observable projectsAll;
    @observable projectsFiltered;
    @observable projectsAllLoaded;
    @observable projectFilteredLoaded;

    constructor() {
        this.filters = initFilters;
        this.pagination = { current: 1, pageSize: 100 };
        this.projectsAll = [];
        this.projectsAllLoaded = false
        this.projectsFilteredLoaded = false
        this.projectsFiltered = []
    }

    @action setProjectsAllLoaded = (status) => {
        this.projectsAllLoaded = status;
    }
    @action setProjectsFilteredLoaded = (status) => {
        this.projectsFilteredLoaded = status;
    }

    // Use @action to modify state value
    @action setFilters = (filters) => {
        console.log(filters)
        this.filters = filters;
    }

    // Use @action to modify state value
    @action clearFilters = () => {
        this.filters = initFilters;
        console.log(this.filters)
    }

    // Use @action to modify state value
    @action setProjectsFiltered = (projects) => {
        this.projectsFiltered = projects;
    }

    // Use @action to modify state value
    @action setProjectsAll = (projects) => {
        this.projectsAll = projects;
    }

    // Use @action to modify state value
    @action setPagination = (pagination) => {
        this.pagination = pagination;
    }

    // Use @action to modify state value
    @action clearProjectsAll = () => {
        this.projectsAll = [];
    }

    // Use @action to modify state value
    @action clearProjectsFiltered = () => {
        this.projectsFiltered = [];
    }

    @action clearAllStore = () => {
        this.projectsFiltered = [];
        this.projectsAll = [];
        this.pagination = { current: 1, pageSize: 100 };
        this.filters = initFilters;
        this.projectsAllLoaded = false
        this.projectsFilteredLoaded = false
    }
}