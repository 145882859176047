import React, { Component } from 'react';
import { Select } from 'antd';
import i18next from "i18next";

class LanguageSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }

    handleChange(value) {
        i18next.changeLanguage(value);
        console.log(`selected ${value}`);
    }

    render() {
        
        return (
            <Select defaultValue="de" style={{ width: 80 }} onChange={this.handleChange} >
                <Option value="de">DE</Option>
                <Option value="en">EN</Option>
            </Select>
        )
    }
}
export default LanguageSelect;