import { Descriptions, PageHeader, Select, Card, Table, Modal, Input, Button, Space, InputNumber, Tag } from 'antd'
import React, { Component } from 'react'
import { getCurrentYearProjects, getYears, getFunders, getAllBreeders, saveFunder, updatePayoutInfo } from '../util/APIUtils';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { formatCurrency } from '../util/Helpers';
import { Link } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

export class ProjectFunding extends Component {

    constructor(props) {
        super(props);
        this.state = {
            year: null,
            loading: false,
            projectYears: [],
            currentProjectDetail: null,
            currentFunderId: null,
            funders: [],
            payout: null,
            comments: null
        };
    }
    loadFunders() {
        let promise;
        promise = getFunders();

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response)
            this.setState({
                funders: response,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    saveFunderInfo = (projectDetailId, funderId) => {
        let promise;
        promise = saveFunder(projectDetailId, funderId);

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response)
            this.setState({
                // funders: response,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    updatePayoutInfo = (projectDetailId, payout, comments) => {
        let promise;
        promise = updatePayoutInfo(projectDetailId, payout, comments);

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response)
            this.setState({
                // funders: response,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    fetchProjects = (year) => {
        this.setState({ loading: true })
        let promise;
        promise = getCurrentYearProjects(year);
        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response)
            this.setState({ loading: false, projects: response })

        }).catch(error => {
            this.setState({ loading: false })
        });
    }

    fetchYears = () => {
        this.setState({ loading: true })
        let promise;
        promise = getYears();
        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response);
            this.setState({
                loading: false,
                projectYears: response.reverse()
            })

        }).catch(error => {
            this.setState({ loading: false })
        });
    }

    handleYearInput = (e) => {
        this.setState({ loading: true, year: e })
        setTimeout(() => {
            this.fetchProjects(e);
        }, 200);

    }

    handleFunderInput = (e) => {
        this.setState({ currentFunderId: e })
    }

    handlePayout = (e, name) => {
        if(name == "payoutAmount") {
            this.setState({ payout: e })
        } else if(name == "boardComments") {
            this.setState({ comments: e.target.value })
        }
        
    }

    componentDidMount() {
        this.fetchYears();
        this.loadFunders();

        // this.fetchPayoutInfo();
    }

    showPayoutUpdate = (projectDetail) => {
        this.setState({
            currentProjectDetail: projectDetail
        })
        confirm({
            title: 'Auszahlung - ' + projectDetail.projectNumber + " - " + projectDetail.year,
            icon: <ExclamationCircleOutlined />,
            content: <div>
                <Space direction='vertical'>
                <InputNumber style={{width: "300px"}} placeholder='Auszahlung' name="payoutAmount" onChange={(val) => this.handlePayout(val, "payoutAmount")}/>
                <Input.TextArea rows={5} style={{width: "300px"}} placeholder='Kommentar' name='boardComments' onChange={(val) => this.handlePayout(val, "boardComments")}/>
                </Space>
            </div>,
            onOk: () => {
                console.log(this.state)
                return new Promise((resolve, reject) => {
                    setTimeout(this.updatePayoutInfo(this.state.currentProjectDetail.projectDetailId, this.state.payout, this.state.comments) ? resolve : reject, 200);
                    this.fetchProjects(this.state.year)
                    //   this.saveFunderInfo(this.state.currentProjectDetail.projectDetailId, this.state.currentFunderId) ;
                    //   this.fetchProjects(this.state.currentProjectDetail.year) ? resolve : reject, 1000
                }).catch((error) => console.log(error));
            },
            onCancel() { },
        });
    }



    showPromiseConfirm = (projectDetail) => {
        this.setState({
            currentProjectDetail: projectDetail
        })
        confirm({
            title: 'Finanzierung aktualisieren - ' + projectDetail.projectNumber + " - " + projectDetail.year,
            icon: <ExclamationCircleOutlined />,
            content: <Select
                placeholder={"Funder"}
                style={{
                    width: '50%'
                }}
                onChange={this.handleFunderInput}
                defaultValue={projectDetail.funderId}>
                {this
                    .state
                    .funders
                    .map(item => (
                        <Select.Option key={item.id} value={item.id}>
                            {item.name}
                        </Select.Option>
                    ))}
            </Select>,
            onOk: () => {
                console.log(this.state)
                return new Promise((resolve, reject) => {
                    setTimeout(this.saveFunderInfo(this.state.currentProjectDetail.projectDetailId, this.state.currentFunderId) ? resolve : reject, 200);
                    this.fetchProjects(this.state.year)
                    //   this.saveFunderInfo(this.state.currentProjectDetail.projectDetailId, this.state.currentFunderId) ;
                    //   this.fetchProjects(this.state.currentProjectDetail.year) ? resolve : reject, 1000
                }).catch((error) => console.log(error));
            },
            onCancel() { },
        });
    }


    render() {
        const { t } = this.props;

        const columns = [

            {
                title: t("projects.project_number"),
                dataIndex: 'projectNumber',
                key: 'projectNumber',
                render: (text, record) => {
                    let linkText = `/project/detail/${record.projectId}`
                    return (
                        <span><b>
                            <Link
                                to={{
                                    pathname: linkText,
                                    state: {
                                        id: record.projectId,
                                    }
                                }}>{text}</Link>
                        </b>
                        </span>
                    )
                },
                width: 150,
                sorter: (a, b) => {
                    if (a.projectNumber < b.projectNumber) {
                        return -1;
                    }
                    if (a.projectNumber > b.projectNumber) {
                        return 1;
                    }
                    return 0;
                },
                sortDirections: ['descend', 'ascend']
            },
            {
                title: t("general.description"),
                dataIndex: 'projectDescription',
                key: 'projectDescription',
                width: 700,
                sortDirections: ['descend', 'ascend']
            },
            {
                title: t("projects.project_type"),
                dataIndex: 'projectType',
                key: 'projectType',
                width: 250,
                // filters: this.getFilterData(projects, "projectType", "projectType"),
                sortDirections: ['descend', 'ascend']
            },
            {
                title: t("breeders.breeder_name"),
                dataIndex: 'breederName',
                key: 'breederName',
                width: 300,
                // filters: this.getFilterData(projects, "breederName", "breederName"),
            },
            {
                title: t("crops.crop_name"),
                dataIndex: 'cropName',
                key: 'cropName',
                width: 200,
                // filters: this.getFilterData(projects, "cropName", "cropName"),
            },
            {
                title: "Amount",
                dataIndex: 'amount',
                align: "right",
                key: 'id',
                width: 300,
                render: (text, record) => {
                    return (
                        <b>{formatCurrency(text)}</b>
                    )
                }
                // filters: this.getFilterData(projects, "cropName", "cropName"),
            },
            {
                title: "FG Status",
                dataIndex: 'fgStatus',
                key: 'id',
                align: "left",
                width: 300,
                render: status => {
                    let color = 'green';
                    if (status == 'zurückgestellt') {
                      color = 'yellow';
                    } else if (status == 'abgelehnt') {
                      color = 'red';
                    }
                    return <Tag color={color}>{status}</Tag>;
                  }
                
                // filters: this.getFilterData(projects, "cropName", "cropName"),
            },
            {
                title: "FG Amount",
                dataIndex: 'fgAmount',
                key: 'id',
                align: "right",
                width: 300,
                render: (text, record) => {
                    return (
                        <b>{formatCurrency(text)}</b>
                    )
                }
                // filters: this.getFilterData(projects, "cropName", "cropName"),
            },
            {
                title: "Payout Amount",
                dataIndex: 'payoutAmount',
                key: 'id',
                align: "right",
                width: 300,
                render: (text, record) => {
                    return (
                        <b>{formatCurrency(text)}</b>
                    )
                }
                // filters: this.getFilterData(projects, "cropName", "cropName"),
            },
            {
                title: "Actual Amount",
                dataIndex: 'projectActualTotal',
                key: 'id',
                align: "right",
                width: 300,
                render: (text, record) => {
                    return (
                        <b>{formatCurrency(text)}</b>
                    )
                }
                // filters: this.getFilterData(projects, "cropName", "cropName"),
            },
            {
                title: "Recovery",
                dataIndex: 'recoveryAmount',
                key: 'id',
                align: "right",
                width: 300,
                render: (text, record) => {
                    return (
                        <b>{formatCurrency(text)}</b>
                    )
                }
                // filters: this.getFilterData(projects, "cropName", "cropName"),
            },
            {
                title: "Own share",
                dataIndex: 'breederShare',
                key: 'id',
                align: "right",
                width: 300,
                render: (text, record) => {
                    return (
                        <b>{formatCurrency(text)}</b>
                    )
                }
                // filters: this.getFilterData(projects, "cropName", "cropName"),
            },

            {
                title: "Funder",
                dataIndex: 'funderName',
                key: 'id',
                width: 300,
                // filters: this.getFilterData(projects, "cropName", "cropName"),
            },
            { title: 'Action', key: 'operation', render: (text, record) => <Space><Button size='small' onClick={() => this.showPromiseConfirm(record)}>F</Button> 
            {/* <Button size='small' onClick={() => this.showPayoutUpdate(record)}>€</Button> */}
             </Space> },
        ];
        return (
            <div><PageHeader
                className="site-page-header"
                onBack={() => this.handleBack()}
                title={"Projektfinanzierung"}
                subTitle="" />
                <Card loading={this.state.loading} >
                    <Descriptions >
                        <Descriptions.Item >
                            <Select
                                placeholder={"Jahr"}
                                style={{
                                    width: '20%'
                                }}
                                onChange={this.handleYearInput}
                                defaultValue={this.state.year}>
                                {this
                                    .state
                                    .projectYears
                                    .map(item => (
                                        <Select.Option key={item.projectYear} value={item.projectYear}>
                                            {item.projectYear}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Descriptions.Item>
                        {/* <Descriptions.Item><Button onClick={fetchPayoutInfo}>{t("general.submit")}</Button></Descriptions.Item> */}
                    </Descriptions>
                    {this.state.projects && <Table
                        title={() => <b>{this.state.projects.length + " Projekte"}</b>}
                        size='small'
                        columns={columns}
                        style={{ fontSize: '10px' }}
                        dataSource={this.state.projects}
                        loading={this.state.loading}
                        pagination={false} />}
                </Card>


            </div>
        )
    }
}

export default withTranslation()(withRouter(ProjectFunding));
