import React, { Component } from 'react'
import {
    Card, notification, Menu, Breadcrumb,
    PageHeader, Form, Input,
    Cascader, Select, Row, Col, Checkbox,
    Button, DatePicker, Space, message
} from 'antd';
// import {PlaceSearch} from '../common/PlaceSearch';
import { Link, withRouter } from 'react-router-dom';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getAllProjectTypes, getAllCrops, getAllBreeders, createProject } from '../util/APIUtils';
import { withTranslation } from "react-i18next";
import { POLL_LIST_SIZE } from '../constants';

import './Projects.css';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;
import moment from 'moment';
import SortenSelect from '../common/SortenSelect';
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 8,
    },
};


class NewProject extends Component {
    constructor(props) {
        console.log(props)
        super(props);
        this.state = {
            projectTypes: [],
            projectType: null,
            sortenName: null,
            breeders: [],
            crops: [],
            isLoading: false,
            visible: false,
            currentUser: props.currentUser
        };
    }

    handleBack = e => {
        this
            .props
            .history
            .goBack();
    };

    formRef = React.createRef();

    onFinish = (values) => {
        let description = values.description;

        if (this.state.projectType == "EZ") {
            description = this.state.sortenName
        }
        if (!description) {
            message.error("Für EZ-Projekte ist sorte erforderlich")
            return;
        }

        console.log(values.years[0].year())
        const projObj = {
            "projectType": values.type,
            "description": description,
            "startYear": values.years[0].year(),
            "status": "Active",
            "endYear": values.years[1].year(),
            "breederId": this.state.currentUser.breederInfo.id,
            "cropId": values.crop,
            "lastLineDescription": values.description,
            "approvalEnd": null,
            "archived": 0
        }

        console.log(projObj);

        createProject(projObj)
            .then(response => {
                console.log(response)
                notification.success({
                    message: 'Kultursaat',
                    description: "Project created!"
                });
                this.props.history.push(`/project/detail/${response}`);
            }).catch(error => {
                if (error.status === 401) {
                    this.props.handleLogout('/login', 'error', 'You have been logged out. Please login again.');
                } else {
                    notification.error({
                        message: 'Kultursaat',
                        description: error.message || 'Sorry! Something went wrong. Please try again!'
                    });
                }
            });

    };
    onReset = () => {
        this.formRef.current.resetFields();
    };

    handleChange = address => {

        this.setState({ address });
    };

    loadBreeders(page = 0, size = POLL_LIST_SIZE) {
        let promise;
        promise = getAllBreeders(page, size);

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });

        promise.then(response => {
            this.setState({
                breeders: response,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    loadCrops() {
        let promise;
        promise = getAllCrops();

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });
        promise.then(response => {
            this.setState({
                crops: response,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    loadProjectTypes() {

        let promise;
        promise = getAllProjectTypes();

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });

        promise.then(response => {
            const breeders = [];
            this.setState({
                projectTypes: response,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    componentDidMount() {

        this.loadProjectTypes();
        this.loadBreeders();
        this.loadCrops();
    }

    componentDidUpdate(nextProps) {
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                projects: [],
                isLoading: false
            });

            this.loadProjectTypes();
            this.loadBreeders();
            this.loadCrops();
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    onBack={() => this.handleBack()}
                    title={t("projects.new_project")}
                    subTitle=""
                    extra={[]
                    }
                />
                <Card bordered={true} loading={this.state.isLoading} >
                    <Form {...layout} ref={this.formRef} name="control-ref" onFinish={this.onFinish}>
                        <Form.Item name="type" label={t("projects.project_type")} rules={[{ required: false }]}>
                            <Select onChange={(type) => this.setState({ projectType: type })}>
                                {this.state.projectTypes.map(fbb =>
                                    <Option key={fbb.id} value={fbb.typeShortName}>{fbb.typeName}</Option>
                                )}

                            </Select>
                        </Form.Item>
                        {this.state.projectType != "EZ" ?
                            <Form.Item name="description" label={"Projekt Titel"} >
                                <TextArea rows={2} />
                            </Form.Item> :
                            <Form.Item name="sorten" label={"Sorten"} >
                                <SortenSelect setItem={(designation) => this.setState({ sortenName: designation })} />
                            </Form.Item>}
                        <Form.Item name="years" label={t("general.start_end_year")} rules={[{ required: false }]}>
                            <RangePicker picker="year" style={{ width: 390 }} disabledDate={(current) => {
                                let customDate = moment().format("YYYY-MM-DD");
                                return current && current < moment().endOf('year' - 1);
                            }} />
                        </Form.Item>
                        <Form.Item name="breeder" label={t("general.breeder")}  >
                            <Select defaultValue={this.state.currentUser.breederInfo.id} disabled>
                                {this.state.breeders.map(fbb =>
                                    <Option key={fbb.id} value={fbb.id}>{fbb.firstName} {fbb.lastName}</Option>
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item name="crop" label={t("general.crop")} rules={[{ required: true }]}>
                            <Select >
                                {this.state.crops.map(fbb =>
                                    <Option key={fbb.id} value={fbb.id}>{fbb.name}</Option>
                                )}
                            </Select>
                        </Form.Item>
                        {/* <Form.Item name="approval_end" label={t("projects.approval_end")} rules={[{ required: false }]}>
                            <DatePicker picker="year" />
                        </Form.Item> */}

                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                {t("general.submit")}
                            </Button>
                            <Button htmlType="button" onClick={this.onReset}>
                                {t("general.reset")}
                            </Button>

                        </Form.Item>
                    </Form>

                </Card>
            </div>
        )
    }
}

export default withTranslation()(withRouter(NewProject));

