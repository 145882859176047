import React from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
import ProjectValidation from './ProjectValidation';
import FgBudgetValidation from './FgBudgetValidation';

function ValidationContainer(props) {

    const confirm = () => {
        console.log(">>>>>>>")
        Modal.confirm({
            width: '60%',
            title: 'Prüfen',
            content: props.type == "project" ? <ProjectValidation projectId={props.projectId} reloadProject={props.reloadProject} /> : <FgBudgetValidation breederId={props.breederId} />,
            cancelText: "Abbrechen",
            okText: null,
            okButtonProps: { style: { display: 'none' } }
        });
    };

    return (
        <Space>
            <Button type='primary' size='small' style={{ backgroundColor: 'green' }} onClick={confirm}>Prüfen</Button>
        </Space>
    )
}

export default ValidationContainer