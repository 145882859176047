import React, { Component } from 'react'
import {
    Badge,
    Card,
    Descriptions,
    Divider,
    Table,
    Layout,
    Input,
    Row,
    Col,
    Typography,
    PageHeader,
    Select,
    List,
    Avatar,
    Tag,
    Space,
    Button,
    
} from 'antd';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { getAllBreeders, getAllCrops, getCropsForBreeder, getForecastForBreederCrop, updateBreederCropForecast } from '../util/APIUtils';

import { dynamicSort } from '../util/Helpers';

const { Title } = Typography;

export class AddCropForecast extends Component {

    constructor(props) {
        super(props);
        this.state = {
            year: null,
            breederId: props.currentUser.breederInfo.id,
            previousProjectYear: props.currentUser.previousProjectYear.projectYear,
            cropId: null,
            breeders: [],
            crops: [],
            forecasts: [],
            isLoading: false,
            background: null,
            activity: null,
            outlook: null,
            lastYearBackground: null,
            currentYearForecast: null
            // currentUser
        };
    }

    fetchBreeders() {
        this.setState({ isLoading: true });
        let promise;
        promise = getAllBreeders();
        if (!promise) {
            return;
        }

        promise.then(response => {
            this.setState({
                breeders: response.sort(dynamicSort("firstName")),
                isLoading: false
            })

        }).catch(error => {
            this.setState({ isLoading: false });
        });
    }

    fetchCrops() {
        this.setState({ isLoading: true });
        let promise;
        promise = getCropsForBreeder(this.state.breederId);
        // promise = getAllCrops();
        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response)
            this.setState({
                crops: response.sort(dynamicSort("shortName")),
                isLoading: false
            })

        }).catch(error => {
            this.setState({ isLoading: false });
        });
    }


    updateBreederCrop = () => {

        console.log("Update breeder crop");
        this.setState({ isLoading: true });

        let breederCropForecastInfo = {
            "breederId": this.state.breederId,
            "cropId": this.state.cropId,
            "year": this.state.previousProjectYear,
            "outlook": this.state.outlook,
            "background": this.state.background,
            "activity": this.state.activity
        }

        let promise;
        promise = updateBreederCropForecast(breederCropForecastInfo);
        if (!promise) {
            return;
        }

        promise.then(response => {
            this.setState({
                forecasts: response.sort(dynamicSort("year")).reverse(),
                isLoading: false,
                showForm: false
            })

        }).catch(error => {
            this.setState({ isLoading: false });
        });
    }


    fetchForecasts(cropId) {
        console.log("Fetching forecasts")
        this.setState({ 
            isLoading: true,
            cropId: cropId
         });
        let promise;
        promise = getForecastForBreederCrop(this.state.breederId, cropId);
        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response)
            console.log(this.state.currentYear)
            let currentYearForecast = response.reverse()[0]
            console.log(currentYearForecast)
            this.setState({
                currentYearForecast: currentYearForecast,
                lastYearBackground: currentYearForecast.background,
                forecasts: response.sort(dynamicSort("year")).reverse(),
                isLoading: false,
                outlook: currentYearForecast != null ? currentYearForecast.outlook: null,
                activity: currentYearForecast != null ? currentYearForecast.activity: null,
                background: currentYearForecast != null ? currentYearForecast.background: null
            })

        }).catch(error => {
            console.log(error)
            this.setState({ isLoading: false });
        });
    }

    handleBack = e => {
        this
            .props
            .history
            .goBack();
    };

    handleBreederSelection = (val) => {

        this.setState({
            crops: [],
            forecasts: [],
            breederId: val
        });

        this.fetchCrops(val);

    }

    handlCropSelection = (val) => {
        this.setState({
            cropId: val,
            forecasts: [],
            showForm: false,
            currentYearForecast: {}
        })

        console.log(this.state.cropId)
        console.log(this.state.breederId)
        if (this.state.breederId && val) {
            this.fetchForecasts(val);
        }
    }


    componentDidMount() {
        // this.fetchBreeders();
        this.fetchCrops();
    }

    componentDidUpdate(nextProps) {
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                breeders: [],
                // page: 0, size: 10, totalElements: 0, totalPages: 0, last: true,
                isLoading: false
            });
            this.fetchBreeders();
            // this.fetchCrops();
        }
    }

    showOutLookForm = () => {
        this.setState({
            showForm: true,
        })
    }

    hideForm = () => {
        this.setState({
            showForm: false,
        })
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    render() {
        const { t } = this.props;
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    onBack={() => this.handleBack()}
                    title={"ZSL Kurzberichte"}
                    extra={<Space>{this.state.cropId && !this.state.showForm?
                        <Button type='primary' onClick={this.showOutLookForm}>Jahr hinzufügen</Button>: ""}
                        {this.state.showForm? 
                        <Space><Button type='primary' onClick={this.updateBreederCrop}>Speichern</Button><Button type='' onClick={this.hideForm}>Abbrechen</Button></Space>: ""}
                        </Space>}
                        
                // extra={<Button type='primary'>Add</Button>}
                // subTitle={this.state.farmProfile.name}
                />
                <Card bordered={true} headStyle={{ padding: "0" }} >

                    <Space>
                        {/* <Select style={{ width: '300px' }} onChange={this.handleBreederSelection} >
                            {this.state.breeders.map(item => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.shortName + " : " + item.firstName + " " + item.lastName}
                                </Select.Option>
                            ))} 
                        </Select> */}
                        {this.state.crops.length > 0 &&
                            <Select style={{ width: '300px' }} onChange={this.handlCropSelection} placeholder={"Select Crop"} >
                                {this.state.crops.map(item => (
                                    <Select.Option key={0} value={0}>
                                        Select Crop
                                    </Select.Option>,
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.shortName + " : " + item.name}
                                    </Select.Option>
                                ))}
                            </Select>}

                    </Space><Divider></Divider>

                    {this.state.showForm &&
                        <Descriptions
                        size="small"
                        title={"Bericht " + this.state.previousProjectYear}
                            bordered="true">
                                <Descriptions.Item label='Hintergrund' span={3} labelStyle={{ fontWeight: 'bold' }}>
                            <Input.TextArea name="background" defaultValue={this.state.background ? this.state.background : this.state.lastYearBackground} onChange={this.handleChange} />
                            </Descriptions.Item>
                            <Descriptions.Item label='Bericht'  span={3} labelStyle={{ fontWeight: 'bold' }}>
                            <Input.TextArea name="activity" defaultValue={this.state.currentYearForecast?.activity}  onChange={this.handleChange} />
                            </Descriptions.Item>
                            <Descriptions.Item label='Plan' span={3} style={{width: '200px'}} labelStyle={{ fontWeight: 'bold' }}>
                            <Input.TextArea name="outlook" defaultValue={this.state.currentYearForecast?.outlook}  onChange={this.handleChange} />
                            </Descriptions.Item>
                            
                            
                        </Descriptions>

                    }

                    <List
                        itemLayout="horizontal"
                        dataSource={this.state.forecasts}
                        loading={this.state.isLoading}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Tag color="#2db7f5">{item.year}</Tag>}
                                    title={<Card title={<Title level={5}>Hintergrund </Title>} size='small'>
                                    {item.background}
                                </Card>}
                                    description={<span>
                                        <Card title={<Title level={5}>Bericht </Title>} size='small'>
                                            {item.activity}
                                        </Card>
                                        <Card title={<Title level={5}>Plan </Title>} size='small' >
                                        {item.outlook}
                                    </Card>
                           
                                        </span>
                                    }
                                />

                            </List.Item>

                        )}
                    />
                </Card>
            </div>
        )
    }
}

export default withTranslation()(withRouter(AddCropForecast));
