import React from 'react'
import { Collapse, List, Spin, Divider } from 'antd';
function FgBudgetPlanValidation(props) {
    console.log(props)
    const loading = props.loading;

    return (
        <div>
            <List
                size="small"
                loading={loading}
                dataSource={props.planValidationInfo}
                renderItem={(item) => (
                    <List.Item >
                        <List.Item.Meta
                            title={<b>{item.cropName} {props.getIcon(item.overallStatus)}</b>}
                            description={ <List
                                size="small"
                                loading={loading}
                                dataSource={[...item.planValidationFields, ...item.accountingValidations]}
                                renderItem={(item) => <List.Item actions={[props.getIcon(item.status)]}>{item.displayName}</List.Item>}
                            />}
                        />
                    </List.Item>
                )
                }
            />
        </div>
    )
}

export default FgBudgetPlanValidation