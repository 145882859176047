import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, Modal, Checkbox, message } from 'antd';
import moment from 'moment';
import { createAccessOverride, updateAccessOverride, getAccessOverrideById } from '../util/APIUtils';
import BreederSelectForm from '../common/BreederSelectForm';

const AccessOverrideForm = ({ id, visible, setVisible, onRefresh }) => {
    const [form] = Form.useForm();
    const [breederId, setBreederId] = useState(null);
    const [breederName, setBreederName] = useState('');

    useEffect(() => {
        if (id != null) {
            getAccessOverrideById(id)
                .then(data => {
                    console.log(data)
                    form.setFieldsValue({
                        ...data,
                        accessEndDate: data.accessEndDate ? moment(data.accessEndDate) : null,
                    });
                    setBreederId(data.breederId);
                    // Assuming 'data' includes breeder's name, update it accordingly
                    setBreederName(data.fullName || 'Unknown Breeder');
                })
                .catch(error => message.error('Error fetching record: ' + error.message));
        } else {
            form.resetFields();
            setBreederId(null);
            setBreederName('');
        }
    }, [id, form]);

    const handleSubmit = (values) => {
       
        const payload = {
            ...values,
            accessEndDate: values.accessEndDate ? values.accessEndDate.format('YYYY-MM-DD') : null,
        };

        const action = id ? updateAccessOverride(id, payload) : createAccessOverride(payload);

        action.then(() => {
            message.success(`AccessOverride ${id ? 'updated' : 'created'} successfully`);
            onRefresh();
            setVisible(false);
        }).catch(error => {
            message.error(`Error ${id ? 'updating' : 'creating'} record: ` + error.message);
        });
    };

    const atLeastOneCheckboxValidator = (_, value) => {
        if (value.planWrite || value.reportWrite || value.fgWrite || value.zslWrite) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('At least one checkbox must be selected'));
    };

    return (
        <Modal
            title={id ? 'Edit Access Override' : 'Add Access Override'}
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={null}
        >
          <Form 
                form={form} 
                onFinish={handleSubmit} 
                layout="horizontal" 
                labelCol={{ span: 8 }} 
                wrapperCol={{ span: 16 }}
                initialValues={{ planWrite: false, reportWrite: false, fgWrite: false, zslWrite: false }}
            >
                {id ? (
                    <Form.Item label="Breeder Name">
                        <Input value={breederName} disabled />
                    </Form.Item>
                ) : (
                    <Form.Item
                    name="breederId"
                    label="Breeder"
                    rules={[{ required: true, message: 'Please select a breeder' }]}
                >
                    <BreederSelectForm />
                </Form.Item>
                )}
                <Form.Item name="accessEndDate" label="Access End Date" rules={[{ required: true, message: 'Access End Date is required' }]}>
                    <DatePicker />
                </Form.Item>
                <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues !== currentValues} rules={[{ validator: atLeastOneCheckboxValidator }]}>
                    {() => (
                        <>
                            <Form.Item name="planWrite" label="Plan Write" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                            <Form.Item name="reportWrite" label="Report Write" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                            <Form.Item name="fgWrite" label="FG Write" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                            <Form.Item name="zslWrite" label="ZSL Write" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </>
                    )}
                </Form.Item>
                
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        {id ? 'Update' : 'Create'}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AccessOverrideForm;
