import React, { useState } from 'react';
import { EditableProTable } from '@ant-design/pro-table';
import { ProFormSelect, ProFormText, ProFormDigit } from '@ant-design/pro-form';
import { useTranslation, Trans } from 'react-i18next';
import { addExaminationItem, addExaminationItems, updateExaminationItemActual } from '../../util/APIUtils';
import { SaveOutlined, CloseCircleTwoTone, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { isPlanEditable } from '../../util/ProjectStatusHelper';

import { Button, Space, message } from 'antd';
import AddItemFormExamination from './AddItemFormExamination';
import EditItemExamForm from './EditItemExamForm';

const waitTime = (time = 100) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};

const convertArrayToObject = (array) => {
    let retArr = [];
    var object = array.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: { 'text': item.name } }), {});
    return object;

};


const EditableExaminationItemsActual = (props) => {
    console.log(props);
    const [editableKeys, setEditableRowKeys] = useState([]);
    const examinationItems = convertArrayToObject(props.examinationItems, 'id');
    const [dataSource, setDataSource] = useState([]);
    const [projectId, setProjectId] = useState(props.projectId);
    const [projectDetailId, setProjectDetailId] = useState(props.projectDetailId);
    const { t } = useTranslation();
    const defaultData = props.data;
    const year = props.year;
    const workFlowState = props.workFlowState;
    const currentUser = props.currentUser;
    const formatDigit = (num) => {
        try {
            return num.toFixed(2);
        }
        catch {
            return num;
        }
    }
    console.log(projectDetailId);

    const processExmObj = (accObj) => {
        console.log(accObj);
        let arObj = [];
        accObj.forEach(element => {
            let exId = 0;
            if (element.hasOwnProperty('examinationItem')) {
                console.log(parseInt(element.examinationItem));
                exId = isNaN(parseInt(element.examinationItem)) ? element.examinationItemId : parseInt(element.examinationItem);
                console.log(exId);
            }

            if (element.hasOwnProperty('projectId')) {
                console.log("case1");
                arObj.push({
                    'projectDetailId': projectDetailId,
                    'projectId': projectId,
                    'examinationItemId': exId,
                    'quantityBudget': element.quantityBudget ? parseFloat(element.quantityBudget) : 0.0,
                    'amountBudget': element.amountBudget ? parseFloat(element.amountBudget) : 0.0,
                    'quantityActual': element.quantityActual ? parseFloat(element.quantityActual) : 0.0,
                    'amountActual': element.amountActual ? parseFloat(element.amountActual) : 0.0,
                    'customItem': element.examinationItemId == 0 ? element.examinationItem : "",
                    'customType': element.examinationItemId == 0 ? 1 : 0,
                    'actual': element.amountActual || element.quantityActual ? 1 : 0,
                    'actualSelected': element.amountActual || element.quantityActual ? 1 : 0,
                    'budget': 1
                })
            } else {
                console.log("case2");
                arObj.push({
                    'projectDetailId': projectDetailId,
                    'projectId': projectId,
                    'examinationItemId': exId ? exId : 0,
                    'quantityBudget': element.quantityBudget ? parseFloat(element.quantityBudget) : 0.0,
                    'amountBudget': element.amountBudget ? parseFloat(element.amountBudget) : 0.0,
                    'quantityActual': element.quantityActual ? parseFloat(element.quantityActual) : 0.0,
                    'amountActual': element.amountActual ? parseFloat(element.amountActual) : 0.0,
                    'customItem': exId == 0 ? element.examinationItem : "",
                    'customType': exId == 0 ? 1 : 0,
                    'actual': element.amountActual || element.quantityActual ? 1 : 0,
                    'actualSelected': element.amountActual || element.quantityActual ? 1 : 0,
                    'budget': 1
                })
            }
        });
        let promise;
        console.log(arObj);
        promise = addExaminationItems(projectDetailId, arObj);
        if (!promise) {
            return;
        }
        let screenState = {
            "year": year,
            "tab": '2'
        }
        promise.then(response => {
            message.success("Examination items saved")
            props.reloadProject(screenState);
        }).catch(error => {
            message.error("Examination items not saved")
        });
    }

    const saveItem = (obj, edit) => {
        let promise;
        if(edit) {
            promise = updateExaminationItemActual(obj)
        } else {
            promise = addExaminationItem(obj);
        }
        
        if (!promise) {
            return;
        }
        let screenState = {
            "year": year.toString(),
            "tab": '2',
            "editing": true
        }
        promise.then(response => {
            message.success("Examination items saved")
            props.reloadProject(screenState);
        }).catch(error => {
            message.error("Examination items not saved")
        });
    }


    const columns = [
        {
            title: t("calculation.examination_item"),
            dataIndex: 'examinationItem',
            editable: ["DRAFT"].includes(workFlowState),
            valueType: 'select',
            valueEnum: examinationItems,
            width: '42%',
            fieldProps: { placeholder: '' },
            // renderFormItem: () => [<ProFormSelect  align={'middle'} placeholder={0.0}></ProFormSelect>],
            formItemProps: (form, { rowIndex }) => {
                return {
                    rules: rowIndex > -1 ? [{ required: true, message: 'Item Required' }] : [],
                };
            },
        },
        {
            title: t("calculation.quantity"),
            children: [
                {
                    title: t("calculation.budget"),
                    align: "right",
                    dataIndex: 'quantityBudget',
                    fieldProps: { placeholder: 0 },
                    editable:  ["DRAFT"].includes(workFlowState),
                    render: (text, row, index) => {
                        return (
                            <span><b>
                                {text != null || text == 0 ? formatDigit(text) : "0.00"}
                            </b>
                            </span>
                        )
                    },
                },
                {
                    title: t("calculation.actual"),
                    align: "right",
                    dataIndex: 'quantityActual',
                    editable: ["REPORT_DRAFT", "ACTUALS_SUBMISSION"].includes(workFlowState),
                    // renderFormItem: () => [<ProFormDigit placeholder={0.0}></ProFormDigit>],
                    fieldProps: { placeholder: 0 },
                    render: (text, row, index) => {
                        return (
                            <span><b>
                                {text != null || text == 0 ? formatDigit(text) : "0.00"}
                            </b>
                            </span>
                        )
                    },
                }]
        },
        {
            title: t("calculation.amount"),
            children: [
                {
                    title: t("calculation.budget"),
                    align: "right",
                    dataIndex: 'amountBudget',
                    fieldProps: { placeholder: 0 },
                    editable: ["DRAFT"].includes(workFlowState),
                    render: (text, row, index) => {
                        return (
                            <span><b>
                                {text != null || text == 0 ? formatDigit(text) : "0.00"}
                            </b>
                            </span>
                        )
                    },
                },
                {
                    title: t("calculation.actual"),
                    align: "right",
                    editable: ["REPORT_DRAFT", "ACTUALS_SUBMISSION"].includes(workFlowState),
                    dataIndex: 'amountActual',
                    // renderFormItem: () => [<ProFormDigit placeholder={0.0}></ProFormDigit>],
                    fieldProps: { placeholder: 0 },
                    render: (text, row, index) => {
                        return (
                            <span><b>
                                {text != null || text == 0 ? formatDigit(text) : "0.00"}
                            </b>
                            </span>
                        )
                    },
                }
            ]
        },
        {
            title: 'Action',
            valueType: 'option',
            align: "right",
            width: 90,
            fieldProps: {
            },
            render: (text, record, _, action) => [
                <EditItemExamForm
                        projectId={projectId}
                        projectDetailId={projectDetailId}
                        isPlanEditable={props.isPlanEditable}
                        isReportEditable={props.isReportEditable}
                        mode={workFlowState}
                        item={record}
                        items={props.examinationItems}
                        saveItem={saveItem} />,
                
                
                // <a key="editable" onClick={() => {
                //     var _a;
                //     (_a = action === null || action === void 0 ? void 0 : action.startEditable) === null || _a === void 0 ? void 0 : _a.call(action, record.key);

                // }}>
                //     <EditTwoTone />
                // </a>,
                props.isPlanEditable ?
                <a key="delete" onClick={() => {
                    setDataSource(dataSource.filter((item) => item.key !== record.key));
                    processExmObj(dataSource.filter((item) => item.key !== record.key));
                }}>
                    <DeleteTwoTone />
                </a>: "",

            ],
        },

    ];
    let locale = {
        emptyText: (
            <span>
                <p>
                    Keine Daten
                </p>
            </span>
        )
    };
    console.log(workFlowState);
    return (

        <div>
            <EditableProTable
                // toolBarRender={() => [<Button color="#ccc" icon={<SaveOutlined />} onClick={() => processExmObj(dataSource)}>Speichern</Button>]}
                rowKey="key"
                size={"small"}
                bordered
                maxLength={50}
                locale={locale}
                // recordCreatorProps={["DRAFT"].includes(workFlowState) ? {
                //     position: 'bottom',
                //     creatorButtonText: "Neuer Eintrag",
                //     message: "No",
                //     record: () => ({ key: (Math.random() * 1000000).toFixed(0) }),
                // } : false}
                recordCreatorProps={false}
                columns={columns}
                request={async () => ({
                    data: defaultData,
                    total: 50,
                    success: true,
                })}
                value={dataSource}
                onChange={setDataSource}
                columnEmptyText={<Button></Button>}
                editable={{
                    type: 'multiple',
                    cancelText: <CloseCircleTwoTone />,
                    saveText: <SaveOutlined />,
                    deleteText: "Löschen",
                    deletePopconfirmMessage: 'Diese Zeile löschen?',
                    onlyAddOneLineAlertMessage: "Fügen Sie jeweils eine Zeile hinzu",
                    editableKeys,
                    onSave: async (rowKey, data, row) => {
                        await waitTime(200);
                    },
                    onChange: setEditableRowKeys,
                    actionRender: (row, config, dom) => [dom.save, dom.cancel,],
                    // actionRender: (row, config, dom) => [dom.save, dom.cancel, <Button >New</Button>],
                }} />
                 <div class="ant-card-body" >
                     <AddItemFormExamination 
                     projectId={projectId} 
                     projectDetailId={projectDetailId} 
                     mode={workFlowState} 
                     items={props.examinationItems} 
                     dataSource={dataSource} 
                     saveItem={saveItem}
                     isPlanEditable={props.isPlanEditable} 
                     isReportEditabe={props.isReportEditable}
                     /></div>

        </div >
    );
};

export default EditableExaminationItemsActual;