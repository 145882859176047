import React, { useState } from 'react';
import { Layout, Menu, Table, PageHeader, Tooltip } from 'antd';
import FgReviewProjectList from './FgReviewProjectList';
import { getFgsForBreeder } from '../util/APIUtils';
import { addLeaderKey, addUniqueKey, uniqueArray } from '../util/Helpers';
import { StarFilled } from '@ant-design/icons'
import { StarTwoTone } from '@mui/icons-material';

const { Sider, Content } = Layout;

function FgReviewContainer(props) {
    const currentUser = props.currentUser;
    const useMyData = getFgsForBreeder(currentUser?.breederInfo.id);
    const [selectedFgId, setSelectedFgId] = useState('0');

    const { isLoading, error, data, refetch } = useMyData(
        (jsonData) => jsonData,
    );

    if (isLoading) {
        return <p>Wird geladen .....</p>;
    }

    if (error) {
        return <p>Error Wird geladen ..: {error.message}</p>;
    }


    let dataWithKey = addUniqueKey(data);
    dataWithKey = dataWithKey.filter((obj, index, self) => {
        return index === self.findIndex((t) => t.id === obj.id);
    });

    dataWithKey = addLeaderKey(dataWithKey, currentUser?.breederInfo.id)
    console.log(dataWithKey)


    return (
        <>
            <PageHeader title="FG Evaluation"
                className="site-page-header"
            />

            <Layout>
                <Sider>
                    {dataWithKey &&
                      <Menu
                      onClick={(e) => setSelectedFgId(e.key)}
                      selectedKeys={[selectedFgId]}
                      mode="inline"
                      style={{ borderBottom: '1px solid #ddd', backgroundColor: '#fafafa', height: '100%' }}
                  >
                      <Menu.Item key="0">Alle FGs</Menu.Item>
                      {dataWithKey.map(data => (
                          <Menu.Item key={data.id}>
                              {data.name}
                              {data.fgLeader ? (
                                  <Tooltip title="FG Leiter">
                                      <StarFilled style={{ color: 'orange', marginLeft: '5px' }} />
                                  </Tooltip>
                              ) : ""}
                          </Menu.Item>
                      ))}
                  </Menu>}
                </Sider>
                <Content>
                    <FgReviewProjectList currentUser={currentUser} fgId={selectedFgId ? selectedFgId : 0} />
                </Content>
            </Layout>
        </>

    );
}


export default FgReviewContainer
