import { List, Tag, Table } from 'antd';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation, Trans } from 'react-i18next';

const BindingView = (props) => {
  console.log(props.tasks)
  // let tasks = props.tasks.filter(o => o.endTime == null);
  let tasks = props.tasks;
  const { t } = useTranslation();
  console.log(tasks);
  tasks = tasks.filter(item => item != null)

  const columns = [
    {
      title: t("projects.project_number"),
      dataIndex: 'projectNumber',
      key: 'projectNumber',
      width: 150,
      render: (text, record) => {
        let linkText = `/project/detail/${record.projectId}`
        return (
          <span><b>
            <Link
              to={{
                pathname: linkText,
                state: {
                  id: record.projectId
                }
              }}>{text} </Link>
          </b>
          </span>
        )
      },
      sortDirections: ['descend', 'ascend']
    },
    {
      title: "Typ",
      dataIndex: 'projectType',
      key: 'projectType',
      width: 70 //<CheckCircleTwoTone twoToneColor="#52c41a" />
    },
    {
      title: t("general.description"),
      dataIndex: 'projectDescription',
      key: 'projectDescription',
      width: 150,
    },
    {
      title: t("farmprofile.breeder_name"),
      dataIndex: 'breederName',
      key: 'breederName',
      width: 120,
    },
    {
      title: t("general.crop"),
      dataIndex: 'cropName',
      key: 'cropName',
      width: 120,
    }, {
      title: "Status",
      dataIndex: 'status',
      key: 'status',
      width: 80,
      render: (text, record) => {
        return <Tag color={"green"}>{text}</Tag>
      }
    },
    , {
      title: "Commente",
      dataIndex: 'status',
      key: 'status',
      width: 400,
      render: (text, record) => {
        let comments = record.comments.map(a => a.fullMessage).join(<br></br>);
        return (
          <span>

            <List
              size="small"
              // bordered
              dataSource={record.comments}
              renderItem={item => <List.Item style={{ padding: 2 }}><b>{item.userId} </b><br></br> {item.fullMessage}</List.Item>}
            />
          </span>
        )
      },
    },
  ];

  return (
    <Fragment>
      <Table
        columns={columns}
        dataSource={tasks}
        loading={props.loading}
      />
      {/* <List
        itemLayout="horizontal"
        dataSource={tasks}
        renderItem={(item) => (
          <List.Item
            extra={<Tag color="green">{item.taskName}</Tag>} >
            <List.Item.Meta
              avatar={item.avatar}
              title={<Link
                to={{
                  pathname: `/project/detail/${item.taskData.projectId}`,
                  state: {
                    id: item.taskData.projectId,
                  }
                }}>{item.taskData.projectNo}<div /> </Link>
              }
              description={item.assignee + " - " + moment(item.time).format('MMM-DD-YYYY hh:mm:ss')}
            />
          </List.Item>
        )}
      /> */}
    </Fragment>
  );
};

export default BindingView;
