import React from 'react'
import {
    Skeleton,
    Card,
    Table,
    Select,
    Divider,
    PageHeader,
    Space,
    notification
} from 'antd';
import moment from 'moment';
import YearSelect from '../common/YearSelect';
import { useState } from 'react';
import { getInstallmentSummary, getInstallmentSummaryLink } from '../util/APIUtils';
import { formatCurrency } from '../util/Helpers';

import { FilePdfTwoTone } from '@ant-design/icons';

function InstallmentSummary(props) {
    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState(0)
    const [serial, setSerial] = useState(1)
    const dateFormat = 'DD.MM.YYYY';

    let useMyData = null;
    useMyData = getInstallmentSummary(year, serial);


    const { isLoading, error, data, refetch } = useMyData(
        (jsonData) => jsonData,
    );

    if (isLoading) {
        return <p>Wird geladen .....</p>;
    }

    if (error) {
        return <p>Error Wird geladen ..: {error.message}</p>;
    }


    const updateYear = (year) => {
        setYear(year);
        console.log(year)
        refetch()
    }

    console.log(data)

    const columns = [
        {
            title: "Züchter",
            dataIndex: 'fullName',
            key: 'fullName',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => {
                if (a.fullName < b.fullName) {
                    return -1;
                }
                if (a.fullName > b.fullName) {
                    return 1;
                }
                return 0;
            },
            width: 350,
        },
        {
            title: "Betrag",
            dataIndex: 'plannedPayout',
            key: 'plannedPayout',
            align: 'right',
            sortDirections: ['descend', 'ascend'],
            render: (text, record) => {

                return (
                    <span><b>
                        {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : formatCurrency(0)}
                    </b>
                    </span>
                )
            },
        },
        {
            title: "überwiesen am",
            dataIndex: 'paidDate',
            key: 'paidDate',
            align: 'center',
            width: 250,
            sortDirections: ['descend', 'ascend'],
            render: (text, record) => {

                return (
                    <span>
                        {text ? moment(text).format(dateFormat) : ''}

                    </span>
                )
            },
        },
        {
            title: "Bemerkung",
            dataIndex: 'remarks',
            key: 'remarks',
            sortDirections: ['descend', 'ascend'],

        },
    ]

    const getReport = (year, serial) => {
        console.log(year)
        console.log(serial)
        if (year && serial) {
            const win = window.open(getInstallmentSummaryLink(year, serial), '_blank');
            if (win != null) {
                win.focus();
            }
        }

    }

    return (
        <div>
            <PageHeader
                className="site-page-header"
                title={"Liste Überweisungen"}
                subTitle=""
                onBack={() => props
                    .history
                    .goBack()}
            />

            <Card loading={loading} >
                <Space>
                    <YearSelect
                        setItem={updateYear}
                        selectedItem={year} />
                    <Select
                        style={{
                            width: 120,
                        }}
                        defaultValue={serial}
                        onSelect={setSerial}
                        options={[
                            {
                                value: 1,
                                label: 'Rate 1',
                            },
                            {
                                value: 2,
                                label: 'Rate 2',
                            },
                            {
                                value: 3,
                                label: 'Rate 3',
                            },
                        ]}
                    /></Space>
                <Divider></Divider>
                {data.length > 0 &&
                    <>
                        <Space><h3>Liste Überweisungen {year} für Rate {serial} </h3> <FilePdfTwoTone style={{ fontSize: "25px" }} onClick={() => getReport(year, serial)} /></Space>
                        <Table

                            size='small'
                            rowKey={(record) => record.id}
                            columns={columns}
                            dataSource={data}
                            loading={isLoading}
                            pagination={false}
                            rowClassName={(record) => record.fullName == "Gesamt" ? 'editable-row' : ''}
                        />
                    </>}
            </Card>
        </div>
    )
}

export default InstallmentSummary
