import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { getYears } from '../util/APIUtils';

function YearSelect({ setItem, selectedItem }) {
    const [itemList, setItemList] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchYears = async () => {
        setLoading(true);
        try {
            const response = await getYears();
            const sortedYears = response.reverse();
            setItemList(sortedYears);

            if (!selectedItem && sortedYears.length > 0) {
                const defaultYearObj = sortedYears.find(year => year.currentYear === 1);
                const defaultYear = defaultYearObj ? defaultYearObj.projectYear : sortedYears[0].projectYear;
                setItem(defaultYear);
            }
        } catch (error) {
            console.error("Error fetching years:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchYears();
    }, []); // Empty dependency array ensures this runs only once

    const handleChange = value => {
        setItem(value);
    };

    return (
        <>
            <Select
                mode="single"
                loading={loading}
                placeholder="Jahr"
                value={selectedItem}
                onChange={handleChange}
                style={{ width: '150px' }}
            >
                {itemList.map(item => (
                    <Select.Option key={item.projectYear} value={item.projectYear}>
                        {item.projectYear}
                    </Select.Option>
                ))}
            </Select>
        </>
    );
}

export default YearSelect;
