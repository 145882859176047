import React, { Component } from 'react';
import { Table, Layout, AutoComplete, Button, Col, PageHeader, Card, Drawer, notification, Badge } from 'antd';
const { Header, Content, Footer, Sider } = Layout;
import { Link } from 'react-router-dom';
import { getAllCrops } from '../util/APIUtils';
import { filterPlainArray } from '../util/Helpers';
import { withRouter } from 'react-router-dom';
import { POLL_LIST_SIZE } from '../constants';
import { SmileTwoTone, CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import { withTranslation } from "react-i18next";
import CropSearch from './CropSearch';

import './Crops.css';

class Crops extends Component {
    constructor(props) {
        super(props);
        this.state = {
            crops: [],
            cropsFull: [],
            isLoading: false,
            visible: false,
            currentUser: props.currentUser
        };
    }

    loadCropList(page = 0, size = POLL_LIST_SIZE) {
        let promise;
        promise = getAllCrops();

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response)
            this.setState({
                crops: response,
                cropsFull: response,
                // page: response.page, size: response.size, totalElements:
                // response.totalElements, totalPages: response.totalPages, last: response.last,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    componentDidMount() {
        if (!this.props.isAuthenticated) {
            this.props.history.push("/login");
            notification.info({
                message: 'Kultursaat',
                description: "Please login to continue.",
            });
            return;
        }
        this.loadCropList();
    }

    componentDidUpdate(nextProps) {
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                farmprofiles: [],
                // page: 0, size: 10, totalElements: 0, totalPages: 0, last: true,
                isLoading: false
            });
            this.loadCropList();
        }
    }
    getFilterData(arr, field, fieldId) {
        let filters = []
        filters = [...new Set(arr.map(a => { return { 'text': a[field], 'value': a[field] } }))];
        return filters.filter((v, i, a) => a.findIndex(t => (t.value === v.value && t.text !== null)) === i)
    }

    handleTableChange = (pagination, filters, sorter) => {
        let filteredArray = this.state.cropsFull;
        let o = Object.fromEntries(Object.entries(filters).filter(([_, v]) => v != null));
        this.setState({
            crops: filterPlainArray(filteredArray, o)
        });
    };
    onSelect = (val) => {
        let linkText = `/crop/detail/${val}`
        this.props.history.push(linkText);
    }

    render() {
        const { t } = this.props;
        const crops = this.state.cropsFull;
        let officeUser = this.state.currentUser.roles.find(x => x.name === 'ROLE_OFFICE');

        const columns = [
            {
                title: t("general.name"),
                dataIndex: 'name',
                key: 'name',
                width: 300,
                sorter: (a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                },
                render: (text, record) => {
                    let linkText = `/crop/detail/${record.id}`
                    return (
                        <span><b>
                            <Link
                                to={{
                                    pathname: linkText,
                                    state: {
                                        id: record.id
                                    }
                                }}>{text} </Link>
                        </b>
                        </span>
                    )
                },
                sortDirections: ['descend', 'ascend']
            },
            {
                title: t("crops.crop_short_name"),
                dataIndex: 'shortName',
                key: 'shortName',
                width: 150 //<CheckCircleTwoTone twoToneColor="#52c41a" />
            },
            {
                title: t("crops.is_group"),
                dataIndex: 'isGroup',
                key: 'shortName',
                width: 150,
                render: (text, record) => {
                    return (
                        <span>
                            {record.isGroup == 1 ? "Yes" : "No"}

                        </span>
                    )
                },
            },
            {
                title: t("crops.crop_group"),
                dataIndex: 'groupName',
                key: 'groupName',
                width: 300,
                filters: this.getFilterData(crops, "groupName", "groupName"),
                sorter: (a, b) => {
                    if (a.groupName < b.groupName) {
                        return -1;
                    }
                    if (a.groupName > b.groupName) {
                        return 1;
                    }
                    return 0;
                },
                sortDirections: ['descend', 'ascend']
            },
            {
                title: t("crops.harvest_interval"),
                dataIndex: 'anniversary',
                key: 'anniversary',
                width: 150,
                sorter: (a, b) => {
                    if (a.anniversary < b.anniversary) {
                        return -1;
                    }
                    if (a.anniversary > b.anniversary) {
                        return 1;
                    }
                    return 0;
                },
                sortDirections: ['descend', 'ascend'],
                filters: this.getFilterData(crops, "anniversary", "anniversary"),
            }, {
                title: t("crops.has_fg"),
                dataIndex: 'fachGroupId',
                key: 'fg',
                width: 150,
                sorter: (a, b) => {
                    if (a.fachGroupId < b.fachGroupId) {
                        return -1;
                    }
                    if (a.fachGroupId > b.fachGroupId) {
                        return 1;
                    }
                    return 0;
                },
                render: (text, record) => {
                    let txt = text > 0 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="red" />
                    return (
                        <span>
                            {txt}
                        </span>
                    )
                },
            },
            // {
            //     title: t("fachgroup.fg_name"),
            //     dataIndex: 'fachGroupName',
            //     key: 'fachGroupName',
            //     width: 350,
            //     render: (text, record) => {
            //         let linkText = `/fachgroup/detail/${record.fachGroupId}`
            //         return (
            //             <span><b>
            //                 <Link
            //                     to={{
            //                         pathname: linkText,
            //                         state: {
            //                             id: record.id
            //                         }
            //                     }}>{text}</Link>
            //             </b>
            //             </span>
            //         )
            //     },
            // },
            // {
            //     title: t("fachgroup.fg_leader_name"),
            //     dataIndex: 'fgLeaderName',
            //     key: 'fgLeaderName',
            //     width: 350,
            //     filters: this.getFilterData(crops, "fgLeaderName", "fgLeaderId"),
            //     render: (text, record) => {
            //         let linkText = `/breeders/profile/${record.fgLeaderId}`
            //         return (
            //             <span><b>
            //                 <Link
            //                     to={{
            //                         pathname: linkText,
            //                         state: {
            //                             id: record.id
            //                         }
            //                     }}>{text}</Link>
            //             </b>
            //             </span>
            //         )
            //     },
            // },
        ];
        let bList = this
            .state
            .crops
            .filter(ab => ab.name !== "")
            .map(a => ({ "value": a.name + " - " + a.shortName, "key": a.id }));
        console.log(bList);
        let placeHolder = `${bList.length} ${t("general.crops")}`
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    title={t("general.crops")}
                    subTitle=""
                    extra={[officeUser? <Link to="/crop/add"><Button key="3" type="primary" >{t("general.add")}</Button></Link>: ""]
                    }
                />
                <Card bordered={true} loading={this.state.isLoading}
                    title={bList.length > 0 ?
                        <CropSearch dataList={bList}
                            dataCount={bList.length}
                            placeHolder={placeHolder}
                            onSelect={this.onSelect} /> : ""}
                >
                    <Table
                        columns={columns}
                        dataSource={this.state.crops}
                        loading={this.state.isLoading}
                        onChange={this.handleTableChange}
                    />
                </Card>
            </div>
        );
    }
}
export default withTranslation()(withRouter(Crops));
