import React from 'react'
import { Collapse, List, Spin, Divider, Button, Tag, Alert, Popconfirm } from 'antd';
import { CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import { getValidationResults, getValidationResultsForProject, submitProjectStatus } from '../util/APIUtils';
import PlanValidation from './PlanValidation';
import ReportValidation from './ReportValidation';
import FgBudgetValidation from './FgBudgetValidation';
const { Panel } = Collapse;

function ProjectValidation(props) {
    const [loading, setLoading] = React.useState(false);
    const [submitLoading, setSubmitLoading] = React.useState(false);
    const [validationResults, setValidationResults] = React.useState(null);
    const [validationStatus, setValidationStatus] = React.useState(false);
    const [planValidation, setPlanValidation] = React.useState(null);
    const [reportValidation, setReportValidation] = React.useState(null);
    const [submitStatus, setSubmitStatus] = React.useState(false);


    console.log(props)

    const text = ""
    const onChange = (key) => {
        // console.log(key);
    };

    const validateProject = React.useCallback(async () => {
        let promise;
        setLoading(true)
        promise = getValidationResultsForProject(props.projectId);
        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response);
            setValidationResults(response);
            setPlanValidation(response.planValidationInfo)
            setReportValidation(response.reportValidationInfo)
            setSubmitStatus(response.planSubmitStatus);
            setValidationStatus(response.overallValidationStatus)
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, [])

    React.useEffect(() => {
        validateProject()
    }, [validateProject]);

    const submitProject = () => {
        let promise;
        setSubmitLoading(true)
        promise = submitProjectStatus(props.projectId);
        if (!promise) {
            return;
        }

        promise.then(response => {
            validateProject();
            setSubmitLoading(false)
            props.reloadProject({ 'year': "", "tab": 1 });
        }).catch(error => {
            console.log(error)
            setSubmitLoading(false)
        });
    }

    const objToArr = (itemObj) => {
        return Object.entries(itemObj).map(([name, value]) => ({ name, value }))
    }

    // if (validationResults) {
    //     setPlanValidation(validationResults.planValidationInfo)
    //     setReportValidation(validationResults.reportValidationInfo)
    // }



    const getIcon = (val) => {
        return val ? <CheckCircleFilled style={{ fontSize: '16px', color: "#52c41a", alignContent: 'start' }} /> :
            < WarningFilled style={{ fontSize: '16px', color: "orange" }} />
    }

    const getEmptyItem = (type) => {
        if (type == "accounting") {
            return [{ displayName: "Kalkulation - Tätigkeiten", status: planValidation?.accountingValidationStatus, message: "At least one item required" }]
        } else if (type == "examination") {
            return [{ displayName: "Kalkulation - Untersuchungen", status: planValidation?.examinationValidationStatus, message: "At least one item required" }]
        } else if (type == "lines") {
            return [{ displayName: "mindestens 1 Position muss angegeben werden", status: planValidation?.lineValidationStatus, message: "At least one item required" }]
        }

    }

    const confirmTitle = (
        <div>
          Sind Sie sicher, dass Sie das Projekt einreichen möchten?
          <br />
          Sie können das Projekt nach der Einreichung bearbeiten, müssen es dann jedoch möglicherweise erneut einreichen
        </div>
      );
    return (
        <div>
            {validationResults ?
                <div>
                    <Collapse defaultActiveKey={['1']} onChange={onChange}>
                        {planValidation &&
                            <Panel header={"Plan - " + planValidation?.planYear} key="plan" extra={getIcon(planValidation?.currentYearPlanValidationStatus)} >
                                <PlanValidation planValidationInfo={planValidation} getEmptyItem={getEmptyItem} getIcon={getIcon} loading={loading} type={validationResults.projectType} />
                            </Panel>
                        }

                        {reportValidation &&
                            <Panel header={"Bericht - " + reportValidation?.reportYear} key="2" extra={getIcon(reportValidation?.lastYearReportValidationStatus)}>
                                <ReportValidation reportValidationInfo={reportValidation} getEmptyItem={getEmptyItem} getIcon={getIcon} loading={loading} type={validationResults.projectType} />


                            </Panel>}

                    </Collapse>
                    {submitStatus ? <Tag color="#108ee9" style={{ marginTop: '10px' }}>Projekt eingereicht</Tag> :
                        <div>
                            {!validationStatus ?
                                <Alert
                                    style={{ marginTop: '10px', marginBottom: '10px' }}
                                    message="Projekteinreichung"
                                    description={"Das Senden wird aktiviert, wenn die Validierung abgeschlossen ist"}
                                    type="warning"
                                /> :
                                <Alert
                                    style={{ marginTop: '10px', marginBottom: '10px' }}
                                    message="Projekteinreichung"
                                    description={<p>Das Projekt kann eingereicht werden<br></br>Sie können das eingereichte Projekt nicht mehr bearbeiten</p>}
                                    type="success"
                                />
                            }
                            <Popconfirm title={confirmTitle} okText="Ja" cancelText="Nein" onConfirm={submitProject}>
                            <Button loading={submitLoading} type='primary' disabled={!validationStatus} style={{ backgroundColor: 'darkgreen' }} >Projekt einreichen</Button>
                            </Popconfirm>
                            

                        </div>
                    }

                </div>
                : <Spin></Spin>
            }
        </div>
    )
}

export default ProjectValidation