import React, { useState } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Typography } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import {inject, observer} from 'mobx-react';

const originData = [];
const { Text, Title } = Typography;
import './Calculation.css'

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [editingKey, setEditingKey] = useState('');
  const [data, setData] = useState(props.data);



  const isEditing = (record) => {
    return record.key === editingKey
    // return true;
  };

  const edit = (record) => {
    form.setFieldsValue({
      daysResearcherBudget: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        console.log(item)
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: t("calculation.accounting_item"),
      dataIndex: 'accountingItem',
      width: 500,
      editable: true,
    },
    {

          title: t("calculation.budget"),
          align: "right",
          editable: true,
          dataIndex: 'daysResearcherBudget',
          
          render: (text, row, index) => {
            console.log(text)
            return (
              <span><b>
               {text}
                {/* {text != null || text == 0 ? text.toFixed(2) : "0.00"} */}
              </b>
              </span>
            )
          },
        },
        {
          title: t("calculation.actual"),
          align: "right",
          editable: true,
          dataIndex: 'daysResearcherActual',
          
          render: (text, row, index) => {
            let textColor = row.daysResearcherActual > row.daysResearcherBudget ? "warning" : "default";

            return (
              <span><b>
                
                {/* <Text type={textColor}>  {text != null || text == 0 ? text.toFixed(2) : "0.00"}</Text> */}
              </b>
              </span>
            )
          },
  
    },
    {

          title: t("calculation.budget"),
          align: "right",
          editable: true,
          dataIndex: 'daysHelperBudget',
          
          render: (text, row, index) => {
            return (
              <span><b>
                {/* {text != null || text == 0 ? text.toFixed(2) : "0.00"} */}
                {text}
              </b>
              </span>
            )
          },
        },
        {
          title: t("calculation.actual"),
          align: "right",
          editable: true,
          dataIndex: 'daysHelperActual',
          
          render: (text, row, index) => {
            let textColor = row.daysHelperActual > row.daysHelperBudget ? "warning" : "default";
            return (
              <span><b>
                {text}
                {/* <Text type={textColor}>{text != null || text == 0 ? text.toFixed(2) : "0.00"}</Text> */}
              </b>
              </span>
            )
          },

    },
    {

          title: t("calculation.budget"),
          align: "right",
          editable: true,
          dataIndex: 'budgetTotalAmount',
          
          render: (text, row, index) => {
            return (
              <span><b>
                {text}
                {/* {text != null || text == 0 ? text.toFixed(2) : "0.00"} */}
              </b>
              </span>
            )
          },
        },
        {
          title: t("calculation.actual"),
          align: "right",
          dataIndex: 'actualTotalAmount',
          editable: true,
          
          render: (text, row, index) => {
            let textColor = row.actualTotalAmount != row.budgetTotalAmount ? "warning" : "default";
            let actualSelected = row.actualSelected ? "" : "#f2d577"
            return {
              props: {
                style: { background: actualSelected },
              },
              children:
                <span><b>
                  {text}
                  {/* <Text type={textColor}>  {text != null || text == 0 ? text.toFixed(2) : "0.00"}</Text> */}
                </b>
                </span>
            }
          },
    
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="javascript:;"
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      console.log("returning")
      return col;
    }
    console.log("proceed")
    return {
      ...col,
      onCell: (record) => ({
        record,
        // inputType: col.dataIndex === 'quantityBudget' ? 'number' : 'text',
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  );
};

export default EditableTable;