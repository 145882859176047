import React, { Component } from 'react'
import {
  PageHeader,
  Card,
  Button,

} from 'antd'
import ForumHome from './ForumHome';

import AddFile from './AddFile';

export class ForumLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: this.props.currentUser
    };
  }

  render() {
    return (
      <div className="site-card-border-less-wrapper">
        <PageHeader
          className="site-page-header"
          title={"Forum"}
          subTitle="" />
        <Card
          bordered={true}
          
        >
         <ForumHome currentUser={this.state.currentUser} />
        </Card>

        
      </div>
    )
  }
}

export default ForumLanding;
