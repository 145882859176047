import { Descriptions, Badge, PageHeader, Select, Checkbox, Space, Button, InputNumber, Popconfirm, message, Form } from 'antd'
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { getYears, getCurrentProjectYear, getPreviousProjectYear, saveCurrentYearSettings, saveLastYearSettings, increment } from '../util/APIUtils';


export class ProjectSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            year: null,
            loading: false,
            projectYears: [],
            currentProjectYear: null,
            previousProjectYear: null,
            editing: false,
            editing1: false,
            projectYear: null,
            // currentPhase: null,
            // enableFgComments: null,
            // showPayoutAmount: null,
            // enableFgBudget: null,
            // enableZsl: null
        };
    }

    handleBack = e => {
        this
            .props
            .history
            .goBack();
        // this.props.history.push('/projects');
    };

    onChange = () => {

    }

    fetchYears = () => {
        this.setState({ loading: true })
        let promise;
        promise = getYears();
        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response);
            this.setState({
                loading: false,
                projectYears: response.reverse()
            })

        }).catch(error => {
            this.setState({ loading: false })
        });
    }

    incrementYear = () => {
        this.setState({ loading: true })
        let promise;
        promise = increment();
        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response);
            this.setState({
                // loading: false,
                currentProjectYear: response
            })
            window.location.reload();

        }).catch(error => {
            console.log(error)
            this.setState({ loading: false })
        });
    }

    getCurrentYear = () => {
        this.setState({ loading: true })
        let promise;
        promise = getCurrentProjectYear();
        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response);
            this.setState({
                loading: false,
                currentProjectYear: response
            })

        }).catch(error => {
            this.setState({ loading: false })
        });
    }

    getPreviousYear = () => {
        this.setState({ loading: true })
        let promise;
        promise = getPreviousProjectYear();
        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response);
            this.setState({
                loading: false,
                previousProjectYear: response
            })

        }).catch(error => {
            this.setState({ loading: false })
        });
    }

    saveCurrentYear = (projectYear, type) => {
        this.setState({ loading: true })
        let promise;
        if(type == 1) {
            promise = saveCurrentYearSettings(projectYear);
        } else {
            promise = saveLastYearSettings(projectYear);
        }
        
        if (!promise) {
            return;
        }

        promise.then(response => {
            if(response) {
                message.success("Projekteinstellungen aktualisiert")
            }
            
            this.setState({
                loading: false,
                editing: false,
                editing1: false,
                currentProjectYear: response
            })

        }).catch(error => {
            this.setState({ loading: false })
        });
    }

    componentDidMount() {
        this.fetchYears();
        this.getCurrentYear();
        this.getPreviousYear();
    }


    setEditing = (edit) => {
        this.setState({
            editing: edit
        })
    }

    setEditing1 = (edit) => {
        this.setState({
            editing1: edit
        })
    }


    handleChange = (name, type, value) => {
        console.log(name)
        console.log(value)

        if (type === "check") {
            value = value.target.checked;
        }

        this.setState({ currentProjectYear: { ...this.state.currentProjectYear, [name]: value } });
        console.log(this.state.currentProjectYear)
    };

    handleChange1 = (name, type, value) => {
        console.log(name)
        console.log(value)

        if (type === "check") {
            value = value.target.checked;
        }

        this.setState({ previousProjectYear: { ...this.state.previousProjectYear, [name]: value } });
        console.log(this.state.previousProjectYear)
    };

    handleSave = () => {
        let currentYear = {
            "id": this.state.currentProjectYear.id,
            "projectYear": this.state.currentProjectYear.projectYear,
            "currentPhase": this.state.currentProjectYear.currentPhase,
            "enableFgComments": this.state.currentProjectYear.enableFgComments,
            "showPayoutAmount": this.state.currentProjectYear.showPayoutAmount,
            "enableFgBudget": this.state.currentProjectYear.enableFgBudget,
            "enableZsl": this.state.currentProjectYear.enableZsl,
            "researcherRate": this.state.currentProjectYear.researcherRate,
            "helperRate": this.state.currentProjectYear.helperRate,
            "fgRate": this.state.currentProjectYear.fgRate,
        }
        this.saveCurrentYear(currentYear, 1);
    }

    handleSave1 = () => {
        let currentYear = {
            "id": this.state.previousProjectYear.id,
            "projectYear": this.state.previousProjectYear.projectYear,
            "currentPhase": this.state.previousProjectYear.currentPhase,
            "enableFgComments": this.state.previousProjectYear.enableFgComments,
            "showPayoutAmount": this.state.previousProjectYear.showPayoutAmount,
            "enableFgBudget": this.state.previousProjectYear.enableFgBudget,
            "enableZsl": this.state.previousProjectYear.enableZsl,
            "researcherRate": this.state.previousProjectYear.researcherRate,
            "helperRate": this.state.previousProjectYear.helperRate,
            "fgRate": this.state.previousProjectYear.fgRate,
        }
        this.saveCurrentYear(currentYear, -1);
    }

    cancelPop = () => {
        this.setState({
            loading: false,
        })
    }


    render() {
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    title={"Projekt Einstellungen"}
                    extra={<Link to="/access-override"><Button key="3" type="primary" >Access Override</Button></Link>}
                    // extra={this.state.editing ? <Space><Button type='primary' onClick={this.handleSave}>Save</Button><Button onClick={() => this.setEditing(false)} type=''>Cancel</Button></Space> : <Button onClick={() => this.setEditing(true)} type='primary'>Edit</Button>}
                />
                {/* <AccessOverride /> */}
                {this.state.currentProjectYear && this.state.previousProjectYear && 
                <div>
                    <Descriptions layout="vertical" bordered loading={this.state.loading} column={4}>
                    <Descriptions.Item label="Projektjahr" style={{width: "250px"}}>
                        <Select
                            style={{
                                width: '150px'
                            }}
                            disabled
                            name="year"
                            placeholder={"Jahr"}
                            onChange={(val) => this.handleChange("projectYear", null, val)}
                            defaultValue={this.state.currentProjectYear.projectYear}>
                            {this
                                .state
                                .projectYears
                                .map(item => (
                                    <Select.Option key={item.projectYear} value={item.projectYear}>
                                        {item.projectYear}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Descriptions.Item>
                    <Descriptions.Item label="Phase" style={{width: "300px"}}>
                        <Select
                            style={{
                                width: '250px'
                            }}
                            name="currentPhase"
                            disabled={!this.state.editing}
                            placeholder={"Phase"}
                            onChange={(val) => this.handleChange("currentPhase", null, val)}
                            defaultValue={this.state.currentProjectYear.currentPhase}>
                            <Select.Option key={"1"} value={1}>
                                Plan
                            </Select.Option>
                            <Select.Option key={"2"} value={2}>
                            FG Evaluation 1
                            </Select.Option>
                            <Select.Option key={"3"} value={3}>
                            FG Änderungen
                            </Select.Option>
                            <Select.Option key={"4"} value={4}>
                            FG Evaluation 2
                            </Select.Option>
                            <Select.Option key={"5"} value={5}>
                            Vorstandsbewilligung
                            </Select.Option>
                            <Select.Option key={"6"} value={6}>
                            Berichte/Abrechnung
                            </Select.Option>
                            <Select.Option key={"7"} value={7}>
                            Abrechnung
                            </Select.Option>
                        </Select>
                    </Descriptions.Item>
                    <Descriptions.Item label="" style={{width: "500px"}}>
                       
                    </Descriptions.Item>
                    <Descriptions.Item label="Actions">
                    {this.state.editing ? <Space><Button type='primary' onClick={this.handleSave} loading={this.state.loading}>Speichern</Button><Button onClick={() => this.setEditing(false)} type=''>Verwerfen</Button></Space> : <Button onClick={() => this.setEditing(true)} type='primary'>Bearbeiten</Button>}
                    </Descriptions.Item>
                    <Descriptions.Item label="Berichtjahr">
                        <Select
                            style={{
                                width: '150px'
                            }}
                            disabled
                            name="year"
                            placeholder={"Jahr"}
                            onChange={(val) => this.handleChange("projectYear", null, val)}
                            defaultValue={this.state.previousProjectYear.projectYear}>
                            {this
                                .state
                                .projectYears
                                .map(item => (
                                    <Select.Option key={item.projectYear} value={item.projectYear}>
                                        {item.projectYear}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Descriptions.Item>
                    <Descriptions.Item label="Phase">
                        <Select
                            style={{
                                width: '250px'
                            }}
                            name="currentPhase"
                            disabled={!this.state.editing1}
                            placeholder={"Phase"}
                            onChange={(val) => this.handleChange1("currentPhase", null, val)}
                            defaultValue={this.state.previousProjectYear.currentPhase}>
                            <Select.Option key={"1"} value={1}>
                                Plan
                            </Select.Option>
                            <Select.Option key={"2"} value={2}>
                            FG Evaluation 1
                            </Select.Option>
                            <Select.Option key={"3"} value={3}>
                            FG Änderungen
                            </Select.Option>
                            <Select.Option key={"4"} value={4}>
                            FG Evaluation 2
                            </Select.Option>
                            <Select.Option key={"5"} value={5}>
                            Vorstandsbewilligung
                            </Select.Option>
                            <Select.Option key={"6"} value={6}>
                            Berichte/Abrechnung
                            </Select.Option>
                            <Select.Option key={"7"} value={7}>
                            Abrechnung
                            </Select.Option>
                        </Select>
                    </Descriptions.Item>
                    <Descriptions.Item label="">
                                         </Descriptions.Item>
                    <Descriptions.Item label="Actions">
                    {this.state.editing1 ? <Space><Button type='primary' onClick={this.handleSave1}>Speichern</Button><Button onClick={() => this.setEditing1(false)} type=''>Verwerfen</Button></Space> : <Button onClick={() => this.setEditing1(true)} type='primary'>Bearbeiten</Button>}
                    </Descriptions.Item>

                </Descriptions>
                    <br></br>
                    <Descriptions bordered loading={this.state.loading} layout="vertical" column={4}>
                        <Descriptions.Item label="Züchter Rate" style={{width: "250px"}}>
                            <InputNumber
                                disabled={!this.state.editing2}
                                defaultValue={this.state.currentProjectYear.researcherRate}
                                formatter={value => `${value} €`}
                                onChange={(val) => this.handleChange("researcherRate", null, val)}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="Hilfskraft Rate" style={{width: "300px"}}>
                            <InputNumber
                                disabled={!this.state.editing2}
                                defaultValue={this.state.currentProjectYear.helperRate}
                                formatter={value => `${value} €`}
                                onChange={(val) => this.handleChange("helperRate", null, val)}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="FG Rate" style={{width: "500px"}}>
                            <InputNumber
                                disabled={!this.state.editing2}
                                defaultValue={this.state.currentProjectYear.fgRate}
                                formatter={value => `${value} €`}
                                onChange={(val) => this.handleChange("fgRate", null, val)}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item>
                        {this.state.editing2 ? <Space><Button type='primary' 
                        >Speichern</Button><Button 
                        type=''>Verwerfen</Button></Space> : <Button  type='primary'>Bearbeiten</Button>}
                        </Descriptions.Item>
                    </Descriptions>
                    <br></br>
                    <Space>
                        <div>
                            Setzen Sie das aktuelle Projektjahr auf das nächste Kalenderjahr
                        </div> <br></br>
                        <Popconfirm
                            title={"Möchten Sie das Projektjahr wirklich erhöhen? Diese wird im nächsten Jahr in die Planphase gesetzt?"}
                            onConfirm={this.incrementYear}
                            onCancel={this.cancelPop}
                        >
                            <Button type='danger' >Nächstes Kalenderjahr</Button>
                        </Popconfirm>
                    </Space>
                </div>}
                
            </div>
        )
    }
}

export default ProjectSettings