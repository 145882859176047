import React, { useState, useEffect, useRef } from 'react';
import {
    Card,
    List,
    Badge,
    Input,
    Tag,
    Space,
    Form,
    Button,
    InputNumber,
    AutoComplete,
    Checkbox,
    DatePicker,
    Select,
    Descriptions,
    message,
    Modal
} from 'antd';
import _ from "lodash";
import { CheckCircleOutlined } from '@ant-design/icons';
import './Plan.css';
import { useTranslation } from 'react-i18next';
import { saveProjectDetail, getAllFarmProfiles, getProjectsByType, getLineSelections, getBreedingMethods } from '../../util/APIUtils';
import { capitalize, formatCurrency, getTextArtva } from '../../util/Helpers';
import MultiSelect from '../../common/MultiSelect';
import SingleSelect from '../../common/SingleSelect';
import AmountSummary from './AmountSummary';
import LineSelectionLookup from '../../common/LineSelectionLookup';
import shortid from 'shortid';
import Satz from '../../satz/Satz';
import LineSelectionLookupSingleWithAdd from '../../common/LineSelectionLookupSingleWithAdd';
import LineSelectionLookupWithAdd from '../../common/LineSelectionLookupWithAdd';

function PlanVa(props) {
    const { t } = useTranslation();
    const [editing, setEditing] = React.useState(false);
    const editable = props.isPlanEditable
    const [loading, setLoading] = React.useState(false);
    const workFlowState = props.workFlowState;
    const [allLineSelections, setAllLineSelections] = useState([])
    const [tLineSelections, setTLineSelections] = useState([])
    const [state, setState] = useState(props.projectDetail);
    const [cultVal, setCultVal] = useState(1)
    const [userProfiles, setUserProfiles] = useState([])
    const [vaProjects, setVaProjects] = useState([])
    const [options, setOptions] = useState([]);
    const [projectOptions, setProjectOptions] = useState([]);

    console.log(props)

    const screenState = {
        "year": state.year,
        "tab": '1'
    }

    const confirm = () => {
        Modal.confirm({
          width: 1200,
          content: <Satz 
          satzType="plan"
          projectDetailId={props.projectDetail.id}
          isPlanEditable={props.isPlanEditable}
          isReportEditable={props.isReportEditable}
      />,
          cancelText: 'Schließen',
        });
      };

    let fetchLines = React.useCallback(async () => {
        setLoading(true)
        let promise;
        promise = getLineSelections(cropId);
        if (!promise) {
            return;
        }

        promise.then(response => {
            setAllLineSelections(response);
            setLoading(false)

        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, [])

    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeOther = name => value => {
        console.log(name)
        console.log(value)
        if (name === 'parentNo') {
            console.log(value)
        } else if (name === "ks_plan") {
            value = value.target.checked ? 1 : 0;
        }
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));

    };

    const handleCultChange = (val) => {
        setCultVal(val);
        setState(prevState => ({
            ...prevState,
            ["artva"]: val,
            // ["parentNo"]: '',
            // ["parentProjectTitle"]: ''
        }));
    }

    const onFinish = (values) => {

        var subsetObj = _.pick(state, [
            "id",
            "year",
            "artva",
            "parentNo",
            "projectNumber",
            "parentProjectTitle",
            "description",
            "plannedActivity",
            "cultivars",
            "referenceLines",
            "numberOfPlannedLines",
            "projectExtension",
            "generationStart",
            "generationEnd",
            "descBreedingMethod",
            "cultivationCompany",
            "cultivationCompanyId",
            "cultivationScope",
            "cultivationScopeUnit",
            "priority",
            "farmProfileId",
            "ks_plan"
        ]);

        console.log(state)

        console.log(subsetObj);


        let promise;
        promise = saveProjectDetail(subsetObj);

        if (!promise) {
            return;
        }

        promise.then(response => {
            message.success("Projektplan aktualisiert")
            props.reloadProject(screenState);

        }).catch(error => {
            message.error("project update failed")
        });
    };

    const getFarmProfiles = React.useCallback(async () => {
        let promise;
        promise = getAllFarmProfiles();

        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response)
            setUserProfiles(response)
            setOptions(response.map(item => ({ "type": "farm", "value": item.name, "id": item.id })))
            console.log(options)

        }).catch(error => {
            // message.error("User profiles loading failed")
        });
    }, [])

    const getVaProjects = React.useCallback(async () => {
        let promise;
        promise = getProjectsByType("VA");

        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response)
            setVaProjects(response)
            setProjectOptions(response.map(item => ({ "type": "project", "value": item.projectNo + " : " + item.description, "id": item.projectNo })))
            console.log(projectOptions)
            // message.success("User profiles loaded")

        }).catch(error => {
            // message.error("User profiles loading failed")
        });
    }, [])


    useEffect(() => {
        getFarmProfiles();
        getVaProjects();
        fetchLines();
    }, [getFarmProfiles, fetchLines, getVaProjects]);

    const lineSelectionChildren = [];
    allLineSelections.forEach(element => {
        lineSelectionChildren.push(<Option label={element.name} value={element.id} key={element.id}>{element.name}</Option>);
    });

    const [form] = Form.useForm();

    let cultivars = state.cultivars.map(a => a.name);
    let referenceLines = state.referenceLines.map(a => a.name);


    const cropId = props.projectInfo.cropId;

    console.log(state)
    console.log(props)

    form.setFieldsValue({
        description: state.description,
        planned_activity: state.plannedActivity,
        youngest: state.generationStart,
        oldest: state.generationEnd,
        breeding_method_description: state.descBreedingMethod,
        priority: state.priority,
        no_planned_lines: state.numberOfPlannedLines,
        cultivation_scope: state.cultivationScope ? parseInt(state.cultivationScope) : 0,
        cultivation_scope_unit: state.cultivationScopeUnit,
    });

    const setEditingLocal = (val) => {
        setEditing(val);
        props.setEditingMaster(val);
    }


    return (
        <div>
            <Card bordered={true}
                extra={
                    editable ?
                        !editing ?
                            <Button onClick={() => setEditingLocal(true)}>Bearbeiten</Button> :
                            <Space>
                                <Button onClick={() => form.submit()} type='primary'>Speichern</Button>
                                <Button onClick={() => setEditingLocal(false)}>Abbrechen</Button>
                            </Space> : ""

                }>
                <Form
                    // ref={this.formRef}
                    name="plan-x"
                    span={3}
                    form={form}
                    onFinish={onFinish}
                    scrollToFirstError
                >
                    <Descriptions
                        size="small"
                        bordered
                        style={{
                            marginBottom: 2
                        }}>
                        <Descriptions.Item span={3} label={t("project_details.what_cultivation")} labelStyle={{ fontWeight: 'bold', width: 250 }}>
                            {editing && editable ?
                                <Select defaultValue={state["artva"]} style={{ width: '100%' }} onChange={handleCultChange}>
                                    <Option value={1}>interner Versuchsanbau nur auf eigenem Betrieb</Option>
                                    <Option value={2}>übergeordnetes Versuchsanbau Projekt mit Tochterprojekten auf extern Standorten</Option>
                                    <Option value={3}>Tochterprojekt zu folgendem übergeordneten projekt</Option>
                                </Select> : getTextArtva(state["artva"])}
                        </Descriptions.Item>
                        {state["artva"] == 3 &&
                            <Descriptions.Item span={3} label="übergeordneten projekt" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                                {editing && editable ?
                                    // <AutoComplete
                                    //     defaultValue={state.parentNo + " : " + state.parentProjectTitle}
                                    //     options={projectOptions}
                                    //     style={{ width: '100%' }}
                                    //     onSelect={onAutoCompleteSelect}
                                    //     onSearch={onAutoCompleteSearch}
                                    //     placeholder="input here"
                                    // />
                                    <Form.Item
                                        name="parentNo"
                                        hasFeedback
                                    >
                                        <SingleSelect
                                            setItems={handleChangeOther("parentNo")}
                                            existingItems={[state.parentNo || "- " + " : " + (state.parentProjectTitle || " -" )]}
                                            itemList={projectOptions} />
                                    </Form.Item>
                                    : state["parentNo"] + " - " + state.parentProjectTitle}
                            </Descriptions.Item>
                        }
                        <Descriptions.Item label="Anbaubetrieb" span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                            {editing && editable ?
                                <Form.Item
                                    name="cultivationCompanyId"
                                    hasFeedback
                                >
                                    <SingleSelect
                                        setItems={handleChangeOther("cultivationCompanyId")}
                                        existingItems={[state.cultivationCompanyId]}
                                        itemList={options} />
                                </Form.Item>
                                // <AutoComplete
                                //     defaultValue={state.cultivationCompany}
                                //     options={options}
                                //     style={{ width: '100%' }}
                                //     onSelect={onAutoCompleteSelect}
                                //     onSearch={onAutoCompleteSearch}
                                //     placeholder="input here"
                                // />
                                
                                : <SingleSelect
                                disabled
                                setItems={handleChangeOther("cultivationCompanyId")}
                                existingItems={[state.cultivationCompanyId]}
                                itemList={options} />}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("project_details.description")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                            {editing && editable ?
                                <Input.TextArea autoSize name="description" value={state["description"]} onChange={handleChange} />
                                : state["description"]}
                        </Descriptions.Item>
                        <Descriptions.Item span={3} label={t("project_details.planned_activity")} labelStyle={{ fontWeight: 'bold', width: 250 }}>
                            {editing && editable ?
                                <Input.TextArea autoSize name="plannedActivity" value={state["plannedActivity"]} onChange={handleChange} />
                                : state["plannedActivity"]}
                        </Descriptions.Item>

                        <Descriptions.Item label="Zuchsorten/Linien" labelStyle={{ fontWeight: 'bold' }} span={3}>
                            {editing && editable ?
                                <Form.Item
                                    name="lineSelections"
                                    hasFeedback
                                >
                                    {/* <Select mode="multiple" style={{ width: '100%' }} onChange={handleChange}>
                                {childrenB}
                            </Select> */}

                                <LineSelectionLookupWithAdd
                                    cropId={cropId}
                                    setItems={handleChangeOther("cultivars")}
                                    existingItems={state.cultivars}
                                    setFormattedItems={handleChangeOther("cultivars")}
                                    projectInfo={props.projectInfo}
                                />
                                    {/* <LineSelectionLookup
                                        cropId={cropId}
                                        setItems={handleChangeOther("cultivars")}
                                        existingItems={state.cultivars}
                                        setFormattedItems={handleChangeOther("cultivars")}
                                    /> */}
                                </Form.Item>
                                :
                                <List
                                    size="small"
                                    // bordered
                                    dataSource={cultivars}
                                    renderItem={item => <List.Item style={{ padding: 2 }}><Badge color={'geekblue'} text={item} /></List.Item>}
                                />}

                        </Descriptions.Item>
                        <Descriptions.Item label="Referenzsorten" labelStyle={{ fontWeight: 'bold' }} span={3}>
                            {editing && editable ?
                                <Form.Item
                                    name="referenceLines"
                                    hasFeedback
                                >
                                    {/* <Select mode="multiple" style={{ width: '100%' }} onChange={handleChange}>
                                    {childrenB}
                                </Select> */}
                                    <LineSelectionLookup
                                        cropId={cropId}
                                        setItems={handleChangeOther("referenceLines")}
                                        existingItems={state.referenceLines}
                                        setFormattedItems={handleChangeOther("referenceLines")}
                                    />
                                </Form.Item>
                                :
                                <List
                                    size="small"
                                    // bordered
                                    dataSource={referenceLines}
                                    renderItem={item => <List.Item style={{ padding: 2 }}><Badge color={'geekblue'} text={item} /></List.Item>}
                                />}

                        </Descriptions.Item>
                        <Descriptions.Item label={t("project_details.planned_lines")} labelStyle={{ fontWeight: 'bold' }} span={3} >
                            {editing && editable ?

                                // <Form.Item
                                //     name="no_planned_lines"
                                //     rules={[
                                //         {
                                //             required: true,
                                //             message: 'Needs to be filled',
                                //         },
                                //     ]}
                                // >
                                <InputNumber style={{ width: '100%' }} name="numberOfPlannedLines" value={state["numberOfPlannedLines"]} onChange={handleChangeOther("numberOfPlannedLines")} />
                                // </Form.Item>
                                : state.numberOfPlannedLines}
                        </Descriptions.Item>
                        {/* <Descriptions.Item label={t("project_details.project_delivery")} labelStyle={{ fontWeight: 'bold' }} span={2} span={3}>
                            {editing && editable ?
                                <Form.Item name="project_delivery" rules={[{ required: false }]}>
                                    <DatePicker picker="year" style={{ width: 360 }} />
                                </Form.Item> : props.projectInfo.generationStart}
                        </Descriptions.Item> */}



                        <Descriptions.Item label={t("project_details.cultivation_scope")} labelStyle={{ fontWeight: 'bold' }} >
                            {editing && editable ?
                                <div>
                                    {/* <Form.Item
                                        name="cultivation_scope"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input',
                                            },
                                        ]}
                                    > */}
                                    <InputNumber name="cultivationScope" value={state["cultivationScope"]} onChange={handleChangeOther("cultivationScope")} />
                                    {/* </Form.Item> */}
                                    {/* <Form.Item
                                        name="cultivation_scope_unit"> */}
                                    <Select
                                        // value={value.currency || currency}
                                        style={{ width: '25%' }}
                                        name="cultivationScopeUnit"
                                        defaultValue={state.cultivationScopeUnit}
                                        onChange={handleChangeOther("cultivationScopeUnit")}
                                    >
                                        <Option value={"qm"}>qm</Option>
                                        <Option value={"ha"}>ha</Option>
                                        <Option value={"stück"}>stück</Option>
                                    </Select>

                                    {/* </Form.Item> */}
                                </div>
                                : state.cultivationScope + " " + state.cultivationScopeUnit}
                        </Descriptions.Item>

                        <Descriptions.Item label={t("project_details.priority")} labelStyle={{ fontWeight: 'bold' }} span={4} >
                            {editing && editable ?
                                <Select
                                    style={{ width: '100%', margin: '0 0px' }}
                                    name="priority"
                                    defaultValue={state.priority}
                                    onChange={handleChangeOther("priority")}
                                >
                                    <Option value={1}>Hoch</Option>
                                    <Option value={2}>Mittel</Option>
                                    <Option value={3}>Neidrig</Option>
                                </Select>
                                : state.priority}
                        </Descriptions.Item>

                        <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label={"bei Kultursaat einreichen"} span={3}>
                            <Checkbox disabled={!(editing && editable)} defaultChecked={state.ks_plan == 1 ? true : false} label={"bei Kultursaat einreichen"} onChange={handleChangeOther("ks_plan")}></Checkbox>
                        </Descriptions.Item>

                    </Descriptions>
                    <div style={{marginTop : "10px", marginBottom: "10px"}}>
                  <Button type='primary' onClick={confirm}>Sätze</Button> 
                    </div>
                    {editing && editable ? "" :
                        // <Badge.Ribbon text={state.ks_plan == 1 ? t("project_details.submitted_to_ks") : t("project_details.not_submitted")} color={state.ks_plan == 1 ? "green" : "volcano"}>
                        <AmountSummary  key={shortid.generate()} projectDetailId={state.id} type={"plan"} workFlowState reloadProject={() => props.reloadProject(screenState)} />
                        // </Badge.Ribbon>
                    }
                </Form>
            </Card>




        </div>
    );
}

export default PlanVa;