import { Icon, Button, Input, AutoComplete, Select } from 'antd';
import React, { Component } from 'react';

class CropSearch extends React.Component {

  constructor(props) {
    console.log(props)
    super(props);
    this.state = {
      dataSource: props.dataList.sort((a, b) => a.value.localeCompare(b.value)),
      itemCount: props.dataCount,
      placeHolder: props.placeHolder,
      key: props.key
    };
  }

  render() {
    return (
      <div key={this.props.key} className="global-search-wrapper" style={{ width: 300 }}>

        <Select
          showSearch
          style={{ width: 300 }}
          placeholder={this.state.placeHolder}
          optionFilterProp="children"
          onSelect={this.props.onSelect}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {this.state.dataSource.map(fbb =>
            <option key={fbb.id} value={fbb.key}>{fbb.value}</option>
          )}
        </Select>

        

      </div>
    );
  }
}

export default CropSearch;