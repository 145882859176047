import React, { useState, useRef } from 'react';
import { Form, Input, Upload, Button, Modal, message } from 'antd';
import { PlusSquareFilled, UploadOutlined } from '@ant-design/icons';
import { uploadForumFile } from '../../util/APIUtils';
import YearSelect from '../../common/YearSelect'
import FarmProfileSelect from '../../common/FarmProfileSelect'

const ResourceInfoForm = React.forwardRef(({ currentUser, handleLoadingChange, handleVisibleChange, refetch, farmProfileId }, ref) => {
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = React.useState(false)
    const [fpId, setFpId] = React.useState(farmProfileId)
    const [year, setYear] = React.useState(null)
    const [form] = Form.useForm();
    console.log(currentUser)

    const ownerId = currentUser?.breederInfo.id;

    const onFinish = async (values) => {

        if (!fpId) {
            form.setFields([
                {
                    name: 'fpId',
                    errors: ['Bitte Standortprofil auswählen'],
                },
            ]);
            return;
        }

        if (!year) {
            form.setFields([
                {
                    name: 'year',
                    errors: ['Bitte jahr auswählen'],
                },
            ]);
            return;
        }

        // Combine form data and file upload here
        let combinedData = {
            ...values,
            file: fileList.length > 0 ? fileList[0].name : null,
            itemId: fpId
        };

        console.log(combinedData)

        // Handle file upload
        if (fileList.length > 0) {
            const formData = new FormData();
            formData.append('file', fileList[0]);
            formData.append('fileName', fileList[0].name);
            formData.append('year', year);
            formData.append('itemId', fpId);
            formData.append('designation', values.designation || '')
            formData.append('description', values.description || '')
            formData.append('keywords', values.keywords || '')
            formData.append('ownerId', ownerId)
            formData.append('mediaType', 3)

            let promise;
            handleLoadingChange(true);
            promise = uploadForumFile(formData, encodeURI(fileList[0].name));
            if (!promise) {
                return;
            }

            promise.then(response => {
                message.success('Daten erfolgreich aktualisiert')
                console.log(response)
                handleLoadingChange(false);
                handleVisibleChange(false);
                refetch();
            }).catch(error => {
                console.log(error)
                setLoading(false)
                handleLoadingChange(false);
                message.success('Datenaktualisierung fehlgeschlagen')
                return;
            });
        }

        handleLoadingChange(false);
    };

    const beforeUpload = (file) => {
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isLt20M) {
            message.error('Die Datei muss kleiner als 20MB sein!');
        } else {
            setFileList([file]);
        }
        return isLt20M;
    };

    return (
        <Form form={form} ref={ref} name="resourceInfoForm" onFinish={onFinish}>
            <Form.Item
                label="Jahr"
                name="year"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
            >
                <YearSelect
                    setItem={setYear}
                    selectedItem={year} />
            </Form.Item>

            <Form.Item
                label="Standortprofil"
                name="fpId"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
            >
                <FarmProfileSelect selectedItem={fpId} setItem={setFpId} disabled={true} />
            </Form.Item>

            <Form.Item
                label="Bezeichnung"
                name="designation"
                rules={[{ required: true, message: 'Bitte Bezeichnung eingeben' }]}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
            >
                <Input.TextArea />
            </Form.Item>

            <Form.Item
                label="Beschreibung"
                name="description"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
            >
                <Input.TextArea />
            </Form.Item>

            <Form.Item
                label="Schlagwort"
                name="keywords"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Datei"
                name="file"
                rules={[{ required: true, message: 'Bitte eine Datei hochladen' }]}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
            >
                <Upload
                    beforeUpload={beforeUpload}
                    fileList={fileList}
                    onRemove={() => setFileList([])}
                >
                    <Button icon={<UploadOutlined />}>Datei auswählen</Button>
                </Upload>
            </Form.Item>
        </Form>

    );
});

export default ResourceInfoForm;