import React from 'react'
import {
    List,
    Tag,
    Table
} from 'antd';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { formatCurrency } from '../util/Helpers';

function FgFgTasks(props) {
    const { t } = props;

    const fgBudgets = props.fgBudgets;
    const loading = props.loading;
    const fgId = props.fgId;

    console.log(fgBudgets)


    const columns = [
        {
            title: t("farmprofile.breeder_name"),
            dataIndex: 'breederName',
            key: 'id',
            width: 120,
            render: (text, record) => {
                let linkText = `/fg_budget_detail/${record.cropId}/${record.breederId}`
                return (
                    <span><b>
                        <Link
                            to={{
                                pathname: linkText,
                                state: {
                                    id: record.cropId,
                                    fgId: fgId,
                                    currentUser: props.currentUser
                                }
                            }}>{text} </Link>
                    </b>
                    </span>
                )
            },
            sortDirections: ['descend', 'ascend']
        },
        {
            title: t("crops.crop_name"),
            dataIndex: 'cropName',
            key: 'id',
            width: 150,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: "Jahr",
            dataIndex: 'year',
            key: 'id',
            width: 70 //<CheckCircleTwoTone twoToneColor="#52c41a" />
        },
        {
            title: "Betrag",
            dataIndex: 'budgetTotal',
            key: 'id',
            width: 150,
            align: 'right',
            render: (text, record) => {
                return <b>{formatCurrency(text)}</b>
            }
        },
       
        {
            title: "Betrag FG",
            dataIndex: 'fgOkAmount',
            key: 'id',
            width: 120,
        }, {
            title: "Status",
            dataIndex: 'status',
            key: 'status',
            width: 80,
            render: (text, record) => {
                return <Tag color={"green"}>{text}</Tag>
            }
        },
       
    ];

    return (
        <Table
            columns={columns}
            dataSource={fgBudgets}
            loading={loading}
        />
    )
}

export default withTranslation()(withRouter(FgFgTasks));