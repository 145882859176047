import React, { Component } from 'react'
import {
    Badge,
    Card,
    Descriptions,
    Divider,
    Table,
    Layout,
    Input,
    Row,
    Col,
    Typography,
    PageHeader,
    Select,
    List,
    Avatar,
    Tag,
    Space,
    Button,

} from 'antd';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";

import { getYears, getZslData, getContractActualLink } from '../util/APIUtils';
import { dynamicSort, formatCurrency } from '../util/Helpers';


import {
    FileExcelOutlined,
    FileWordOutlined
} from '@ant-design/icons';



export class ContractActual extends Component {

    constructor(props) {
        super(props);
        this.state = {
            year: null,
            projectYears: [],
            zslData: [],
            isLoading: false,
            breederId: props.currentUser.breederInfo.id
        };
    }

    fetchYears() {
        this.setState({ isLoading: true });
        let promise;
        promise = getYears();
        if (!promise) {
            return;
        }

        promise.then(response => {
            this.setState({ projectYears: response.reverse() })
            this.setState({ isLoading: false });

        }).catch(error => {
            this.setState({ isLoading: false });
        });
    }

    fetchZslData(year) {
        this.setState({ isLoading: true });
        let promise;
        promise = getZslData(year);
        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response)
            this.setState({
                zslData: response,
                isLoading: false
            })

        }).catch(error => {
            console.log(error)
            this.setState({ isLoading: false });
        });
    }

    handleYearInput = (val) => {
        console.log(val);
        this.setState({
            year: val
        })

        console.log(this.state.year);
        this.getReport(val);
    }

    handleBack = e => {
        this
            .props
            .history
            .goBack();
    };

    componentDidMount() {
        if (!this.props.isAuthenticated) {
            this
                .props
                .history
                .push("/login");
        }

        this.fetchYears();

    }

    getReport = (year) => {
        const win = window.open(getContractActualLink(year, this.state.breederId ), '_blank');
        if (win != null) {
            win.focus();
        }
      
    }

    render() {
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    onBack={() => this.handleBack()}
                    title={"Abrechnung drucken"}
                // extra={<Button type='primary'>Add</Button>}
                // subTitle={this.state.farmProfile.name}
                />
                <Card bordered={true} headStyle={{ padding: "0" }} loading={this.state.isLoading} >

                    <Space>
                        <Select style={{ width: '140px' }} onChange={this.handleYearInput} defaultValue={this.state.year} placeholder={"Jahr"}>
                            {this.state.projectYears.map(item => (
                                <Select.Option key={item.projectYear} value={item.projectYear}>
                                    {item.projectYear}
                                </Select.Option>
                            ))}
                        </Select>
                    </Space><Divider></Divider>
                    

                    </Card>
            </div>
        )
    }
}

export default withTranslation()(withRouter(ContractActual));
