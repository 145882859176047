import React from 'react'
import {
    Card,
    Divider,
    PageHeader,
    Select,
    Button,
    Spin,
    Space,
    Alert
} from 'antd';
import { getYears, getSpecialReport, getBudgetValidationReport, getZslSummary } from '../util/APIUtils';
import FarmProfileSelect from '../common/FarmProfileSelect';
import YearSelect from '../common/YearSelect';



function ZslSummary(props) {
    const [loading, setLoading] = React.useState(false);
    const [year, setYear] = React.useState(null);
    const [format, setFormat] = React.useState('pdf');

    const getReport = () => {
        let promise;
        promise = getZslSummary(year, format);
        if (!promise) {
            return;
        }
        setLoading(true)
        promise.then(response => {
            setLoading(false)

        }).catch(error => {
            setLoading(false)
        });
    }

    return (
        <div>
            <PageHeader
                className="site-page-header"
                title={"Saatgutfonds"}
            />
            <Card bordered={true} headStyle={{ padding: "0" }} loading={loading}>
                <Space>
                    <YearSelect
                    setItem={setYear}
                    selectedItem={year} />

                    {year &&
                        <Select style={{ width: '140px' }} onChange={setFormat} placeholder={"format"}>
                            <Select.Option key={1} value="pdf"> PDF</Select.Option>
                            <Select.Option key={2} value="xlsx"> Excel</Select.Option>
                            <Select.Option key={3} value="docx"> Word</Select.Option>
                        </Select>
                    }

                    <Button onClick={getReport}>
                        Download
                    </Button>

                </Space><Divider></Divider>

            </Card>
        </div>
    )
}

export default ZslSummary