import React, { useState, useEffect } from 'react';
import { Image, List, Avatar, Card, Button, notification } from 'antd';
import { downloadFile, getFileListsForProjectId, getMediaPath, deleteGeneralFile } from '../../util/APIUtils';
import { FileExcelFilled, FilePdfFilled, FileWordFilled, DeleteOutlined } from '@ant-design/icons';
import LoadingIndicator from '../../common/LoadingIndicator';
import UploadFiles from '../../common/UploadFiles';

const ProjectResources = (props) => {
    const [projectId, setProjectId] = useState(props.projectId);
    const [path, setPath] = useState("");
    const [year, setYear] = useState(props.year);
    const [fileList, setFileList] = useState([]);
    const [fileListImages, setFileListImages] = useState([]);
    const [fileListDocs, setFileListDocs] = useState([]);
    const [loading, setLoading] = useState(false);
    const currentUser = props.currentUser;

    useEffect(() => {
        fetchFiles();
    }, [projectId]);

    const deleteFile = (id) => {
        setLoading(true);
        deleteGeneralFile(id).then(response => {
            setLoading(false);
            refreshPage();
            notification.success({
                message: "Kultursaat",
                description: "Erfolgreich gelöscht",
                placement: 'topLeft'
            });
        }).catch(error => {
            setLoading(false);
            notification.error({
                message: "Kultursaat",
                description: "Fehler beim Löschen",
                placement: 'topLeft'
            });
            // Inspect the error object and response status
            if (error.response) {
                console.error("Error response status:", error.response.status);
                console.error("Error response data:", error.response.data);
            }
        });
    };

    const fetchFiles = async () => {
        setLoading(true);
        try {
            const response = await getFileListsForProjectId(projectId);
            if (response) {
                setFileList(response);
                setFileListImages(response.filter(a => a.resType === 1));
                setFileListDocs(response.filter(a => a.resType === 2));
                getPath();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getPath = async () => {
        setLoading(true);
        try {
            const response = await getMediaPath(year, projectId);
            if (response) {
                setPath(response.path);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getFileNameDoc = (path) => path.substring(
        path.indexOf("docs/") + 5,
        path.lastIndexOf("?X-Amz")
    );

    const getFileNameImg = (path) => path.substring(
        path.indexOf("bilder/") + 7,
        path.lastIndexOf("?X-Amz")
    );

    const getFileExtension = (fileName) => {
        return fileName.split('.').pop();
    }

    const getIconForExtension = (ext) => {
        if (['xls', 'xlsx'].includes(ext)) return <FileExcelFilled />;
        if (['doc', 'docx'].includes(ext)) return <FileWordFilled />;
        if (['pdf'].includes(ext)) return <FilePdfFilled />;
    };

    const refreshPage = () => {
        fetchFiles();
    };

    return (
        <div>
            {loading ? <LoadingIndicator /> :
                <div>
                    <Card title="Bilder" bordered>
                        {fileListImages.map((item) => (
                            <div key={item.filename} style={{ paddingBottom: '10px' }}>
                                <Image
                                    alt={getFileNameImg(item.fileWithPath)}
                                    width={200}
                                    style={{ padding: '2px' }}
                                    src={item.url}
                                />
                                {/* Delete Button next to each image */}
                                <Button
                                    type="danger"
                                    icon={<DeleteOutlined />}
                                    onClick={() => deleteFile(item.id)}
                                    style={{ marginLeft: '10px' }}
                                />
                            </div>
                        ))}
                        <br />
                        <UploadFiles path={path} fileType={"bilder"} refreshPage={refreshPage}
                            year={year} projectId={projectId} projectNo={props.projectNo} ownerId={currentUser.breederInfo.id} />

                    </Card>
                    <Card title="Sonstige Dokumente" bordered>
                        <List
                            itemLayout="horizontal"
                            dataSource={fileListDocs}
                            renderItem={item => (
                                <List.Item key={item.filename} actions={[
                                    <Button
                                        type="text"
                                        icon={<DeleteOutlined />}
                                        onClick={() => deleteFile(item.id)}
                                        danger
                                    />
                                ]}>
                                    <List.Item.Meta
                                        avatar={<Avatar shape="square" icon={getIconForExtension(getFileExtension(item.filename))} />}
                                        title={<a onClick={() => downloadFile(item.id, item.filename, 1)}>{item.filename}</a>}
                                    />
                                </List.Item>
                            )}
                        />
                        <UploadFiles path={path} fileType={"docs"} refreshPage={refreshPage} year={year} projectId={projectId} ownerId={currentUser.breederInfo.id} />
                    </Card>
                </div>}
        </div>
    );
};

export default ProjectResources;
