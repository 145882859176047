import React, { useState } from 'react';
import { Table, Collapse, Button, Modal, Tag, List, Descriptions, Checkbox, Typography } from 'antd';
import { getFgReviewProjectList } from '../util/APIUtils';
import { formatCurrency } from '../util/Helpers';
import FgReviewModal from './FgReviewModal';
import { Link } from 'react-router-dom';
import CommentList from './CommentList';
import FgBudgetReview from '../fg_budget/FgBudgetReview';
const { Title } = Typography;

const { confirm } = Modal;

function FgReviewProjectList(props) {
    const currentUser = props.currentUser;
    const useMyData = getFgReviewProjectList(props.fgId);
    const [showComments, setShowComments] = useState(false);
    const { isLoading, error, data, refetch } = useMyData(
        (jsonData) => jsonData,
        {
            refetchInterval: 30000, // refresh every 30 seconds
        }
    );

    if (isLoading) {
        return <p>Wird geladen .....</p>;
    }

    if (error) {
        return <p>Error Wird geladen ..: {error.message}</p>;
    }

    console.log(data)

    const getStatusTag = (status) => {
        switch (status) {
            case 'ok':
                return <Tag color="#87d068" style={{ float: 'right', width: '100px' }} >OK</Tag>;
            case 'abgelehnt':
                return <Tag color="#f50" style={{ float: 'right', width: '100px' }} >Abgelehnt</Tag>;
            case 'zurückgestellt':
                return <Tag color="#ffc300" style={{ float: 'right', width: '100px' }}>Zurückgestellt</Tag>;
            default:
                return <Tag style={{ float: 'right', width: '100px' }} >Kein Status</Tag>;
        }
    }

    const panelCounts = ({ counts }) => {
        return (
            <>
                <Tag color="#87d068" style={{ float: 'right', width: '80px' }} >OK: {counts['ok']}</Tag>
                <Tag color="#f50" style={{ float: 'right', width: '80px' }} >Abgelehnt: {counts['abgelehnt']}</Tag>
                <Tag color="#ffc300" style={{ float: 'right', width: '80px' }}>Zurückgestellt: {counts['zurückgestellt']}</Tag>
                <Tag style={{ float: 'right', width: '80px' }} >Kein Status: {counts['noStatus']}</Tag>
            </>
        );
    }

    const panels = [];
    data.forEach((project) => {
        project.showComments = false;
        const panel = panels.find((p) => p.header === project.cropName);
        if (panel) {
            console.log(project)
            panel.children.push(project);
            if (project.statusFG == null || project.statusFG == "") {
                panel.counts['kein']++;
            } else if (project.statusFG.toLowerCase() == 'ok') {
                panel.counts['ok']++;
            } else if (project.statusFG.toLowerCase() == 'abgelehnt') {
                panel.counts['abgelehnt']++;
            } else if (project.statusFG.toLowerCase() == 'zurückgestellt') {
                panel.counts['zurückgestellt']++;
            }
        } else {
            panels.push({
                header: project.cropName,
                cropId: project.cropId,
                key: project.cropName,
                children: [project],
                counts: { ok: 0, abgelehnt: 0, zurückgestellt: 0, kein: 0 }
            });
        }
    });

    const keys = panels.map((p) => p.key);


    return (
        <>

            <Collapse defaultActiveKey={keys} style={{ borderLeft: 'none', borderRight: 'none' }} extra={
                <Button type='primary' onClick={() => refetch()}>Refresh</Button>
            }>
                {panels.map((panel) => (
                    <Collapse.Panel {...panel}  header={
                        <>
                            <b>{panel.header}</b>
                            {/* <span style={{ float: 'right' }}>
                                {panel.counts['ok'] ? `Ok: ${panel.counts['ok']}` : null}
                                {panel.counts['abgelehnt'] ? `Rejected: ${panel.counts['abgelehnt']}` : null}
                                {panel.counts['zurückgestellt'] ? `On Hold: ${panel.counts['zurückgestellt']}` : null}
                                {panel.counts['kein'] ? `Kein: ${panel.counts['kein']}` : null}
                            </span> */}
                        </>}>

                        <FgBudgetReview cropId={panel.cropId} />
                        <br></br>
                        <Title level={5} style={{ backgroundColor: '#ccc', color: 'black', padding: '10px' }}>
                            Projekte Evaluation
                        </Title>
                        {panel.children.map((item, index) => (
                            <>

                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <>
                                                <b><Link
                                                    to={{
                                                        pathname: `/project/detail/${item.projectId}`,
                                                        state: {
                                                            id: item.projectId,
                                                        }
                                                    }}> {item.projectNumber} - {item.projectTitle} </Link></b>
                                                {/* <CommentList /> */}
                                                {item.valid && <FgReviewModal projectDetailId={item.projectDetailId} refetch={refetch} comment={item.commentFG} approvedAmount={item.amountFG} approvalStatus={item.statusFG} projectNumber={item.projectNumber} />}
                                                {getStatusTag(item.statusFG)}
                                            </>
                                        }

                                        description={
                                            <Descriptions span={3} size='small' bordered style={{ borderLeft: 'none', borderRight: 'none' }}>
                                                <Descriptions.Item labelStyle={{ width: 170 }} span={3} label="Züchter">{item.breederName}</Descriptions.Item>
                                                <Descriptions.Item span={3} label="Kulturart">{item.cropName}</Descriptions.Item>
                                                <Descriptions.Item span={1} label="Betrag"><b>{formatCurrency(item.amount)}</b></Descriptions.Item>
                                                <Descriptions.Item span={2} labelStyle={{ width: 150 }} style={{ width: 150 }} label="Betrag FG"><b>{formatCurrency(item.amountFG)}</b></Descriptions.Item>
                                                <Descriptions.Item span={3} label="Kommentar FG leiter" style={{ backgroundColor: '#fffceb' }}>
                                                    {item.commentFG}
                                                </Descriptions.Item>
                                                <Descriptions.Item span={3} label="Kommentar" style={{ backgroundColor: '#f6fff8' }}>
                                                    <CommentList currentUser={currentUser} itemId={item.projectDetailId} />
                                                </Descriptions.Item>
                                            </Descriptions>
                                        }
                                    />
                                </List.Item>
                            </>
                        ))}
                    </Collapse.Panel>
                ))}
            </Collapse>


        </>
    );
}

export default FgReviewProjectList
