import {
    DataGrid, useGridApiContext, deDE, useGridApiRef, GridToolbarContainer, GridRowModes,
    GridActionsCellItem,
} from '@mui/x-data-grid';
import { NumericFormat } from 'react-number-format';
import { styled } from '@mui/material/styles';


import { Button, Select, Card, Descriptions, Input, Space, message, InputNumber, Divider, Popconfirm } from 'antd';
import React from 'react'
import { useEffect, useState, useMemo, useRef, useLayoutEffect } from 'react'
import { currencyFormatter, currencyParser, formatCurrency } from '../util/Helpers';
import { DeleteOutlined, PlusCircleOutlined, SaveOutlined, PlusOutlined } from '@ant-design/icons';
import { deleteFgCalculationById, getFgAccountingItems, getFgBudgetCalculations, getProjectDetailAccountingItems, updateFgCalculationItem } from '../util/APIUtils';
import { Box } from '@mui/system';
import { CheckBoxOutlineBlankTwoTone, CheckBoxOutlined } from '@mui/icons-material';
import SelectEditInputCell from '../common/SelectEditInputCell';

const useFakeMutation = () => {
    return;
};



const EditableGridFg = (props) => {
    const [loading, setLoading] = useState(false);
    const projectId = props.projectId;
    const projectDetailId = props.projectDetailId;
    const isPlanEditable = props.isPlanEditable;
    const isReportEditable = props.isReportEditable;
    const [data, setData] = useState(props.data || []);
    const [budgetTotal, setBudgetTotal] = useState(0);
    const [actualTotal, setActualTotal] = useState(0);
    const [snackbar, setSnackbar] = React.useState(null);
    const year = props.year;
    const fgBudgetId = props.fgBudgetId;
    const accountingItems = props.accountingItems;

    console.log(isPlanEditable)
    console.log(isReportEditable)

    const messagesRef = React.useRef(null);

    const scrollToBottom = () => {
        if (messagesRef.current) {
            messagesRef.current.scrollIntoView({
                behavior: "smooth",
      block: "nearest",
      inline: "start"
              });
          }
       
      };

    const loadFgCalculations = React.useCallback(async () => {
        let promise;
        promise = getFgBudgetCalculations(fgBudgetId);

        if (!promise) {
            return;
        }
        setLoading(true);
        promise.then(response => {
            response.push(createSummaryData(response));
            setData(response);
        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, [])

    useEffect(() => {
        loadFgCalculations();
        scrollToBottom();
    }, [loadFgCalculations, messagesRef]);

    const createSummaryData = (response) => {
        let summaryRow = createNewRow("sum")
        summaryRow.accountingItemName = "Gesamt"
        summaryRow.budgetRowTotal = response.reduce((a, b) => a + (b['budgetRowTotal'] || 0), 0).toFixed(2)
        summaryRow.actualRowTotal = response.filter(a => a.selected == true).reduce((a, b) => a + (b['actualRowTotal'] || 0), 0).toFixed(2)
        summaryRow.daysWorkerBudget = response.reduce((a, b) => a + (b['daysWorkerBudget'] || 0), 0).toFixed(2)
        summaryRow.daysWorkerActual = response.reduce((a, b) => a + (b['daysWorkerActual'] || 0), 0).toFixed(2)
        return summaryRow;
    }

    const mutateRow = useFakeMutation();

    const CustomToolbar = () => {
        const apiRef = useGridApiContext();

        const handleSave = () => console.log(apiRef.current.getRowModels());

        if (isReportEditable || isPlanEditable) {
            return (
                <GridToolbarContainer >
                    <Space>
                        <Button onClick={handleAddRow} style={{ marginBottom: "10px" }} icon={<PlusOutlined />}>

                        </Button>
                        {/* <Button style={{ marginBottom: "10px" }} onClick={handleSave} icon={<SaveOutlined />}></Button> */}
                    </Space>
                </GridToolbarContainer>
            );
        }
        else {
            return null
        }
    };

    const getAccountingItemIdForDesc = (desc) => {
        let item = accountingItems.find(x => x.description === desc) || null;
        if (item) {
            return item.id;
        } else {
            return;
        };
    }

    function CurrencyInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = async (event) => {
            await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
        };

        return (
            <NumericFormat
                style={{ height: "100px", minWidth: "50px", border: "0px", borderColor: "green" }}
                autoFocus
                align='right'
                value={value} decimalSeparator="," onChange={handleChange} />
        )
    }

    const handleParentClick = (e) => {
        e.stopPropagation();
    }


    const renderSelectEditInputCell = (params) => {
        return (<div style={{ minWidth: "400px", borderWidth: "0px" }} onClick={handleParentClick}>
            <SelectEditInputCell {...params} items={accountingItems} itemName='description' apiRef={useGridApiContext()} />
        </div>);
    };

    const renderCurrencyInputCell = (params) => {
        return <CurrencyInputCell {...params} />;
    };

    const renderCustomToolbar = (params) => {
        return <CustomToolbar {...params} />;
    };

    const createNewRow = (type) => {
        return {
            id: type == "sum" ? 108080 : 0,
            accountingItemName: "",
            budgetRowTotal: 0,
            actualRowTotal: 0,
            daysWorkerActual: 0,
            daysWorkerBudget: 0,
            isNew: true,
            fgBudgetId: fgBudgetId,
            budget: isPlanEditable,
            actual: isReportEditable,
            rowType: type
        }
    }

    const deleteSum = (arr) => {
        let localArr = [...arr];
        localArr.splice(localArr.findIndex(function (i) {
            return i.id === 108080;
        }), 1);
        return localArr;
    }

    const handleAddRow = () => {
        setData((prevRows) => [...deleteSum(prevRows)]);
        setData((prevRows) => [...prevRows, createNewRow(null), createSummaryData(prevRows)]);
    };

    const renderDeleteButton = (params) => {

        const onCancel = () => {
            return
        }

        const onOk = () => {
            setLoading(true)

            let promise = deleteFgCalculationById(params.row.id);
            if (!promise) {
                return;
            }

            promise.then(response => {
                setLoading(false);
                loadFgCalculations();


            }).catch(error => {
                message.error("Failed")
            });

        }


        if (params.row.id === 0 && params.row.accountingItemName == "") {
            return ""
        }

        if (params.row.rowType === "sum") {
            return ""
        } else {
            return (
                <strong>

                    {(params.row.budget && isPlanEditable) || (params.row.actual && isReportEditable) ?
                        <Popconfirm
                            title="Löschen bestätigen?"
                            onConfirm={onOk}
                            onCancel={onCancel}
                            okText="Ok"
                            cancelText="Abbrechen"
                        >
                            <Button danger icon={<DeleteOutlined />}
                                size="small"
                                style={{ marginLeft: 16 }}
                            // onClick={onOk}
                            >
                            </Button>
                        </Popconfirm> : ""}

                </strong>
            )
        }

    }

    const renderActualSelected = (row) => {
        if (row.row.accountingItemName === 'Gesamt') {
            return "";
        } else {
            return row.row.selected ? <CheckBoxOutlined /> : <CheckBoxOutlineBlankTwoTone />
        }
    }


    const columnGroupingModel = [
        {
            groupId: 'Züchter',
            description: '',
            headerAlign: 'center',
            children: [{ field: 'daysWorkerBudget' }, { field: 'daysWorkerActual' }],
        },
        {
            groupId: 'Gesamt',
            description: '',
            headerAlign: 'center',
            children: [{ field: 'budgetRowTotal' }, { field: 'actualRowTotal' }],
        },
    ];


    const columns = [
        { field: 'accountingItemId', headerName: 'Id', hide: true },
        { field: 'selected', headerName: 'Abrechnung', minWidth: 20, editable: true, type: 'boolean', flex: 1, hide: isPlanEditable, renderCell: renderActualSelected },
        { field: 'accountingItemName', headerName: 'Tätigkeiten', minWidth: 400, editable: true, renderEditCell: renderSelectEditInputCell, flex: 1 },
        {
            field: 'daysWorkerBudget',
            headerName: 'Kalkulation',
            valueFormatter: ({ value }) => currencyFormatter(value),
            // valueGetter: ({ value }) => currencyParser(value),
            renderEditCell: renderCurrencyInputCell,
            editable: isPlanEditable,
            headerAlign: 'right',
            align: 'right',
            minWidth: 50,
            flex: 1
        },
        {
            field: 'daysWorkerActual',
            headerName: 'Abrechnung',
            valueFormatter: ({ value }) => currencyFormatter(value),
            renderEditCell: renderCurrencyInputCell,
            // type: 'number',
            minWidth: 50,
            flex: 1,
            editable: isReportEditable,
            headerAlign: 'right',
            align: 'right'
        },
        {
            field: 'budgetRowTotal',
            headerName: 'Kalkulation',
            valueFormatter: ({ value }) => formatCurrency(value),
            // renderEditCell: renderCurrencyInputCell,
            type: 'number',
            minWidth: 70,
            flex: 1,
            // editable: true,
            headerAlign: 'right',
            align: 'right',
            // valueGetter: getTotalBudget,
            // valueSetter: setTotalBudget,
            // valueParser: parseTotalBudget
        },
        {
            field: 'actualRowTotal',
            headerName: 'Abrechnung',
            valueFormatter: ({ value }) => formatCurrency(value),
            // renderEditCell: renderCurrencyInputCell,
            type: 'number',
            minWidth: 70,
            flex: 1,
            // editable: true,
            headerAlign: 'right',
            align: 'right',
            // valueGetter: getTotalActual,
            // valueSetter: setTotalBudget,
            // valueParser: parseTotalBudget
        },

        {
            field: 'action',
            headerName: 'Action',
            // renderEditCell: renderCurrencyInputCell,
            minWidth: 30,
            flex: 1,
            // editable: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: renderDeleteButton,
            disableClickEventBubbling: true,

        },
    ];

    const processRowUpdate = React.useCallback(
        async (newRow) => {
            newRow.customItem = newRow.accountingItemName ? newRow.accountingItemName : null;
            newRow.customType = newRow.customItem ? true : false;
            if (newRow.customType) {
                newRow.accountingItemId = 0;
            } else {
                newRow.accountingItemId = getAccountingItemIdForDesc(newRow.accountingItemName)
            }

            newRow.daysWorkerBudget = currencyParser(currencyFormatter(newRow.daysWorkerBudget));
            newRow.daysWorkerActual = currencyParser(currencyFormatter(newRow.daysWorkerActual));
            newRow.fgBudgetId = fgBudgetId;

            let promise = updateFgCalculationItem(newRow);
            if (!promise) {
                return;
            }
            const updatedRow = { ...newRow, isNew: false };
            promise.then(response => {

                setData(data.map((row) => (row.id === newRow.id ? updatedRow : row)));
                response.push(createSummaryData(response))
                setData(response);

            }).catch(error => {
                message.error("project update failed")
            });

            return updatedRow;


            // Make the HTTP request to save in the backend
            // const response = await mutateRow(newRow);
            return response;
        },
        [mutateRow],
    );

    const handleProcessRowUpdateError = React.useCallback((error) => {
        // setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const checkEditable = (param) => {
        console.log(param)
        console.log(isPlanEditable)

        if (param.row.rowType === "sum") {
            return false;
        }
        if (param.colDef.field === "accountingItemName" && param.row.budget && isPlanEditable) {
            return true;
        }

        if (param.colDef.field === "accountingItemName" && param.row.actual && isReportEditable) {
            return true;
        }

        if (isPlanEditable && ['daysWorkerBudget'].includes(param.colDef.field)) {
            return true;
        }

        if (isReportEditable && ['selected', 'daysWorkerActual'].includes(param.colDef.field)) {
            return true;
        }

        return false;

    }

    function CustomFooter(props) {
        return (
            <Box sx={{ fontWeight: "600", border: `1px solid #f0f0f0`, backgroundColor: "#f1f7ee", padding: "0px", display: "flex", justifyContent: "right", alignItems: "right" }}>
                {/* <TableRow sx={{ width: "100%", border: "1px solid"}}>
        <TableCell sx={{ minWidth: 400, flex: 1,  padding: "0, 10" }}>Dessert (100g serving)</TableCell>
        <TableCell sx={{ minWidth: 100 ,  padding: "0, 10", flex: 1}} align="right">10</TableCell>
        <TableCell sx={{ minWidth: 100 ,  padding: "0, 10", flex: 1}} align="right">10</TableCell>
        <TableCell sx={{ minWidth: 100 ,  padding: "0, 10", flex: 1}} align="right">10</TableCell>
        <TableCell sx={{ minWidth: 100 ,  padding: "0, 10", flex: 1}} align="right">10</TableCell>
        <TableCell sx={{ minWidth: 100 ,  padding: "0, 10", flex: 1}} align="right">10</TableCell>
        <TableCell sx={{ minWidth: "70px" }} align="right"></TableCell>
        <TableCell sx={{ minWidth: "30px" }} align="right"></TableCell>
      </TableRow> */}
            </Box>)
        // return (
        //     <Box sx={{ fontWeight: "600", border: `1px solid #f0f0f0`, backgroundColor: "#f1f7ee", padding: "10px", display: "flex", justifyContent: "right", alignItems: "right" }}>Gesamt Kalkulation : {formatCurrency(props.budgetTotal)} | Gesamt Abrechnung : {formatCurrency(props.actualTotal)}</Box>
        // );
    }

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        border: 1,
        color:
            theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            // backgroundColor: '#ccc',
            border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnHeader': {
            height: 22,
            backgroundColor: '#f1f7ee',
        },
        '& .MuiDataGrid-cell': {

            color:
                theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
        },
        '& .MuiPaginationItem-root': {
            borderRadius: 0,
        },
        '& .sum-row-sum': {
            bgcolor: (theme) =>
                getBackgroundColor(theme.palette.success.main, theme.palette.mode),
            '&:hover': {
                bgcolor: (theme) =>
                    getHoverBackgroundColor(
                        theme.palette.success.main,
                        theme.palette.mode,
                    ),
            },
        },

    }));

    return (

        <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }} ref={messagesRef}>
                {/* <Space direction='vertical'> */}

                <br></br>

                <Box
                    sx={{
                        width: '100%',
                        '& .budget': {
                            backgroundColor: '#dde5b6',
                            color: 'e8e8e4',
                        },
                        '& .actual': {
                            backgroundColor: '#fae1ca',
                            color: '#1a3e72',
                        },
                        '& .actual-selected': {
                            backgroundColor: 'white',
                            color: 'green !important',
                        },
                        '& .actual-not-selected': {
                            // backgroundColor: '#fae1ca',
                            color: 'red !important',
                        },
                        '& .sum-row': {
                            backgroundColor: '#f1f7ee ',
                            // color: '#1a3e72',
                            fontWeight: 800
                        },
                    }}
                >
                    <StyledDataGrid
                        disableColumnFilter
                        autoHeight
                        rowHeight={27}
                        headerRowHeight={27}
                        rows={data}
                        columns={columns}
                        experimentalFeatures={{ newEditingApi: true, columnGrouping: true }}
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        // hideFooter
                        columnGroupingModel={columnGroupingModel}
                        processRowUpdate={processRowUpdate}
                        disableColumnMenu
                        isCellEditable={checkEditable}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        getRowClassName={(params) => {
                            if (params.row.rowType === "sum") {
                                return 'sum-row'
                            }
                        }}
                        getCellClassName={(params) => {
                            if (['daysWorkerBudget'].includes(params.field)) {
                                return 'budget';
                            } else if (['daysWorkerActual'].includes(params.field)) {
                                return 'actual'
                            }
                            if (params.field == "actualRowTotal") {
                                if (params.row.selected) {
                                    return 'actual-selected'
                                } else {
                                    if (params.row.rowType === "sum") {
                                        return
                                    }
                                    return 'actual-not-selected'
                                }
                            }

                        }}
                        components={{
                            Toolbar: renderCustomToolbar,
                            Footer: CustomFooter
                        }}
                        componentsProps={{
                            footer: { budgetTotal, actualTotal }
                        }}
                    />
                </Box>

                {/* </Space> */}
            </div>
        </div>

    )
}

export default EditableGridFg