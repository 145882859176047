import React, { Component } from 'react'
import {
    Card, notification, Menu, Breadcrumb,
    PageHeader, Form, Input, InputNumber,
    Cascader, Select, Row, Col, Checkbox,
    Button, AutoComplete, Space
} from 'antd';
// import {PlaceSearch} from '../common/PlaceSearch';
import { withRouter } from 'react-router-dom';
import { getBreeders, createCrop, getAllCrops } from '../util/APIUtils';
import { withTranslation } from "react-i18next";
import { POLL_LIST_SIZE } from '../constants'
import './Crops.css';


const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 8,
    },
};
const formItemLayout = {
    labelCol: {
        span: 8
    },
    wrapperCol: {
        span: 8
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        offset: 8,
        span: 8,
    },
};

class NewCrop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breeders: [],
            isLoading: false,
            visible: false,
            cropGroups: [],
        };
    }

    loadCropGroups() {
        let promise;
        promise = getAllCrops();

        if (!promise) {
            return;
        }

        this.setState({ isLoading: false })
        promise.then(response => {
            this.setState({
                cropGroups: response.filter(x => {
                    return x.isGroup == 1
                })});
                this.setState({ isLoading: false })

        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    loadBreederList() {
        let promise;
        promise = getBreeders();

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });

        promise.then(response => {
            console.log(response);
            this.setState({
                breeders: response,
                // page: response.page, size: response.size, totalElements:
                // response.totalElements, totalPages: response.totalPages, last: response.last,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    componentDidMount() {
        this.loadBreederList();
        this.loadCropGroups();
    }

    componentDidUpdate(nextProps) {
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                breeders: [],
                // page: 0, size: 10, totalElements: 0, totalPages: 0, last: true,
                isLoading: false
            });
            this.loadBreederList();
        }
    }

    handleBack = e => {
        this
            .props
            .history
            .goBack();
    };

    formRef = React.createRef();

    onFinish = (values) => {
        const cropObj = {

            "shortName": values.shortName,
            "name": values.name,
            "groupName": values.groupName,
            "anniversary": values.anniversary,
            "fgLeaderId": values.fgLeaderId || 0,
            "createFg": 0        }
        console.log(cropObj);
        createCrop(cropObj)
            .then(response => {
                notification.success({
                    message: 'Kultursaat',
                    description: response
                });
                this.props.history.push("/crops");
            }).catch(error => {
                if (error.status === 401) {
                    this.props.handleLogout('/login', 'error', 'You have been logged out. Please login create poll.');
                } else {
                    notification.error({
                        message: 'Kultursaat',
                        description: error.message || 'Sorry! Something went wrong. Please try again!'
                    });
                }
            });


    };
    onReset = () => {
        this.formRef.current.resetFields();
    };


    render() {
        const { t } = this.props;
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    onBack={() => this.handleBack()}
                    title={t("crops.new_crop")}
                    subTitle=""
                    extra={[]
                    }
                />
                <Card bordered={true} loading={this.state.isLoading} >
                    <Form {...layout} ref={this.formRef} name="control-ref" onFinish={this.onFinish}>
                        <Form.Item name="shortName" label="Kultur-Kürzel" rules={[{ required: true }]}>
                        <Input onInput={e => e.target.value = e.target.value.toUpperCase()} maxLength={4} />
                        </Form.Item>
                        <Form.Item name="name" label={t("crops.crop_name")} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="groupName" label={t("crops.crop_group")} rules={[{ required: true }]}>
                        <Select style={{ width: "250px" }}  >
                            {this.state.cropGroups.map(fbb =>
                                <option key={fbb.id} value={fbb.id}>{fbb.name}</option>
                            )}
                        </Select>
                        </Form.Item>
                        <Form.Item name="anniversary" label={t("crops.harvest_interval")} rules={[{ required: false }]}>
                            <InputNumber min={1} max={10} defaultValue={1}/>
                        </Form.Item>
                        <Form.Item name="fgLeaderId" label={t("fachgroup.fg_leader")} rules={[{ required: false }]}>
                            <Select >

                                {this.state.breeders.map(fbb =>
                                    <option key={fbb.id} value={fbb.id}>{fbb.firstName + " " + fbb.lastName}</option>
                                )}
                            </Select>
                        </Form.Item>
                        {/* <Form.Item name="createFg" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                            <Checkbox>{t("fachgroup.create_fg_auto")}</Checkbox>
                        </Form.Item> */}

                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                            {t("general.submit")}
                            </Button>
                            <Button htmlType="button" onClick={this.onReset}>
                            {t("general.reset")}
                            </Button>
                        </Form.Item>
                    </Form>

                </Card>
            </div>
        )
    }
}

export default withTranslation()(withRouter(NewCrop));
