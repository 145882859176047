import React from 'react'
import { Collapse, List, Spin, Divider } from 'antd';
import { CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import { getFgValidationResultsForBreeder, getValidationResults, getValidationResultsForProject } from '../util/APIUtils';
import PlanValidation from './PlanValidation';
import ReportValidation from './ReportValidation';
import FgBudgetPlanValidation from './FgBudgetPlanValidation';
import FgBudgetReportValidation from './FgBudgetReportValidation';
const { Panel } = Collapse;

function FgBudgetValidation(props) {
    const [loading, setLoading] = React.useState(false);
    const [validationResults, setValidationResults] = React.useState(null);
    const [planValidation, setPlanValidation] = React.useState(null);
    const [reportValidation, setReportValidation] = React.useState(null);

    console.log(props)

    const onChange = (key) => {
        // console.log(key);
    };

    const validateFgBudget = React.useCallback(async () => {
        let promise;
        setLoading(true)
        promise = getFgValidationResultsForBreeder(props.breederId);
        if (!promise) {
            return;
        }

        promise.then(response => {
            setValidationResults(response);
            setPlanValidation(response.fgPlanValidation)
            setReportValidation(response.fgReportValidation)
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, [])

    React.useEffect(() => {
        validateFgBudget()
    }, [validateFgBudget]);

    const getIcon = (val) => {
        return val ? <CheckCircleFilled style={{ fontSize: '16px', color: "#52c41a" }} /> :
            < WarningFilled style={{ fontSize: '16px', color: "orange" }} />
    }

    const getEmptyItem = (type) => {
        if (type == "accounting") {
            return [{ displayName: "FG Kalkulation - Tätigkeiten", status: planValidation?.accountingValidationStatus, message: "At least one item required" }]
        } else if (type == "examination") {
            return [{ displayName: "Kalkulation - Untersuchungen", status: planValidation?.examinationValidationStatus, message: "At least one item required" }]
        } else if (type == "lines") {
            return [{ displayName: "mindestens 1 Position muss angegeben werden", status: planValidation?.lineValidationStatus, message: "At least one item required" }]
        }

    }

    return (
        <div>
            {validationResults ?
                <Collapse defaultActiveKey={['1']} onChange={onChange}>
                    {planValidation &&
                        <Panel header={"FG Plan - " + validationResults?.planYear} key="plan-fg" extra={getIcon(validationResults?.currentYearPlanValidationStatus)} >
                            <FgBudgetPlanValidation planValidationInfo={planValidation} getEmptyItem={getEmptyItem} getIcon={getIcon} loading={loading} />
                        </Panel>
                    }

                    {reportValidation &&
                        <Panel header={"FG Bericht - " + validationResults?.reportYear} key="report-fg" extra={getIcon(validationResults?.lastYearReportValidationStatus)}>
                            <FgBudgetReportValidation reportValidationInfo={reportValidation} getEmptyItem={getEmptyItem} getIcon={getIcon} loading={loading}  />
                        </Panel>}

                </Collapse>
                : <Spin></Spin>
            }
        </div>
    )
}

export default FgBudgetValidation