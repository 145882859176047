import React from 'react'
import moment from 'moment'
import { Descriptions, Tag, Form, Empty, Input, Checkbox, Select, InputNumber, Button, DatePicker, Space, notification, Divider, Row, Col, List, Badge, Popconfirm, Modal } from 'antd'
import { useTranslation } from 'react-i18next';
import { getProjectReport, getLineSelections, saveProjectReport, getBreedingMethods, createReportYear } from '../../util/APIUtils';
import { useEffect, useState } from 'react'

import './Report.css'
import { getTextArtva } from '../../util/Helpers';
import LineSelectionLookup from '../../common/LineSelectionLookup';
import Satz from '../../satz/Satz';

function ReportVa(props) {
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = React.useState(false);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [canceled, setCanceled] = useState(props.projectDetailInfo.projectReportInfo.canceled ? 1 : 0)
    const [state, setState] = useState(props.projectDetailInfo.projectReportInfo)
    const projectInfo = props.projectInfo;
    const projectDetailInfo = props.projectDetailInfo;
    const cropId = props.projectInfo.cropId;
    const editable = props.isReportEditable
    const projectType = props.projectInfo.projectType;
    const { t } = useTranslation();
    const [form] = Form.useForm();

    let cultivars = state.cultivars.map(a => a.name);
    let referenceGrades = state.referenceGrades.map(a => a.name);

    console.log(props)

    const confirm = () => {
        Modal.confirm({
          width: 1200,
          content: <Satz 
          satzType="report"
          projectDetailId={props.projectDetailInfo.id}
          isPlanEditable={props.isPlanEditable}
          isReportEditable={props.isReportEditable}
      />,
          cancelText: 'Schließen',
        });
      };

    const setEditingLocal = (val) => {
        setEditing(val);
        props.setEditingMaster(val);
        if (!val) {
            props.reloadProject(screenState)
        }
    }

    const screenState = {
        "year": props.year,
        "tab": '3'
    }

    const onChange = e => {
        setCanceled(e);
    };

    let getReport = React.useCallback(async () => {
        let promise;
        promise = getProjectReport(projectDetailInfo.id);
        if (!promise) {
            return;
        }

        promise.then(response => {
            setState(response);
            setCanceled(response.canceled)
            setLoading(false)

        }).catch(error => {
            setLoading(false)
        });
    }, [])

    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeOther = name => value => {

        // if (name === 'referenceGrades') {
        //     value = value.length > 0 ? value.map(elem => ({ id: parseInt(elem) })) : state.referenceGrades;
        // } else
        //  if (name === 'cultivars') {
        //     value = value.length > 0 ? value.map(elem => ({ id: parseInt(elem) })) : state.cultivars;
        // } else 
        if (name === "ks_plan") {
            value = value.target.checked ? 1 : 0;
        }
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        getReport();
        // fetchLines();
    }, [getReport]);

    const mapObjToReport = (values, type) => {

        // cultivationOperation
        // description
        //referenceGrades
        // cultivars
        // noOfLines
        // numberOfRepetitions
        // toPlant
        // rowSpacing
        // distanceInLine
        // culturalTour
        // deviationPlan
        // results
        // CommentMibetrBSAG


        let planObj = {}

        planObj = {
            canceled: canceled,
            cultivationOperation: values.cultivationOperation,
            description: values.description,
            referenceGrades: state.referenceGrades,
            cultivars: state.cultivars,
            // referenceGrades: type === "form" ? state.referenceGrades : state.referenceGrades.map(elem => (
            //     elem.id
            // )),
            // cultivars: type === "form" ? state.cultivars : state.cultivars.map(elem => (
            //     elem.id
            // )),
            noOfLines: values.noOfLines,
            numberOfRepetitions: values.numberOfRepetitions,
            toPlant: values.toPlant,
            rowSpacing: values.rowSpacing,
            distanceInLine: values.distanceInLine,
            culturalTour: values.culturalTour,
            deviationPlan: values.deviationPlan,
            results: values.results,
            commentMibetrBSAG: values.commentMibetrBSAG,
            id: state.id,
            projectId: props.projectInfo.projectId,
            projectDetailId: props.projectDetailInfo.id,
            projectNumber: props.projectInfo.projectNumber
        }
        return planObj;
    }

    const onFinish = (values) => {
        setLoading(true);

        let promise;
        promise = saveProjectReport(mapObjToReport(values, "form"));
        if (!promise) {
            return;
        }

        promise.then(response => {
            setLoading(false)

            props.reloadProject(screenState);
            notification.success({ message: "Kultursaat", description: "Bericht aktualisiert!", placement: 'topLeft' })
        }).catch(error => {
            console.log(error)
            setLoading(false)
            notification.error({ message: "Kultursaat", description: "Error saving report", placement: 'topLeft' })
        });
    };

    // useEffect(() => form.resetFields(), [props.projectReportInfo]);

    const initVals = mapObjToReport(state, "obj");
    console.log(initVals)

    const getCanceledBlock = () => {
        return (
            <>
                <Descriptions.Item label={t("project_report.the_project_was")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start', width: '300px' }} >
                    {editing && editable ?
                        <Form.Item name="canceled">
                            <Space>
                                <Select style={{ width: 250 }} onChange={onChange} defaultValue={canceled}>
                                    <Option value={0}>{t("project_report.completed")}</Option>
                                    <Option value={1}>{t("project_report.not_completed")}</Option>
                                </Select>
                            </Space>
                        </Form.Item> :

                        !state.canceled ?
                            <Tag color="#87d068">{t("project_report.completed")}</Tag> :
                            <Tag color="#f50">{t("project_report.not_completed")}</Tag>}
                </Descriptions.Item>
            </>
        )
    }

    const getReportBlock = () => {
        return (
            <>
                <Descriptions.Item span={3} label={t("project_details.what_cultivation")} labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {getTextArtva(projectDetailInfo.artva)}
                </Descriptions.Item>
                {projectDetailInfo.artva == 3 &&
                    <Descriptions.Item span={3} label="übergeordneten projekt" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                        {projectDetailInfo.parentNo + " - " + projectDetailInfo.parentProjectTitle}
                    </Descriptions.Item>
                }
                <Descriptions.Item label="Anbaubetrieb" span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                    {projectDetailInfo.cultivationCompany}
                </Descriptions.Item>

                <Descriptions.Item label={t("project_details.description")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                    {editing && editable ?
                        <Form.Item
                            name="description"
                            hasFeedback
                        >
                            <Input.TextArea autoSize name="description" value={state["description"]} onChange={handleChange} />
                        </Form.Item>
                        : state.description}
                </Descriptions.Item>

                <Descriptions.Item label="Zuchsorten/Linien" labelStyle={{ fontWeight: 'bold' }} span={3}>
                    {editing && editable ?
                        <Form.Item
                            name="cultivars"
                            hasFeedback
                        >
                            <LineSelectionLookup
                                cropId={cropId}
                                setItems={handleChangeOther("cultivars")}
                                existingItems={state.cultivars}
                                setFormattedItems={handleChangeOther("cultivars")}
                            />
                        </Form.Item>
                        :
                        <List
                            size="small"
                            dataSource={cultivars}
                            renderItem={item => <List.Item style={{ padding: 2 }}><Badge color={'geekblue'} text={item} /></List.Item>}
                        />}
                </Descriptions.Item>

                <Descriptions.Item label="Referenzsorten" labelStyle={{ fontWeight: 'bold' }} span={3}>
                    {editing && editable ?
                        <Form.Item
                            name="referenceGrades"
                            hasFeedback
                        >
                            <LineSelectionLookup
                                cropId={cropId}
                                setItems={handleChangeOther("referenceGrades")}
                                existingItems={state.referenceGrades}
                                setFormattedItems={handleChangeOther("referenceGrades")}
                            />
                        </Form.Item>
                        :
                        <List
                            size="small"
                            dataSource={referenceGrades}
                            renderItem={item => <List.Item style={{ padding: 2 }}><Badge color={'geekblue'} text={item} /></List.Item>}
                        />}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Anbaummfang" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {state["cultivationScope"] + " " + state["cultivationScopeUnit"]}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Anz Sorten/Linien" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="noOfLines"
                            hasFeedback
                        >
                            <InputNumber />
                        </Form.Item>
                        : state["noOfLines"]}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Anz Feldwiederh" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="numberOfRepetitions"
                            hasFeedback
                        >
                            <InputNumber />
                        </Form.Item>
                        : state["numberOfRepetitions"]}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Anz Pflanzen" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="toPlant"
                            hasFeedback
                        >
                            <InputNumber />
                        </Form.Item>
                        : state["toPlant"]}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Reihenabstand" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="rowSpacing"
                            hasFeedback
                        >
                            <InputNumber />
                        </Form.Item>
                        : state["rowSpacing"]}
                </Descriptions.Item>


                <Descriptions.Item span={3} label="Abstand in der Reihe" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="distanceInLine"
                            hasFeedback
                        >
                            <InputNumber />
                        </Form.Item>
                        : state["distanceInLine"]}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Bemerkungen" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="culturalTour"
                            hasFeedback
                        >
                            <Input.TextArea />
                        </Form.Item>
                        : state["culturalTour"]}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Abweichung von plan" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="deviationPlan"
                            hasFeedback
                        >
                            <Input.TextArea />
                        </Form.Item>
                        : state["deviationPlan"]}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Resultate" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="results"
                            hasFeedback
                        >
                            <Input.TextArea />
                        </Form.Item>
                        : state["results"]}
                </Descriptions.Item>

                <Descriptions.Item span={3} label="Kommentar Mitbetr BSAG" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    {editing && editable ?
                        <Form.Item
                            name="commentMibetrBSAG"
                            hasFeedback
                        >
                            <Input.TextArea />
                        </Form.Item>
                        : state["commentMibetrBSAG"]}
                </Descriptions.Item>

                <Descriptions.Item span={3} label={<Button type='primary' onClick={confirm}>Sätze</Button> } labelStyle={{ fontWeight: 'bold', width: 250 }}>
                    
                </Descriptions.Item>

               
            </>
        )
    }

    return (
        <div>
            <Form name="reports-form" form={form} onFinish={onFinish} initialValues={initVals}>
                <Descriptions
                    size="small"
                    extra=
                    {editable ?
                        !editing ?
                            <Space>
                                <Popconfirm
                                    title={"Möchten Sie den Bericht wirklich löschen??"}
                                    onConfirm={props.deleteReport}
                                >
                                    <Button type="danger" >Bericht löschen</Button>
                                </Popconfirm>

                                <Button onClick={() => setEditingLocal(true)}>Bearbeiten</Button> </Space> :
                            <Space>
                                <Button onClick={() => form.submit()} type='primary'>Speichern</Button>
                                <Button onClick={() => setEditingLocal(false)}>Abbrechen</Button>
                            </Space> : ""

                    }>
                </Descriptions>

                {
                    <Descriptions
                        size="small"
                        bordered
                    >
                        {getCanceledBlock()}
                        {!canceled && getReportBlock()}

                    </Descriptions>
                    
                    }
            </Form>
        </div>
    )
}

export default ReportVa
