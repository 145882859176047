import { Icon, Button, Input, AutoComplete, Select } from 'antd';
import React, { Component } from 'react';


const { Option } = Select;

class FachGroupSearch extends React.Component {

  constructor(props) {
    console.log(props)
    super(props);
    this.state = {
      dataSource: props.dataList,
      itemCount: props.dataCount,
      placeHolder: props.placeHolder,
      key: props.key
    };
  }

  render() {
    
        
    return (
      <div key={this.props.key} className="global-search-wrapper" style={{ width: 300 }}>

        <Select
          showSearch
          style={{ width: 300 }}
          placeholder={this.state.placeHolder}
          optionFilterProp="children"
          onSelect={this.props.onSelect}
        >
          {this.state.dataSource.map(fbb =>
            <Select.Option key={fbb.id} value={fbb.key}>{fbb.value}</Select.Option>
          )}
        </Select>
      </div>
    );
  }
}

export default FachGroupSearch;