import React, { useState } from 'react';
import { Modal, Button, Form, Select, InputNumber, Input, Descriptions } from 'antd';
import { updateBoardReview, updateFgReview } from '../util/APIUtils';
import CurrencyInputNumber from '../common/CurrencyInputNumber';
import { currencyFormatter, currencyParser, formatCurrency } from '../util/Helpers';

const { Option } = Select;

function BoardReviewModal(props) {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState(props.comment)
    const [approvalStatus, setApprovalStatus] = useState(props.approvalStatus)
    const [approvedAmount, setApprovedAmount] = useState(props.approvedAmount)


    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                console.log(values)

                let workFlowResponse = {
                    itemId: props.projectDetailId,
                    comment: values.comment,
                    status: values.approvalStatus,
                    approvedAmount: values.approvedAmount ? values.approvedAmount : approvedAmount
                }
                console.log(workFlowResponse)
                let promise;
                setLoading(true)
                form.setFieldsValue({
                    approvedAmount: values.approvedAmount,
                    comment: values.comment,
                });
                promise = updateBoardReview(workFlowResponse);
                if (!promise) {
                    return;
                }

                promise.then(response => {
                    setLoading(false)
                    setVisible(false);
                    props.refetch()

                }).catch(error => {
                    setLoading(false)
                });
            })
            .catch((info) => {
                console.error('Validate Failed:', info);
            });
    };

    const handleCancel = () => {
        setVisible(false);
    };

    // form.resetFields()

    const getStatusValue = (val) => {
        let st = null;
        switch (val) {
            case "abgelehnt":
                st = 0;
                break;
            case "ok":
                st = 1;
                break;

            case "zurückgestellt":
                st = -1;
                break;
            default:
                break;
        }

        return st
    }

    let initVals = {
        comment: props.comment,
        approvalStatus: getStatusValue(props.approvalStatus),
        approvedAmount: props.approvedAmount
    }

    const updateAmount = (val) => {
        if (val != 1) {
            form.setFieldValue({
                approvedAmount: 0
            })
        }
    }

    return (
        <>
            <Button size='small' style={{ float: 'right' }} type="primary" onClick={showModal}>
                Evaluieren
            </Button>
            <Modal
                forceRender
                style={{ top: 20 }}
                title={"Evaluation - " + props.projectNumber}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={loading}
            >
                <Form form={form} initialValues={initVals}
                    onValuesChange={(changedValues, allValues) => {

                        if (changedValues.hasOwnProperty('approvalStatus') && changedValues.approvalStatus != '1') {
                            form.setFieldsValue({ approvedAmount: 0 });
                        }
                    }}>
                    <Descriptions size='small' bordered>
                    <Descriptions.Item span={3} label="Projekt Betrag"><b>{formatCurrency(props.projectAmount)}</b></Descriptions.Item>
                    <Descriptions.Item span={3} label="FG Status" ><b>{props.statusFg}</b></Descriptions.Item>
                    <Descriptions.Item span={3} label="FG Kommentar" >{props.commentsFg}</Descriptions.Item>
                    <Descriptions.Item span={3} label="FG Betrag" ><b>{formatCurrency(props.amountFg)}</b></Descriptions.Item>
                        <Descriptions.Item span={3} label="VS Status" >
                            <Form.Item
                                name="approvalStatus"
                                rules={[{ required: true, message: 'Pflichtfeld' }]}
                            >
                                <Select onChange={setApprovalStatus}>
                                    <Option value={99}> </Option>
                                    <Option value={1}>ok</Option>
                                    <Option value={0}>abgelehnt</Option>
                                    <Option value={-1}>zurückgestellt</Option>
                                </Select>
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="VS Bemerkungen" span={3}  >
                            <Form.Item
                                name="comment"
                                rules={[{ required: true, message: 'Pflichtfeld' }]}
                            >
                                <Input.TextArea rows={4} onChange={setComment} />
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="VS Betrag" span={3} >
                            <Form.Item
                                name="approvedAmount"
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Pflichtfeld',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (getFieldValue('approvalStatus') === 1 && (!value || value <= 0)) {
                                                return Promise.reject('Der genehmigte Betrag muss größer als Null sein, wenn der Genehmigungsstatus "ok" ist');
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <InputNumber
                                    formatter={currencyFormatter}
                                    parser={currencyParser}
                                    onChange={setApprovedAmount}
                                />

                            </Form.Item>






                        </Descriptions.Item>
                    </Descriptions>
                </Form>
            </Modal>
        </>
    );

}

export default BoardReviewModal;
