import React, { useState } from 'react';
import { Modal, Button, Select } from 'antd';
import { EditFilled } from '@ant-design/icons';


export default function 
PickBreeder({ modalVisible, setBreeders, existingBreeders = [], breederList = [], onExit }) {
  const [visible, setVisible] = React.useState(modalVisible);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState('Content of the modal');
  const [selectedItems, setSelectedItems] = React.useState(existingBreeders);
  const mode = 'multiple';

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setBreeders(selectedItems);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleChange = selectedItems => {
    setSelectedItems(selectedItems);
  };

  const filteredOptions = breederList.filter(o => !selectedItems.includes(o));
  filteredOptions.sort((a, b) => a.fullName.localeCompare(b.fullName));

  return (
    <>
      <Button type="primary" icon={<EditFilled />} onClick={showModal}/>
      <Modal
        title="Züchter"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        afterClose={onExit}
      >
        <Select
          mode={mode}
          placeholder="Züchter"
          value={[...new Set(selectedItems)]}
          onChange={handleChange}
          style={{ width: '100%' }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {filteredOptions.map(item => (
            <Select.Option key={item.id} value={item.id}>
              {item.fullName}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </>
  );
}
