import React, { Component } from 'react'
import { Card, PageHeader, Select, Table, Typography, Divider, Checkbox, Space, Tag } from 'antd'
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { getBudgetOverViews, getBudgetOverViewSummary, getYears } from '../util/APIUtils';
import { formatCurrency } from '../util/Helpers';
import TableSummaryCell from './TableSummaryCell';
import './Finance.css'
import YearSelect from '../common/YearSelect';

const { Text, Title } = Typography;

const fg_style = "ccd5ae"
const prio_style = "e9edc9"

class BudgetOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            year: null,
            projectYears: [],
            budgetOverviewSummary: [],
            isLoading: false,
            visible: false,
            priorityChecked: true,
            fgChecked: true,
            payoutChecked: false,
            cropSummaryList: [],
            projectTypeSummaryList: [],
            fullList: []
        };
    }

    loadBudgetOverviews(val) {
        let promise;
        // if (!this.state.year) {
        //     return;
        // }

        console.log("Loading")
        promise = getBudgetOverViewSummary(val);

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });
        promise.then(response => {
            // console.log(response);
            console.log(response.budgetOverViews.filter((x) => x.overviewType === "crop"))
            this.setState({
                budgetOverviewSummary: response,
                fullList: response.budgetOverViews.sort((a, b) => a.overviewType.localeCompare(b.overviewType)),
                cropSummaryList: response.budgetOverViews,
                // cropSummaryList: response.budgetOverViews.filter((x) => x.overviewType === "crop"),
                projectTypeSummaryList: response.budgetOverViews.filter((x) => x.overviewType === "project_types"),
                summary: response.budgetOverViews.filter((x) => x.cropName === "Summary")[0],
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    componentDidMount() {
        if (!this.props.isAuthenticated) {
            this
                .props
                .history
                .push("/login");
        }

    }

    handleYearInput = (val) => {
        console.log(val);
        this.setState({
            year: val
        })

        console.log(this.state.year);
        this.loadBudgetOverviews(val);
    }

    onFgChange = (val) => {
        console.log(val)
        this.setState({ fgChecked: !this.state.fgChecked });
        console.log(this.state.fgChecked)
    }


    onPayoutChange = (val) => {
        console.log(val)
        this.setState({ payoutChecked: !this.state.payoutChecked });
        console.log(this.state.payoutChecked)
    }

    onPrioChange = (val) => {
        console.log(val)
        this.setState({ priorityChecked: !this.state.priorityChecked });
        console.log(this.state.priorityChecked)
    }






    render() {
        const { t } = this.props;

        let columns = [
            {
                title: "Kulturaart",
                dataIndex: 'cropName',
                key: 'cropName',
                width: 50,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Gesamt Kalkulation",
                dataIndex: 'budgetTotalAmount',
                key: 'budgetTotalAmount',
                align: "right",
                render: (text, row, index) => {
                    return (
                        <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    )
                },
                // width: 310,
            },
            {
                title: "#",
                align: "right",
                dataIndex: 'totalNumberOfProjects',
                key: 'totalNumberOfProjects',
                align: "left",
                minWidth: 50,
                render(text, record) {
                    return {
                        props: {
                            style: { background: "bfd8bd" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                }
            },

            // ####### PRIO
            {
                title: "Gesamt Prio 1",
                type: "prio",
                dataIndex: 'totalPriority1Projects',
                key: 'totalPriority1Projects',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#bfd8bd" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
                // width: 310,
            },
            {
                title: "#",
                type: "prio",
                dataIndex: 'noPriority1Projects',
                key: 'noPriority1Projects',
                align: "left",
                minWidth: 50,
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#bfd8bd" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                }
            },
            {
                title: "Gesamt Prio 2",
                type: "prio",
                dataIndex: 'totalPriority2Projects',
                key: 'totalPriority2Projects',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#dde7c7" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                }
                // width: 300,
            },
            {
                title: "#",
                type: "prio",
                dataIndex: 'noPriority2Projects',
                key: 'noPriority2Projects',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#dde7c7" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                minWidth: 50,
                // width: 260
            },
            {
                title: "Gesamt Prio 3",
                type: "prio",
                dataIndex: 'totalPriority3Projects',
                key: 'totalPriority3Projects',
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#edeec9" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
                align: "right",
                // width: 300,
            },
            {
                title: "#",
                type: "prio",
                dataIndex: 'noPriority3Projects',
                key: 'noPriority3Projects',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#edeec9" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                minWidth: 50,
                // width: 260
            },




            // #### PRIO END

            // #########  Betrag FG 
            {
                title: "FG OK",
                dataIndex: 'projectFgOkTotalAmount',
                type: "betrag_fg",
                key: 'projectFgOkTotalAmount',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#d5cdbb" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
                align: "right",
            },
            {
                title: "#",
                type: "betrag_fg",
                dataIndex: 'projectFgOkCount',
                key: 'projectFgOkCount',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#d5cdbb" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                minWidth: 50,
            },
            {
                title: "FG Zurückgestellt",
                type: "betrag_fg",
                dataIndex: 'projectFgOnHoldTotalAmount',
                key: 'projectFgOnHoldTotalAmount',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#e8d1be" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
            },
            {
                title: "#",
                type: "betrag_fg",
                dataIndex: 'projectFgOnHoldCount',
                key: 'projectFgOnHoldCount',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#e8d1be" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                minWidth: 50,
                // width: 260
            },
            {
                title: "FG Abgelehnt",
                type: "betrag_fg",
                dataIndex: 'projectFgRejectedTotalAmount',
                key: 'projectFgRejectedTotalAmount',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#f1d3bf" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
            },
            {
                title: "#",
                type: "betrag_fg",
                dataIndex: 'projectFgRejectedCount',
                key: 'projectFgRejectedCount',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#f1d3bf" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                minWidth: 50,
                // width: 260
            },

            // #########  Betrag FG END
            // #########  Payout
            {
                title: "Auszahlbetrag OK",
                type: "payout",
                dataIndex: 'projectPayoutOkTotalAmount',
                key: 'projectPayoutOkTotalAmount',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#fcde73" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
            },
            {
                title: "#",
                type: "payout",
                dataIndex: 'projectPayoutOkCount',
                key: 'projectPayoutOkCount',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#fcde73" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                minWidth: 50,
                // width: 260
            },
            {
                title: "Auszahlbetrag zurückgestellt",
                type: "payout",
                dataIndex: 'projectPayoutOnHoldTotalAmount',
                key: 'projectPayoutOnHoldTotalAmount',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#fde89b" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
            },
            {
                title: "#",
                type: "payout",
                dataIndex: 'projectPayoutOnHoldCount',
                key: 'projectPayoutOnHoldCount',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#fde89b" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                minWidth: 50,
                // width: 260
            },
            {
                title: "Auszahlbetrag abgelehnt",
                type: "payout",
                dataIndex: 'projectPayoutRejectedTotalAmount',
                key: 'projectPayoutRejectedTotalAmount',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#fdecaf" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
            },
            {
                title: "#",
                type: "payout",
                dataIndex: 'projectPayoutRejectedCount',
                key: 'projectPayoutRejectedCount',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#fdecaf" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                minWidth: 50,
                // width: 260
            },
            // ########### FG FIXED
            {
                title: "Gesamt FG",
                dataIndex: 'fgBudgetTotal',
                key: 'fgBudgetTotal',
                align: "right",
                render: (text, row, index) => {
                    return (
                        <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    )
                },
                // width: 300,
            },
            {
                title: "#",
                dataIndex: 'noFgBudgets',
                key: 'noFgBudgets',
                render(text, record) {
                    return {

                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                minWidth: 50,
                // width: 250
            },
            {
                title: "Reisekosten",
                dataIndex: 'travelExpenses',
                key: 'travelExpenses',
                align: "right",
                render: (text, row, index) => {
                    return (
                        <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    )
                },
                // width: 300,
            },


        ];

        if (!this.state.fgChecked) {
            columns = columns.filter(c => c.type != "betrag_fg")
        }
        if (!this.state.payoutChecked) {
            columns = columns.filter(c => c.type != "payout")
        }

        if (!this.state.priorityChecked) {
            columns = columns.filter(c => c.type != "prio")
        }


        // const fullList = this.state.cropSummaryList.push(...this.state.projectTypeSummaryList)
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    title="Gesamtübersicht"
                    subTitle=""
                />

                <Card bordered={true} loading={this.state.isLoading}>
                    <Space>

                        {/* <Select style={{ width: '140px' }} onChange={this.handleYearInput} defaultValue={this.state.year} placeholder={"Jahr"}>
                            {this.state.projectYears.map(item => (
                                <Select.Option key={item.projectYear} value={item.projectYear}>
                                    {item.projectYear}
                                </Select.Option>
                            ))}
                        </Select> */}
                        <YearSelect
                            setItem={this.handleYearInput}
                            selectedItem={this.state.year} />
                        <Checkbox
                            checked={this.state.priorityChecked}
                            onChange={this.onPrioChange}
                        >
                            Plan
                        </Checkbox>
                        <Checkbox
                            checked={this.state.fgChecked}
                            onChange={this.onFgChange}
                        >
                            Betrag FG
                        </Checkbox>
                        <Checkbox
                            checked={this.state.payoutChecked}
                            onChange={this.onPayoutChange}
                        >
                            Auszahlbetrag
                        </Checkbox>
                    </Space>
                    <Divider dashed />
                    {this.state.fullList.length > 0 &&
                        <Table
                            className='finance-table'
                            size='small'
                            columns={columns}
                            style={{ fontSize: '8px' }}
                            scroll={{ x: 'max-content' }}
                            dataSource={this.state.cropSummaryList}
                            loading={this.state.isLoading}
                            pagination={false}
                            rowClassName={(record, index) => record.overviewType == 'project_types' ? 'table-row-pt' : 'table-row-normal'}
                        //     summary={pageData => {
                        //         return (
                        //             <>


                        //                   <Table.Summary.Row align="right">
                        //                   <Table.Summary.Cell align="left"><Title level={5}>{this.state.summary.cropName}</Title></Table.Summary.Cell>

                        //                   <TableSummaryCell val={item.budgetTotalAmount} />
                        //                   <TableSummaryCell val={item.totalNumberOfProjects} count={true} />

                        //                   {this.state.priorityChecked && <TableSummaryCell val={item.totalPriority1Projects} color={"#bfd8bd"} />}
                        //                   {this.state.priorityChecked &&<TableSummaryCell val={item.noPriority1Projects} count={true} color={"#bfd8bd"}/>}
                        //                   {this.state.priorityChecked &&<TableSummaryCell val={item.totalPriority2Projects} color={"#bfd8bd"}/>}
                        //                   {this.state.priorityChecked &&<TableSummaryCell val={item.noPriority2Projects} count={true} color={"#bfd8bd"}/>}
                        //                   {this.state.priorityChecked &&<TableSummaryCell val={item.totalPriority3Projects} color={"#bfd8bd"} />}
                        //                   {this.state.priorityChecked &&<TableSummaryCell val={item.noPriority3Projects} count={true} color={"#bfd8bd"} />}

                        //                   {this.state.fgChecked && <TableSummaryCell val={item.projectFgOkTotalAmount} color={"#bfd8bd"} />}
                        //                   {this.state.fgChecked &&<TableSummaryCell val={item.projectFgOkCount} count={true} color={"#bfd8bd"}/>}
                        //                   {this.state.fgChecked &&<TableSummaryCell val={item.projectFgOnHoldTotalAmount} color={"#bfd8bd"}/>}
                        //                   {this.state.fgChecked &&<TableSummaryCell val={item.projectFgOnHoldCount} count={true} color={"#bfd8bd"}/>}
                        //                   {this.state.fgChecked &&<TableSummaryCell val={item.projectFgRejectedTotalAmount} color={"#bfd8bd"}/>}
                        //                   {this.state.fgChecked &&<TableSummaryCell val={item.projectFgRejectedCount} count={true} color={"#bfd8bd"}/>}

                        //                   {this.state.payoutChecked && <TableSummaryCell val={item.projectPayoutOkTotalAmount} color={"#bfd8bd"}/>}
                        //                   {this.state.payoutChecked &&<TableSummaryCell val={item.projectPayoutOkCount} count={true} color={"#bfd8bd"}/>}
                        //                   {this.state.payoutChecked &&<TableSummaryCell val={item.projectPayoutOnHoldTotalAmount} color={"#bfd8bd"}/>}
                        //                   {this.state.payoutChecked &&<TableSummaryCell val={item.projectPayoutOnHoldCount} count={true} color={"#bfd8bd"}/>}
                        //                   {this.state.payoutChecked &&<TableSummaryCell val={item.projectPayoutRejectedTotalAmount} color={"#bfd8bd"}/>}
                        //                   {this.state.payoutChecked &&<TableSummaryCell val={item.projectPayoutRejectedCount} count={true} color={"#bfd8bd"}/>}

                        //                   <TableSummaryCell val={item.fgBudgetTotal} />
                        //                   <TableSummaryCell val={item.noFgBudgets} count={true}/>
                        //                   <TableSummaryCell val={item.travelExpenses} />


                        //               </Table.Summary.Row >




                        //             </>
                        //         );
                        //     }
                        // }
                        />}



                </Card>

            </div>
        )
    }
}

export default withTranslation()(withRouter(BudgetOverview));
