import React, { useState } from 'react';
import LineSelectionLookup from './LineSelectionLookup'
import { Button, Space, Modal, Tabs, Empty, Switch, Row, Col, message, notification, Popconfirm } from 'antd';
import LineSelectionSingle from './LineSelectionSingle';
import AddLine from './AddLine';
import { createLineSelection } from '../util/APIUtils';
import shortid from 'shortid';


function LineSelectionLookupSingleWithAdd(props) {
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [key, setKey] = useState("key")
    const [newItem, setNewItem] = useState(null)

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = (vals) => {
        console.log(vals)
        setLoading(true);
        let name;
        if(vals.selection == 1) {
            name = vals.name_1 + "-" + vals.name_2 + "-" + vals.name_3 + "-" + vals.name_4 + "G"
        } else {
            name = vals.name_5
        }

        let lineSelection = {
            cropId: props.projectInfo.cropId,
            name: name
        }
        
        let promise;
        promise = createLineSelection(lineSelection)
        if (!promise) {
            return;
        }
        promise.then(response => {
            setLoading(false)
            setOpen(false);
            setNewItem(response?.name)
            props.setItem(response?.name)
        }).catch(error => {
            console.log(error)
            setOpen(false);
            setLoading(false);
        });
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };
    return (
        <div>
            <Modal
                title="Eingabe Linien/Sortenbezeichnung"
                width={800}
                visible={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={handleCancel}
                footer={''}
            >
                <AddLine projectInfo={props.projectInfo} onClose={handleCancel} loading={loading} handleOk={handleOk}/>
            </Modal>

            <Space>
                <LineSelectionSingle {...props} key={shortid.generate()} existingItem={newItem ? newItem : props.existingItem} setNewItem={setNewItem}/>
                <Button type='primary' onClick={showModal}> + Linie</Button>
            </Space>
        </div>
    )
}

export default LineSelectionLookupSingleWithAdd