import React, { Component } from 'react';
import { Table, Layout, AutoComplete, Button, Col, PageHeader, Card, Drawer } from 'antd';
import { withTranslation } from "react-i18next";
const { Header, Content, Footer, Sider } = Layout;
import { Link, useHistory  } from 'react-router-dom';
import { getAllBreeders } from '../util/APIUtils';
import { withRouter } from 'react-router-dom';
import { POLL_LIST_SIZE } from '../constants';
import LoadingIndicator from '../common/LoadingIndicator';
import Search from './search/Search'

import './Breeders.css';
import CropSearch from '../crops/CropSearch';
const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

class Breeders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breeders: [],
            page: 0,
            size: 10,
            totalElements: 0,
            totalPages: 0,
            last: true,
            isLoading: false,
            visible: false,
            currentUser: props.currentUser
        };
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = async (values) => {
        await sleep(500);
        console.log(values);
        // alert(JSON.stringify(values, null, 2));
        this.setState({
            visible: false,
        });
    };

    loadBreederList(page = 0, size = POLL_LIST_SIZE) {
        let promise;
        promise = getAllBreeders(page, size);

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });

        promise.then(response => {
            const breeders = [];
            this.setState({
                breeders: response,
                // page: response.page, size: response.size, totalElements:
                // response.totalElements, totalPages: response.totalPages, last: response.last,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    componentDidMount() {
        this.loadBreederList();
    }

    componentDidUpdate(nextProps) {
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                breeders: [],
                // page: 0, size: 10, totalElements: 0, totalPages: 0, last: true,
                isLoading: false
            });
            this.loadBreederList();
        }
    }

    onSelect = (val)  => {
        let linkText = `/breeders/profile/${val}/0`
        this.props.history.push(linkText);
    }
    render() {
        const { t } = this.props;
        const columns = [
            {
                title: t("general.name"),
                dataIndex: 'fullName',
                key: 'fullName',
                render: (text, record) => {
                    let linkText = `/breeders/profile/${record.id}/0`
                    let fullName = `${record.firstName} ${record.lastName}`
                    return (
                        <span><b>
                            <Link
                                to={{
                                    pathname: linkText,
                                    state: {
                                        id: record.id
                                    }
                                }}>{fullName}</Link>
                        </b>
                        </span>
                    )
                },
                width: 250
            }
            ,{
                title: t("general.short_name"),
                dataIndex: 'shortName',
                key: 'shortName',
                ellipsis: true,
                width: 100
            },,{
                title: t("general.address"),
                dataIndex: 'address',
                key: 'address',
                ellipsis: true
            },  {
                title: t("general.phone"),
                dataIndex: 'phone',
                key: 'phone',
                ellipsis: true
            }, {
                title: t("general.email"),
                dataIndex: 'email',
                key: 'email',
                ellipsis: true
            }
        ];
        let bList = this
            .state
            .breeders
            .map(a => ({ "value": a.firstName + " "  + a.lastName, "key": a.id }));
        let placeHolder = `${bList.length} ${t("general.breeders")}`
        let officeUser = this.state.currentUser.roles.find(x => x.name === 'ROLE_OFFICE');
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    title={t("general.breeders")}
                    subTitle=""
                    extra={[ officeUser ? <Link to="/breeder/add"><Button key="3" type="primary" >{t("general.add")}</Button></Link>: "",]}
                />
                <Card bordered={true} loading={this.state.isLoading}
                    title={bList.length > 0 ?
                        <CropSearch dataList={bList}
                            dataCount={bList.length}
                            placeHolder={placeHolder}
                            onSelect={this.onSelect} /> : ""}
                >

                    <Table
                        columns={columns}
                        dataSource={this.state.breeders}
                        loading={this.state.isLoading} />
                </Card>
            </div>

        );
    }
}
export default withTranslation()(withRouter(Breeders));
