
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useQuery } from 'react-query';
import { Card, PageHeader, Select, Table, Typography, Divider, Checkbox, Space, Tag, Tooltip } from 'antd'
import {
    DataGrid, useGridApiContext, deDE, GridToolbarContainer, GridRowModes, GridToolbar,GridToolbarFilterButton,GridToolbarDensitySelector, GridToolbarColumnsButton,
    gridVisibleColumnDefinitionsSelector,
    gridVisibleSortedRowIdsSelector,
    GridActionsCellItem, GRID_CHECKBOX_SELECTION_COL_DEF
} from '@mui/x-data-grid';

import { getVaExternReportXls, getVaExterns, getYears, updateVaExtern } from '../util/APIUtils';
import { formatCurrency } from '../util/Helpers';
import { CheckCircleOutline, HourglassBottomOutlined, WarningOutlined } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { blue, green, red } from '@mui/material/colors';
import moment from 'moment'
import YearSelect from '../common/YearSelect';
import {

    FileExcelOutlined,
    FilePdfOutlined
} from '@ant-design/icons';

const useFakeMutation = () => {
    return;
};


function VaExtern() {
    const [rows, setRows] = React.useState([]);
    const [year, setYear] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [rowModesModel, setRowModesModel] = React.useState({});

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarColumnsButton/>
                {/* Add other toolbar items you need, excluding the CSV export option */}
            </GridToolbarContainer>
        );
    }



    const loadVaExterns = (year) => {
        if (!year) return;
        console.log(year)
        let promise;
        promise = getVaExterns(year);
        if (!promise) {
            return;
        }

        // setLoading(true)
        promise.then(response => {
            console.log(response)
            setRows(response)
            // setLoading(false)
        }).catch(error => {
            console.log(error)
            // setLoading(false)
        });
    }

    const { isLoading, status, data, error, refetch } = useQuery(["year", year], () => loadVaExterns(year), {
        refetchOnWindowFocus: true,
        enabled: true // disable this query from automatically running
    });



    // const { isLoading, error, data, refetch } = useMyData(
    //     (jsonData) => jsonData,

    // );

    // if (isLoading) {
    //     return <p>Wird geladen .....</p>;
    // }

    // if (error) {
    //     return <p>Error Wird geladen ..: {error.message}</p>;
    // }


    // refetch()

    console.log(data)



    const setYearFromList = (val) => {
        setYear(val)
        refetch();
    }

    const mutateRow = useFakeMutation();



    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    // const handleDeleteClick = (id) => () => {
    //     setRows(rows.filter((row) => row.id !== id));
    // };

    // const handleCancelClick = (id) => () => {
    //     setRowModesModel({
    //         ...rowModesModel,
    //         [id]: { mode: GridRowModes.View, ignoreModifications: true },
    //     });

    //     const editedRow = rows.find((row) => row.id === id);
    //     if (editedRow.isNew) {
    //         setRows(rows.filter((row) => row.id !== id));
    //     }
    // };

    const processRowUpdate = React.useCallback(
        async (newRow) => {
            console.log(newRow)

            // const apiRef = useGridApiContext();

            newRow.invoiceIncoming = stripDate(newRow.invoiceIncoming)
            newRow.invoiceRequest = stripDate(newRow.invoiceRequest)
            newRow.invoiceTransferred = stripDate(newRow.invoiceTransferred)
            const updatedRow = { ...newRow, isNew: false };
            setRows(rows.map((row) =>
                (row.id === newRow.id ? updatedRow : row)
            )
            );

            let promise = updateVaExtern(newRow);
            if (!promise) {
                return;
            }
            promise.then(response => {
                // setRows(response);
                refetch();

            }).catch(error => {
                message.error("project update failed")
            });
            // apiRef.current.stopCellEditMode({ id: newRow.id });


            return;
        },
        [mutateRow],
    );

    const handleYearInput = (val) => {
        setYear(val)
        console.log(val);
        // loadVaExterns(val);
        refetch();
    }

    function getCarriedOut(params) {
        if (params.value === 1) {
            return <WarningOutlined style={{ fill: red[500] }} />;
        } else if (params.value === 0) {
            return <CheckCircleOutline style={{ fill: green[500] }} />;
        } else {
            return <HourglassBottomOutlined style={{ fill: blue[500] }} />
        }
    }

    function getInvoiceOk(params) {
        if (params.value === 0) {
            return <WarningOutlined style={{ fill: red[500] }} />;
        } else if (params.value === 1) {
            return <CheckCircleOutline style={{ fill: green[500] }} />;
        } else {
            return <HourglassBottomOutlined style={{ fill: blue[500] }} />
        }
    }

    const stripDate = (dateString) => {

        let dtStr = ""
        if (!dateString) {
            return ""
        } else {
            dtStr = dateString.toString();
        }

        var date = moment(new Date(dtStr.substr(0, 16)));
        if (date.isValid) {
            return date.format("YYYY-MM-DD")
        }

        if (moment.isMoment(dateString)) {
            return moment(dateString).format('YYYY-MM-DD');
        }

        if (dtStr.includes("00:00:00")) {
            return dtStr.replace(' 00:00:00', '');
        } else {
            return dtStr;
        }


    }

    const handleProcessRowUpdateError = React.useCallback((error) => {
        // setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const columns = [
        { field: 'projectId', headerName: 'projectId', width: 180, hide: true, flex: 1 },
        { field: 'id', headerName: 'projectDetailId', width: 180, hide: true, flex: 1 },
        { field: 'breederShortName', headerName: 'Projektleiter', minWidth: 50, flex: 1 },
        { field: 'projectNumber', headerName: 'Projekt-Nr.', minWidth: 70, flex: 1 },
        { field: 'cropName', headerName: 'Kulturart', minWidth: 45, flex: 1 },
        { field: 'title', headerName: 'Projekttitel', minWidth: 200, flex: 1, fontSize: 10 },
        { field: 'farmProfile', headerName: 'Anbaubetrieb', minWidth: 200, flex: 1, hide: true, },
        { field: 'payoutAmount', headerName: 'Auszahlungsbetrag', valueFormatter: ({ value }) => formatCurrency(value), headerAlign: 'right', align: 'right', minWidth: 85, flex: 1 },
        { field: 'actualTotal', headerName: 'Betrag abgerechnet', valueFormatter: ({ value }) => formatCurrency(value), headerAlign: 'right', align: 'right', minWidth: 85, flex: 1 },
        {
            field: 'cancelled', headerName: 'Durchgeführt', minWidth: 50, flex: 1,
            renderCell: (params) => {
                return getCarriedOut(params);
            }
        },
        {
            field: 'invoiceIncoming', headerName: 'Aufforderung Rechnung', minWidth: 80, flex: 1, editable: true, type: 'date',
            valueFormatter: ({ value }) => stripDate(value),
            // valueSetter:  ({ value }) => stripDate(value),
        },
        { field: 'invoiceRequest', headerName: 'Rechnungseingang', minWidth: 80, flex: 1, editable: true, type: 'date', valueFormatter: ({ value }) => stripDate(value), },
        { field: 'invoiceTransferred', headerName: 'Überwiesen am', minWidth: 80, flex: 1, editable: true, type: 'date', valueFormatter: ({ value }) => stripDate(value), },
        { field: 'commentVaExternal', headerName: 'Bemerkung Geschäftsstelle', minWidth: 250, flex: 1, editable: true,  renderCell: (params) => (
            <Tooltip title={params.value || ''}>
              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {params.value}
              </div>
            </Tooltip>
          ), },
        {
            field: 'invoiceOk', headerName: 'Rechnungsaufforderung', minWidth: 50, flex: 1, editable: true, type: 'singleSelect',
            valueOptions: [{ value: 1, label: "Ja" }, { value: 0, label: "Nein" }],
            renderCell: (params) => {
                return getInvoiceOk(params);
            }
        },
        // // {
        // //     field: 'actions',
        // //     type: 'actions',
        // //     headerName: 'Actions',
        // //     width: 100,
        // //     cellClassName: 'actions',
        // //     getActions: ({ id }) => {
        // //         const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        // //         if (isInEditMode) {
        // //             return [
        // //                 <GridActionsCellItem
        // //                     icon={<SaveIcon />}
        // //                     label="Save"
        // //                     onClick={handleSaveClick(id)}
        // //                 />,
        // //                 <GridActionsCellItem
        // //                     icon={<CancelIcon />}
        // //                     label="Cancel"
        // //                     className="textPrimary"
        // //                     onClick={handleCancelClick(id)}
        // //                     color="inherit"
        // //                 />,
        // //             ];
        // //         }

        // //         return [
        // //             <GridActionsCellItem
        // //                 icon={<EditIcon />}
        // //                 label="Edit"
        // //                 className="textPrimary"
        // //                 onClick={handleEditClick(id)}
        // //                 color="inherit"
        // //             />,
        // //             <GridActionsCellItem
        // //                 icon={<DeleteIcon />}
        // //                 label="Delete"
        // //                 onClick={handleDeleteClick(id)}
        // //                 color="inherit"
        // //             />,
        // //         ];
        // //     },
        // },
    ];

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        border: 1,
        color:
            theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        fontSize: '12px',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            // backgroundColor: '#ccc',
            border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
        },
        '& .MuiDataGrid-columnHeader': {
            fontSize: '12px',
            height: 22,
            backgroundColor: '#f1f7ee',
        },
        '& .MuiDataGrid-cell': {
            wordWrap: "break-word !important",
            paddingTop: "4px",
            paddingBottom: "4px",
            color:
                theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
        },

        '&.MuiDataGrid-root .MuiDataGrid-cell ': {
            whiteSpace: "normal !important",
            wordWrap: "break-word !important",
        },
        '& .MuiPaginationItem-root': {
            borderRadius: 0,
        },
        '& .sum-row-sum': {
            fontSize: '8pt',
            bgcolor: (theme) =>
                getBackgroundColor(theme.palette.success.main, theme.palette.mode),
            '&:hover': {
                bgcolor: (theme) =>
                    getHoverBackgroundColor(
                        theme.palette.success.main,
                        theme.palette.mode,
                    ),
            },
        },

    }));

    const handleCellEditStopEvent = (e) => {
        console.log(e)
        e.preventDefault();
    }

    const setUpdatedState = (state) => {
        // console.log(state)
    }

    const getReport = () => {
        const win = window.open(getVaExternReportXls(year), '_blank');
        if (win != null) {
            win.focus();
        }

    }

    return (
        <div>
            <PageHeader
                className="site-page-header"
                title="Übersicht VA-Extern"
                subTitle=""
            />
            <Card bordered={true} >
                <Space>
                <YearSelect
                    setItem={setYearFromList}
                    selectedItem={year} />
                    {year ? <Space> <FileExcelOutlined style={{ fontSize: '28px', color: '#08c' }} onClick={getReport} /></Space> : ""}
                    </Space>
                
                <div style={{ display: 'flex', height: '100%', marginTop: '15px' }}>
                    <div style={{ flexGrow: 1 }}>
                        <Box
                            sx={{
                                width: '100%',
                                '& .editcol': {
                                    backgroundColor: '#f1f7ee',
                                    color: '#1a3e72',
                                },
                                '& .date': {
                                    fontSize: '10px',
                                    backgroundColor: '#f1f7ee',
                                    color: '#1a3e72',
                                },
                                '& .small-text': {
                                    // backgroundColor: '#f1f7ee',
                                    // color: '#1a3e72',
                                    fontSize: '10px'
                                },
                            }}
                        >
                            <StyledDataGrid
                                loading={loading}
                                autoHeight
                                components={{ Toolbar: CustomToolbar }}
                                // rowHeight={35}
                                headerRowHeight={27}
                                disableColumnMenu
                                disableClickEventBubbling
                                disableSelectionOnClick
                                hideFooter
                                // getRowId={(row) => row.projectDetailId}
                                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                                rows={rows}
                                columns={columns}
                                // editMode="row"
                                onCellEditCommit={handleCellEditStopEvent}
                                // onCellClick={handleCellClick}
                                // rowModesModel={rowModesModel}
                                // onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                                // onRowEditStart={handleRowEditStart}
                                // onRowEditStop={handleRowEditStop}
                                getCellClassName={(params) => {
                                    if (['invoiceIncoming', 'invoiceRequest', 'invoiceTransferred'].includes(params.field)) {
                                        return 'date';
                                    } else if (['title', 'farmProfile'].includes(params.field)) {
                                        return 'small-text'
                                    } else if (['commentVaExternal', 'invoiceOk'].includes(params.field)) {
                                        return 'editcol'
                                    }
                                }}
                                processRowUpdate={processRowUpdate}
                                onProcessRowUpdateError={handleProcessRowUpdateError}
                                onStateChange={(state) => setUpdatedState(state)}
                                componentsProps={{
                                    toolbar: { setRows, setRowModesModel },
                                }}
                                experimentalFeatures={{ newEditingApi: true }}
                            />
                        </Box>
                    </div></div>
            </Card>
        </div>
    );
}

export default VaExtern