import React, { Component } from 'react';
import {
    Badge,
    Card,
    Descriptions,
    Divider,
    Checkbox,
    Table,
    Layout,
    AutoComplete,
    Input,
    List,
    Row,
    Col,
    Form,
    Skeleton,
    PageHeader,
    Space,
    notification
} from 'antd';
import { PageContainer } from '@ant-design/pro-layout';
const { Header, Content, Footer, Sider } = Layout;
import { Link, useHistory } from 'react-router-dom';
import { getBreederInfo, getAProjectsByBreeder, editBreeder } from '../util/APIUtils';
import { withRouter } from 'react-router-dom';
import { POLL_LIST_SIZE } from '../constants';
import LoadingIndicator from '../common/LoadingIndicator';
import Search from './search/Search'
import { Button, Icon } from 'antd';
import './BreederProfile.css'
import { withTranslation } from "react-i18next";
import PasswordChangeForm from '../user/management/PasswordChangeForm';


class BreederProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breederProjects: [],
            breederInfo: {},
            page: 0,
            size: 10,
            totalElements: 0,
            totalPages: 0,
            last: true,
            isLoading: false,
            isProjectsLoading: false,
            editing: false,
            initVals: {},
            currentUser: props.currentUser
        };
    }

    formRef = React.createRef();

    onActiveChange (e)  {
        console.log(`checked = ${e.target.checked}`);
    };

    loadBreederInfo(id, profile) {
        let promise;
        promise = getBreederInfo(id, profile);
        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });

        promise.then(response => {
            this.setState({
                breederInfo: response,
                isLoading: false
            })
            this.formRef.current.setFieldsValue({
                phone: this.state.breederInfo.phone,
                email: this.state.breederInfo.email,
                houseNo: this.state.breederInfo.houseNo,
                address: this.state.breederInfo.address,

            });
            if (profile != '1') {
                this.loadBreederProjectList(id);
            } else {
                this.formRef.current.setFieldsValue({
                    bank: this.state.breederInfo.financialInfo.bank,
                    bic: this.state.breederInfo.financialInfo.bic,
                    blz: this.state.breederInfo.financialInfo.blz,
                    iban: this.state.breederInfo.financialInfo.iban,
                    ktonr: this.state.breederInfo.financialInfo.ktonr,
                    taxNumber: this.state.breederInfo.financialInfo.taxNumber,
                    ust: this.state.breederInfo.financialInfo.ust,
                    ustHint: this.state.breederInfo.financialInfo.ustHint,
                    ustId: this.state.breederInfo.financialInfo.ustId,
                })
            }

        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    loadBreederProjectList(id) {
        let promise;
        console.log(this.props);
        promise = getAProjectsByBreeder(id);

        if (!promise) {
            return;
        }

        this.setState({ isProjectsLoading: true });

        promise.then(response => {
            console.log(response['breederInfo'].fullName);
            this.setState({
                breederProjects: response['breederProjects'],
                // page: response.page, size: response.size, totalElements:
                // response.totalElements, totalPages: response.totalPages, last: response.last,
                isProjectsLoading: false
            })

        }).catch(error => {
            this.setState({ isProjectsLoading: false })
        });
    }

    componentDidMount() {
        console.log("Breeder profile")
        let { id, profile } = this.props.match.params
        if (id === '0') {
            id = this.props.currentUser.id;
        }
        this.loadBreederInfo(id, profile);

    }

    componentDidUpdate(nextProps) {
        let { id, profile } = this.props.match.params

        if (id === '0') {
            id = this.props.currentUser.id;
        }
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                breederProjects: [],
                // page: 0, size: 10, totalElements: 0, totalPages: 0, last: true,
                isLoading: false
            });
            this.loadBreederInfo(id, profile);
        }
    }

    handleBack = e => {
        this
            .props
            .history
            .goBack();
    };

    setEditing = () => {

        this.setState({
            editing: true
        })
    }

    cancelEditing = () => {
        this.setState({
            editing: false
        })
    }

    saveBreederProfile = (obj) => {
        this.setState({
            editing: false
        })
    }

    onFinish = (obj) => {
        console.log(obj);
        let breederProfObj = {
            id: this.state.breederInfo.id,
            userProfileId: this.state.breederInfo.userProfileId,
            phone: obj.phone,
            email: obj.email,
            houseNo: obj.houseNo,
            address: obj.address,
            financialInfo: {
                bank: obj.bank,
                bic: obj.bic,
                blz: obj.blz,
                iban: obj.iban,
                ktonr: obj.ktonr,
                taxNumber: obj.taxNumber,
                ust: obj.ust,
                ustHint: obj.ustHint,
                ustId: obj.ustId,
            }
        }

        let promise;
        promise = editBreeder(breederProfObj);

        if (!promise) {
            return;
        }

        this.setState({ loading: true });

        promise.then(response => {
            this.setState({
                // page: response.page, size: response.size, totalElements:
                // response.totalElements, totalPages: response.totalPages, last: response.last,
                loading: false,
                editing: false
            })
            notification.success({ message: "Kultursaat", description: "Saved", placement: 'topLeft' })
            this.loadBreederInfo(this.state.breederInfo.id, 1);

        }).catch(error => {
            console.log(error)
            this.setState({ loading: false })
            notification.error({ message: "Kultursaat", description: "Failed", placement: 'topLeft' })
        });
    }

    render() {
        const { t } = this.props;
        let { id, profile } = this.props.match.params;
        let ownUser = this.state.currentUser.breederInfo.isLoggedInUser

        const columns = [
            {
                title: t("breeders.project_number"),
                dataIndex: 'projectNumber',
                key: 'projectNumber',
                render: (text, record) => {
                    let linkText = `/project/detail/${record.projectId}`
                    return (
                        <span>
                            <b>
                                <Link
                                    to={{
                                        pathname: linkText,
                                        state: {
                                            id: record.projectId
                                        }
                                    }}>{text}</Link></b>
                        </span>
                    )
                },
                // render: text => <Link to="/projects/detail/">{text}</Link>,
                width: 120,
                sorter: (a, b) => {
                    if (a.shortName < b.shortName) {
                        return -1;
                    }
                    if (a.shortName > b.shortName) {
                        return 1;
                    }
                    return 0;
                },
                sortDirections: [
                    'descend', 'ascend'
                ],
                ellipsis: true
            }, {
                title: t("general.description"),
                dataIndex: 'description',
                key: 'description',
                width: 500
            }, {
                title: t("breeders.breeder_name"),
                dataIndex: 'breederName',
                key: 'breederName',
                ellipsis: true
            }, {
                title: t("farmprofile.project_type"),
                dataIndex: 'projectType',
                key: 'projectType',
                width: 120
            }, {
                title: 'Status',
                dataIndex: 'status',
                key: 'status'
            }
        ];
        let placeHolder = "Search breeders";
        let bInfo = this.state.breederInfo;
        if (bInfo) {
            console.log(bInfo.fullName);
        }


        let buttonBar = this.state.editing ?
            <Space>
                <Button key="31" type="primary" htmlType="submit" >Speichern</Button>
                <Button key="35" onClick={this.saveBreederProfile}>Abbrechen</Button>
            </Space>
            : <Space>
                <Button key="32" type="primary" onClick={this.setEditing}>Bearbeiten</Button>  
                <PasswordChangeForm />
                </Space>

        return (
            <div>
                <Form name="breeder-form" ref={this.formRef} onFinish={this.onFinish} >
                    <PageHeader
                        className="site-page-header"
                        onBack={() => this.handleBack()}
                        extra={[
                            this.state.breederInfo.id == this.state.currentUser.breederInfo.id ? buttonBar : ""
                        ]}
                        title={this.state.breederInfo.shortName ? this.state.breederInfo.shortName.toUpperCase() : ""}
                        subTitle={this.state.breederInfo.fullName} />

                    <Card bordered={true} loading={this.state.isLoading}>

                        <Descriptions
                            title={t("breeders.breeder_profile")}
                            size="small"
                            bordered="true"
                            style={{
                                marginBottom: 20

                            }}>
                            <Descriptions.Item label={t("general.name")} ><b>{this.state.breederInfo.firstName} {this.state.breederInfo.lastName} </b></Descriptions.Item>
                            <Descriptions.Item label={t("general.short_name")}>{this.state.breederInfo.shortName}</Descriptions.Item>

                            <Descriptions.Item label={t("general.phone")}>
                                {this.state.editing ?
                                    <Form.Item
                                        name="phone"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Bitte eingeben ' + t("general.phone"),
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item> :

                                    this.state.breederInfo.phone
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={t("general.email")}>
                                {this.state.editing ?
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Bitte eingeben ' + t("general.email"),
                                            },
                                        ]}
                                    >
                                        <Input disabled />
                                    </Form.Item> :

                                    this.state.breederInfo.email
                                }
                            </Descriptions.Item>

                           
                        </Descriptions>
                        <Divider
                            style={{
                                marginBottom: 20
                            }} />
                        <Descriptions
                            title={t("general.address")}
                            size="small"
                            bordered="true"
                            style={{
                                marginBottom: 20
                            }}>
                            <Descriptions.Item label="House No" >
                                {this.state.editing ?
                                    <Form.Item
                                        name="houseNo"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Bitte eingeben House No',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item> :

                                    this.state.breederInfo.houseNo
                                }
                            </Descriptions.Item>

                            <Descriptions.Item label={t("general.address")}>
                                {this.state.editing ?
                                    <Form.Item
                                        name="address"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Bitte eingeben ' + t("general.address"),
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item> :

                                    this.state.breederInfo.address
                                }
                            </Descriptions.Item>

                        </Descriptions>
                        <Divider
                            style={{
                                marginBottom: 20
                            }} />

                        {/* {(this.state.breederInfo.financialInfo && profile == "1") && */}
                        {(this.state.breederInfo.financialInfo && this.state.breederInfo.id == this.state.currentUser.breederInfo.id) &&
                            <div>
                                <Descriptions
                                    title={"Roles and FG memberships"}
                                    bordered
                                    size="small"
                                    style={{
                                        marginBottom: 20
                                    }}>
                                    <Descriptions.Item label="Roles" >
                                        <List
                                            size="small"
                                            // bordered
                                            dataSource={this.state.currentUser.roles}
                                            renderItem={item => <List.Item style={{ padding: 2 }}><Badge color={'blue'} text={item.name} /></List.Item>}
                                        />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="FG Memberships" >
                                        <List
                                            size="small"
                                            // bordered
                                            dataSource={this.state.currentUser.fgMemberships}
                                            renderItem={item => <List.Item style={{ padding: 2 }}><Badge color={'blue'} text={item.description} /></List.Item>}
                                        />
                                    </Descriptions.Item>
                                </Descriptions>

                                <Descriptions
                                    title={t("breeders.financial_info")}
                                    bordered
                                    size="small"
                                    style={{
                                        marginBottom: 20
                                    }}>
                                    <Descriptions.Item label="Bank" >
                                        {this.state.editing ?
                                            <Form.Item
                                                name="bank"
                                            >
                                                <Input />
                                            </Form.Item> :

                                            this.state.breederInfo.financialInfo.bank
                                        }
                                    </Descriptions.Item>
                                    <Descriptions.Item label="bic">
                                        {this.state.editing ?
                                            <Form.Item
                                                name="bic"
                                            >
                                                <Input />
                                            </Form.Item> :

                                            this.state.breederInfo.financialInfo.bic
                                        }
                                    </Descriptions.Item>
                                    <Descriptions.Item label="biz">
                                        {this.state.editing ?
                                            <Form.Item
                                                name="biz"

                                            >
                                                <Input />
                                            </Form.Item> :

                                            this.state.breederInfo.financialInfo.biz
                                        }
                                    </Descriptions.Item>
                                    <Descriptions.Item label="iban">
                                        {this.state.editing ?
                                            <Form.Item
                                                name="iban"

                                            >
                                                <Input />
                                            </Form.Item> :

                                            this.state.breederInfo.financialInfo.iban
                                        }
                                    </Descriptions.Item>
                                    <Descriptions.Item label="ktonr">
                                        {this.state.editing ?
                                            <Form.Item
                                                name="ktonr"

                                            >
                                                <Input />
                                            </Form.Item> :

                                            this.state.breederInfo.financialInfo.ktonr
                                        }
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Steuernummer">
                                        {this.state.editing ?
                                            <Form.Item
                                                name="taxNumber"

                                            >
                                                <Input />
                                            </Form.Item> :

                                            this.state.breederInfo.financialInfo.taxNumber
                                        }
                                    </Descriptions.Item>
                                    <Descriptions.Item label="ust">
                                        {this.state.editing ?
                                            <Form.Item
                                                name="ust"

                                            >
                                                <Input />
                                            </Form.Item> :

                                            this.state.breederInfo.financialInfo.ust
                                        }
                                    </Descriptions.Item>
                                    <Descriptions.Item label="ustHint">
                                        {this.state.editing ?
                                            <Form.Item
                                                name="ustHint"

                                            >
                                                <Input />
                                            </Form.Item> :

                                            this.state.breederInfo.financialInfo.ustHint
                                        }
                                    </Descriptions.Item>
                                    <Descriptions.Item label="ustId">
                                        {this.state.editing ?
                                            <Form.Item
                                                name="ustId"

                                            >
                                                <Input />
                                            </Form.Item> :

                                            this.state.breederInfo.financialInfo.ustId
                                        }
                                    </Descriptions.Item>
                                </Descriptions>

                            </div>
                        }

                        <div style={{
                            // marginLeft: 10
                        }}>
                            {/* <Row className="page-header">
                    <Col span={8}><h1><Button type="primary" onClick={this.handleBack}>
                    <Icon type="left"/>
                    Go back
                </Button></h1></Col>
                    <Col span={12} push={10}>

                    </Col>
                </Row>
                <Row className="page-header">
                    <Col span={8}><h1>Breeder Profile</h1></Col>
                    <Col span={12} push={10}>
                    <AutoComplete
                    style={{
                    width: 250,
                    paddingRight: 4,
                }}
                    dataSource={['Test1']}
                    placeholder={placeHolder}
                    filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}/>
                    </Col>
                </Row> */}

                            {/* <Search dataList={bList} dataCount={bList.length}/> */}
                            {/* {profile != '1' &&
                                <Table
                                    className="data-table"
                                    columns={columns}
                                    dataSource={this.state.breederProjects}
                                    loading={this.state.isLoading} />} */}
                        </div>
                    </Card>
                </Form>
            </div>

        );
    }
}
export default withTranslation()(withRouter(BreederProfile));
