import { Card, Descriptions, Tag, Button, Row, Col, Typography } from 'antd'
import React from 'react'
import { useTranslation, Trans } from 'react-i18next';
import { capitalize, formatCurrency, getColorToneForSummary } from '../../util/Helpers';
import { ReloadOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { getProjectAmountSummary } from '../../util/APIUtils';
const { Text, Title } = Typography;

function AmountSummary(props) {
    const projectDetailId = props.projectDetailId;
    const disType = props.type;
    const key = props.key
    const [loading, setLoading] = React.useState(false);
    const [projectSummary, setProjectSummary] = React.useState(null)
    const { t } = useTranslation();

    let useMyData = getProjectAmountSummary(projectDetailId);

    const { isLoading, error, data, refetch } = useMyData(
        (jsonData) => jsonData,
    );

    if (isLoading) {
        return <p>Wird geladen .....</p>;
    }

    if (error) {
        return <p>Error Wird geladen ..: {error.message}</p>;
    }

    const getOwnShare = (payoutAmount, planTotal) => {
        let ownShare = 0;
        let pa = parseFloat(payoutAmount);
        let pt = parseFloat(planTotal);
        if(pt > pa) {
            ownShare = pt - pa;
        }
        return ownShare;
    }

    return (
        data.projectDetailId ?
        <Card bordered loading={isLoading} >
            {data != null && disType === "plan" &&
                <Descriptions size='small' bordered bodyStyle={{ background: "#fefee3", paddingTop: 25 }} 
                // extra={<Button onClick={loadAccountSummary} type={'primary'}  icon={<ReloadOutlined />} size={'small'} >Aktualisierung</Button>}
                >
                    <Descriptions.Item label={t("project_details.bewillig_status")} labelStyle={{ fontWeight: 'bold' }} span={2}>
                        <Tag style={{ fontWeight: 'bold' }} icon={<CheckCircleOutlined />} color={getColorToneForSummary(data.status)}>
                            {capitalize(data.status)}
                        </Tag>
                    </Descriptions.Item>

                    <Descriptions.Item label={t("project_details.fg_status")} labelStyle={{ fontWeight: 'bold' }}>
                        <Tag style={{ fontWeight: 'bold' }} icon={<CheckCircleOutlined />} color={getColorToneForSummary(data.fgStatus)}>
                            {capitalize(data.fgStatus)}
                        </Tag>
                    </Descriptions.Item>

                    <Descriptions.Item label={t("project_details.project_amount")} labelStyle={{ fontWeight: 'bold' }} span={2}>
                        <Tag style={{ fontWeight: 'bold' }} color="#2db7f5">{formatCurrency(data.amount)}</Tag>
                    </Descriptions.Item>

                    <Descriptions.Item label={t("project_details.fg_amount")} labelStyle={{ fontWeight: 'bold' }}>
                        <Tag style={{ fontWeight: 'bold' }} color="#2db7f5">{formatCurrency(data.fgAmount)}</Tag>
                    </Descriptions.Item>

                    <Descriptions.Item label={t("project_details.payout_amount")} labelStyle={{ fontWeight: 'bold' }} span={2}>
                        <Tag style={{ fontWeight: 'bold' }} color="#2db7f5">{formatCurrency(data.payoutAmount)}</Tag>
                    </Descriptions.Item>

                    <Descriptions.Item label={t("project_details.breeder_share")} labelStyle={{ fontWeight: 'bold' }}>
                        <Tag style={{ fontWeight: 'bold' }} color="#2db7f5">{formatCurrency(getOwnShare(data.payoutAmount, data.amount))}</Tag>
                    </Descriptions.Item>
                </Descriptions> }
                {data != null && disType === "actual" &&
                <Descriptions size='small' bordered bodyStyle={{ background: "#fefee3", paddingTop: 25 }} 
                // extra={<Button onClick={loadAccountSummary} type={'primary'}  icon={<ReloadOutlined />} size={'small'} >Aktualisierung</Button>}
                >
                
                <Descriptions.Item label="Gesambetrag" labelStyle={{fontSize: 18, fontWeight: 800, justifyContent: 'end' }} span={3} >
                        <Text style={{ fontWeight: 'bold' , fontSize: 18 }} color="#2db7f5">{formatCurrency(data.actualAmount)}</Text>
                    </Descriptions.Item>
                    <Descriptions.Item label={t("project_details.payout_amount")} labelStyle={{ fontWeight: 'bold' }} style= {{alignItems: 'right'}} span={3}>
                        <Text style={{ fontWeight: 'bold' }} color="#2db7f5">{formatCurrency(data.payoutAmount)}</Text>
                    </Descriptions.Item>
                    <Descriptions.Item label={"Rückzahlung"} labelStyle={{ fontWeight: 'bold' }} span={3}>
                        <Text style={{ fontWeight: 'bold', align: 'right'}} color="#2db7f5">{formatCurrency(data.recoveryAmount)}</Text>
                    </Descriptions.Item>
                    <Descriptions.Item label={"Eigenanteil"} labelStyle={{ fontWeight: 'bold' }} span={3}>
                        <Text style={{ fontWeight: 'bold' }} color="#2db7f5">{formatCurrency(data.breederShare)}</Text>
                    </Descriptions.Item>
                </Descriptions>
            }
        </Card > : ""
    )
}

export default AmountSummary