import React, { useState, useEffect } from 'react';
import { Empty, List, Descriptions, Button, Space, Input, InputNumber, Select, DatePicker, Form } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { editLine } from '../../util/APIUtils';
import moment from 'moment';
function EditLine(props) {
    const [loading, setLoading] = useState(false);
    const [varietyLevel, setVarietyLevel] = useState(props.line.varietyLevel);
    let line = props.line;
    const { t } = useTranslation();
    const dateFormat = 'MMM-DD-YYYY';
    const [form] = Form.useForm();
    let formattedName = false;
    let nameArr = []
    nameArr = props.line.description ? props.line.description.split("-") : []
    console.log(nameArr)
    form.resetFields();
    if(nameArr.length == 5 ){
        line.name_1 = nameArr[0]
        line.name_2 = nameArr[1] + "-" + nameArr[2]
        line.name_3 = nameArr[3]
        line.name_4 = nameArr[4]
        formattedName = true;
    } else {
        formattedName = false;
    }

    console.log(line)

    const children = [];

    for (let i = 0; i < 100; i++) {
        children.push(<Option key={i}>{i}</Option>);
    }

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        
        let lineObj = {
            amountOfSeeds: values.amountOfSeeds,
            bloomSeedBearer: values.bloomSeedBearer,
            briefDescription: values.briefDescription,
            bulkEliteSeeds: values.bulkEliteSeeds,
            description: values.description,
            germination: values.germination,
            germinationElite: values.germinationElite,
            germinationVa: values.germinationVa,
            id: line.id,
            invitedSeedBearer: values.invitedSeedBearer,
            largeSelectionStock: values.largeSelectionStock,
            mixedSeeds: values.mixedSeeds,
            oldLineId: line.oldLineId,
            plantingDate: values.plantingDate1,
            projectDetailId: line.projectDetailId,
            projectId: line.projectId,
            projectReportId: values.projectReportId,
            results: values.results,
            sowingDate: values.sowingDate1,
            stockUnit: values.stockUnit,
            task: values.task,
            varietyDescription: values.varietyDescription,
            varietyLevel: values.varietyLevel,
        }
        console.log(lineObj);
        setLoading(true)
        let promise;
        promise = editLine(lineObj);
        if (!promise) {
            return;
        }

        // this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response)
            setLoading(false)
            form.resetFields();
            props.onClose();

        }).catch(error => {
            console.log(error)
            // this.setState({ isLoading: false })
        });

    };

    var initVals = Object.assign({}, line); 
    initVals.plantingDate = line.plantingDate? moment(moment(line.plantingDate).format(dateFormat), dateFormat): ""
    initVals.sowingDate = line.sowingDate? moment(moment(line.sowingDate).format(dateFormat), dateFormat): ""
    initVals.description = line.description
    initVals.varietyLevel = varietyLevel
    console.log(initVals)

    const sortenStufeBlock = () => {

        if (varietyLevel >= 3) {
            return (
                <>
                    <Descriptions.Item span={2} label={"Menge vorh EliteSaatgut"}>
                        <Form.Item
                            name="bulkEliteSeeds"
                        >
                            <InputNumber />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={"keimfähigkeit %"} >
                        <Form.Item
                            name="germinationElite"
                        >
                            <InputNumber /> 
                        </Form.Item>
                    </Descriptions.Item>

                    <Descriptions.Item span={3} label={"Sortenbeschreibung"}>
                        <Form.Item
                            name="varietyDescription"
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Descriptions.Item>

                    <Descriptions.Item span={2} label={"Insgesamt vorhandenes Saatgut für Versuchsanbau"}>
                        <Form.Item
                            name="mixedSeeds"
                        >
                            <InputNumber />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={"keimfähigkeit %"}>
                        <Form.Item
                            name="germinationVa"
                        >
                            <InputNumber /> 
                        </Form.Item>%
                    </Descriptions.Item>

                </>)
        }
    }
    

    return (
        <div>
            <Form name="lines-edit-form" form={form} onFinish={onFinish} initialValues={initVals}>
                <Descriptions
                    bordered
                    title={t("lines.title")}
                    size={"small"}

                >
                    <Descriptions.Item span={3} label={t("lines.description")}>
                        {false ? 
                    <Space>
                            <Form.Item
                                name="name_1"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please enter ' + t("lines.description"),
                            //     },
                            // ]}
                            >
                                <Select defaultValue="KSZ" style={{ width: 80 }} >
                                    <Option value="KSZ">KSZ</Option>
                                    <Option value="KS">KS</Option>
                                    <Option value="KSV">KSV</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="name_2"
                            >
                                <Input disabled style={{ width: 90 }}  />
                            </Form.Item>
                            <Form.Item
                                name="name_3"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter ' + t("lines.description"),
                                    },
                                ]}
                            >
                                <Input style={{ width: 220 }} defaultValue={"Neue Linie"} />
                            </Form.Item>
                            <Form.Item
                                name="name_4"
                            >
                                <Select defaultValue={0} style={{ width: 80 }} >
                                    {children}
                                </Select>
                            </Form.Item>
                        </Space> : <Form.Item
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter ' + t("lines.short_description"),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>}
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label={t("lines.short_description")}>
                        <Form.Item
                            name="briefDescription"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter ' + t("lines.short_description"),
                                },
                            ]}
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label={t("lines.this_year_on_line")}>
                        <Form.Item
                            name="task"
                           
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label={t("lines.results")}>
                        <Form.Item
                            name="results"
                            
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={t("lines.variety_level")}>
                    <Form.Item
                            name="varietyLevel"
                        >
                            <Select style={{ width: 80 }} onChange={setVarietyLevel} >
                                <Option value={1}>1</Option>
                                <Option value={2}>2</Option>
                                <Option value={3}>3</Option>
                                <Option value={4}>4</Option>
                            </Select>
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={t("lines.seed_carrier_number")}>
                        <Form.Item
                            name="invitedSeedBearer"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please enter ' + t("lines.seed_carrier_number"),
                        //     },
                        // ]}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={t("lines.readout_stock_size")}>
                        <Space><Form.Item
                            name="largeSelectionStock"
                            
                        >
                            <InputNumber />
                            {/* {line.stockUnit} */}
                        </Form.Item>
                            <Form.Item
                                name="stockUnit"
                               
                            >
                                <Select  >
                                    <Option value="qm">QM</Option>
                                    <Option value="ha">HA</Option>
                                    <Option value="stück">Stück</Option>
                                </Select>
                                {/* {line.stockUnit} */}
                            </Form.Item></Space>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={t("lines.blooming_seed_carriers")}>
                        <Form.Item
                            name="bloomSeedBearer"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please enter ' + t("lines.blooming_seed_carriers"),
                        //     },
                        // ]}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={t("lines.planting_date")}>
                        <Form.Item
                            name="plantingDate1"
                            initialValue={line.plantingDate? moment(moment(line.plantingDate).format(dateFormat), dateFormat): ""}
                            
                        >
                            <DatePicker format={dateFormat} />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={t("lines.sowing_date")}>
                        <Form.Item
                            name="sowingDate1"
                            initialValue={line.sowingDate? moment(moment(line.sowingDate).format(dateFormat), dateFormat): ""}
                            
                        >
                            <DatePicker format={dateFormat} />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={t("lines.amount_of_seeds")}>
                        <Form.Item
                            name="amountOfSeeds"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please enter ' + t("lines.amount_of_seeds"),
                        //     },
                        // ]}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={"keimfähigkeit %"}>
                        <Form.Item
                            name="germination"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please enter ' + t("lines.germination"),
                        //     },
                        // ]}
                        >
                            <InputNumber  /> 
                        </Form.Item>
                    </Descriptions.Item>
                    {sortenStufeBlock()}
                    <Descriptions.Item span={1} >
                        <Form.Item
                            name="submit"

                        >
                            <Space><Button type="primary" htmlType="submit" loading={loading}>
                            Speichern   
                            </Button> <Button type="secondary" onClick={props.onClose}>
                                    Abbrechen
                                </Button></Space>
                        </Form.Item>
                    </Descriptions.Item>

                    
                </Descriptions>
            </Form>
        </div>
    )
}

export default EditLine
