import React, { Component } from 'react';
import { Table, Layout, AutoComplete, Button, Col, PageHeader, Card, Checkbox, Space } from 'antd';
const { Header, Content, Footer, Sider } = Layout;
import { Link } from 'react-router-dom';
import { getAllFachgroups } from '../util/APIUtils';
import { withRouter } from 'react-router-dom';
import { POLL_LIST_SIZE } from '../constants';
import { withTranslation  } from "react-i18next";
import FachGroupSearch from './FachGroupSearch';

import './FachGroups.css';

class FachGroups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fachGroups: [],
            isLoading: false,
            visible: false,
            currentUser: props.currentUser
        };
    }

    loadFachgroupList(page = 0, size = POLL_LIST_SIZE) {
        let promise;
        promise = getAllFachgroups();

        if (!promise) {
            return;
        }


        this.setState({ isLoading: true });
        promise.then(response => {
            this.setState({
                
                fachGroups: response,
                // page: response.page, size: response.size, totalElements:
                // response.totalElements, totalPages: response.totalPages, last: response.last,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    componentDidMount() {
        if (!this.props.isAuthenticated){
            this
            .props
            .history
            .push("/login");
        }
       
        this.loadFachgroupList();
    }

    componentDidUpdate(nextProps) {
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                farmprofiles: [],
                // page: 0, size: 10, totalElements: 0, totalPages: 0, last: true,
                isLoading: false
            });
            this.loadFachgroupList();
        }
    }

    onSelect = (val) => {
        let linkText = `/fachgroup/detail/${val}`
        this.props.history.push(linkText);
    }

    onChange = (e) => {
        if (e.target.checked) {
            this.setState({
                fachGroups: this.state.currentUser.fgMemberships
            })
        } else {
            this.loadFachgroupList();
        }
    };

    render() {
        const { t } = this.props;
        let officeUser = this.state.currentUser.officeUser
        const columns = [
            {
                title: t("general.name"),
                dataIndex: 'name',
                key: 'name',
                // render: text => <Link to="/breeders/profile">{text}</Link>,
                render: (text, record) => {
                    let linkText = `/fachgroup/detail/${record.id}`
                    return (
                        <span><b>
                            <Link
                                to={{
                                    pathname: linkText,
                                    state: {
                                        id: record.id
                                    }
                                }}>{text}</Link>
                        </b>
                        </span>
                    )
                },
                width: 150,
                sortDirections: ['descend', 'ascend'],
                sorter: (a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                },
            },
            {
                title: t("general.description"),
                dataIndex: 'description',
                key: 'description',
                width: 300,
                sortDirections: ['descend', 'ascend']
            },
            {
                title: t("fachgroup.fg_leader"),
                dataIndex: 'fachGroupLeader',
                key: 'fachGroupLeader',
                width: 250
            }
        ];
        let bList = this
        .state
        .fachGroups
        .filter(ab => ab.name !== "")
        .map(a => ({ "value": a.name, "key": a.id }));
        let placeHolder = `${bList.length} ${t("general.fachgroups")}`
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    title={t("general.fachgroups")}
                    subTitle=""
                    extra={[officeUser ? <Link to="/fachgroup/add"><Button key="3" type="primary" >{t("general.add")}</Button></Link>: ""]
                    }
                />
                <Card bordered={true} loading={this.state.isLoading}
                                title={bList.length > 0 ?
                                    <Space><FachGroupSearch dataList={bList}
                                        dataCount={bList.length}
                                        placeHolder={placeHolder}
                                        onSelect={this.onSelect} /> <Checkbox onChange={this.onChange}>Meine Fachgruppen</Checkbox></Space> : ""}
                >
                    <Table
                        columns={columns}
                        dataSource={this.state.fachGroups}
                        loading={this.state.isLoading} 
                        pagination={
                            { defaultPageSize: 100, showSizeChanger: true, pageSizeOptions: ['50', '100', '250']}}
                        
                        />
                </Card>
            </div>
        );
    }
}
export default withTranslation()(withRouter(FachGroups));
