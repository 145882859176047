import React, { Component } from 'react';
import './NotFound.css';
import { Link } from 'react-router-dom';
import { Empty, Button, Card } from 'antd';

class NotFound extends Component {
    render() {
        return (
  
            <Card>
                  <Empty 
            description={
                <span>
                  Not Found
                </span>
              }
            />
            </Card>
        );
    }
}

export default NotFound;