import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { Table, Button, Popconfirm, message, PageHeader, Checkbox, Tag } from 'antd';
import { getAccessOverrides, deleteAccessOverride } from '../util/APIUtils';
import AccessOverrideForm from './AccessOverrideForm';
import moment from 'moment'


const AccessOverrides = () => {
    let useMyData = getAccessOverrides();
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [editingId, setEditingId] = useState(null);


    const { isLoading, error, data, refetch } = useMyData(
        (jsonData) => jsonData,
    );

    if (isLoading) {
        return <p>Wird geladen .....</p>;
    }

    if (error) {
        return <p>Error Wird geladen ..: {error.message}</p>;
    }

    const showForm = (id = null) => {
        setEditingId(id);
        setIsFormVisible(true);
    };
    const showEditForm = (id) => {
        console.log(id)
        setEditingId(id);
        setIsFormVisible(true);
    };

    const hideForm = () => {
        setIsFormVisible(false);
    };

    console.log(data)
    
    const handleDelete = (id) => {
        deleteAccessOverride(id)
            .then(response => {
                if (response) {
                    message.success('AccessOverride deleted successfully');
                    refetch();
                } else {
                    message.error('Failed to delete AccessOverride');
                }
            })
            .catch(error => {
                message.error('Error: ' + error.message);
            });
    };


    const columns = [
        // {
        //     title: 'ID',
        //     dataIndex: 'id',
        //     key: 'id',
        // },
        // {
        //     title: 'User ID',
        //     dataIndex: 'userId',
        //     key: 'userId',
        // },
        {
            title: 'Züchter',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'Access End Date',
            dataIndex: 'accessEndDate',
            key: 'accessEndDate',
            render: (text) => text ? moment(text).format('DD.MM.YYYY') : '',
        },

        {
            title: 'Plan Write',
            dataIndex: 'planWrite',
            key: 'planWrite',
            render: (text, record) => (
                <Checkbox checked={record.planWrite} disabled />
            ),
        },
        {
            title: 'Report Write',
            dataIndex: 'reportWrite',
            key: 'reportWrite',
            render: (text, record) => (
                <Checkbox checked={record.reportWrite} disabled />
            ),
        },
        {
            title: 'FG Write',
            dataIndex: 'fgWrite',
            key: 'fgWrite',
            render: (text, record) => (
                <Checkbox checked={record.fgWrite} disabled />
            ),
        },
        {
            title: 'ZSL Write',
            dataIndex: 'zslWrite',
            key: 'zslWrite',
            render: (text, record) => (
                <Checkbox checked={record.zslWrite} disabled />
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <span>
                    <Button type="link" onClick={() => showEditForm(record.id)}>Edit</Button>
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
                        <Button type="link">Delete</Button>
                    </Popconfirm>
                </span>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            render: (active) => (
              active ? <Tag color="green">Active</Tag> : <Tag color="orange">Inactive</Tag>
            ),
          },
        
    ];

    return (
        <div>
            <PageHeader title="FG Evaluation" className="site-page-header" />
            <br></br>
            <Button type="primary" onClick={() => showForm()} style={{ marginBottom: 16 }}>
                Add Access Override
            </Button>

            <Table columns={columns} dataSource={data} rowKey="id" />

            <AccessOverrideForm
                id={editingId}
                visible={isFormVisible}
                setVisible={hideForm}
                onRefresh={refetch}
            />
        </div>
    );
};

export default AccessOverrides;
