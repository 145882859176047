import React from 'react';
import { Table, Button, Tooltip, Space, Popconfirm, notification, message } from 'antd';
import {
  DownloadOutlined,
  DeleteOutlined

} from '@ant-design/icons';

import { downloadFile, deleteForumFile } from '../util/APIUtils';

const ForumList = ({ items, refetch }) => {

  const [loading, setLoading] = React.useState(false)
  const handleDownload = (id, fileName) => {
    downloadFile(id, fileName);
  };

  const onDelete = (id) => {
    console.log(id)
    let promise;
    promise = deleteForumFile(id);

    if (!promise) {
      return;
    }
    setLoading(true)
    promise.then(response => {
      setLoading(false);
      console.log(response)
      refetch();
      notification.success({ message: "Kultursaat", description: "Erfolgreich gelöscht", placement: 'topLeft' })

    }).catch(error => {
      console.log(error)
      setLoading(false)
      notification.error({ message: "Kultursaat", description: error, placement: 'topLeft' })
    });
  }


  const columns = [
    {
      width: '100',
      title: 'Jahr',
      dataIndex: 'year',
      key: 'year',
    },
    {
      width: '300',
      title: 'Züchter',
      dataIndex: 'ownerName',
      key: 'ownerName',
    },
    {
      title: 'Bezeichnung',
      dataIndex: 'designation',
      key: 'designation',
    },
    {
      title: 'Beschreibung',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Schlágwort',
      dataIndex: 'keywords',
      key: 'keywords',
    },
    {
      title: ' ',
      key: 'download',
      render: (_, item) => (
        item.filename && (
          <Space><Tooltip title={item.filename}>
            <Button type="primary" onClick={() => downloadFile(item.id, item.filename, 2)} icon={<DownloadOutlined />}>
            </Button></Tooltip>

          </Space>
        )
      ),
    },
    {
      title: ' ',
      key: 'delete',
      render: (_, item) => (
        item.editable && (
          <Space>
            <Popconfirm title="Löschen?" onConfirm={() => onDelete(item.id)}>
              <Button type="danger" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        )
      ),
    }
    // Add more columns for additional details if needed
  ];

  return (
    <Table
      size='small'
      bordered
      dataSource={items}
      loading={loading}
      columns={columns}
      rowKey="id"
      pagination={false}
    />
  );
};

export default ForumList;
