import { Select, Space, Card, Button, Form, Row, Col } from 'antd'
import { getAllBreedersWithFarmProfile, getAllCrops } from '../../util/APIUtils';
import React, { useState, useEffect } from 'react'
import { observable, computed } from "mobx";
import { observer, inject } from "mobx-react";
import BreederSelect from '../../common/BreederSelect';


const ProjectFilter = (props) => {
    const [loading, setLoading] = useState(false)
    const [cropsLoaded, setCropsLoaded] = useState(false)
    const [breedersLoaded, setBreedersLoaded] = useState(false)
    const [breeders, setBreeders] = useState([]);
    const [crops, setCrops] = useState([]);
    const { setFilters, clearFilters, setPagination, setProjectsAll } = props.projectsStore;
    const { filters } = props.projectsStore;

    const [form] = Form.useForm();

    const onFinish = (vals) => {
        console.log(vals)
        console.log(mapObjToFilter(vals, "form"))
        props.reloadProjects();
    }

    const loadBreeders = React.useCallback(async () => {
        if (breedersLoaded) return;
        setLoading(true)
        let promise;
        promise = getAllBreedersWithFarmProfile();

        if (!promise) {
            return;
        }
        

        promise.then(response => {
            setBreeders(response);
            setLoading(false)

        }).catch(error => {
            setLoading(false)
        });
    }, [])

    let initVals = {}


    const loadCrops = React.useCallback(async () => {
        if (cropsLoaded) return;
        let promise;
        promise = getAllCrops();

        if (!promise) {
            return;
        }
        setLoading(true)

        promise.then(response => {
            setCrops(_.sortBy(response, 'firstName'));
            setLoading(false)

        }).catch(error => {
            setLoading(false)
        });
    }, [])

    const getFormattedName = (breeder) => {
        let farmProfileName = breeder.farmProfiles?.name ? breeder.farmProfiles?.name : "-"
        return breeder.lastName + " " + breeder.firstName + " / " + farmProfileName
    }


    useEffect(() => {
        loadBreeders();
        loadCrops();
    }, [loadBreeders, loadCrops]);

    const breederChildren = [];
    breeders.forEach(element => {
        breederChildren.push(
            <Option
                label={getFormattedName(element)}
                value={element.id}
                key={element.id}>
                {getFormattedName(element)}
            </Option>);
    });

    const cropsChildren = [];
    crops.forEach(element => {
        cropsChildren.push(<Option label={element.name} value={element.id} key={element.id}>{element.name}</Option>);
    });

    const resetFilters = () => {
        console.log("resetting filers")
        clearFilters();
        form.resetFields();
        console.log(filters)
    }

    const mapObjToFilter = (val, type) => {
        let filterObj = {
            status: val.status ? val.status : [],
            projectTypes: val.projectTypes ? val.projectTypes : [],
            breederIds: type === "form" ? val.breederIds : breeders.map(elem => (
                elem.id
            )),
            // breederIds: val.breederIds ? val.breederIds : [],
            cropIds: type === "form" ? val.cropIds : crops.map(elem => (
                elem.id
            )),
            userProjectsOnly: val.userProjectsOnly,
            activeProjects: val.activeProjects,
        }
        setFilters(filterObj);
        console.log(filters)
    }

    initVals = filters;
    console.log(initVals)
    // const initVals = {}

    return (
        <Form name="reports-form" form={form} onFinish={onFinish} initialValues={initVals}>
            <Row gutter={3}>
                <Col order={1}>
                    <Form.Item
                        name="breederIds"
                    >
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            mode="multiple"
                            style={{ minWidth: '280px' }}
                            allowClear
                            loading={loading}
                            placeholder="Züchter"
                        >
                            {breederChildren}
                        </Select>

                    </Form.Item>
                </Col>
                <Col order={2}>
                    <Form.Item
                        name="cropIds"
                    >
                        <Select
                            showSearch
                            mode="multiple"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            style={{ minWidth: '150px' }}
                            allowClear
                            placeholder="Kulturen"
                        >
                            {cropsChildren}
                        </Select>
                    </Form.Item>
                </Col>
                <Col order={2}>
                    <Form.Item
                        name="projectTypes"
                    >
                        <Select placeholder="Typ"
                            mode="multiple"
                            style={{ minWidth: '90px', flex: 1 }}
                            allowClear
                        >
                            <Option label="NZ" value="NZ" key="NZ">NZ</Option>
                            <Option label="VA" value="VA" key="VA">VA</Option>
                            <Option label="EZ" value="EZ" key="EZ">EZ</Option>
                            <Option label="GL" value="GL" key="GL">GL</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col order={4}>
                    <Form.Item
                        name="status"
                    >
                        <Select placeholder={"Status"}
                            style={{ minWidth: '160px', flex: 1 }}
                            mode="multiple"
                            allowClear
                        >

                            <Option label="Erfolgreich abgeschlossen" value="erfolgreich abgeschlossen" key="erfolgreich abgeschlossen">Erfolgreich abgeschlossen</Option>
                            <Option label="Weiterhin aktuell" value="weiterhin aktuell" key="weiterhin aktuell">Weiterhin aktuell</Option>
                            <Option label="Abgebrochen" value="abgebrochen" key="abgebrochen">Abgebrochen</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col order={5}>
                    <Form.Item
                        name="userProjectsOnly"
                        initialValue={true}
                    >
                        <Select style={{ minWidth: 120, flex: 1 }}  >
                            <Option value={true}>Meine Projekte</Option>
                            <Option value={false}>Alle Projekte</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col order={6}>
                    <Form.Item
                        name="activeProjects"
                        initialValue={true}
                    >
                        <Select style={{ minWidth: 120, flex: 1 }}  >
                            <Option value={true}>Aktive Projekte</Option>
                            <Option value={false}>Alle Projekte</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col order={7}>
                    <Form.Item
                        name="submit"
                    >
                        <Button htmlType="submit" type='primary' style={{ minWidth: '50px', flex: 1 }}>Übernehmen </Button>
                    </Form.Item>
                </Col>
                <Col order={8}>
                    <Form.Item
                        name="submit"
                    >
                        <Button key="3" htmlType="reset" onClick={resetFilters} style={{ minWidth: '50px', flex: 1 }}>Filter löschen</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )



}

export default inject("projectsStore")(observer(ProjectFilter))
