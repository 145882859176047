import { DataGrid, deDE } from '@mui/x-data-grid';
import { Button, Card, Descriptions, Input, Space, message } from 'antd';
import React from 'react'
import { useEffect, useState } from 'react'
import { getSortenBio, saveSortenBio } from '../util/APIUtils';
import {
    randomCreatedDate,
    randomTraderName,
    randomUpdatedDate,
  } from '@mui/x-data-grid-generator';
import { currencyFormatter } from '../util/Helpers';

function SortenBiographie(props) {
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const projectId = props.projectId;
    const [state, setState] = useState({})
    const editable = props.reportEditable;

    const screenState = {
        "year": props.year,
        "tab": '7'
    }

    console.log(screenState)


    let fetchSortenBio = React.useCallback(async () => {
        
        setLoading(true)
        let promise;
        promise = getSortenBio(projectId);
        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response)
            setState(response);
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, [])

    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const onFinish = () => {


        var subsetObj = _.pick(state, [
            "id",
            "suggestedVarietyName",
            "suggestionPhoto",
            "description",
            "breedingCourse",
            "projectId"
        ]);
        console.log(subsetObj)
        subsetObj.projectId = projectId

        let promise;
        promise = saveSortenBio(subsetObj);

        if (!promise) {
            return;
        }

        promise.then(response => {
            message.success("Aktualisiert!")
            props.reloadProject(screenState);

        }).catch(error => {
            console.log(error)
            message.error("project update failed")
        });
    };

    useEffect(() => {
        fetchSortenBio();
    }, [fetchSortenBio]);

    const columns = [
        { field: 'name', headerName: 'Name', width: 180, editable: true },
        { field: 'age', headerName: 'Age', type: 'number', editable: true, format: currencyFormatter },
        {
          field: 'dateCreated',
          headerName: 'Date Created',
          type: 'date',
          width: 180,
          editable: true,
        },
        {
          field: 'lastLogin',
          headerName: 'Last Login',
          type: 'dateTime',
          width: 220,
          editable: true,
        },
      ];
      
      const rows = [
        {
          id: 1,
          name: randomTraderName(),
          age: 25,
          dateCreated: randomCreatedDate(),
          lastLogin: randomUpdatedDate(),
        },
        {
          id: 2,
          name: randomTraderName(),
          age: 36,
          dateCreated: randomCreatedDate(),
          lastLogin: randomUpdatedDate(),
        },
        {
          id: 3,
          name: randomTraderName(),
          age: 19,
          dateCreated: randomCreatedDate(),
          lastLogin: randomUpdatedDate(),
        },
        {
          id: 4,
          name: randomTraderName(),
          age: 28,
          dateCreated: randomCreatedDate(),
          lastLogin: randomUpdatedDate(),
        },
        {
          id: 5,
          name: randomTraderName(),
          age: 23,
          dateCreated: randomCreatedDate(),
          lastLogin: randomUpdatedDate(),
        },
      ];


    return (
        <div>
            <Card>
                <Descriptions
                    size="small"
                    bordered
                    style={{
                        marginBottom: 2
                    }}
                    extra=
                    {editable ? editing ? <Space> <Button type={"primary"} onClick={onFinish} >Speichern</Button><Button onClick={() => setEditing(false)}>Abbrechen</Button> </Space> : <Button type={"primary"} onClick={() => setEditing(true)}>Bearbeiten</Button>: ""}>
                    <Descriptions.Item span={3} label="Stand" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                        {editing && editable ?
                            <Input name="stand" value={""} disabled />
                            : state["stand"]}
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="Vorschläge Sortenname" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                        {editing && editable ?
                            <Input name="suggestedVarietyName" value={state["suggestedVarietyName"]} onChange={handleChange} />
                            : state["suggestedVarietyName"]}
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="Vorschläge Fotos" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                        {editing && editable ?
                            <Input.TextArea name="suggestionPhoto" value={state["suggestionPhoto"]} onChange={handleChange} />
                            : state["suggestionPhoto"]}
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="Sortenbeschreibung" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                        {editing && editable ?
                            <Input.TextArea name="description" value={state["description"]} onChange={handleChange} />
                            : state["description"]}
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="Züchtungsgang" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                        {editing && editable ?
                            <Input.TextArea name="breedingCourse" value={state["breedingCourse"]} onChange={handleChange} />
                            : state["breedingCourse"]}
                    </Descriptions.Item>
                </Descriptions>

            </Card>

            

        </div>
    )
}

export default SortenBiographie