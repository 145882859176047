import { Empty, List, Descriptions, Button, Space, notification, Form, Modal, Popconfirm } from 'antd';
import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Link, Prompt } from 'react-router-dom';
import moment from 'moment';
import { useTranslation, Trans } from 'react-i18next';
// import EditLine from './EditLine';
import { deleteSatz } from '../util/APIUtils';
import AddSatz from './AddSatz';

const SatzDetail = (props) => {
    const [editing, _setEditing] = useState();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const dateFormat = 'MMM-DD-YYYY';
    const [form] = Form.useForm();
    const satz = props.satzs;

    const setEditing = () => {
        _setEditing(!editing)
    }

    useEffect(() => form.resetFields(), [props.satz]);

    const onClose = () => {
        _setEditing(false);
        // notification.success({
        //     message: 'Kultursaat',
        //     description: "Line saved succesfully"
        // });
        props.fetchSatz();
    }
    const onFinish = (values) => {
        form.submit();
    }

    const onCloseAdd = () => {
        setEditing(false);
        props.fetchSatz();
    }

    const deleteSatzDetail = () => {
        let promise;
        promise = deleteSatz(satz.id);
        if (!promise) {
            return;
        }
        promise.then(response => {
            setLoading(false)
            notification.success({
                message: 'Kultursaat',
                description: "Erfolgreich gelöscht"
            });
            props.fetchSatz();
        }).catch(error => {
            console.log(error)
        });
    }

    return (
        <Fragment>
            {satz ?
                editing ?
                    <Modal width={1000} forceRender visible={editing} footer={[]} onCancel={onCloseAdd} destroyOnClose={true}>
                        <AddSatz satz={satz} onClose={onCloseAdd} projectDetailId={props.projectDetailId} satzType={props.satzType} projectInfo={props.projectInfo} fetchSatz={props.fetchSatz} editing={true} />
                    </Modal> :
                    <div>
                        <Descriptions
                            bordered
                            title="Dokumentation Sätze"
                            size={"small"}
                            extra={<div> {((props.satzType == "plan" && props.isPlanEditable) || (props.satzType == "report" && props.isReportEditable)) && <Space>
                                <Button type="primary" onClick={setEditing}>Bearbeiten</Button>
                                <Popconfirm
                                    title="Sicher löschen?"
                                    onConfirm={deleteSatzDetail}
                                >
                                    <Button type="danger" >Löschen</Button>
                                </Popconfirm>
                            </Space>}</div>}
                        >
                            <Descriptions.Item span={3} label="Bezeichnung">{satz.description}</Descriptions.Item>
                            <Descriptions.Item span={3} label="Anbauumfang">{satz.cultivationScope}</Descriptions.Item>
                            <Descriptions.Item span={3} label="geplate Aussaat">{satz.sowingDate ? moment(satz.sowingDate).format(dateFormat) : ""}</Descriptions.Item>
                            <Descriptions.Item span={3} label="geplate Pflanzung">{satz.plantingDate ? moment(satz.plantingDate).format(dateFormat) : ""}</Descriptions.Item>
                            <Descriptions.Item span={2} label="Vorfrucht">{satz.previousCrop}</Descriptions.Item>
                            <Descriptions.Item span={2} label="Düngung">{satz.fertilization}</Descriptions.Item>
                            <Descriptions.Item span={2} label="Bewässerung">{satz.irrigation} </Descriptions.Item>
                            <Descriptions.Item span={2} label="Anbaubesonderheiten">{satz.particularities}</Descriptions.Item>
                            <Descriptions.Item span={2} label="Mitbetr. BSAG sinnvoll">{satz.betrBsag}</Descriptions.Item>
                            <Descriptions.Item span={2} label="Vor Besuchstermin">{satz.visitDate} {satz.visitMonth ? moment(satz.visitMonth).format("MMM"): ""} </Descriptions.Item>
                        </Descriptions>

                        {/* <Modal width={800} forceRender visible={editing} onClose={onClose} onCancel={onClose}
                        footer={[
                        ]}
                    >
                        <EditLine line={line} onClose={onClose} />
                    </Modal> */}
                    </div> :
                <Empty description={t("lines.select_item")}></Empty>}
        </Fragment>
    );
};

export default SatzDetail;
