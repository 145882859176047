import React, { useState, useEffect, useRef } from 'react';
import { getLineSelections } from '../util/APIUtils';
import {
    Select,
} from 'antd';

function LineSelectionLookup({cropId, setItems, setFormattedItems,  existingItems = [] }) {
    const [itemList, setItemList] = useState([])
    const [loading, setLoading] = React.useState(false);
    const [selectedItems, setSelectedItems] = useState(existingItems.map(a => a.id));

    let fetchLines = React.useCallback(async () => {
        setLoading(true)
        let promise;
        promise = getLineSelections(cropId);
        if (!promise) {
            return;
        }

        promise.then(response => {
            setItemList(response.reverse());
            setLoading(false)

        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, [])

    useEffect(() => {
        fetchLines()
    }, [fetchLines]);

    const handleChange = selectedItems => {
        console.log(selectedItems)
        let formattedItems = selectedItems.length > 0 ? selectedItems.map(elem => ({ id: parseInt(elem) })) : [];
        setSelectedItems(selectedItems);
        setItems(selectedItems);
        setFormattedItems(formattedItems)
    };

    const filteredOptions = itemList.filter(o => !selectedItems.includes(o))
    return (
        <>
            <Select
                mode="multiple"
                loading={loading}
                placeholder="Gegenstände auswählen"
                value={[...new Set(selectedItems)]}
                onChange={handleChange}
                style={{ width: '500px' }}
            >
                {filteredOptions.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                        {item.name}
                    </Select.Option>
                ))}
            </Select>

        </>
    );
}

export default LineSelectionLookup