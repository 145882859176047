import React, { useState, useEffect, useRef } from 'react';
import { getLineSelections } from '../util/APIUtils';

import {
    Select,
} from 'antd';

function LineSelectionSingle({cropId, setItem, setNewItem, existingItem }) {
    const [itemList, setItemList] = useState([])
    const [loading, setLoading] = React.useState(false);
    const [selectedItem, setSelectedItem] = useState(existingItem);

    let fetchLines = React.useCallback(async () => {
        setLoading(true)
        let promise;
        promise = getLineSelections(cropId);
        if (!promise) {
            return;
        }

        promise.then(response => {
            setItemList(response);
            setLoading(false)

        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, [])

    useEffect(() => {
        fetchLines()
    }, [fetchLines]);

    const handleChange = selectedItem => {
        setSelectedItem(selectedItem);
        setItem(selectedItem);
        setNewItem(selectedItem);
    };

    const filteredOptions = itemList.filter(o => !selectedItem)
    return (
        <>
            <Select
                mode="single"
                loading={loading}
                placeholder="Gegenstände auswählen"
                value={selectedItem}
                onChange={handleChange}
                style={{ width: '500px' }}
            >
                {itemList.map(item => (
                    <Select.Option key={item.id} value={item.name}>
                        {item.name}
                    </Select.Option>
                ))}
            </Select>

        </>
    );
}

export default LineSelectionSingle