import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getFarmLocation } from '../../util/APIUtils';
import { withTranslation } from "react-i18next";
import Chart from "react-google-charts";
import { CloseCircleTwoTone,  CheckCircleTwoTone ,ClockCircleTwoTone, HourglassTwoTone  } from '@ant-design/icons';

import {
    Card,
    Table,
    Space,
} from 'antd';

class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            projectDetails: props.projectDetails
        };
    }

    componentDidMount() {
        const { id } = this.props.match.params
    }

    componentDidUpdate(nextProps) {
        const { id } = nextProps.match.params
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                isLoading: false,
                farmProfileId: id
            });
        }
    }
    
    prepateChartData() {
        let d = [["Year", "Budget", "Actual"]];
        
        this.state.projectDetails.forEach(function (arrayItem) {
            let budgetTotal = arrayItem.accountingBudgetTotal + arrayItem.examinationBudgetTotal;
            let actualTotal = arrayItem.accountingActualTotal + arrayItem.examinationActualTotal;
            let tArr = [arrayItem.year, budgetTotal, actualTotal]
            d.push(
                [arrayItem.year.toString(), budgetTotal, actualTotal]
            )
        });
        return d;
    }

    render() {
        const { t } = this.props;
        let chartData = this.prepateChartData();
        const columns = [
            {
                title: t("project_details.year"),
                dataIndex: 'year',
                key: 'year',
                width: 70,
            },
            {
                title: t("project_details.priority"),
                dataIndex: 'priorityFG',
                key: 'priorityFG',
                width: 70,
               
            },
            {
                title: t("project_details.fg_status"),
                dataIndex: 'statusFG',
                key: 'statusFG',
                width: 70,
                render: (text, row, index) => {
                    let status ;
                    switch(text) {
                        case "ok":
                            status = <CheckCircleTwoTone twoToneColor="#52c41a" />;
                            break;
                            case "zurückgestellt":
                                status = <HourglassTwoTone  twoToneColor="#ff928b"/>;    
                                break;
                            case "abgelehnt":
                                status = <CloseCircleTwoTone twoToneColor="#ff928b"/>;    
                                break;  
                            break;
                        default:
                            status = ""; 
    
                    }
                    return (
                        <span>{status}
                        </span>
                    )
                  },
            },
            {
                title: t("project_details.bewillig_status"),
                dataIndex: 'status',
                key: 'status',
                width: 70,
                render: (text, row, index) => {
                    let status ;
                    switch(text) {
                        case "ok":
                            status = <CheckCircleTwoTone twoToneColor="#52c41a" />;
                            break;
                        case "zurückgestellt":
                            status = <HourglassTwoTone  twoToneColor="#ff928b"/>;    
                            break;
                        case "abgelehnt":
                            status = <CloseCircleTwoTone twoToneColor="#ff928b"/>;    
                            break;
                        default:
                            status = ""; 
    
                    }
                    return (
                        <span>{status}
                        </span>
                    )
                  },
            },
            {
                title: t("project_details.fg_pate"),
                dataIndex: 'commentFG',
                key: 'commentFG',
                width: 500,
            },
            {
                title: t("project_details.board"),
                dataIndex: 'commentBoardOfDirectors',
                key: 'commentBoardOfDirectors',
                width: 400,
            },
            {
                title: t("project_details.office"),
                dataIndex: 'commentGF',
                key: 'commentGF',
                width: 400,
            },
        ]
        return (
            <Card bordered={true}>
                <Space direction="vertical">
                <Table
                    columns={columns}
                    dataSource={this.state.projectDetails.reverse()}
                    loading={this.state.isLoading}
                    pagination={false}
                />
                <div style={{ alignContent: "left"}}>
                    <Chart
                        height={300}
                        chartType="ColumnChart"
                        loader={<div>Loading Chart</div>}
                        data={
                            chartData
                        }
                        options={{
                            title: 'Budget Vs Actual',
                            chartArea: { width: '80%' },
                            hAxis: {
                                title: 'Year',
                                minValue: 0,
                            },
                            vAxis: {
                                title: 'Amount',
                            },
                        }}
                        legendToggle
                    /></div>
                    </Space>
            </Card>)
    }
}

export default withTranslation()(withRouter(Overview));