import React, { useState, useRef } from 'react'
import { GridContent } from '@ant-design/pro-layout';
import { Card, Button, Menu, Modal, notification, Space, Divider, Row, Col, Radio, Badge } from 'antd';
// import LineDetail from './LineDetail';
// import AddLine from './AddLine';
// import CopyLinesPopup from './CopyLinesPopup';
import SatzDetail from './SatzDetail';
import CopySatzPopup from './CopySatzPopup';
import { getAllSatzPlan, getAllSatzReport } from '../util/APIUtils';
import AddSatz from './AddSatz';
const { Item } = Menu;

function Satz(props) {
    console.log(props)
    const [adding, _setAdding] = useState();
    const [editing, setEditing] = useState(false);
    const projectDetailId = props.projectDetailId;
    const projectId = props.projectId;
    const [mode, setMode] = useState("plan")
    const [loading, setLoading] = useState(false)
    const [defaultSelected, setDefaultSelected] = useState(0)
    const [satz, setSatz] = useState(null)

    const [initConfig, setInitConfig] = useState({
        mode: 'inline',
        selectKey: 'myTasks',
    });
    const [satzs, setSatzs] = useState([]);
    const [selectedSatz, setSelectedSatz] = useState(0);
    const dom = useRef();

    const setAdding = () => {
        _setAdding(!adding)
    }
    const onClose = () => {
        _setAdding(false);
        fetchSatz();
    }

    const onCloseAdd = () => {
        _setAdding(false);
        setEditing(false)
        fetchSatz();
    }

    let fetchSatz = React.useCallback(async () => {
        let promise;
        setLoading(true)
        if (props.satzType == "plan") {
            promise = getAllSatzPlan(projectDetailId);
        } else {
            promise = getAllSatzReport(projectDetailId);
        }

        if (!promise) {
            return;
        }

        // this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response)
            setLoading(false)
            setSatzs(response);
            if (response.length > 0) {
                setDefaultSelected(response[0].id)
                setSelectedSatz(response[0])
                setInitConfig({ ...initConfig, selectKey: response[0].id });
            }

        }).catch(error => {
            console.log(error)

            // this.setState({ isLoading: false })
        });
    }, [mode])

    React.useEffect(() => {
        fetchSatz();
    }, [mode]);


    const setScreenMode = (e) => {
        setMode(e.target.value)
        fetchSatz(e.target.value);
    }

    const _setEditing = (item) => {
        
        setSatz(item)
        setEditing(true)
    }


    const getMenu = () => {
        return satzs.map((item) => <Item style={{ fontWeight: 600, borderBottom: "2px solid red !important" }} key={item.id}><Badge color={"purple"} text={item.description} /></Item>)
    };

    const renderChildren = () => {
        return (<SatzDetail
            satzs={satzs.find(x => x.id == selectedSatz)}
            fetchSatz={fetchSatz}
            isReportEditable={props.isReportEditable}
            isPlanEditable={props.isPlanEditable}
            onClose={onCloseAdd}
            projectDetailId={projectDetailId}
            satzType={props.satzType} 
            projectInfo={props.projectInfo}

        />);
    };

    console.log(defaultSelected)

    return (
        <Card
            loading={loading}
        >

            <GridContent>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        paddingTop: '16px',
                        paddingBottom: '16px'
                    }}
                    ref={(ref) => {
                        if (ref) {
                            dom.current = ref;
                        }
                    }}
                >
                    <div style={{ width: '275px' }}>
                        <Menu
                            mode={initConfig.mode}
                            defaultSelectedKeys={[defaultSelected.toString()]}
                            // selectedKeys={[initConfig.selectKey]}
                            onClick={({ key }) => {
                                console.log(key)
                                setSelectedSatz(key)
                                setInitConfig({ ...initConfig, selectKey: key });
                            }}
                        >
                            {getMenu()}
                            <Divider></Divider>
                            {((props.satzType == "plan" && props.isPlanEditable) || (props.satzType == "report" && props.isReportEditable)) ?
                                <Row justify="center">
                                    <Col span={8}>
                                        <Button onClick={setAdding}>+ Satz</Button>
                                    </Col>
                                    <Col span={8}>
                                    {(props.satzType == "report" && props.isReportEditable) && <CopySatzPopup projectDetailId={projectDetailId} projectId={projectId} year={props.year} fetchSatz={fetchSatz} />}
                                        </Col>
                                </Row> : ""}
                            {/* {props.isPlanEditable ? <Space align='center'>
                                <Button onClick={setAdding}>+ Satz</Button>
                                <CopySatzPopup projectDetailId={projectDetailId} projectId={projectId} year={props.year} fetchSatz={fetchSatz} />
                            </Space> : ""} */}
                            <Modal width={1000} forceRender visible={adding || editing} footer={[]} onCancel={onCloseAdd} destroyOnClose={true}>
                                <AddSatz satz={satz} onClose={onCloseAdd} projectDetailId={projectDetailId} satzType={props.satzType} projectInfo={props.projectInfo} fetchSatz={fetchSatz} />
                            </Modal>
                        </Menu>

                    </div>

                    <div style={{
                        flex: 1,
                        padding: '8px 40px'
                    }}>

                        {renderChildren()}

                    </div>
                </div>
            </GridContent>
        </Card>
    );
}

export default Satz;
