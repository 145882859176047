import React, { Component } from 'react'
import {
    Badge,
    Card,
    Descriptions,
    Divider,
    Table,
    Layout,
    Input,
    Row,
    Col,
    Typography,
    PageHeader,
    Select,
    List,
    Avatar,
    Tag,
    Space,
    Button,

} from 'antd';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";

import { getYears, getZslData, getZslReportLink } from '../util/APIUtils';
import { dynamicSort, formatCurrency } from '../util/Helpers';

import './zsl.css'


import {

    FileExcelOutlined,
    FileTextOutlined,
    FileMarkdownOutlined,
    FileWordOutlined
} from '@ant-design/icons';



export class ZslReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            year: null,
            format: "word",
            projectYears: [],
            zslData: [],
            isLoading: false,
        };
    }

    fetchYears() {
        this.setState({ isLoading: true });
        let promise;
        promise = getYears();
        if (!promise) {
            return;
        }

        promise.then(response => {
            this.setState({ projectYears: response.reverse() })
            this.setState({ isLoading: false });

        }).catch(error => {
            this.setState({ isLoading: false });
        });
    }

    fetchZslData(year) {
        this.setState({ isLoading: true });
        let promise;
        promise = getZslData(year);
        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response)
            const sumReducer = (sum, item, fieldName) =>
                sum + (Number(item[fieldName]) || 0);

            const totalProjectAmountWithFg = response.reduce((sum, item) =>
                sumReducer(sum, item, 'totalVaExternBudget'), 0);
            const totalPayoutAmountWithFg = response.reduce((sum, item) =>
                sumReducer(sum, item, 'totalVaExternPayout'), 0);
            const totalProjectAmount = response.reduce((sum, item) =>
                sumReducer(sum, item, 'totalProjectAmount'), 0);
            const totalPayoutAmount = response.reduce((sum, item) =>
                sumReducer(sum, item, 'totalPayoutAmount'), 0);

            // Append totals to the response data
            const totalRow = {
                breeder: 'Externer Versuchsanbau (z.B. Kandidatensichtung)',
                totalProjectAmountWithFg,
                totalPayoutAmountWithFg
            };
            const grandTotalRow = {
                breeder: 'Gesamt',
                totalProjectAmountWithFg: response.reduce((sum, item) =>
                sumReducer(sum, item, 'totalProjectAmountWithFg'), 0),
                totalPayoutAmountWithFg:response.reduce((sum, item) =>
                sumReducer(sum, item, 'totalPayoutAmountWithFg'), 0)
            };

            this.setState({
                zslData: [...response, totalRow, grandTotalRow],
                isLoading: false
            });

            console.log(this.state.zslData);

        }).catch(error => {
            console.log(error)
            this.setState({ isLoading: false });
        });
    }

    handleYearInput = (val) => {
        console.log(val);
        this.setState({
            year: val
        })

        console.log(this.state.year);
        this.fetchZslData(val);
    }

    handleBack = e => {
        this
            .props
            .history
            .goBack();
    };

    componentDidMount() {
        if (!this.props.isAuthenticated) {
            this
                .props
                .history
                .push("/login");
        }

        this.fetchYears();

    }

    getReport = (format) => {
        const win = window.open(getZslReportLink(this.state.year, this.state.format), '_blank');
        if (win != null) {
            win.focus();
        }

    }



    render() {

        let columns = [
            {
                title: "Züchter",
                dataIndex: 'breeder',
                key: 'breederShortName',
                width: 200,
                sortDirections: ['descend', 'ascend'],
                render: (text, record) => {
                    return (
                        record["farmProfileName"] ? <span><b>{record["breeder"]}</b>, {record["farmProfileName"]}</span>:  <span><b>{record["breeder"]}</b></span>
                    )
                },
            },
            {
                title: "Bedarf(Projekte und FG)",
                dataIndex: 'totalProjectAmountWithFg',
                key: 'totalProjectAmountWithFg',
                align: "right",
                width: 160,
                render: (text, row, index) => {
                    return (
                        <span><b>
                            {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}
                        </b>
                        </span>
                    )
                },

                // width: 300,
            },
            {
                title: "Auszahlbetrag(Projekte und FG)",
                dataIndex: 'totalPayoutAmountWithFg',
                key: 'totalPayoutAmountWithFg',
                align: "right",
                width: 160,
                render: (text, row, index) => {
                    return (
                        <span><b>
                            {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}
                        </b>
                        </span>
                    )
                },

            }];

        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    onBack={() => this.handleBack()}
                    title={"ZSL-Bericht"}
                // extra={<Button type='primary'>Add</Button>}
                // subTitle={this.state.farmProfile.name}
                />

                {this.state.year &&
                    <Card bordered={true} headStyle={{ padding: "0" }} loading={this.state.isLoading}>
                        <Space>
                            <Select style={{ width: '140px' }} onChange={(val) => this.setState({ format: val })} placeholder={"format"}>
                                <Select.Option key={2} value="rtf"> RTF</Select.Option>
                                <Select.Option key={4} value="word"> Word</Select.Option>
                                <Select.Option key={3} value="html"> HTML</Select.Option>
                            </Select>
                            <Button onClick={this.getReport}>
                                Download
                            </Button>
                        </Space>
                    </Card>
                }

                <Card bordered={true} headStyle={{ padding: "0" }} loading={this.state.isLoading}
                // extra={this.state.year ? <Space>
                //     <FileTextOutlined style={{ fontSize: '28px', color: '#08c' }} onClick={this.getReport("rtf")} />
                //     <FileWordOutlined style={{ fontSize: '28px', color: '#08c' }} onClick={this.getReport("word")} />
                //     <FileMarkdownOutlined style={{ fontSize: '28px', color: '#08c' }} onClick={this.getReport("html")}
                //     />
                // </Space> : ""}
                >

                    <Space>
                        <Select style={{ width: '140px' }} onChange={this.handleYearInput} defaultValue={this.state.year} placeholder={"Jahr"}>
                            {this.state.projectYears.map(item => (
                                <Select.Option key={item.projectYear} value={item.projectYear}>
                                    {item.projectYear}
                                </Select.Option>
                            ))}
                        </Select>
                    </Space><Divider></Divider>
                    {this.state.zslData.length > 0 && <span>
                        <Table
                            size='small'
                            rowClassName={(record, index) => {
                                if (record.breeder === 'Externer Versuchsanbau (z.B. Kandidatensichtung)') {
                                    return 'total-row';
                                } else if (record.breeder === 'Gesamt') {
                                    return 'grand-total-row';
                                }
                                return ''; // Return empty string or another class for other rows
                            }}
                            columns={columns}
                            style={{ fontSize: '10px' }}
                            dataSource={this.state.zslData}
                            loading={this.state.isLoading}
                            pagination={false} />
                        <br></br>
                        <h3>Berichte der Projekte im Einzelnen</h3>

                        {this.state.zslData.map(itemZsl => (
                            <div>
                                <Descriptions bordered column={1}>
                                    <Descriptions.Item label="Projektleiter" span={3} labelStyle={{ 'width': '150px' }}>{itemZsl.breeder}</Descriptions.Item>
                                    <Descriptions.Item label="Zuchtstandort" span={3}>{itemZsl.farmProfileName} <br/> {itemZsl.breederAddress}</Descriptions.Item>
                                    <Descriptions.Item span={3}>
                                        <div>
                                            <List
                                                itemLayout="horizontal"
                                                style={{ 'backgroundColor': '#F7F7F7' }}
                                                dataSource={itemZsl.breederCropForecastInfos}
                                                loading={this.state.isLoading}
                                                renderItem={item => (
                                                    <div>
                                                        <h4 style={{ 'paddingLeft': '10px' }}><i>{item.cropName}</i></h4>
                                                        <List.Item>
                                                            <List.Item.Meta
                                                                // title={<Card title="Aúsblick" size='small' >
                                                                //     {item.outlook}
                                                                // </Card>}
                                                                description={<span>
                                                                    <Card title="Hintergrund" size='small'>
                                                                        {item.background}
                                                                    </Card>
                                                                    <Card title="Tätigkeit" size='small'>
                                                                        {item.activity}
                                                                    </Card>

                                                                </span>

                                                                }
                                                            />

                                                        </List.Item>

                                                    </div>


                                                )}


                                            />


                                        </div>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Auszahlbetrag" span={3}><b>{formatCurrency(itemZsl.totalPayoutAmountWithFg)}</b></Descriptions.Item>
                                </Descriptions>
                                <Divider></Divider><br></br>
                            </div>


                        ))}

                    </span>}

                </Card>

            </div>
        )
    }
}

export default withTranslation()(withRouter(ZslReport));
