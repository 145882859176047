import {
    DataGrid, useGridApiContext, deDE, useGridApiRef, GridToolbarContainer, GridRowModes,
    GridActionsCellItem,
} from '@mui/x-data-grid';
import { NumericFormat } from 'react-number-format';
import { styled } from '@mui/material/styles';
import uuid from "uuid";
import Snackbar from '@mui/material/Snackbar';

import { Button, Select, Card, Descriptions, Input, Space, message, InputNumber, Divider, Popconfirm } from 'antd';
import React from 'react'
import { useEffect, useState, useMemo, useRef } from 'react';

import { currencyFormatter, currencyParser, formatCurrency } from '../../util/Helpers';
import { DeleteOutlined, PlusCircleOutlined, SaveOutlined, PlusOutlined } from '@ant-design/icons';
import { getExaminationItems, deleteExaminationItem, getAccountingItems, getProjectDetailAccountingItems, getProjectDetailExaminationItems, updateAccountingItem, updateExaminationItem } from '../../util/APIUtils';
import { Box } from '@mui/system';
import SelectEditInputCell from '../../common/SelectEditInputCell';
import { CheckCircleOutline, CheckBoxOutlined, CancelOutlined } from '@mui/icons-material';


const useFakeMutation = () => {
    return;
};


const ExaminationSummary = (props) => {
    const [loading, setLoading] = useState(false);
    const projectDetailId = props.projectDetailId;
    const isPlanEditable = props.isPlanEditable;
    const isReportEditable = props.isReportEditable;
    const [data, setData] = useState(props.data || []);
    const [snackbar, setSnackbar] = React.useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [budgetTotal, setBudgetTotal] = useState(0);
    const [actualTotal, setActualTotal] = useState(0);
    const projectNumber =  props.projectNumber;

    const loadExaminationItems = React.useCallback(async () => {
        let promise;
        promise = getProjectDetailExaminationItems(projectDetailId);

        if (!promise) {
            return;
        }
        setLoading(true);
        promise.then(response => {
            response.push(createSummaryData(response));
            setData(response);

        }).catch(error => {
            console.log(error);
            setLoading(false)
        });
    }, [])

    useEffect(() => {
        loadExaminationItems();
    }, [loadExaminationItems]);

    const createSummaryData = (response) => {
        let summaryRow = createNewRow("sum")
        summaryRow.examinationItem = projectNumber + " - Untersuchungen Gesamt";
        // summaryRow.examinationItem = "Gesamt"
        summaryRow.amountBudget = response.reduce((a, b) => a + (b['amountBudget'] || 0), 0).toFixed(2)
        summaryRow.amountActual = response.reduce((a, b) => a + (b['amountActual'] || 0), 0).toFixed(2)
        summaryRow.quantityBudget = response.reduce((a, b) => a + (b['quantityBudget'] || 0), 0).toFixed(2)
        summaryRow.quantityActual = response.reduce((a, b) => a + (b['quantityActual'] || 0), 0).toFixed(2)
        summaryRow.budgetTotal = response.reduce((a, b) => a + (b['budgetTotal'] || 0), 0).toFixed(2)
        // summaryRow.actualTotal = response.reduce((a, b) => a + (b['actualTotal'] || 0), 0).toFixed(2)
        summaryRow.actualTotal = response.filter(a => a.actualSelected == true).reduce((a, b) => a + (b['actualTotal'] || 0), 0).toFixed(2)
        summaryRow.actualSelected = "-"
        return summaryRow;
    }


    
    const handleParentClick = (e) => {
        e.stopPropagation();
    }

    const renderActualSelected = (row) => {
        if(row.row.id === 108080) {
            return "";
        } else {
            return row.row.actualSelected ? <CheckCircleOutline style={{ fill: "green" }} /> : <CancelOutlined style={{ fill: 'orangered' }} />
        }
    }



    const createNewRow = (type) => {
        return {
            id: type == "sum" ? 108080 : 0,
            examinationItem: "",
            actualTotal: 0,
            budgetTotal: 0,
            quantityBudget: 0,
            quantityActual: 0,
            amountActual: 0,
            amountBudget: 0,
            isNew: true,
            projectDetailId: projectDetailId,
            budget: isPlanEditable,
            actual: isReportEditable,
            rowType: type

        }
    }

    const deleteSum = (arr) => {
        let localArr = [...arr];
        localArr.splice(localArr.findIndex(function (i) {
            return i.id === 108080;
        }), 1);
        return localArr;
    }

    const renderItemName = (params) => {
        if (params.row.customType) {
            return params.row.customItem;
        } else {
            return params.row.examinationItem;
        }
    }


    const columnGroupingModel = [
        {
            groupId: 'Menge',
            description: '',
            headerAlign: 'center',
            children: [{ field: 'quantityBudget' }, { field: 'quantityActual' }],
        },
        {
            groupId: 'Einzelpreis',
            description: '',
            headerAlign: 'center',
            children: [{ field: 'amountBudget' }, { field: 'amountActual' }],
        },
        {
            groupId: 'Gesamt',
            description: '',
            headerAlign: 'center',
            children: [{ field: 'actualTotal' }, { field: 'actualSelected' }, { field: 'budgetTotal' }],
        },
    ];



    const columns = [
        { field: 'examinationItemId', headerName: 'Id', hide: true, editable: true },
        { field: 'budget', headerName: 'budget', hide: true, editable: true },
        { field: 'actual', headerName: 'actual', hide: true, editable: true },
        
        {
            field: 'examinationItem',
            headerName: 'Untersuchungen/Saatgutdiagnostik',
            flex: 1,
            minWidth: 400,
            editable: true,
        },
        {
            field: 'quantityBudget',
            headerName: 'Kalkulation',
            valueFormatter: ({ value }) => currencyFormatter(value),
            // valueGetter: ({ value }) => currencyParser(value),
            headerAlign: 'right',
            align: 'right',
            minWidth: 50,
            flex: 1
        },
        {
            field: 'quantityActual',
            headerName: 'Abrechnung',
            valueFormatter: ({ value }) => currencyFormatter(value),
            // type: 'number',
            minWidth: 50,
            flex: 1,
            headerAlign: 'right',
            align: 'right'
        },
        {
            field: 'amountBudget',
            headerName: 'Kalkulation',
            valueFormatter: ({ value }) => currencyFormatter(value),
            // type: 'number',
            minWidth: 50,
            flex: 1,
            headerAlign: 'right',
            align: 'right'
        },
        {
            field: 'amountActual',
            headerName: 'Abrechnung',
            headerClassName: 'table-header',
            valueFormatter: ({ value }) => currencyFormatter(value),
            // type: 'number',
            minWidth: 50,
            flex: 1,
            headerAlign: 'right',
            align: 'right',

        },
        {
            field: 'budgetTotal',
            headerName: 'Kalkulation',
            valueFormatter: ({ value }) => formatCurrency(value),
            // renderEditCell: renderCurrencyInputCell,
            type: 'number',
            minWidth: 100,
            flex: 1,
            // editable: true,
            headerAlign: 'right',
            align: 'right',
            // valueGetter: getTotalBudget,
            // valueSetter: setTotalBudget,
            // valueParser: parseTotalBudget
        },
        { field: 'actualSelected', headerName: '-', minWidth: 20, type: 'boolean', flex: 1, renderCell: renderActualSelected},
        {
            field: 'actualTotal',
            headerName: 'Abrechnungsbetrag',
            valueFormatter: ({ value }) => formatCurrency(value),
            // renderEditCell: renderCurrencyInputCell,
            type: 'number',
            minWidth: 100,
            flex: 1,
            // editable: true,
            headerAlign: 'right',
            align: 'right',
            // valueGetter: getTotalActual,
            // valueSetter: setTotalBudget,
            // valueParser: parseTotalBudget
        },

    ];



    

    function CustomFooter(props) {
        return (
            <Box sx={{ fontWeight: "600", border: `1px solid #f0f0f0`, backgroundColor: "#f1f7ee", padding: "0px", display: "flex", justifyContent: "right", alignItems: "right" }}></Box>)

    }

    const checkEditable = (param) => {
        if (param.row.rowType === "sum") {
            return false;
        }
        if (param.colDef.field === "examinationItem" && param.row.budget && isPlanEditable) {
            return true;
        }

        if (param.colDef.field === "examinationItem" && param.row.actual && isReportEditable) {
            return true;
        }

        if (isPlanEditable && ['quantityBudget', 'amountBudget'].includes(param.colDef.field)) {
            return true;
        }

        if (isReportEditable && ['actualSelected', 'quantityActual', 'amountActual'].includes(param.colDef.field)) {
            return true;
        }

        return false;

    }

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        border: 1,
        color:
            theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        "& .MuiDataGrid-columnHeaders": { display: "none" },
        "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            height: "20px",
            // backgroundColor: '#ccc',
            border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            height: "20px",
            border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnHeader': {
            headerRowHeight: "22!important",
            backgroundColor: '#f1f7ee',
        },
        '& .MuiDataGrid-cell': {

            color:
                theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
        },
        '& .MuiPaginationItem-root': {
            borderRadius: 0,
        },

    }));

    return (



        <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
                {/* <Space direction='vertical'> */}

                <br></br>
                <Box
                    sx={{
                        width: '100%',
                        '& .budget': {
                            backgroundColor: '#dde5b6',
                            color: 'e8e8e4',
                        },
                        '& .actual': {
                            backgroundColor: '#fae1ca',
                            color: '#1a3e72',
                        },
                        '& .sum-row': {
                            backgroundColor: '#f1f7ee',
                            color: '#1a3e72',
                            fontWeight: 800
                        },
                    }}
                >
                    <StyledDataGrid
                        disableColumnFilter
                        disableClickEventBubbling
                        disableSelectionOnClick
                        autoHeight
                        rowHeight={27}
                        headerRowHeight={27}
                        rows={data}
                        columns={columns}
                        experimentalFeatures={{ newEditingApi: true, columnGrouping: true }}
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        // hideFooter
                        columnGroupingModel={columnGroupingModel}
                        disableColumnMenu
                        isCellEditable={checkEditable}
                        getCellClassName={(params) => {
                            if (['quantityBudget', 'amountBudget'].includes(params.field)) {
                                return 'budget';
                            } else if (['quantityActual', 'amountActual'].includes(params.field)) {
                                return 'actual'
                            }

                        }}
                        getRowClassName={(params) => {
                            if (params.row.rowType === "sum") {
                                return 'sum-row'
                            }
                        }}
                        // editMode="row"
                        // rowModesModel={rowModesModel}
                        // onRowEditStart={handleRowEditStart}
                        // onRowEditStop={handleRowEditStop}
                        // processRowUpdate={processRowUpdate}
                        // components={{
                        //     Toolbar: EditToolbar,
                        // }}
                        // componentsProps={{
                        //     toolbar: { setData, setRowModesModel },
                        // }}
                        components={{
                            Footer: CustomFooter
                        }}
                        componentsProps={{
                            footer: { budgetTotal, actualTotal }
                        }}
                    />
                </Box>

                {/* </Space> */}
            </div>
        </div>

    )
}

export default ExaminationSummary