import React, { useState, useEffect } from 'react';
import { Table, Select, Tag, PageHeader, message, Button, Space } from 'antd';
import { getPdmForYear } from '../util/APIUtils';
import { exportToExcel, formatCurrency } from '../util/Helpers';

import { Link } from 'react-router-dom';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import BoardReviewModal from './BoardReviewModal';
import YearSelect from '../common/YearSelect';



const { Option } = Select;

const getStatusTag = (status) => {
    switch (status) {
        case 'ok':
            return <Tag color="#87d068" style={{ float: 'right', width: '100px' }} >OK</Tag>;
        case 'abgelehnt':
            return <Tag color="#f50" style={{ float: 'right', width: '100px' }} >Abgelehnt</Tag>;
        case 'zurückgestellt':
            return <Tag color="#ffc300" style={{ float: 'right', width: '100px' }}>Zurückgestellt</Tag>;
        default:
            return <Tag style={{ float: 'right', width: '100px' }} >Kein Status</Tag>;
    }
}





const subtotalRow = (data, breederName) => (
    <Table.Row style={{ fontWeight: 'bold' }}>
        <Table.Cell colSpan={3} align="right">
            Subtotal for {breederName}:
        </Table.Cell>
        <Table.Cell align="right">
            ${data.reduce((sum, row) => sum + row.amount, 0)}
        </Table.Cell>
    </Table.Row>
);


const ReviewStatusProjects = () => {
    const [projects, setProjects] = useState([]);
    const [selectedYear, setSelectedYear] = useState("2023");
    // const [filterValues, setFilterValues] = useState(JSON.parse(localStorage.getItem('filterValues') || '{}'));
    // useEffect(() => {
    //     setFilterValues(JSON.parse(localStorage.getItem('filterValues') || '{}')); // Retrieve the selected filter values from localStorage when the component is mounted
    //   }, []);

    let useMyData = getPdmForYear(selectedYear);

    const handleYearChange = (year) => {
        setSelectedYear(year);
        // refetch()
    };

    const { isLoading, error, data, refetch } = useMyData(
        (jsonData) => jsonData,
    );

    if (isLoading) {
        return <p>Wird geladen .....</p>;
    }

    if (error) {
        return <p>Error Wird geladen ..: {error.message}</p>;
    }



    const columns = [

        {
            title: 'Züchter/Züchterin',
            dataIndex: 'breederName',
            key: 'breederName',
        },
        {
            title: 'Projekt No',
            dataIndex: 'projectNumber',
            key: 'projectNumber',
            render: (text, record) => {
                let linkText = `/project/detail/${record.projectId}`
                return (
                    <span><b>
                        <Link
                            to={{
                                pathname: linkText,
                                state: {
                                    id: record.projectId,
                                }
                            }}>{text}</Link>
                    </b>
                    </span>
                )
            },
        },
        {
            title: 'Kulturart',
            dataIndex: 'cropName',
            key: 'cropName',
        },
        {
            title: 'KS Plan',
            dataIndex: 'ks_plan',
            key: 'ks_plan',
            render: (text, record) => {
                return (
                    text == 1 ? <CheckCircleTwoTone twoToneColor={'green'} /> : <CloseCircleTwoTone twoToneColor={'orange'} />
                )
            },
            filters: [
                {
                    text: 'Ja',
                    value: 1,
                },
                {
                    text: 'Nein',
                    value: 0,
                },
            ],
            onFilter: (value, record) => {
                return record.ks_plan === value
            }

        },
        {
            title: 'artva',
            dataIndex: 'artva',
            key: 'artva',
            render: (text, record) => {
                return (
                    text != 3 ? <CheckCircleTwoTone twoToneColor={'green'} /> : <CloseCircleTwoTone twoToneColor={'orange'} />
                )
            },
            filters: [
                {
                    text: 'Ja',
                    value: 1,
                },

            ],
            onFilter: (value, record) => {
                if (value = 1) {
                    return record.ks_plan !== 3
                } else {
                    return record.ks_plan === 3
                }

            }

        },
        {
            title: 'Betrag',
            dataIndex: 'amount',
            key: 'amount',
            align: 'right',
            render: (text) => <b>{formatCurrency(text)}</b>
        },
        {
            title: 'FG Status',
            dataIndex: 'statusFG',
            key: 'statusFG',
            filters: [
                {
                    text: 'OK',
                    value: 'ok',
                },
                {
                    text: 'Abgelehnt',
                    value: 'abgelehnt',
                },
                {
                    text: 'Zurückgestellt',
                    value: 'zurückgestellt',
                },
                {
                    text: 'Kein Status',
                    value: 'none',
                },
            ],
            sorter: (a, b) => a.statusFG.localeCompare(b.statusFG),
            onFilter: (value, record) => {
                // const filterValues = JSON.parse(localStorage.getItem('filterValues') || '{}');
                // filterValues['statusFG'] = value;
                // localStorage.setItem('filterValues', JSON.stringify(filterValues));
                return record.statusFG.indexOf(value) === 0;
              },
            //   defaultFilteredValue: [filterValues['statusFG']], // Set the default selected filter value using the filterValues object
              render: (text, record) => {
                return getStatusTag(text)
              }
        },
        {
            title: 'FG Betrag',
            dataIndex: 'amountFG',
            key: 'amountFG',
            align: 'right',
            render: (text) => <b>{formatCurrency(text)}</b>
        },
        {
            title: 'Vorstand Status',
            dataIndex: 'status',
            key: 'status',
            filters: [
                {
                    text: 'OK',
                    value: 'ok',
                },
                {
                    text: 'Abgelehnt',
                    value: 'abgelehnt',
                },
                {
                    text: 'Zurückgestellt',
                    value: 'zurückgestellt',
                },
                {
                    text: 'Kein Status',
                    value: 'none',
                },
            ],
            onFilter: (value, record) => {
                // const filterValues = JSON.parse(localStorage.getItem('filterValues') || '{}');
                // filterValues['status'] = value;
                // localStorage.setItem('filterValues', JSON.stringify(filterValues));
                return record.status.indexOf(value) === 0;
              },
            //   defaultFilteredValue: [filterValues['status']], // Set the default selected filter value using the filterValues object
              render: (text, record) => {
                return getStatusTag(text)
              }
        },
        {
            title: 'Vorstand Betrag',
            dataIndex: 'payoutAmount',
            key: 'payoutAmount',
            align: 'right',
            render: (text) => <b>{formatCurrency(text)}</b>
        },
        {
            title: 'Vorstand Review',
            dataIndex: 'status',
            key: 'status',

            render: (text, record) => {
                return <BoardReviewModal 
                projectDetailId={record.projectDetailId} 
                refetch={refetch} 
                comment={record.commentBoardOfDirectors} 
                approvedAmount={record.payoutAmount} 
                approvalStatus={record.status} 
                projectNumber={record.projectNumber} 
                projectAmount={record.amount}
                amountFg={record.amountFG}
                statusFg={record.statusFG}
                commentsFg={record.commentFG}
                />

                // {getStatusTag(text)}
            }
        },


    ];

    let new_updated_data = data.map((item) => {
        if (!item.statusFG) {
            return {
                ...item,
                statusFG: "none",
            };
        }
        return item;
    });

    new_updated_data = new_updated_data.map((item) => {
        if (!item.status) {
            return {
                ...item,
                status: "none",
            };
        }
        return item;
    });



    const groupedData = _.groupBy(new_updated_data, 'breederName');

    const handleExport = () => {
        const columnsToSelect = ['breederName', 'projectNumber', 'cropName', 'ks_plan', 'artva', 'amount', 'statusFG', 'amountFG', 'status', 'payoutAmount' ];
        const filteredData = data.map(item => {
            const filteredItem = {};
            for (const key in item) {
                if (columnsToSelect.includes(key) && item.hasOwnProperty(key)) {
                    filteredItem[key] = item[key];
                }
            }
            return filteredItem;
        });
        exportToExcel(filteredData);
    };

    return (
        <div>
            <PageHeader title="Projekt Evaluation"
                className="site-page-header"
            />
            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Space>
                <YearSelect
                    setItem={handleYearChange}
                    selectedItem={selectedYear} />

                                <Button onClick={handleExport}>Export to Excel</Button>
                </Space>
            </div>
            <Table
                size='small'
                columns={columns}
                dataSource={new_updated_data}
                rowKey={(record) => record.id}
                pagination={false}
            >

            </Table>

        </div>
    );
}

export default ReviewStatusProjects;
