import React, { useState, useEffect } from 'react';
import { PageHeader, Button, Select, Modal, notification, Space, Divider, Row, Col, Radio, Badge, Table, Text } from 'antd';

import { getResourceList, getResourceListByItemIdAndMediaType } from '../util/APIUtils';
const { Option } = Select;
import { Link } from 'react-router-dom';
import ForumList from './ForumList';
import CropSelect from '../common/CropSelect';
import AddFile from './AddFile';

function ForumHome(props) {
    const [cropId, setCropId] = useState(1)
    const currentUser = props.currentUser

    let useMyData = getResourceListByItemIdAndMediaType(cropId, 2);

    const { isLoading, error, data, refetch } = useMyData(
        (jsonData) => jsonData,
    );

    if (isLoading) {
        return <p>Wird geladen .....</p>;
    }

    if (error) {
        return <p>Error Wird geladen ..: {error.message}</p>;
    }
    console.log(data)

    return (
        <div>
            <Row gutter={16}>
                <Col span={16}>
                    <Space>Sonstige dokumente für kulturart -
                        <CropSelect selectedItem={cropId} setItem={setCropId} style={{ width: '200px' }} />
                    </Space>
                </Col>
                <Col xs={{ span: 4, offset: 1 }} lg={{ span: 6, offset: 2 }} align='end'>
                    <AddFile currentUser={currentUser} refetch={refetch} />
                </Col>
            </Row>
            <br></br>
            <ForumList items={data} currentUser={currentUser} refetch={refetch} />
        </div>
    )


}

export default ForumHome;
