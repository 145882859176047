import React from 'react'
import {Button, Popconfirm} from 'antd';

function DeleteWithConfirm(props) {

    const cancel = (e) => {};

    return (
        <Popconfirm
            title="Sind Sie sicher, dass Sie löschen möchten?"
            onConfirm={props.confirm}
            onCancel={cancel}
            okText="Ok"
            cancelText="Abbrechen"
        >
            <Button key="del-bt"  type="primary" danger>{"Löschen"}</Button>
        </Popconfirm>
    )
}

export default DeleteWithConfirm