
import {
    Badge,
    Card,
    Descriptions,
    Button,
    Table,
    Layout,
    Input,
    Row,
    Col,
    Typography,
    PageHeader,
    Select,
    List,
    Avatar,
    Space

} from 'antd';
import {Link, useHistory} from 'react-router-dom';

import React, { useState, useEffect } from 'react'
import { getFgCrops } from '../util/APIUtils'
import { BranchesOutlined, TabletTwoTone } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import ValidationContainer from '../validations/ValidationContainer';


function FachGroupBudget(props) {
    const history = useHistory()

    const [editMode, setEditMode] = useState(false)
    const [loading, setLoading] = useState(false)
    const [crops, setCrops] = useState([])
    const currentUser = props.currentUser;
    const onSave = () => { }
    const onEdit = () => { }
    const handleBack = () => { history.goBack()}
    const cropName = ""

    console.log(currentUser)

    const loadCrops = React.useCallback(async () => {
        let promise;
        promise = getFgCrops();

        if (!promise) {
            return;
        }
        setLoading(true)

        promise.then(response => {
            console.log(response)
            setCrops(_.sortBy(response, 'year'));
            setLoading(false)

        }).catch(error => {
            setLoading(false)
        });
    }, [])

    useEffect(() => {
        loadCrops();
    }, [loadCrops]);
    


    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={() => handleBack()}
                extra={[<ValidationContainer breederId={currentUser.breederInfo.id}/>]}
                title="Fachgruppen Tatigkeit "
                subTitle={cropName} />

            <Card bordered={true} loading={loading} headStyle={{ padding: "0" }}>

          
                <List
                    itemLayout="horizontal"
                    dataSource={crops}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={ <BranchesOutlined />}
                                title={<Link
                                    to={{
                                        pathname: `/fg_budget_detail/${item.id}/${currentUser.breederInfo.id}`,
                                        currentUser: currentUser,
                                        state: {
                                            id: item.id,
                                            name: item.name,
                                            currentUser: currentUser
                                        }
                                    }}>{item.name}</Link>}
                                description={item.shortName}
                            />
                        </List.Item>
                    )}
                />
            </Card>
        </div>
    )
}

export default FachGroupBudget
