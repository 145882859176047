import React, { useState, useEffect, useRef } from 'react';
import { getAllFarmProfiles } from '../util/APIUtils';
import {
    Select,
} from 'antd';

function FarmProfileSelect({setItem, selectedItem , width, disabled=false}) {
    const [itemList, setItemList] = useState([])
    const [val, setVal] = useState(null)
    const [loading, setLoading] = React.useState(false);

    width = width || 300

    const fetchFarmProfiles = React.useCallback(async () => {
        let promise;
        promise = getAllFarmProfiles();
        if (!promise) {
            return;
        }
        setLoading(true)

        promise.then(response => {
            setItemList(response);
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, [])

    useEffect(() => {
        fetchFarmProfiles()
    }, [fetchFarmProfiles]);


    const handleChange = selectedItem => {
        setVal(selectedItem)
        setItem(selectedItem);
    };

    const filteredOptions = itemList
    return (
        <>
            <Select
            disabled={disabled}
                mode="single"
                loading={loading}
                placeholder="Gegenstände auswählen"
                value={selectedItem}
                onChange={handleChange}
                style={{width: width}}
                
            >
                {filteredOptions.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                        {item.name}
                    </Select.Option>
                ))}
            </Select>
        </>
    );
}

export default FarmProfileSelect