import React, { Component } from 'react'
import {
    Card, notification, Menu, Breadcrumb,
    PageHeader, Form, Input,
    Cascader, Select, Row, Col, Checkbox,
    Button, AutoComplete, Space, Descriptions
} from 'antd';
import { withRouter } from 'react-router-dom';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { createBreeder, getBreeders } from '../util/APIUtils';
import { withTranslation } from "react-i18next";

import './Breeders.css';


const { Option } = Select;
const { TextArea } = Input;
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 8,
    },
};
const formItemLayout = {
    labelCol: {
        span: 8
    },
    wrapperCol: {
        span: 8
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        offset: 8,
        span: 8,
    },
};

class NewBreeder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            farmProfile: null,
            isLoading: false,
            visible: false,
            breeders: []
        };
    }

    componentDidMount() {
        this.loadBreederList();
    }

    componentDidUpdate(nextProps) {
        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                breeders: [],
                // page: 0, size: 10, totalElements: 0, totalPages: 0, last: true,
                isLoading: false
            });
            this.loadBreederList();
        }
    }

    loadBreederList() {
        let promise;
        promise = getBreeders();

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });

        promise.then(response => {
            console.log(response);
            this.setState({
                breeders: response,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    handleBack = e => {
        this
            .props
            .history
            .goBack();
    };

    formRef = React.createRef();

    onFinish = (values) => {
        console.log(values)


        const breederObj = {
            "shortName": values.shortName.toUpperCase(),
            "lastName": values.lastName,
            "firstName": values.firstName,
            "place": "",
            "postCode": "",
            "road": "",
            "houseNo": values.houseNo,
            "country": "",
            "phone": values.phone,
            "fax": values.phone,
            "patronId": values.patronId || null,
            "email": values.email,
            "address": values.location,
        }
        createBreeder(breederObj)
            .then(response => {
                notification.success({
                    message: 'Kultursaat',
                    description: response
                });
                this.props.history.push("/user-management");
            }).catch(error => {
                if (error.status === 401) {
                    this.props.handleLogout('/login', 'error', 'You have been logged out. Please login.');
                } else {
                    notification.error({
                        message: 'Kultursaat',
                        description: 'Standortkürzel oder E-Mail könnte bereits existieren.'
                    });
                }
            });
    };

    onReset = () => {
        this.formRef.current.resetFields();
    };

    handleChange = address => {
        this.setState({ address });
    };
    handleSelect1 = address => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => console.log('Success', latLng))
            .catch(error => console.error('Error', error));
    };

    updateData = res => {
        console.log(res)
        if (res.length > 0) {
            let nAddr = res[0].gmaps.address_components;
            const addr = nAddr.reduce((seed, { long_name, types }) => (types.forEach(t => seed[t] = long_name), seed), {});
        }
    }


    render() {
        const { t } = this.props;
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    onBack={() => this.handleBack()}
                    title={"Neuer Züchter"}
                    subTitle=""
                    extra={[]
                    }

                />
                <Card bordered={false} >
                    <Form {...layout} ref={this.formRef} name="control-ref" onFinish={this.onFinish}>
                        <Descriptions size="small"
                            column={1}
                            bordered
                            style={{
                                marginBottom: 2
                            }}>
                            <Descriptions.Item >
                                <Form.Item
                                    name="shortName"
                                    label="Standortkürzel"
                                    required
                                    tooltip="Maximal drei Zeichen, keine Leerzeichen"
                                    rules={[
                                        { required: true },
                                        { min: 3, message: 'Sollte zwischen 3 und 5 Zeichen lang sein.' },
                                        { max: 4, message: 'Sollte zwischen 3 und 5 Zeichen lang sein.' },
                                    ]}
                                >
                                    <Input
                                        onInput={e => e.target.value = e.target.value.toUpperCase()}
                                    />
                                </Form.Item>

                            </Descriptions.Item>
                            <Descriptions.Item>
                                <Form.Item name="firstName" label="Vorname" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item>
                                <Form.Item name="lastName" label="Nachname" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            {/* <Descriptions.Item>
                                <Form.Item name="houseNo" label="House No" rules={[{ required: false }]}>
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item> */}
                            <Descriptions.Item>
                                <Form.Item name="location" label={t("general.address")} rules={[{ required: true }]}>
                                    <Input.TextArea rows={3} />
                                    {/* <GeoSuggest onChange={(result) => this.updateData(result)} /> */}
                                </Form.Item>
                            </Descriptions.Item>

                            <Descriptions.Item>
                                <Form.Item name="email" label="E-Mail" rules={[{ required: true },]} >
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item>
                                <Form.Item name="phone" label={"Telefon-Nr."} rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item>
                                <Form.Item name="fax" label="Fax" rules={[{ required: false }]}>
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>

                            {/* <Form.Item name="patronId" label={t("breeders.patron")} rules={[{ required: false }]}>
                            <Select >
                                {this.state.breeders.map(fbb =>
                                    <option key={fbb.id} value={fbb.id}>{fbb.firstName + " " + fbb.lastName}</option>
                                )}
                            </Select>
                        </Form.Item> */}
                            <Descriptions.Item>

                                <Form.Item {...tailLayout}>
                                    <br></br>
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            {t("general.submit")}
                                        </Button>
                                        <Button htmlType="button" onClick={this.onReset}>
                                            {t("general.reset")}
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Descriptions.Item>
                        </Descriptions>
                    </Form>

                </Card>
            </div>
        )
    }
}

export default withTranslation()(withRouter(NewBreeder));
