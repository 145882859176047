import React, { useState, useRef, useLayoutEffect } from 'react';
import { GridContent } from '@ant-design/pro-layout';
import { Menu, PageHeader } from 'antd';
import { getUserTasks, getVariableTasks, getMySubmissions } from '../../util/APIUtils';
import BindingView from './Binding';
import BreederProfile from '../../breeders/BreederProfile';
// import NotificationView from './components/notification';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router";
const { Item } = Menu;

const Account = (props) => {
    console.log(props)
    const menuMap = {
        myTasks: 'Meine Aufgaben',
        mySubmissions: 'Meine Anträge',
        // cropOutlook: "Kulturart Aúsblick"
    };
    const [initConfig, setInitConfig] = useState({
        mode: 'inline',
        selectKey: 'myTasks',
    });
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(false)
    const dom = useRef();

    React.useEffect(function effectFunction() {
        let promise;
        setLoading(true);
        promise = getUserTasks();
        // promise = getVariableTasks('projectNo','NZ01898' );
        if (!promise) {
            return;
        }

        // this.setState({ isLoading: true });
        promise.then(response => {
            setTasks(response);
            setLoading(false);
            console.log(response)
        }).catch(error => {
            console.log(error);
            setLoading(false);
            console.log("Error")
            // this.setState({ isLoading: false })
        });
    }, []);

    const getMenu = () => {
        return Object.keys(menuMap).map((item) => <Item key={item}>{menuMap[item]}</Item>);
    };

    const renderChildren = () => {
        const { selectKey } = initConfig;
        const id = props.currentUser.breederInfo.id;
        const history = useHistory();

        switch (selectKey) {
            case 'myTasks':
                return <BindingView tasks={tasks} loading={loading} />;
            case 'myTasks':
                return <BindingView tasks={tasks} loading={loading} />;
            // case 'myProfile':
            //     console.log(props)
                
            //     const profile = 1
                
            //     let linkText = `/breeders/profile/${id}/${profile}`
            //     history.push(linkText);
            // return <BreederProfile tasks={tasks} loading={loading} />;
            // case 'cropOutlook':
            //     console.log(props)
            //     let linkText1 = `/crop-forecast/user/${id}`
            //     history.push(linkText1);
            // // return <BreederProfile tasks={tasks} loading={loading} />;


            default:
                return null;
        }
    };


    return (
        <div>
            <PageHeader
                className="site-page-header"
                title="Aufgaben"
                subTitle=""
                extra={[
                ]}
            />
            <GridContent>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        paddingTop: '16px',
                        paddingBottom: '16px'
                    }}
                    ref={(ref) => {
                        if (ref) {
                            dom.current = ref;
                        }
                    }}
                >
                    <div style={{ width: '224px' }}>
                        <Menu
                            mode={initConfig.mode}
                            selectedKeys={[initConfig.selectKey]}
                            onClick={({ key }) => {
                                setInitConfig({ ...initConfig, selectKey: key });
                            }}
                        >
                            {getMenu()}
                        </Menu>
                    </div>

                    <div style={{
                        flex: 1,
                        padding: '8px 40px'
                    }}>
                        <div >{menuMap[initConfig.selectKey]}</div>
                        {renderChildren()}
                    </div>
                </div>
            </GridContent>
        </div>
    );
};

export default withTranslation()(withRouter(Account));
