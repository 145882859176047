import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { getUserProfileByType } from '../../util/APIUtils';
import { withTranslation  } from "react-i18next";


import {
    Button,
    Card,
    Descriptions,
    Divider,
    Table,
    Layout,
    AutoComplete,
    Row,
    Col,
    Typography,
    PageHeader,
    List,
    Avatar,
    Space,

} from 'antd';

class UserProfiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            userprofiles: [],
            typeId: null
        };
    }

    loadUserProfileByType(type) {
        console.log("loading")
        let promise;
        promise = getUserProfileByType(type);

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });
        promise.then(response => {
            this.setState({
                userprofiles: response,
                typeId: type,
                // page: response.page, size: response.size, totalElements:
                // response.totalElements, totalPages: response.totalPages, last: response.last,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }
    
    componentDidMount() {
        const { id } = this.props.match.params
        this.loadUserProfileByType(id);
    }

    
    componentDidUpdate(nextProps) {
        const { id } = nextProps.match.params
        console.log("next")
        console.log(id)
        console.log(this.state.typeId)

        // if(id == this.state.typeId){
        //     return;
        //   }else {
        //     this.loadUserProfileByType(id);
        //   }

        if (this.props.isAuthenticated !== nextProps.isAuthenticated) {

            console.log("in block")
            // Reset State
            this.setState({
                isLoading: false,
                userProfileType: id
            });
            // this.loadLocations(id);
            this.loadUserProfileByType(id);
            
        }
    }

    getUserProfileType = (id) => {
        console.log(id)
        let text = ""
        switch(id) {
            case "1":
              text = "general.breeders";
              break;
            case "2":
              text = "general.funders";
              break;
            case "3":
              text = "general.board";
              break;
            default:
              text = "";
          }
          console.log(text)
          return text;
    }
    render() {
        const { t } = this.props;
        const columns = [
            {
                title: t("general.first_name"),
                dataIndex: 'firstName',
                key: 'firstName',
                // render: text => <Link to="/breeders/profile">{text}</Link>,
                render: (text, record) => {
                    let linkText = `/farmlocation/detail/${record.locationId}`
                    return (
                        <span><b>
                            <Link
                                to={{
                                    pathname: linkText,
                                    state: {
                                        id: record.id
                                    }
                                }}>{text}</Link>
                        </b>
                        </span>
                    )
                },
                width: 250
            },
            {
                title: t("general.last_name"),
                dataIndex: 'lastName',
                key: 'lastName',
                render: (text, record) => {
                    return (
                        <span>
                            {text}
                        </span>
                    )
                },
                width: 250
            }, {
                title: t("general.address"),
                dataIndex: 'address',
                key: 'address',
                width: 400,
                sortDirections: ['descend', 'ascend']
            }, {
                title: t("general.email"),
                dataIndex: 'email',
                key: 'email',
                ellipsis: true,
                
            },
            , {
                title: t("general.funder_type"),
                dataIndex: 'email1',
                key: 'email1',
                ellipsis: true,
                
            }
        ];
        let bList = this
            .state
            .userprofiles
            .map(a => a.name);
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    title={t("general.user_profiles") + " - " + t(this.getUserProfileType(this.state.typeId))} 
                    extra={[
                        <Link to="/userprofiles/add"><Button key="36" type="primary" >Add</Button></Link>
                    ]}
                />
                <Card bordered={true} loading={this.state.isLoading}>
                    {/* <Search dataList={bList} dataCount={bList.length}/> */}
                    <Table
                        columns={columns}
                        dataSource={this.state.userprofiles}
                        loading={this.state.isLoading} />
                </Card>
            </div>

        );
    }
}

export default withTranslation()(withRouter(UserProfiles));
