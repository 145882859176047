import React, { useState } from 'react';
import { Select } from 'antd';


export default function MultiSelect({ setItems, existingItems = [], itemList = [] }) {

    const [selectedItems, setSelectedItems] = React.useState(existingItems);
    
    const handleChange = selectedItems => {
        setSelectedItems(selectedItems);
        setItems(selectedItems);
    };

    const filteredOptions = itemList.filter(o => !selectedItems.includes(o))
    return (
        <>
            <Select
                mode="multiple"
                placeholder="Gegenstände auswählen"
                value={[...new Set(selectedItems)]}
                onChange={handleChange}
                style={{ width: '100%' }}
            >
                {filteredOptions.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                        {item.name}
                    </Select.Option>
                ))}
            </Select>

        </>
    );
}
