import { idID } from '@mui/material/locale';
import { Button, Modal, notification, Table, Space } from 'antd';
import React, { useState } from 'react';
import { getLastYearLines, copyLines } from '../../util/APIUtils';
import shortid from 'shortid';

function CopyLinesPopup(props) {
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');
    const [lines, setLines] = useState([]);
    const [selectedLines, setSelectedLines] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const projectReportId = props.projectReportId || 0

    let fetchLines = React.useCallback(async () => {
        // if(!open) {
        //     return;
        // }
        let promise;
        setLoading(true);
        promise = getLastYearLines(props.projectId, props.year);
        if (!promise) {
            return;
        }
        promise.then(response => {
            console.log(response)
            setLines(response.map(obj => ({ ...obj, key: shortid.generate() })));
            setLoading(false);

        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, [])

    const linesCopy = () => {
        let promise;
        console.log(props.projectId)
        console.log(props.projectDetailId)
        console.log(props.projectReportId)
        promise = copyLines(props.projectId, props.projectDetailId, projectReportId, selectedLines);
        if (!promise) {
            return;
        }
        promise.then(response => {
            setLoading(false)
            notification.success({
                message: 'Kultursaat',
                description: "Erfolgreich kopiert"
            });
            props.fetchLines();
            setOpen(false);
            setConfirmLoading(false);
        }).catch(error => {
            console.log(error)
            notification.warn({
                message: 'Kultursaat',
                description: "Kopieren fehlgeschlagen"
            });
            setOpen(false);
            setConfirmLoading(false);
        });
    }
    React.useEffect(() => {
        fetchLines();
    }, [fetchLines]);

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        setModalText('The modal will be closed after two seconds');
        setConfirmLoading(true);
        linesCopy()


    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rndInt = Math.floor(Math.random() * 1000) + 1

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectedLines(selectedRows)
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
    };

    const columns = [
        {
            title: 'Linie',
            dataIndex: 'description',
        }
    ];
    return (
        <>
            <Button type="primary" onClick={showModal}>
                + Kopie
            </Button>
            {open &&
                <Modal
                    width={800} forceRender
                    title="Kopie vom letzten Jahr"
                    visible={open}
                    onOk={handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={handleCancel}
                >
                    {/* <Table
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={lines} /> */}
                    <Space direction='vertical' style={{width: '100%'}}>
                    <Button onClick={fetchLines}>Aktualisierung</Button>


                    <Table
                        size='small'
                        rowSelection={{
                            type: 'checkbox',
                            ...rowSelection,
                        }}
                        columns={columns}
                        dataSource={
                            lines
                        }
                    />
                    </Space>
                </Modal>}
        </>
    );
};
export default CopyLinesPopup;