import React from 'react'
import moment from 'moment'
import { Descriptions, Tag, Form, Radio, Input, Select, InputNumber, Button, DatePicker, Space, notification, Divider } from 'antd'
import { useTranslation } from 'react-i18next';
import { getBreedingMethods, getLineSelections, saveProjectReport } from '../../util/APIUtils';
import { useEffect, useState } from 'react'

import './Report.css'
import LineSelectionLookup from '../../common/LineSelectionLookup';
import BreedingMethodLookup from '../../common/BreedingMethodLookup';
import LineSelectionLookupWithAdd from '../../common/LineSelectionLookupWithAdd';

function CreateReport(props) {
    const [loading, setLoading] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [lineSelections, setLineSelections] = useState([]);
    const [breedingMethods, setBreedingMethods] = useState([]);
    const [state, setState] = useState(props.projectReportInfo);
    const [canceled, setCanceled] = useState(props.projectReportInfo.canceled)
    const cropId = props.cropId;
    const projectType = props.projectType;
    const { t } = useTranslation();
    const [form] = Form.useForm();
    console.log(props.projectReportInfo)

    console.log(props)

    const screenState = {
        "year": props.year,
        "tab": '3'
    }

    const onChange = e => {
        setCanceled(e);
    };

    const mapObjToReport = (values, type) => {

        let renewalYear = null;
        if (type == 'form') {
            renewalYear = moment(values.renewal, 'YYYY').year()
        } else {
            renewalYear = values.renewal != null ? moment(values.renewal, 'YYYY') : null
        }

        let reportObj = {}

        if (!canceled) {
            reportObj = {
                finished: values.finished,
                canceled: canceled != null ? canceled : values.canceled,
                description: values.description,
                lineSelections: state.lineSelections,
                projectStatus: values.projectStatus,
                renewal: renewalYear,
                breedingMethods: state.breedingMethods,
                descBreedingMethod: values.descBreedingMethod,
                cultivationScope: values.cultivationScope,
                cultivationScopeUnit: values.cultivationScopeUnit,
                culturalTour: values.culturalTour,
                deviationPlan: values.deviationPlan,
                results: values.results,
                descExtension: values.descExtension,
                projectDetailId: props.projectDetailId,
                projectId: props.projectId,

            }
        } else {
            reportObj = {
                canceled: canceled,
                projectDetailId: props.projectDetailId,
                projectId: props.projectId,
            }
        }

        return reportObj;
    }

    const onFinish = (values) => {
        setLoading(true);

        // console.log(reportObj);

        let promise;
        promise = saveProjectReport(mapObjToReport(values, "form"));
        if (!promise) {
            return;
        }

        promise.then(response => {
            setLoading(false)

            props.reloadProject(screenState);
            notification.success({ message: "Kultursaat", description: "Bericht aktualisiert!", placement: 'topLeft' })
        }).catch(error => {
            console.log(error)
            setLoading(false)
            notification.error({ message: "Kultursaat", description: "Error saving report", placement: 'topLeft' })
        });

    };

    // useEffect(() => form.resetFields(), [props.projectReportInfo]);

    const initVals = mapObjToReport(props.projectReportInfo, "obj");
    console.log(initVals)


    const handleChangeOther = name => value => {
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));

    };

    return (
        <div>
            <Form name="reports-form" form={form} onFinish={onFinish} initialValues={initVals}>
                <Descriptions
                    size="small"

                    // style={{
                    //     marginBottom: 2,

                    // }}
                    extra={<Space><Button type={"primary"} htmlType="submit" >Bericht speichern</Button><Button onClick={props.setEditing}>Abbrechen</Button></Space>}>
                    {/* </Descriptions.Item> */}
                </Descriptions>
                {/* <Descriptions.Item label={t("project_report.the_project_was")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start', verticalAlign: 'middle' }} > */}
                <Space size="large" direction="vertical">
                    <Form.Item name="canceled">
                        <Space>
                            <b>{t("project_report.the_project_was")}</b>
                            <Select style={{ width: 250 }} onChange={onChange} defaultValue={canceled}>
                                <Option value={0}>{t("project_report.completed")}</Option>
                                <Option value={1}>{t("project_report.not_completed")}</Option>
                            </Select>
                        </Space>
                    </Form.Item>
                </Space>
                <Divider></Divider>

                {/* {projectReportInfo != null ? <Tag color="#87d068">{t("project_report.completed")}</Tag> : <Tag color="#f50">{t("project_report.not_completed")}</Tag>} */}

                {!canceled ?
                    <Descriptions
                        size="small"
                        bordered
                        style={{
                            marginBottom: 2,

                        }}
                    >
                        <Descriptions.Item label={t("project_report.description")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start', width: '300px' }} >
                            <Form.Item
                                name="description"

                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Descriptions.Item>

                        <Descriptions.Item label={t("project_report.starting_variety")} labelStyle={{ fontWeight: 'bold' }} span={3}>
                            <Form.Item
                                name="lineSelections"

                            >
                                <LineSelectionLookupWithAdd
                                    cropId={cropId}
                                    setItems={handleChangeOther("lineSelections")}
                                    existingItems={state.lineSelections}
                                    setFormattedItems={handleChangeOther("lineSelections")}
                                    projectInfo={props.projectInfo}
                                />
                            </Form.Item>


                        </Descriptions.Item>

                        <Descriptions.Item label={t("project_report.project_status")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >

                            <Form.Item
                                name="projectStatus"
                            >
                                <Select >
                                    <Option value="weiterhin aktuell">weiterhin aktuell</Option>
                                    <Option value="erfolgreich abgeschlossen">erfolgreich abgeschlossen</Option>
                                    <Option value="abgebrochen">abgebrochen</Option>

                                </Select>
                            </Form.Item>
                            {/* {projectReportInfo.projectStatus} */}
                        </Descriptions.Item>



                        <Descriptions.Item label={t("project_report.breeding_method")} labelStyle={{ fontWeight: 'bold' }} span={3}>

                            <Form.Item
                                name="breedingMethods"
                            >
                                <BreedingMethodLookup
                                    projectType={'EZ'}
                                    setItems={handleChangeOther("breedingMethods")}
                                    existingItems={state.breedingMethods}
                                    setFormattedItems={handleChangeOther("breedingMethods")}
                                />
                            </Form.Item>

                        </Descriptions.Item>

                        <Descriptions.Item label={t("project_report.breeding_method_description")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                            <Form.Item
                                name="descBreedingMethod"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Bitte eingeben ' + t("project_report.breeding_method_description"),
                            //     },
                            // ]}
                            >
                                <Input.TextArea />
                            </Form.Item>

                            {/* {projectReportInfo.descBreedingMethod} */}
                        </Descriptions.Item>

                        <Descriptions.Item label={t("project_report.cultivation_scope")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                            <Space>
                                <Form.Item
                                    name="cultivationScope"

                                >
                                    <InputNumber />
                                </Form.Item>
                                <Form.Item
                                    name="cultivationScopeUnit"

                                >
                                    <Select style={{ width: 100 }} >
                                        <Option value="qm">QM</Option>
                                        <Option value="ha">HA</Option>
                                        <Option value="stück">Stück</Option>
                                    </Select>
                                </Form.Item>
                            </Space>
                            {/* {projectReportInfo.cultivationScope + " " + projectReportInfo.cultivationScopeUnit} */}
                        </Descriptions.Item>

                        <Descriptions.Item label={t("project_report.cultural_tour")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                            <Form.Item
                                name="culturalTour"

                            >
                                <Input.TextArea />
                            </Form.Item>

                            {/* {projectReportInfo.culturalTour} */}
                        </Descriptions.Item>

                        <Descriptions.Item label={t("project_report.plan_deviation")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                            <Form.Item
                                name="deviationPlan"

                            >
                                <Input.TextArea />
                            </Form.Item>

                            {/* {projectReportInfo.deviationPlan} */}
                        </Descriptions.Item>

                        <Descriptions.Item label={t("project_report.results")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                            <Form.Item
                                name="results"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Bitte eingeben ' + t("project_report.results"),
                            //     },
                            // ]}
                            >
                                <Input.TextArea />
                            </Form.Item>

                            {/* {projectReportInfo.results} */}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("project_report.project_extention")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                            <Form.Item
                                name="renewal"
                            // initialValue={moment} 
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Bitte eingeben ' + t("project_report.project_extention"),
                            //     },
                            // ]}
                            >
                                <DatePicker picker="year" />
                            </Form.Item>

                            {/* {projectReportInfo.renewal ? projectReportInfo.renewal : "Nein"} */}
                        </Descriptions.Item>

                        <Descriptions.Item label={t("project_report.reason_project_extension")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                            <Form.Item
                                name="descExtension"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Bitte eingeben ' + t("project_report.reason_project_extension"),
                            //     },
                            // ]}
                            >
                                <Input.TextArea />
                            </Form.Item>

                            {/* {projectReportInfo.descExtension} */}
                        </Descriptions.Item>

                    </Descriptions> : ""}
            </Form>
        </div>
    )
}

export default CreateReport
