import {
    DataGrid, useGridApiContext, deDE, useGridApiRef, GridToolbarContainer, GridRowModes,
    GridActionsCellItem,
} from '@mui/x-data-grid';
import { NumericFormat } from 'react-number-format';
import { styled } from '@mui/material/styles';
import uuid from "uuid";
import Snackbar from '@mui/material/Snackbar';

import { Button, Select, Card, Descriptions, Input, Space, message, InputNumber, Divider, Popconfirm } from 'antd';
import React from 'react'
import { useEffect, useState, useMemo, useRef } from 'react';

import { currencyFormatter, currencyParser, formatCurrency } from '../../util/Helpers';
import { DeleteOutlined, PlusCircleOutlined, SaveOutlined, PlusOutlined } from '@ant-design/icons';
import { getExaminationItems, deleteExaminationItem, getAccountingItems, getProjectDetailAccountingItems, getProjectDetailExaminationItems, updateAccountingItem, updateExaminationItem } from '../../util/APIUtils';
import { Box } from '@mui/system';
import SelectEditInputCell from '../../common/SelectEditInputCell';
import { CheckBoxOutlineBlankTwoTone, CheckBoxOutlined } from '@mui/icons-material';


const useFakeMutation = () => {
    return;
};


const EditableGridExamination = (props) => {
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const projectId = props.projectId;
    const projectDetailId = props.projectDetailId;
    const [state, setState] = useState({})
    const editable = props.reportEditable;
    const [examinationItems, setExaminationItems] = React.useState(props.examinationItems);
    const isPlanEditable = props.isPlanEditable;
    const isReportEditable = props.isReportEditable;
    const [data, setData] = useState(props.data || []);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [snackbar, setSnackbar] = React.useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [budgetTotal, setBudgetTotal] = useState(0);
    const [actualTotal, setActualTotal] = useState(0);

    const getExamItems = React.useCallback(async () => {
        let promise;
        promise = getExaminationItems();

        if (!promise) {
            return;
        }
        setLoading(true);
        promise.then(response => {
            setExaminationItems(response);


        }).catch(error => {
            setLoading(false)
        });
    }, [])

    const loadExaminationItems = React.useCallback(async () => {
        let promise;
        promise = getProjectDetailExaminationItems(projectDetailId);

        if (!promise) {
            return;
        }
        setLoading(true);
        promise.then(response => {
            response.push(createSummaryData(response));
            setData(response);

        }).catch(error => {
            console.log(error);
            setLoading(false)
        });
    }, [])

    useEffect(() => {
        loadExaminationItems();
        getExamItems();
    }, [loadExaminationItems, getExamItems]);

    const createSummaryData = (response) => {
        let summaryRow = createNewRow("sum")
        summaryRow.examinationItem = "Gesamt"
        summaryRow.amountBudget = response.reduce((a, b) => a + (b['amountBudget'] || 0), 0).toFixed(2)
        summaryRow.amountActual = response.reduce((a, b) => a + (b['amountActual'] || 0), 0).toFixed(2)
        summaryRow.quantityBudget = response.reduce((a, b) => a + (b['quantityBudget'] || 0), 0).toFixed(2)
        summaryRow.quantityActual = response.reduce((a, b) => a + (b['quantityActual'] || 0), 0).toFixed(2)
        summaryRow.budgetTotal = response.reduce((a, b) => a + (b['budgetTotal'] || 0), 0).toFixed(2)
        // summaryRow.actualTotal = response.reduce((a, b) => a + (b['actualTotal'] || 0), 0).toFixed(2)
        summaryRow.actualTotal = response.filter(a => a.actualSelected == true).reduce((a, b) => a + (b['actualTotal'] || 0), 0).toFixed(2)
        console.log(summaryRow)
        return summaryRow;
    }

    const mutateRow = useFakeMutation();

    const CustomToolbar = () => {
        const apiRef = useGridApiContext();

        // const handleSave = () => console.log(apiRef.current.getRowModels());
        if (isReportEditable || isPlanEditable) {
            return (
                <GridToolbarContainer >
                    <Space>
                        <Button onClick={handleAddRow} style={{ marginBottom: "10px" }} icon={<PlusOutlined />}>

                        </Button>
                        {/* <Button style={{ marginBottom: "10px" }} onClick={handleSave} icon={<SaveOutlined />}></Button> */}
                    </Space>
                </GridToolbarContainer>
            );
        } else {
            return null
        }
    };

    const getExaminationItemIdForDesc = (examinationItems, desc) => {
        let item = examinationItems.find(x => x.name === desc) || null;

        if (item) {
            return item.id;
        } else {
            return;
        };
    }

    function CurrencyInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = async (event) => {
            await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
        };

        return (
            <NumericFormat
                style={{ height: "100px", minWidth: "48px", border: "0px", borderColor: "green" }}
                autoFocus
                align='right'
                value={value} decimalSeparator="," onChange={handleChange} />
        )
    }

    function SelectEditInputCell1(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();
        const inputRef = useRef(null);
        const [items, setItems] = useState(props.items);
        const [name, setName] = useState('');

        const handleChange = async (event) => {
            await apiRef.current.setEditCellValue({ id: id, field, value: event });
            apiRef.current.stopCellEditMode({ id: id, field });
        };

        const onNameChange = (event) => {
            setName(event.target.value);
        };

        const addItem = (e) => {
            e.preventDefault();
            setItems([...items, { id: 1111, name: name } || `New item ${index++}`]);
            setName('');
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        };

        return (
            <Select
                style={{ minWidth: "400px", height: "1", borderWidth: "0px" }}
                onChange={handleChange}
                showSearch
                // mode="tags"
                dropdownRender={menu => (
                    <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                            <Input
                                placeholder="Artikel hinzufügen"
                                ref={inputRef}
                                style={{ minWidth: "350px", height: "1", borderWidth: "0px" }}
                                value={name}
                                onChange={onNameChange}
                            />
                            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                            </Button>
                        </Space>
                    </>
                )}
                defaultValue={props.value}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.includes(input)}
                filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
            >
                {items.map(item => (
                    <Select.Option key={item.id} value={item.name} >
                        {item.name}
                    </Select.Option>
                ))}
            </Select>
        );
    }
    const handleParentClick = (e) => {
        e.stopPropagation();
    }


    const renderSelectEditInputCell = (params) => {
        return (<div style={{ minWidth: "400px", borderWidth: "0px" }} onClick={handleParentClick}>
            <SelectEditInputCell {...params} items={examinationItems} itemName='name' apiRef={useGridApiContext()} />
        </div>);

    };

    const renderCurrencyInputCell = (params) => {
        return <CurrencyInputCell {...params} />;
    };

    const renderCustomToolbar = (params) => {
        return <CustomToolbar {...params} />;
    };

    const renderActualSelected = (row) => {
        if (row.row.examinationItem === 'Gesamt') {
            return "";
        } else {
            return row.row.actualSelected ? <CheckBoxOutlined /> : <CheckBoxOutlineBlankTwoTone />
        }
    }



    const createNewRow = (type) => {
        return {
            id: type == "sum" ? 108080 : 0,
            examinationItem: "",
            actualTotal: 0,
            budgetTotal: 0,
            quantityBudget: 0,
            quantityActual: 0,
            amountActual: 0,
            amountBudget: 0,
            isNew: true,
            projectId: projectId,
            projectDetailId: projectDetailId,
            budget: isPlanEditable,
            actual: isReportEditable,
            rowType: type

        }
    }

    const deleteSum = (arr) => {
        let localArr = [...arr];
        localArr.splice(localArr.findIndex(function (i) {
            return i.id === 108080;
        }), 1);
        return localArr;
    }

    const handleAddRow = () => {
        setData((prevRows) => [...deleteSum(prevRows)]);
        setData((prevRows) => [...prevRows, createNewRow(null), createSummaryData(prevRows)]);
    };

    const renderDeleteButton = (params) => {

        const onCancel = () => {
            return
        }

        const onOk = () => {
            setLoading(true)

            let promise = deleteExaminationItem(params.row.id);
            if (!promise) {
                return;
            }

            promise.then(response => {
                setLoading(false);
                loadExaminationItems();


            }).catch(error => {
                message.error("Failed")
            });

        }

        if (params.row.id === 0 && params.row.examinationItem == "") {
            return ""
        }

        if (params.row.rowType === "sum") {
            return ""
        } else {
            return (
                <strong>
                    {(params.row.budget && isPlanEditable) || (params.row.actual && isReportEditable) ?
                        <Popconfirm
                            title="Löschen bestätigen?"
                            onConfirm={onOk}
                            onCancel={onCancel}
                            okText="Ok"
                            cancelText="Abbrechen"
                        >
                            <Button danger icon={<DeleteOutlined />}
                                size="small"
                                style={{ marginLeft: 16 }}
                            // onClick={onOk}
                            >
                            </Button>
                        </Popconfirm> : ""}

                </strong>
            )
        }
    }

    const renderItemName = (params) => {
        if (params.row.customType) {
            return params.row.customItem;
        } else {
            return params.row.examinationItem;
        }
    }


    const columnGroupingModel = [
        {
            groupId: 'Menge',
            description: '',
            headerAlign: 'center',
            children: [{ field: 'quantityBudget' }, { field: 'quantityActual' }],
        },
        {
            groupId: 'Einzelpreis',
            description: '',
            headerAlign: 'center',
            children: [{ field: 'amountBudget' }, { field: 'amountActual' }],
        },
        {
            groupId: 'Gesamt',
            description: '',
            headerAlign: 'center',
            children: [{ field: 'actualTotal' }, { field: 'budgetTotal' }],
        },
    ];


    const columns = [
        { field: 'examinationItemId', headerName: 'Id', hide: true, editable: true },
        { field: 'budget', headerName: 'budget', hide: true, editable: true },
        { field: 'actual', headerName: 'actual', hide: true, editable: true },
        { field: 'actualSelected', headerName: 'Abrechnung', minWidth: 20, editable: true, type: 'boolean', flex: 1, hide: isPlanEditable, renderCell: renderActualSelected },
        {
            field: 'examinationItem',
            headerName: 'Untersuchungen/Saatgutdiagnostik',
            flex: 1,
            minWidth: 400,
            editable: true,
            renderCell: renderItemName,
            renderEditCell: renderSelectEditInputCell
        },
        {
            field: 'quantityBudget',
            headerName: 'Kalkulation',
            valueFormatter: ({ value }) => currencyFormatter(value),
            // valueGetter: ({ value }) => currencyParser(value),
            renderEditCell: renderCurrencyInputCell,
            editable: isPlanEditable,
            headerAlign: 'right',
            align: 'right',
            minWidth: 50,
            flex: 1
        },
        {
            field: 'quantityActual',
            headerName: 'Abrechnung',
            valueFormatter: ({ value }) => currencyFormatter(value),
            renderEditCell: renderCurrencyInputCell,
            // type: 'number',
            minWidth: 50,
            flex: 1,
            editable: isReportEditable,
            headerAlign: 'right',
            align: 'right'
        },
        {
            field: 'amountBudget',
            headerName: 'Kalkulation',
            valueFormatter: ({ value }) => currencyFormatter(value),
            renderEditCell: renderCurrencyInputCell,
            // type: 'number',
            minWidth: 50,
            flex: 1,
            editable: isPlanEditable,
            headerAlign: 'right',
            align: 'right'
        },
        {
            field: 'amountActual',
            headerName: 'Abrechnung',
            headerClassName: 'table-header',
            valueFormatter: ({ value }) => currencyFormatter(value),
            renderEditCell: renderCurrencyInputCell,
            // type: 'number',
            minWidth: 50,
            flex: 1,
            editable: isReportEditable,
            headerAlign: 'right',
            align: 'right',

        },
        {
            field: 'budgetTotal',
            headerName: 'Kalkulation',
            valueFormatter: ({ value }) => formatCurrency(value),
            // renderEditCell: renderCurrencyInputCell,
            type: 'number',
            minWidth: 100,
            flex: 1,
            // editable: true,
            headerAlign: 'right',
            align: 'right',
            // valueGetter: getTotalBudget,
            // valueSetter: setTotalBudget,
            // valueParser: parseTotalBudget
        },
        {
            field: 'actualTotal',
            headerName: 'Abrechnung',
            valueFormatter: ({ value }) => formatCurrency(value),
            // renderEditCell: renderCurrencyInputCell,
            type: 'number',
            minWidth: 100,
            flex: 1,
            // editable: true,
            headerAlign: 'right',
            align: 'right',
            // valueGetter: getTotalActual,
            // valueSetter: setTotalBudget,
            // valueParser: parseTotalBudget
        },

        {
            field: 'action',
            headerName: 'Action',
            // renderEditCell: renderCurrencyInputCell,
            minWidth: 20,
            flex: 1,
            // editable: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: renderDeleteButton,
            disableClickEventBubbling: true,

        },
    ];



    const processRowUpdate = React.useCallback(
        async (newRow) => {

            newRow.customItem = newRow.examinationItem ? newRow.examinationItem : null;
            newRow.customType = newRow.customItem ? true : false;
            if (newRow.customType) {
                newRow.examinationItemId = 0;
            } else {
                newRow.examinationItemId = getExaminationItemIdForDesc(examinationItems, newRow.examinationItem)
            }
            newRow.quantityBudget = currencyParser(currencyFormatter(newRow.quantityBudget));
            newRow.amountBudget = currencyParser(currencyFormatter(newRow.amountBudget));

            newRow.quantityActual = currencyParser(currencyFormatter(newRow.quantityActual));
            newRow.amountActual = currencyParser(currencyFormatter(newRow.amountActual));
            newRow.projectId = projectId;
            newRow.projectDetailId = projectDetailId;

            let promise = updateExaminationItem(newRow);
            if (!promise) {
                return;
            }

            promise.then(response => {
                const updatedRow = { ...newRow, isNew: false };
                // setData(data.map((row) => (row.id === newRow.id ? updatedRow : row)));
                response.push(createSummaryData(response))
                setData(response);
                setBudgetTotal(response.reduce((a, b) => a + (b['budgetTotal'] || 0), 0));
                setActualTotal(response.reduce((a, b) => a + (b['actualTotal'] || 0), 0))
                setSnackbar({ children: 'Saved', severity: 'success' });


            }).catch(error => {
                message.error("project update failed")
            });

            // return updatedRow;


            // Make the HTTP request to save in the backend
            // const response = await mutateRow(newRow);
            return response;
        },
        [mutateRow],
    );

    const handleProcessRowUpdateError = React.useCallback((error) => {
        setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    function CustomFooter(props) {
        return (
            <Box sx={{ fontWeight: "600", border: `1px solid #f0f0f0`, backgroundColor: "#f1f7ee", padding: "0px", display: "flex", justifyContent: "right", alignItems: "right" }}></Box>)

    }

    const checkEditable = (param) => {
        if (param.row.rowType === "sum") {
            return false;
        }
        if (param.colDef.field === "examinationItem" && param.row.budget && isPlanEditable) {
            return true;
        }

        if (param.colDef.field === "examinationItem" && param.row.actual && isReportEditable) {
            return true;
        }

        if (isPlanEditable && ['quantityBudget', 'amountBudget'].includes(param.colDef.field)) {
            return true;
        }

        if (isReportEditable && ['actualSelected', 'quantityActual', 'amountActual'].includes(param.colDef.field)) {
            return true;
        }

        return false;

    }




    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        border: 1,
        color:
            theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            height: "20px",
            // backgroundColor: '#ccc',
            border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            height: "20px",
            border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnHeader': {
            headerRowHeight: 22,
            backgroundColor: '#f1f7ee',
        },
        '& .MuiDataGrid-cell': {

            color:
                theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
        },
        '& .MuiPaginationItem-root': {
            borderRadius: 0,
        },

    }));

    return (



        <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
                {/* <Space direction='vertical'> */}

                <br></br>
                <Box
                    sx={{
                        width: '100%',
                        '& .budget': {
                            backgroundColor: '#dde5b6',
                            color: 'e8e8e4',
                        },
                        '& .actual': {
                            backgroundColor: '#fae1ca',
                            color: '#1a3e72',
                        },
                        '& .actual-selected': {
                            backgroundColor: 'white',
                            color: 'green !important',
                        },
                        '& .actual-not-selected': {
                            // backgroundColor: '#fae1ca',
                            color: 'red !important',
                        },
                        '& .sum-row': {
                            backgroundColor: '#f1f7ee',
                            color: '#1a3e72 !important',
                            fontWeight: 800
                        },
                    }}
                >
                    <StyledDataGrid
                        disableColumnFilter
                        disableClickEventBubbling
                        disableSelectionOnClick
                        autoHeight
                        rowHeight={27}
                        headerRowHeight={27}
                        rows={data}
                        columns={columns}
                        experimentalFeatures={{ newEditingApi: true, columnGrouping: true }}
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        // hideFooter
                        columnGroupingModel={columnGroupingModel}
                        processRowUpdate={processRowUpdate}
                        disableColumnMenu
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        isCellEditable={checkEditable}
                        getCellClassName={(params) => {
                            if (['quantityBudget', 'amountBudget'].includes(params.field)) {
                                return 'budget';
                            } else if (['quantityActual', 'amountActual'].includes(params.field)) {
                                return 'actual'
                            }
                            if (params.field == "actualTotal") {
                                if (params.row.actualSelected) {
                                    return 'actual-selected'
                                } else {
                                    if (params.row.rowType === "sum") {
                                        return
                                    }
                                    return 'actual-not-selected'
                                }
                            }

                        }}
                        getRowClassName={(params) => {
                            if (params.row.rowType === "sum") {
                                return 'sum-row'
                            }
                        }}
                        // editMode="row"
                        // rowModesModel={rowModesModel}
                        // onRowEditStart={handleRowEditStart}
                        // onRowEditStop={handleRowEditStop}
                        // processRowUpdate={processRowUpdate}
                        // components={{
                        //     Toolbar: EditToolbar,
                        // }}
                        // componentsProps={{
                        //     toolbar: { setData, setRowModesModel },
                        // }}
                        components={{
                            Toolbar: renderCustomToolbar,
                            Footer: CustomFooter
                        }}
                        componentsProps={{
                            footer: { budgetTotal, actualTotal }
                        }}
                    />
                </Box>

                {/* </Space> */}
            </div>
        </div>

    )
}

export default EditableGridExamination