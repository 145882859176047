import React, { useState, useEffect } from 'react';
import { Empty, List, Descriptions, Button, Space, Input, InputNumber, Select, DatePicker, Form, notification } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { createLine } from '../../util/APIUtils';
import moment from 'moment';
function AddLine(props) {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const dateFormat = 'MMM-DD-YYYY';
    const [form] = Form.useForm();
    console.log(props)

    const [varietyLevel, setVarietyLevel] = useState(null)

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        setLoading(true)
        let lineObj = {
            amountOfSeeds: values.amountOfSeeds,
            bloomSeedBearer: values.bloomSeedBearer,
            briefDescription: values.briefDescription,
            amountOfSeeds: values.amountOfSeeds,
            description: values.name_1 + "-" + values.name_2 + "-" + values.name_3 + "-" + values.name_4 + "G",
            germination: values.germination,
            id: null,
            invitedSeedBearer: values.invitedSeedBearer,
            largeSelectionStock: values.largeSelectionStock,
            oldLineId: null,
            plantingDate: values.plantingDate1,
            projectDetailId: props.projectDetailId,
            projectId: props.projectId,
            projectReportId: null,
            results: values.results,
            sowingDate: values.sowingDate1,
            stockUnit: values.stockUnit,
            task: values.task,
            
            varietyLevel: values.varietyLevel,

            bulkEliteSeeds: values.bulkEliteSeeds,
            germinationElite: values.germinationElite,
            varietyDescription: values.varietyDescription,
            mixedSeeds: values.mixedSeeds,
            germinationVa: values.germinationVa


        }
        console.log(lineObj);

        let promise;
        promise = createLine(lineObj);
        if (!promise) {
            return;
        }

        // this.setState({ isLoading: true });
        promise.then(response => {
            console.log(response)
            setLoading(false)
            props.onClose();
            notification.success({
                message: 'Kultursaat',
                description: "erfolgreich hinzugefügt"
            });
        }).catch(error => {
            console.log(error)
            if (error == "Line Exists") {
                notification.warn({
                    message: 'Kultursaat',
                    description: "Linie existiert"
                });
            }
            setLoading(false)
            notification.error({
                message: 'Kultursaat',
                description: "Error"
            });
            // this.setState({ isLoading: false })
        });

    };

    useEffect(() => form.resetFields(), [props.line]);

    const children = [];

    for (let i = 0; i < 100; i++) {
        children.push(<Option key={i}>{i}</Option>);
    }

    let initVals = {
        name_1: "KSZ",
        name_2: props.projectInfo.cropShortName + "-" + props.projectInfo.breederShortName,
        name_3: "Neue Linie",
        name_4: 0

    }

    const sortenStufeBlock = () => {

        if (varietyLevel >= 3) {
            return (
                <>
                    <Descriptions.Item span={2} label={"Menge vorh EliteSaatgut"}>
                        <Form.Item
                            name="bulkEliteSeeds"
                        >
                            <InputNumber />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={"keimfähigkeit"}>
                        <Form.Item
                            name="germinationElite"
                        >
                            <InputNumber /> 
                        </Form.Item>%
                    </Descriptions.Item>

                    <Descriptions.Item span={3} label={"Sortenbeschreibung"}>
                        <Form.Item
                            name="varietyDescription"
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Descriptions.Item>

                    <Descriptions.Item span={2} label={"Insgesamt vorhandenes Saatgut für Versuchsanbau"}>
                        <Form.Item
                            name="mixedSeeds"
                        >
                            <InputNumber />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={"keimfähigkeit"}>
                        <Form.Item
                            name="germinationVa"
                        >
                            <InputNumber /> 
                        </Form.Item>%
                    </Descriptions.Item>

                </>)
        }
    }


    return (
        <div>
            <Form name="lines-form" form={form} onFinish={onFinish} initialValues={initVals} >
                <Descriptions
                    bordered
                    title={t("lines.title")}
                    size={"small"}

                >
                    <Descriptions.Item span={3} label={t("lines.description")}>
                        <Space>
                            <Form.Item
                                name="name_1"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please enter ' + t("lines.description"),
                            //     },
                            // ]}
                            >
                                <Select defaultValue="KSZ" style={{ width: 80 }} >
                                    <Option value="KSZ">KSZ</Option>
                                    <Option value="KS">KS</Option>
                                    <Option value="KSV">KSV</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="name_2"
                            >
                                <Input disabled style={{ width: 90 }} defaultValue={props.projectInfo.cropShortName + "-" + props.projectInfo.breederShortName} />
                            </Form.Item>
                            <Form.Item
                                name="name_3"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter ' + t("lines.description"),
                                    },
                                ]}
                            >
                                <Input style={{ width: 220 }} defaultValue={"Neue Linie"} />
                            </Form.Item>
                            <Form.Item
                                name="name_4"
                            >
                                <Select defaultValue={0} style={{ width: 80 }} >
                                    {children}
                                </Select>
                            </Form.Item>G
                        </Space>
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label={t("lines.short_description")}>
                        <Form.Item
                            name="briefDescription"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter ' + t("lines.short_description"),
                                },
                            ]}
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label={t("lines.this_year_on_line")}>
                        <Form.Item
                            name="task"
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label={t("lines.results")}>
                        <Form.Item
                            name="results"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please enter ' + t("lines.results"),
                        //     },
                        // ]}
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={t("lines.variety_level")}>
                        <Form.Item
                            name="varietyLevel"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please enter ' + t("lines.variety_level"),
                        //     },
                        // ]}
                        >
                            <Select style={{ width: 80 }} onChange={setVarietyLevel} >
                                <Option value={1}>1</Option>
                                <Option value={2}>2</Option>
                                <Option value={3}>3</Option>
                                <Option value={4}>4</Option>
                            </Select>
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={t("lines.seed_carrier_number")}>
                        <Form.Item
                            name="invitedSeedBearer"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please enter ' + t("lines.seed_carrier_number"),
                        //     },
                        // ]}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={t("lines.readout_stock_size")}>
                        <Space><Form.Item
                            name="largeSelectionStock"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please enter ' + t("lines.readout_stock_size"),
                        //     },
                        // ]}
                        >
                            <InputNumber />
                            {/* {line.stockUnit} */}
                        </Form.Item>
                            <Form.Item
                                name="stockUnit"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please enter ' + t("lines.readout_stock_size"),
                            //     },
                            // ]}
                            >
                                <Select style={{width : "90px"}} >
                                    <Option value="qm">QM</Option>
                                    <Option value="ha">HA</Option>
                                    <Option value="stück">Stück</Option>
                                </Select>
                                {/* {line.stockUnit} */}
                            </Form.Item></Space>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={t("lines.blooming_seed_carriers")}>
                        <Form.Item
                            name="bloomSeedBearer"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please enter ' + t("lines.blooming_seed_carriers"),
                        //     },
                        // ]}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={t("lines.planting_date")}>
                        <Form.Item
                            name="plantingDate1"

                        >
                            <DatePicker format={dateFormat} />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={t("lines.sowing_date")}>
                        <Form.Item
                            name="sowingDate1"

                        >
                            <DatePicker format={dateFormat} />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={t("lines.amount_of_seeds")}>
                        <Form.Item
                            name="amountOfSeeds"
                        >
                            <InputNumber />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={2} label={t("lines.germination")}>
                        <Form.Item
                            name="germination"
                        >
                            <InputNumber /> 
                        </Form.Item>%
                    </Descriptions.Item>

                    {sortenStufeBlock()}
                    <Descriptions.Item span={1} >
                        <Form.Item
                            name="submit"

                        >
                            <Space><Button type="primary" htmlType="submit" loading={loading}>
                                Submit
                            </Button> <Button type="secondary" onClick={props.onClose}>
                                    Cancel
                                </Button></Space>
                        </Form.Item>
                    </Descriptions.Item>
                </Descriptions>
            </Form>
        </div>
    )
}

export default AddLine
