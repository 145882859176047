import React from 'react'
import {
    SyncOutlined,
    AimOutlined 
} from '@ant-design/icons';
import { Tag, Divider } from 'antd';

export default function StatusTag(props) {
    const status = props.status;
    let name =  status.name;
    if(name == "PAYOUT") { name == "BOARD_REVIEW"}
    return ( name ? 
            <Tag icon={<SyncOutlined spin />} color="processing">
            {name}
            </Tag> : ""
    )
}
