import React from 'react'
import ReportEz from './ReportEz';
import { Empty, Space, Button, message} from 'antd'
import { createReportYear, deleteReportYear } from '../../util/APIUtils';
import ReportVa from './ReportVa';
import ReportGl from './ReportGl';

function ReportContainer(props) {
    console.log(props)
    let reportExist = props.projectDetailInfo.projectReportInfo ? true : false;
    let projectDetailId = props.projectDetailInfo.id;
    const screenState = {
        "year": props.year,
        "tab": '3'
    }

    const createReportNew = () => {
        let promise;
        promise = createReportYear(props.projectDetailInfo.id);

        if (!promise) {
            return;
        }

        promise.then(response => {
            message.success("Projektbericht aktualisiert")
            props.reloadProject(screenState);

        }).catch(error => {
            message.error("Update fehlgeschlagen")
        });
    }

    
    const deleteReport = () => {
        console.log(projectDetailId)
        let promise;
        promise = deleteReportYear(projectDetailId);

        if (!promise) {
            return;
        }

        promise.then(response => {
            message.success("Projektbericht gelöscht")
            props.reloadProject(screenState);

        }).catch(error => {
            console.log(error)
            message.error("Report delete failed")
        });
    }

    const getReportType = () => {
        if(props.projectInfo.projectType == "EZ") {
            return <ReportEz {...props} deleteReport={deleteReport} />
        } else if(props.projectInfo.projectType == "VA") {
            return <ReportVa {...props} deleteReport={deleteReport} />
        }else if(props.projectInfo.projectType == "GL") {
            return <ReportGl {...props} deleteReport={deleteReport} />
        }
    }

    return (
        <div>
            {reportExist ? getReportType() :
                <Empty description={<Space direction="vertical">Bericht nicht verfügbar
                    {props.isReportEditable ?
                        <Button onClick={createReportNew} type="primary">Bericht erstellen</Button> :
                        ""} </Space>} />
            }
        </div>
    )
}

export default ReportContainer