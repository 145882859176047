import React, { Component } from 'react';
import { Space, Popover, Avatar } from 'antd';

class ProjectStatusComponent extends Component {
    // Determine background and border style based on active status
    getStatusColorActive = (currentYearActive, lastYearActive) => {
        let baseStyle = {
            color: "#CCC", // Default text color for better contrast, especially for transparent backgrounds
            border: "0px solid transparent", // Default no border
        };

        if (currentYearActive) {
            // Green background for currently active
            return { ...baseStyle, backgroundColor: "#a7c957", color: "#fff" }; // White text for green background
        } else if (lastYearActive && !currentYearActive) {
            // Green border only if active last year and not this year
            return { ...baseStyle, backgroundColor: "transparent", border: "1px solid #a7c957" };
        }
        // Grey otherwise
        return { ...baseStyle, backgroundColor: "#d9d9d9", color: "#fff" }; // White text for grey background
    }

    // Set avatar color based on statusFG value
    getStatusColorByStatusFG = (status) => {
        switch(status) {
            case "ok":
                return { backgroundColor: '#a7c957', color: "#fff" }; // Green with white text
            case "zurückgestellt":
                return { backgroundColor: '#ffd700', color: "#000" }; // Yellow with black text
            case "abgelehnt":
                return { backgroundColor: '#ff8c00', color: "#fff" }; // Orange with white text
            default:
                return { backgroundColor: '#d9d9d9', color: "#fff" }; // Grey with white text
        }
    }

    // Generate dynamic popover content based on status and specific conditions
    getPopoverMessage = (statusLabel, specificStatus) => {
        switch (statusLabel) {
            case 'A':
                return specificStatus ? "Aktives Projekt" : "Projekt nicht aktiv";
            case 'P':
                return specificStatus ? "Planung liegt vor" : "Planung liegt nicht vor";
            case 'FG':
                switch (specificStatus) {
                    case "ok": return "Fachgruppe empfielt Durchführung";
                    case "zurückgestellt": return "Begutachtung durch Fachgruppe in Arbeit";
                    case "abgelehnt": return "Von Fachgruppe abgelehnt";
                    default: return "Von der Fachgruppe nicht evaluiert";
                }
            case 'Vo':
                switch (specificStatus) {
                    case "ok": return "Durch Vorstand bewilligt";
                    case "zurückgestellt": return "Vorstand empfielt Uberarbeitung";
                    case "abgelehnt": return "Von Vorstand abgelehnt";
                    default: return "Von Vorstand nicht evaluiert";
                }
            default:
                return "Unknown Status";
        }
    }

    renderStatusPopover = (statusLabel, currentYearActive, lastYearActive, specificStatus) => {
        let message = this.getPopoverMessage(statusLabel, specificStatus);
        let style = (statusLabel === 'A') ? this.getStatusColorActive(currentYearActive, lastYearActive) : this.getStatusColorByStatusFG(specificStatus);

        return (
            <Popover content={message} trigger="hover">
                <Avatar
                    style={style}
                    size={24}
                >
                    {statusLabel}
                </Avatar>
            </Popover>
        );
    }

    render() {
        const { currentUser, record } = this.props;

        const currentProjectYear = currentUser.projectYear.projectYear;
        const previousProjectYear = currentProjectYear - 1;

        const currentYearActive = record.year === currentProjectYear;
        const lastYearActive = record.year === previousProjectYear;

        const fgStatus = record.statusFG;
        const boardStatus = record.status;
        const ksPlanStatus = record.ks_plan === 1 ? "ok" : record.ks_plan === false ? "abgelehnt" : null;

        return (
            <Space>
                {this.renderStatusPopover('A', currentYearActive, lastYearActive, currentYearActive ? "ok" : null)}
                {currentYearActive && (
                    <Space>
                        {this.renderStatusPopover('P', currentYearActive, lastYearActive, ksPlanStatus)}
                        {this.renderStatusPopover('FG', currentYearActive, lastYearActive, fgStatus)}
                        {this.renderStatusPopover('Vo', currentYearActive, lastYearActive, boardStatus)}
                    </Space>
                )}
            </Space>
        );
    }
}

export default ProjectStatusComponent;
