import React, { Component } from 'react'
import {
    Badge,
    Card,
    Descriptions,
    Divider,
    Table,
    Layout,
    Input,
    Row,
    Col,
    Typography,
    PageHeader,
    Select,
    List,
    Avatar,
    Tag,
    Space,
    Button,

} from 'antd';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";

import { getYears, getZslData, getContractLink } from '../util/APIUtils';

import YearSelect from '../common/YearSelect';



export class Contract extends Component {

    constructor(props) {
        super(props);
        this.state = {
            year: null,
            projectYears: [],
            zslData: [],
            isLoading: false,
            breederId: props.currentUser.breederInfo.id
        };
    }

    handleYearInput = (val) => {
        console.log(val);
        this.setState({
            year: val
        })

        console.log(this.state.year);
        this.getReport(val);
    }

    handleBack = e => {
        this
            .props
            .history
            .goBack();
    };

    componentDidMount() {
        if (!this.props.isAuthenticated) {
            this
                .props
                .history
                .push("/login");
        }
    }

    getReport = (year) => {
        const win = window.open(getContractLink(year, this.state.breederId), '_blank');
        if (win != null) {
            win.focus();
        }
    }


    render() {
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    onBack={() => this.handleBack()}
                    title={"Auftrag"}
                />
                <Card bordered={true} headStyle={{ padding: "0" }} loading={this.state.isLoading} >

                    <Space>
                        <YearSelect selectedItem={this.state.year} setItem={this.handleYearInput}/>
                       
                    </Space><Divider></Divider>

                    

                    </Card>
            </div>
        
        )
    }
}

export default withTranslation()(withRouter(Contract));
