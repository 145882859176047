import React, { createRef, Component } from 'react';
import shortid from 'shortid';
import {
  Badge,
  Typography,
  Form,
  Card,
  Descriptions,
  Divider,
  Row,
  Table,

} from 'antd';

import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { RightCircleFilled } from '@ant-design/icons';
import EditableTable from './EditableTable'
import './Calculation.css'
import EditableAccountingItems from './EditableAccountingItems';
import EditableExaminationItems from './EditableExaminationItems';
import EditableAccountingItemsActuals from './EditableAccountingItemsActual';
import EditableExaminationItemsActual from './EditableExaminationItemsActual';
import { formatCurrency } from '../../util/Helpers';
import EditableGrid from './EditableGridAccounting';
import EditableGridAccounting from './EditableGridAccounting';
import EditableGridExamination from './EditableGridExamination';
import AmountSummary from './AmountSummary';


const { Text, Title } = Typography;

class Calculation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: props.year,
      projectYearDetails: props.projectDetail,
      calculation: props.projectDetail.projectDetailAccountingItemInfos,
      examinations: props.projectDetail.projectDetailExaminationItemInfos,
      examinationItems: props.examinationItems,
      accountingItems: props.accountingItems,
      isPlanEditable: props.isPlanEditable,
      isReportEditable: props.isReportEditable,
      currentUser: props.currentUser
      // projectDetailYear: props.projectDetails.find(o => o.year === props.year)
    };
  }

  render() {
    console.log(this.props)
    const { t } = this.props;
    const { editing } = this.props;
    const columnAccountingItems = [
      {
        title: t("calculation.accounting_item"),
        dataIndex: 'accountingItem',
        key: 'accountingItem',
        width: 500,
      },
      {
        title: t("calculation.researcher"),
        children: [
          {
            title: t("calculation.budget"),
            align: "right",
            dataIndex: 'daysResearcherBudget',
            key: 'daysResearcherBudget',
            render: (text, row, index) => {
              return (
                <span><b>
                  {text != null || text == 0 ? text.toFixed(2) : "0.00"}
                </b>
                </span>
              )
            },
          },
          {
            title: t("calculation.actual"),
            align: "right",
            dataIndex: 'daysResearcherActual',
            key: 'daysResearcherActual',
            render: (text, row, index) => {
              let textColor = row.daysResearcherActual > row.daysResearcherBudget ? "warning" : "default";
              return (
                <span><b>
                  <Text type={textColor}>  {text != null || text == 0 ? text.toFixed(2) : "0.00"}</Text>
                </b>
                </span>
              )
            },
          },
        ]
      },
      {
        title: t("calculation.helper"),
        children: [
          {
            title: t("calculation.budget"),
            align: "right",
            dataIndex: 'daysHelperBudget',
            key: 'daysHelperBudget',
            render: (text, row, index) => {
              return (
                <span><b>
                  {text != null || text == 0 ? text.toFixed(2) : "0.00"}
                </b>
                </span>
              )
            },
          },
          {
            title: t("calculation.actual"),
            align: "right",
            dataIndex: 'daysHelperActual',
            key: 'daysHelperActual',
            render: (text, row, index) => {
              let textColor = row.daysHelperActual > row.daysHelperBudget ? "warning" : "default";
              return (
                <span><b>
                  <Text type={textColor}>{text != null || text == 0 ? text.toFixed(2) : "0.00"}</Text>
                </b>
                </span>
              )
            },
          }]
      },
      {
        title: t("calculation.total"),
        children: [
          {
            title: t("calculation.budget"),
            align: "right",
            dataIndex: 'budgetTotalAmount',
            key: 'budgetTotalAmount',
            render: (text, row, index) => {
              return (
                <span><b>
                  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}
                </b>
                </span>
              )
            },
          },
          {
            title: t("calculation.actual"),
            align: "right",
            dataIndex: 'actualTotalAmount',
            key: 'actualTotalAmount',
            render: (text, row, index) => {
              let textColor = row.actualTotalAmount != row.budgetTotalAmount ? "warning" : "default";
              let actualSelected = row.actualSelected ? "" : "#f2d577"
              return {
                props: {
                  style: { background: actualSelected },
                },
                children:
                  <span><b>
                    <Text type={textColor}>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                  </b>
                  </span>
              }
            },
          }]
      }
    ];
    const calculationWithKey = [...this.state.calculation];
    const examinationsWithKey = [...this.state.examinations];


    calculationWithKey.forEach(function (entry) {
      entry.key = shortid.generate();
    });

    examinationsWithKey.forEach(function (entry) {
      entry.key = shortid.generate();
    });


    const columnExaminationItems = [
      {
        title: t("calculation.examination_item"),
        dataIndex: 'examinationItem',
        key: 'examinationItem',
        width: 500,
      },
      {
        title: t("calculation.quantity"),
        children: [
          {
            title: t("calculation.budget"),
            align: "right",
            dataIndex: 'quantityBudget',
            key: 'quantityBudget',
            render: (text, row, index) => {
              return (
                <span><b>
                  {text}
                </b>
                </span>
              )
            },
          },
          {
            title: t("calculation.actual"),
            align: "right",
            dataIndex: 'quantityActual',
            key: 'quantityActual',
            render: (text, row, index) => {
              return (
                <span><b>
                  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}
                </b>
                </span>
              )
            },
          },
        ]
      },
      {
        title: t("calculation.amount"),
        children: [
          {
            title: t("calculation.budget"),
            align: "right",
            dataIndex: 'amountBudget',
            key: 'amountBudget',
            render: (text, row, index) => {
              return (
                <span><b>
                  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}
                </b>
                </span>
              )
            },
          },
          {
            title: t("calculation.actual"),
            align: "right",
            dataIndex: 'amountActual',
            key: 'amountActual',
            render: (text, row, index) => {
              return (
                <span><b>
                  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}
                </b>
                </span>
              )
            },
          }]
      },
      {
        title: t("calculation.total"),
        children: [
          {
            title: t("calculation.budget"),
            align: "right",
            dataIndex: 'budgetTotal',
            key: 'budgetTotal',
            render: (text, row, index) => {
              return (
                <span><b>
                  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}
                </b>
                </span>
              )
            },
          },
          {
            title: t("calculation.actual"),
            align: "right",
            dataIndex: 'actualTotalAmount',
            key: 'actualTotalAmount',
            render: (text, row, index) => {
              let textColor = row.actualTotalAmount != row.budgetTotalAmount ? "warning" : "default";
              return (
                <span><b>
                  <Text type={textColor}>{text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                </b>
                </span>
              )
            },
          }]
      }
    ];
    let locale = {
      emptyText: (
        <span>
          <p>
            No Data
          </p>

        </span>
      )
    };

    return (

      <div>
        {/* {!editing ?

          <div>
            <Table
              size={"small"}
              locale={locale}
              bordered
              dataSource={this.state.calculation}
              columns={columnAccountingItems}
              pagination={false}
              summary={pageData => {
                let textColor = this.state.projectYearDetails.accountingActualTotal > this.state.projectYearDetails.accountingBudgetTotal ? "warning" : "default"
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell><Title level={5}>Gesamt</Title></Table.Summary.Cell>
                      <Table.Summary.Cell>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right" >
                        <Title level={5}>{formatCurrency(this.state.projectYearDetails.accountingBudgetTotal.toFixed(2))}</Title>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right" >
                        <Title type={textColor} level={5}>{formatCurrency(this.state.projectYearDetails.accountingActualTotal.toFixed(2))}</Title>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
            <Table
              bordered
              size={"small"}
              locale={locale}
              dataSource={this.state.examinations}
              columns={columnExaminationItems}
              pagination={false}
              summary={pageData => {
                let textColor = this.state.projectYearDetails.examinationActualTotal > this.state.projectYearDetails.examinationBudgetTotal ? "warning" : "default"
                return (
                  <>
                    <Table.Summary.Row className="summary-row">
                      <Table.Summary.Cell><Title level={5}>Gesamt</Title></Table.Summary.Cell>
                      <Table.Summary.Cell>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Title level={5}>{formatCurrency(this.state.projectYearDetails.examinationBudgetTotal.toFixed(2))}</Title>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="right">
                        <Title type={textColor} level={5} align="right">{formatCurrency(this.state.projectYearDetails.examinationActualTotal.toFixed(2))}</Title>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }} />
          </div> : */}
          <div>
            <EditableGridAccounting
              projectId={this.state.projectYearDetails.projectID}
              projectDetailId={this.state.projectYearDetails.id}
              data={calculationWithKey}
              accountingItems={this.state.accountingItems}
              isPlanEditable={this.state.isPlanEditable}
              isReportEditable={this.state.isReportEditable}
              workFlowState={this.props.workFlowState}
            />

            <EditableGridExamination
              projectId={this.state.projectYearDetails.projectID}
              projectDetailId={this.state.projectYearDetails.id}
              data={calculationWithKey}
              examinationItems={this.state.examinationItems}
              isPlanEditable={this.state.isPlanEditable}
              isReportEditable={this.state.isReportEditable}
              workFlowState={this.props.workFlowState}
            />

            {/* <EditableAccountingItemsActuals
              year={this.state.year}
              data={calculationWithKey}
              projectId={this.state.projectYearDetails.projectID}
              projectDetailId={this.state.projectYearDetails.id}
              accountingItems={this.state.accountingItems}
              reloadProject={this.props.reloadProject}
              workFlowState={this.props.workFlowState}
              isPlanEditable={this.state.isPlanEditable}
              isReportEditable={this.state.isReportEditable}
            />
            <EditableExaminationItemsActual
              year={this.state.year}
              data={examinationsWithKey}
              projectId={this.state.projectYearDetails.projectID}
              projectDetailId={this.state.projectYearDetails.id}
              examinationItems={this.state.examinationItems}
              reloadProject={this.props.reloadProject}
              workFlowState={this.props.workFlowState}
              isPlanEditable={this.state.isPlanEditable}
              isReportEditable={this.state.isReportEditable}
            /> */}

          </div>

          <AmountSummary projectDetailId={this.state.projectYearDetails.id} type={"actual"} currentUser={this.state.currentUser}/>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Calculation));