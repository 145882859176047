import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";


import {
    Badge,
    Card,
    Descriptions,
    Button,
    Table,
    Layout,
    Input,
    Row,
    Col,
    Typography,
    PageHeader,
    Popconfirm,
    Select,
    List,
    Avatar,
    Space,
    Tabs,
    message

} from 'antd';
import { getFgCropDetails, createFgBudgetYear, createFgBudgetYearCrop, getFgCropDetailsForFgAndBreeder } from '../util/APIUtils'
import DetailFgContainer from './DetailFgContainer';
const { TabPane } = Tabs;

function FachGroupBudgetDetail(props) {
    const history = useHistory()

    console.log(props)
    const { cropId, breederId } = props.match.params;

    const [fgBudgetDetails, setFgBudgetDetails] = useState([])
    const [loading, setLoading] = useState(false)
    const [editing, setEditing] = useState(false)
    // const currentUser = props.currentUser;
    const currentUser = props.currentUser;
    console.log(currentUser)
    const currentPhase = currentUser.projectYear.currentPhase;

    const { fgId } = (props.location && props.location.state) || {};

    const onChange = () => {

    }

    const loadDetails = React.useCallback(async () => {
        console.log("Loading details")
        // console.log(breederId)
        let promise;
        let brId = breederId ? breederId : 0
        
        promise = getFgCropDetailsForFgAndBreeder(cropId, brId);

        if (!promise) {
            return;
        }
        setLoading(true)

        promise.then(response => {
            console.log(response)
            setFgBudgetDetails(response.reverse());
            setLoading(false)
            console.log(fgBudgetDetails)

        }).catch(error => {
            setLoading(false)
        });
    }, [])

    useEffect(() => {
        loadDetails();
    }, [loadDetails]);


    const title = fgBudgetDetails.length > 0 ? "Fachgruppen Tatigkeit - " + fgBudgetDetails[0].breederName + " / " + fgBudgetDetails[0].cropName : " ";

    // console.log(title)

    const reload = () => {
        console.log("Reloading")
        loadDetails();
    }
    
    const handleBack = e => {
        if(fgId) {
            props
            .history
            .push({ 
                pathname: '/fg-tasks/' + fgId.toString(),
                state: {tab : "2"}
               });
        } else {
            props.history.goBack();
        }
        
           
    };

    const cancelPop = () => {
        // this.setState({
        //     loading: false,
        // })
    }

    const addFgBudgetYear = () => {

        let promise;
        promise = createFgBudgetYearCrop(cropId);

        if (!promise) {
            return;
        }
        setLoading(true)

        promise.then(response => {
            if (!response) {
                message.warn("Jahresdaten vorhanden")
            }
            console.log(response)
            loadDetails();
            setLoading(false)

        }).catch(error => {
            setLoading(false)
        });

    }

    const startEdit = () => {
        setEditing(true);
    }

    const onSave = () => {

    }

    const onCancel = () => {
        setEditing(false)
    }

    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={() => handleBack()}
                // extra={[editMode ? <Button key="334" type="primary" onClick={onSave}>Save</Button> : <Button key="334" type="primary" onClick={onEdit}>Edit</Button>, <Button key="34" type="primary" danger>Delete</Button>]}
                title={!loading && title}
            />

            <Card bordered loading={loading} headStyle={{ padding: "0" }}>

                <Descriptions
                    size="small"
                    bordered
                    column={2}
                    // extra={[ currentPhase === 1 ? <Space> {editing? <Space><Button key="334" type="primary" onClick={onSave}>Save</Button> <Button onClick={onCancel} key="34" type="primary" danger>Cancel</Button></Space> : <Button key="334" type="primary" onClick={startEdit}>Edit</Button>}</Space> : "", ]}
                    style={{
                        marginBottom: 0
                    }}>
                    <Descriptions.Item label="Geplante Fachgruppenarbeit" span={2} style={{ "width": "300px" }}>
                        <b>Plan</b>
                    </Descriptions.Item>

                </Descriptions>
                <br></br>
                {fgBudgetDetails ?
                    <Tabs tabPosition="left" onChange={onChange} bordered>
                        {fgBudgetDetails.map(
                            item => (
                                <TabPane tab={`${item.year}`} key={item.year}>
                                    <DetailFgContainer
                                        fgDetails={item}
                                        editing={editing}
                                        currentPhase={currentPhase}
                                        currentUser={currentUser}
                                        year={item.year}
                                        onCancel={onCancel}
                                        startEdit={startEdit}
                                        reload={reload}
                                    />
                                </TabPane>
                            )
                        )}
                        {([1,3].includes(currentPhase)  || currentUser.accessOverride?.planWrite) &&
                        <TabPane tab={
                            <Popconfirm
                                title={"Sind Sie sicher, dass Sie ein neues jahr erstellen?"}
                                onConfirm={addFgBudgetYear}
                                onCancel={cancelPop}
                            >
                                <Button >Jahr hinzufügen</Button>
                            </Popconfirm>
                        } key={"sss"}>
                        </TabPane>}
                    </Tabs> : ""}

            </Card>
        </div>
    )
}

export default FachGroupBudgetDetail
