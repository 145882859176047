import React, { Component } from 'react'
import {
    Card, notification, Menu, Breadcrumb,
    PageHeader, Form, Input,
    Cascader, Select, Row, Col, Checkbox,
    Button, AutoComplete, Space
} from 'antd';
// import {PlaceSearch} from '../common/PlaceSearch';
import { withRouter } from 'react-router-dom';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { createFarmProfile } from '../util/APIUtils';
import { withTranslation  } from "react-i18next";

import './FarmProfile.css';


const { Option } = Select;
const { TextArea } = Input;
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 8,
    },
};
const formItemLayout = {
    labelCol: {
       span: 8 
    },
    wrapperCol: {
       span: 8 
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
        offset: 8,
        span: 8,
    },
  };

class NewFarmProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            farmProfile: null,
            isLoading: false,
            visible: false
        };
    }

    handleBack = e => {
        this
            .props
            .history
            .goBack();
    };

    formRef = React.createRef();

    mapObject = (values, type) => {
        const fpObj = {
            "id": values.id,
            "name": values.name,
            "description": values.description,
            "houseNo": values.houseNo || "",
            "country": addr.country,
            "location": addr.route || "",
            "place": addr.locality,
            "pin": addr.postal_code,
            "street":  addr.route || "",
            "socialLinks" : values.names ? values.names.join() || [] : null,
            "phone": values.phone,
            "type": values.type || 1,
            "website": values.website || "",
            "email": values.email,
            "lat": values.location[0].lat,
            "lon": values.location[0].lng,
        }
        console.log(fpObj);
    }

    onFinish = (values) => {
        console.log(values)
        

        const fpObj = {
            "name": values.name,
            "description": values.description,
            "houseNo":  "",
            "country": "",
            "location":  "",
            "place": "",
            "pin": "",
            "street":  "",
            "socialLinks" : values.names ? values.names.join() || [] : null,
            "phone": values.phone,
            "type": values.type || 1,
            "website": values.website || "",
            "email": values.email,
            "lat": "",
            "lon": "",
            "address": values.location
        }
        console.log(fpObj);
        createFarmProfile(fpObj)
        .then(response => {
            notification.success({
                message: 'Kultursaat',
                description: response
            });    
            this.props.history.push("/farmprofile/list");
        }).catch(error => {
            if(error.status === 401) {
                this.props.handleLogout('/login', 'error', 'You have been logged out. Please login again.');    
            } else {
                notification.error({
                    message: 'Kultursaat',
                    description: error.message || 'Sorry! Something went wrong. Please try again!'
                });              
            }
        });

    };
    onReset = () => {
        this.formRef.current.resetFields();
    };

    handleChange = address => {
        
        this.setState({ address });
    };
    handleSelect1 = address => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => console.log('Success', latLng))
            .catch(error => console.error('Error', error));
    };

    updateData = res => {
        if(res.length > 0){
            let nAddr = res[0].gmaps.address_components;
            const addr = nAddr.reduce((seed, { long_name, types }) => (types.forEach(t => seed[t] = long_name), seed), {});
    }
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    onBack={() => this.handleBack()}
                    title={t("farmprofile.new_farm_profile")}
                    subTitle=""
                    extra={[]
                    }

                />
                <Card bordered={false} >
                    <Form {...layout} ref={this.formRef} name="control-ref" onFinish={this.onFinish}>
                        <Form.Item name="name" label={t("general.name")} rules={[{ required: true }]}>
                            <Input maxLength={250}/>
                        </Form.Item>
                        <Form.Item name="description" label={t("general.description")} rules={[{ required: true }]} >
                            <TextArea rows={3} maxLength={250} />
                        </Form.Item>
                        <Form.Item name="location" label={t("general.address")} rules={[{ required: true }]}>
                        <Input.TextArea rows={3} maxLength={250}/>
                        </Form.Item>
                        {/* <Form.Item name="houseNo" label={t("general.house_no")} rules={[{ required: false }]}>
                            <Input />
                        </Form.Item> */}
                        <Form.Item name="phone" label={"Telefon-Nr"} rules={[{ required: false }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="email" label={"E-mail"} rules={[{ required: false }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="website" label={"Website"} rules={[{ required: false }]}>
                            <Input addonBefore="https://" defaultValue="" />
                        </Form.Item>
                        <Form.Item name="type" label={"Anrede"} rules={[{ required: false }]}>
                            <Select  style={{ width: 120 }} defaultValue={1}>
                            <Option value={1} key={1} >{"Firma"}</Option>
                            <Option value={2} key={2}>{"Frau"} </Option>
                            <Option value={3} key={3}>{"Herr"}</Option>
                            </Select>
                            
                        </Form.Item>

                        <Form.List
                            name="names"
                            rules={[
                                {
                                    validator: async (_, names) => {
                                        
                                    },
                                },
                            ]}
                        >
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                            label={index === 0 ? 'Social Media' : ''}
                                            required={false}
                                            key={field.key}
                                        >
                                            <Form.Item
                                                {...field}
                                                validateTrigger={['onChange', 'onBlur']}
                                                rules={[
                                                    {
                                                        required: false,
                                                        whitespace: true,
                                                        message: t("farmprofile.add_social_media"),
                                                    },
                                                ]}
                                                noStyle
                                            >
                                                <Input placeholder={t("farmprofile.social_media_link")} style={{ width: '85%' }} />
                                            </Form.Item>
                                            {fields.length > 1 ? (
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => remove(field.name)}
                                                />
                                            ) : null}
                                        </Form.Item>
                                    ))}
                                    <Form.Item
                                    {...formItemLayoutWithOutLabel}>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            style={{ width: '60%' }}
                                            icon={<PlusOutlined />}
                                        >
                                            {t("farmprofile.add_social_media_text")}
                                        </Button>
                                       
                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>

                        <Form.Item {...tailLayout}>
                            <br></br>
                            <Space>
                            <Button type="primary" htmlType="submit">
                            {t("general.submit")}
                            </Button>
                            <Button htmlType="button" onClick={this.onReset}>
                            {t("general.reset")}
                            </Button>
                            </Space>

                        </Form.Item>
                    </Form>

                </Card>
            </div>
        )
    }
}

export default withTranslation()(withRouter(NewFarmProfile));

