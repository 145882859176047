import React, { useState, useEffect, useRef } from 'react';
import {
    Card,
    List,
    Badge,
    Input,
    Tag,
    Space,
    Row,
    Col,
    Form,
    Button,
    InputNumber,
    Checkbox,
    DatePicker,
    Select,
    Descriptions,
    message,
} from 'antd';
import _ from "lodash";
import { CheckCircleOutlined } from '@ant-design/icons';
import './Plan.css';
import { useTranslation } from 'react-i18next';
import { saveProjectDetail } from '../../util/APIUtils';
import { capitalize, formatCurrency } from '../../util/Helpers';
import MultiSelect from '../../common/MultiSelect';
import AmountSummary from './AmountSummary';
import LineSelectionLookup from '../../common/LineSelectionLookup';
import GlLineSelectionTable from '../../common/GlLineSelectionTable';
import shortid from 'shortid';

function getColorToneForStatus(statusText) {
    let status;
    switch (statusText) {
        case "ok":
            status = "#55a630";
            break;
        case "zurückgestellt":
            status = "#ff6d00";
            break;
        case "abgelehnt":
            status = "#ff6b6b";
            break;
        default:
            status = "";
    }
    return status
}


function processLineSelections(arr, cropId) {
    const bList = arr
        .filter(function (el) {
            return el.cropId == cropId
        })
    return bList;
}

function onReset() {
    this.formRef.current.resetFields();
};


function PlanGl(props) {
    
    const { t } = useTranslation();
    const [editing, setEditing] = React.useState(false);
    const workFlowState = props.workFlowState;

    const [tLineSelections, setTLineSelections] = useState([])
    const [state, setState] = useState(props.projectDetail);
    const editable = props.isPlanEditable



    const screenState = {
        "year": state.year.toString(),
        "tab": '1'
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeOther = name => value => {
        if (name === 'investigations') {
            value = Array.isArray(value) ? value.join(";") : [];
        } else if (name === "ks_plan") {
            value = value.target.checked ? 1 : 0;
        }
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const getCheckedStatus = (val) => {
        const investigationsArray = state.investigations != null ? state.investigations.split(";") : null;
        return investigationsArray;
    }


    const onFinish = (values) => {
        var subsetObj = _.pick(state, [
            "id",
            "description",
            "plannedActivity",
            "descExperimentalSetup",
            "glLineSelections",
            "variantTreatments",
            "breedingMethodList",
            "numberOfPlannedLines",
            "repetitions",
            "projectExtension",
            "generationStart",
            "generationEnd",
            "descBreedingMethod",
            "cultivationScope",
            "cultivationScopeUnit",
            "priority",
            "investigations",
            "ks_plan"
        ]);

        console.log(subsetObj)

        let promise;
        promise = saveProjectDetail(subsetObj);

        if (!promise) {
            return;
        }

        promise.then(response => {
            message.success("Projektplan aktualisiert")
            props.reloadProject(screenState);

        }).catch(error => {
            message.error("project update failed")
        });
    };

    const [form] = Form.useForm();

    form.setFieldsValue({
        description: state.description,
        planned_activity: state.plannedActivity,
        descExperimentalSetup: state.descExperimentalSetup,
        youngest: state.generationStart,
        oldest: state.generationEnd,
        repetitions: state.repetitions,
        breeding_method_description: state.descBreedingMethod,
        priority: state.priority,
        numberOfPlannedLines: state.numberOfPlannedLines,
        cultivationScope: state.cultivationScope ? parseInt(state.cultivationScope) : 0,
        cultivationScopeUnit: state.cultivationScopeUnit,
        investigations: getCheckedStatus()
    });

   
    const setEditingLocal = (val) => {
        setEditing(val);
        props.setEditingMaster(val);
        if (!val) {
            props.reloadProject(screenState)
        }
    }

    const setGlI1tems = (vals) => {
        setState(prevState => ({
            ...prevState,
            ["glLineSelections"]: vals
        }));
    }

    const setGlI2tems = (vals) => {
        setState(prevState => ({
            ...prevState,
            ["variantTreatments"]: vals
        }));
    }


    return (
        <div>
            <Card bordered={true}
                extra={
                    editable ?
                        !editing ?
                            <Button onClick={() => setEditingLocal(true)}>Bearbeiten</Button> :
                            <Space>
                                <Button onClick={() => form.submit()} type='primary'>Speichern</Button>
                                <Button onClick={() => setEditingLocal(false)}>Abbrechen</Button>
                            </Space> : ""

                }>
                <Form
                    // ref={this.formRef}
                    name="plan-x"
                    span={3}
                    form={form}
                    onFinish={onFinish}
                    scrollToFirstError
                >
                    <Descriptions
                        size="small"
                        bordered
                        style={{
                            marginBottom: 2
                        }}>
                        <Descriptions.Item label={t("project_details.description")} span={3} labelStyle={{ fontWeight: 'bold', textAlign: 'start' }} >
                            {editing && editable ?
                                <Input.TextArea name="description" value={state["description"]} onChange={handleChange} />
                                : state["description"]}
                        </Descriptions.Item>
                        <Descriptions.Item span={3} label={t("project_details.planned_activity")} labelStyle={{ fontWeight: 'bold', width: 250 }}>
                            {editing && editable ?
                                <Input.TextArea name="plannedActivity" value={state["plannedActivity"]} onChange={handleChange} />
                                : state["plannedActivity"]}
                        </Descriptions.Item>
                        <Descriptions.Item span={3} label="Erläuterung Versuchsdurchf" labelStyle={{ fontWeight: 'bold', width: 250 }}>
                            {editing && editable ?
                                <Input.TextArea name="descExperimentalSetup" value={state.descExperimentalSetup} onChange={handleChange} />
                                : state.descExperimentalSetup}
                        </Descriptions.Item>

                        <Descriptions.Item label="Sorte(n)/Linei(n)" labelStyle={{ fontWeight: 'bold' }} span={3}>
                            {editing && editable ?
                                <Form.Item
                                    name="glLineSelections"
                                    hasFeedback
                                >
                                    <GlLineSelectionTable dataSource={state.glLineSelections} setItems={setGlI1tems} />
                                </Form.Item>
                                :
                                <List
                                    size="small"
                                    dataSource={state.glLineSelections}
                                    renderItem={item => <List.Item style={{ padding: 2 }}><Badge color={'geekblue'} text={item.name} /></List.Item>}
                                />}
                        </Descriptions.Item>

                        <Descriptions.Item label="Behandlungsvarianten" labelStyle={{ fontWeight: 'bold' }} span={3}>
                            {editing && editable ?
                                <Form.Item
                                    name="variantTreatments"
                                    hasFeedback
                                >
                                    <GlLineSelectionTable dataSource={state.variantTreatments} setItems={setGlI2tems} />
                                </Form.Item>
                                :
                                <List
                                    size="small"
                                    dataSource={state.variantTreatments}
                                    renderItem={item => <List.Item style={{ padding: 2 }}><Badge color={'geekblue'} text={item.name} /></List.Item>}
                                />}

                        </Descriptions.Item>
                        <Descriptions.Item label="Anzahl Varianten" labelStyle={{ fontWeight: 'bold' }} span={3} >
                            {editing && editable ?

                                <Form.Item
                                    name="numberOfPlannedLines"
                                >
                                    <InputNumber  onChange={handleChangeOther("numberOfPlannedLines")}/>
                                </Form.Item>
                                : state.numberOfPlannedLines}
                        </Descriptions.Item>

                        <Descriptions.Item label="Wiederholungen" labelStyle={{ fontWeight: 'bold' }} span={3} >
                            {editing && editable ?

                                <Form.Item
                                    name="repetitions"
                                >
                                    <Select
                                        style={{ width: '150px' }}
                                        name="repetitions"
                                        defaultValue={state.repetitions}
                                        onChange={handleChangeOther("repetitions")}
                                    >
                                        <Option value={0}>nein</Option>
                                        <Option value={2}>2</Option>
                                        <Option value={3}>3</Option>
                                        <Option value={4}>4</Option>
                                    </Select>
                                </Form.Item>
                                :  state.repetitions == 0 ? "nein" : state.repetitions}
                        </Descriptions.Item>

                        <Descriptions.Item label={t("project_details.cultivation_scope")} labelStyle={{ fontWeight: 'bold' }} span={3} >
                            {editing && editable ?

                                <div>
                                    <Space>
                                    <InputNumber name="cultivationScope" value={state["cultivationScope"]} onChange={handleChangeOther("cultivationScope")} />
                                    
                                    <Select
                                        // value={value.currency || currency}
                                        style={{ width: '150px' }}
                                        name="cultivationScopeUnit"
                                        defaultValue={state.cultivationScopeUnit}
                                        onChange={handleChangeOther("cultivationScopeUnit")}
                                    >

                                        <Option value={"qm"}>qm</Option>
                                        <Option value={"ha"}>ha</Option>
                                        <Option value={"stück"}>stück</Option>
                                    </Select>
                                    </Space>

                                </div>
                                : state.cultivationScope + " " + state.cultivationScopeUnit}
                        </Descriptions.Item>

                        <Descriptions.Item label="Geplante Untersuchungen" labelStyle={{ fontWeight: 'bold' }} span={3}>
                            {editing && editable ?
                                <Form.Item
                                    name="investigations"
                                >
                                    <Checkbox.Group style={{ width: '100%' }}  onChange={handleChangeOther("investigations")} >
                                        <Row>
                                            <Col span={8}>
                                                <Checkbox value="B" >Bonituren</Checkbox>
                                            </Col>
                                            <Col span={8}>
                                                <Checkbox value="S" >Sensorik</Checkbox>
                                            </Col>
                                            <Col span={8}>
                                                <Checkbox value="BM">Bildschaffende Methoden</Checkbox>
                                            </Col>
                                            <Col span={8}>
                                                <Checkbox value="A" >Analysen</Checkbox>
                                            </Col>
                                            <Col span={8}>
                                                <Checkbox value="BK">Bildekrafteuntersuchungen</Checkbox>
                                            </Col>

                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>

                                : <Checkbox.Group style={{ width: '100%' }} defaultValue={getCheckedStatus()} disabled >
                                    <Row>
                                        <Col span={8}>
                                            <Checkbox value="B" >Bonituren</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="S" >Sensorik</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="BM">Bildschaffende Methoden</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="A" >Analysen</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="BK">Bildekrafteuntersuchungen</Checkbox>
                                        </Col>

                                    </Row>
                                </Checkbox.Group>}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("project_details.breeding_method_description")} labelStyle={{ fontWeight: 'bold' }} span={3}>
                            {editing && editable ?
                                // <Form.Item
                                //     name="breeding_method_description"
                                //     rules={[
                                //         {
                                //             required: true,
                                //             message: 'Please input',
                                //         },
                                //     ]}
                                // >
                                <Input.TextArea name="descBreedingMethod" value={state["descBreedingMethod"]} onChange={handleChange} />
                                // </Form.Item>
                                : state.descBreedingMethod}
                        </Descriptions.Item>

                        <Descriptions.Item label={t("project_details.priority")} labelStyle={{ fontWeight: 'bold' }} span={3} >
                            {editing && editable ?
                                <Form.Item
                                    name="priority"
                                >
                                    <Select
                                        style={{ width: '100%', margin: '0 0px' }}
                                        name="priority"
                                        defaultValue={state.priority}
                                        onChange={handleChangeOther("priority")}
                                    >
                                        <Option value={1}>Hoch</Option>
                                        <Option value={2}>Mittel</Option>
                                        <Option value={3}>Neidrig</Option>
                                    </Select>
                                </Form.Item>
                                : state.priority}
                        </Descriptions.Item>

                        <Descriptions.Item label={t("project_details.reason_for_extension")} labelStyle={{ fontWeight: 'bold' }} span={3} >
                            {/* {editing && editable ? */}
                                {/* <Input.TextArea onChange={handleChangeOther("planDeviation")}/> : */}
                                {state.planDeviation}
                                {/* } */}
                        </Descriptions.Item>

                        <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label={"bei Kultursaat einreichen"} span={3}>
                            <Checkbox disabled={!(editing && editable)} defaultChecked={state.ks_plan == 1 ? true : false} label={"bei Kultursaat einreichen"} onChange={handleChangeOther("ks_plan")}></Checkbox>
                        </Descriptions.Item>

                    </Descriptions>
                    {editing && editable ? "" :
                        // <Badge.Ribbon text={state.ks_plan == 1 ? t("project_details.submitted_to_ks") : t("project_details.not_submitted")} color={state.ks_plan == 1 ? "green" : "volcano"}>
                        <AmountSummary  key={shortid.generate()} projectDetailId={state.id} type={"plan"} workFlowState reloadProject={() => props.reloadProject(screenState)} />
                        // </Badge.Ribbon>
                    }
                </Form>
            </Card>
        </div>
    );
}

export default PlanGl;