import {
    DataGrid, useGridApiContext, deDE, useGridApiRef, GridToolbarContainer, GridRowModes,
    GridActionsCellItem,
} from '@mui/x-data-grid';
import { NumericFormat } from 'react-number-format';
import { styled } from '@mui/material/styles';
import moment from 'moment'

import { Button, Select, Card, Descriptions, Input, Space, message, InputNumber, Divider, Popconfirm } from 'antd';
import React from 'react'
import { useEffect, useState, useMemo, useRef } from 'react'
import { currencyFormatter, currencyParser, formatCurrency } from '../util/Helpers';
import { updatePayoutDetail, getPayoutInstallmentReport } from '../util/APIUtils';
import { Box } from '@mui/system';

const useFakeMutation = () => {
    return;
};

const RateCard = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(props.payoutArray);
    const payoutInfo = props.payoutInfo;

    React.useEffect(() => {
        setData(props.payoutArray);
    }, [props.payoutArray])

    console.log(props.payoutArray)
    console.log(data)

    const stripDate = (dateString) => {

        let dtStr = ""
        if (!dateString) {
            return ""
        } else {
            dtStr = dateString.toString();
        }

        var date = moment(new Date(dtStr.substr(0, 16)));
        if (date.isValid) {
            return date.format("YYYY-MM-DD")
        }

        if (moment.isMoment(dateString)) {
            return moment(dateString).format('YYYY-MM-DD');
        }

        if (dtStr.includes("00:00:00")) {
            return dtStr.replace(' 00:00:00', '');
        } else {
            return dtStr;
        }
    }

    const stripDateOrig = (dateString) => {

        let dtStr = ""
        if (!dateString) {
            return ""
        } else {
            dtStr = dateString.toString();
        }

        var date = moment(new Date(dtStr.substr(0, 16)));
        if (date.isValid) {
            return date.format("DD.MM.YYYY")
        }

        if (moment.isMoment(dateString)) {
            return moment(dateString).format('DD.MM.YYYY');
        }

        if (dtStr.includes("00:00:00")) {
            return dtStr.replace(' 00:00:00', '');
        } else {
            return dtStr;
        }
    }

    const mutateRow = useFakeMutation();

    function CurrencyInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = async (event) => {
            await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
        };

        return (
            <NumericFormat
                style={{ height: "100px", minWidth: "197px", border: "0px", borderColor: "green" }}
                autoFocus
                align='right'
                value={value} decimalSeparator="," onChange={handleChange} />
        )
    }

    const handleParentClick = (e) => {
        e.stopPropagation();
    }

    const renderCurrencyInputCell = (params) => {
        return <CurrencyInputCell {...params} />;
    };

    const parseName = (col) => {
        console.log(col)

        const win = window.open(getPayoutInstallmentReport(payoutInfo.breederId, payoutInfo.year, col.serial ? col.serial : 4), '_blank');
        if (win != null) {
            win.focus();
        }
    }

    const renderDetailsButton = (params) => {
        return (
            <strong>
                {params.row.actualPayout > 0  && params.row.name != "Gesamt"?
                <Button
                    type="primary"
                    size="small"
                    style={{ marginLeft: 16 }}
                    onClick={() => {
                        parseName(params.row)
                    }}
                >
                    Gutschrift erstellen
                </Button> : ""}
            </strong>
        )
    }


    const columns = [
        { field: 'id', headerName: 'Id', hide: true },
        {
            field: 'name',
            headerName: 'Rate',
            headerAlign: 'left',
            align: 'left',
            minWidth: 80,
            // flex : 1
        },
        {
            field: 'plannedPayout',
            headerName: 'Geplanter Betrag',
            valueFormatter: ({ value }) => formatCurrency(value),
            renderEditCell: renderCurrencyInputCell,
            // type: 'number',
            width: 200,
            // flex : 1,
            editable: true,
            headerAlign: 'right',
            align: 'right'
        },
        {
            field: 'actualPayout',
            headerName: 'Ausgezahlter Betrag',
            valueFormatter: ({ value }) => formatCurrency(value),
            renderEditCell: renderCurrencyInputCell,
            // type: 'number',
            width: 200,
            // flex : 1,
            editable: true,
            headerAlign: 'right',
            align: 'right',
            // valueGetter: getTotalBudget,
            // valueSetter: setTotalBudget,
            // valueParser: parseTotalBudget
        },
        {
            field: 'paidDate',
            headerName: 'Überweisung am',
            // valueFormatter: ({ value }) => formatCurrency(value),
            // renderEditCell: renderCurrencyInputCell,
            type: 'date',
            minWidth: 140,
            editable: true,
            headerAlign: 'left',
            align: 'left',
            valueFormatter: ({ value }) => stripDateOrig(value),
            // valueGetter: getTotalActual,
            // valueSetter: setTotalBudget,
            // valueParser: parseTotalBudget
        },
        {
            field: 'remarks',
            headerName: 'Bemerkung',
            // renderEditCell: renderCurrencyInputCell,
            minWidth: 270,
            flex: 1,
            editable: true,
            headerAlign: 'left',
            align: 'left',
            // valueGetter: getTotalActual,
            // valueSetter: setTotalBudget,
            // valueParser: parseTotalBudget
        },
        {
            field: 'action',
            headerName: 'Beleg',
            renderCell: renderDetailsButton,
            // renderEditCell: renderCurrencyInputCell,
            minWidth: 50,
            flex: 1,
            editable: true,
            headerAlign: 'center',
            align: 'center',
            // valueGetter: getTotalActual,
            // valueSetter: setTotalBudget,
            // valueParser: parseTotalBudget
        },


    ];

    const processRowUpdate = React.useCallback(
        async (newRow) => {
            if (newRow.name === "Gesamt") return;

            newRow.actualPayout = currencyParser(currencyFormatter(newRow.actualPayout));
            newRow.plannedPayout = currencyParser(currencyFormatter(newRow.plannedPayout));
            newRow.planPayDate = stripDate(newRow.planPayDate)
            newRow.paidDate = stripDate(newRow.paidDate)
            console.log(newRow)

            let promise = updatePayoutDetail(newRow);
            if (!promise) {
                return;
            }

            promise.then(response => {
                console.log(response)
                // setData(data.map((row) => (row.id === newRow.id ? updatedRow : row)));
                // response.push(createSummaryData(response))
                setData(response);

            }).catch(error => {
                console.log(error)
                message.error("project update failed")
            });

            return newRow;
        },
        [mutateRow],
    );

    const handleProcessRowUpdateError = React.useCallback((error) => {
        console.log(error)
    }, []);

    const checkEditable = (param) => {

        if (param.row.name === "Gesamt") {
            return false;
        } else {
            return true;
        }
    }

    function CustomFooter(props) {
        return (
            <Box sx={{ fontWeight: "600", border: `1px solid #f0f0f0`, backgroundColor: "#f1f7ee", padding: "0px", display: "flex", justifyContent: "right", alignItems: "right" }}>
            </Box>)
    }

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        border: 1,
        color:
            theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            // backgroundColor: '#ccc',
            border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
                }`,
        },
        '& .MuiDataGrid-columnHeader': {
            height: 22,
            backgroundColor: '#f1f7ee',
        },
        '& .MuiDataGrid-cell': {

            color:
                theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
        },
        '& .MuiPaginationItem-root': {
            borderRadius: 0,
        },
        '& .sum-row-sum': {
            bgcolor: (theme) =>
                getBackgroundColor(theme.palette.success.main, theme.palette.mode),
            '&:hover': {
                bgcolor: (theme) =>
                    getHoverBackgroundColor(
                        theme.palette.success.main,
                        theme.palette.mode,
                    ),
            },
        },

    }));

    return (

        <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
                {/* <Space direction='vertical'> */}
                <br></br>
                <Box
                    sx={{
                        width: '100%',
                        '& .budget': {
                            backgroundColor: '#dde5b6',
                            color: 'e8e8e4',
                        },
                        '& .actual': {
                            backgroundColor: '#fae1ca',
                            color: '#1a3e72',
                        },
                        '& .sum-row': {
                            backgroundColor: '#f1f7ee',
                            color: '#1a3e72',
                            fontWeight: 800
                        },
                        '& .sum-row-red': {
                            backgroundColor: '#f2cc8f',
                            color: '#1a3e72',
                            fontWeight: 800
                        },
                    }}
                >
                    <StyledDataGrid
                        disableColumnFilter
                        autoHeight
                        rowHeight={35}
                        headerRowHeight={27}
                        rows={data}
                        columns={columns}
                        experimentalFeatures={{ newEditingApi: true, columnGrouping: true }}
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        // hideFooter
                        processRowUpdate={processRowUpdate}
                        disableColumnMenu
                        isCellEditable={checkEditable}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        getRowClassName={(params) => {
                            if (params.row.name === "Gesamt") {
                                if (params.field === "actualPayout") {
                                    console.log(params.row)
                                    if (params.row.actualPayout !== params.row.plannedPayout) {
                                        return 'sum-row-red'
                                    }
                                } else {
                                    return 'sum-row'
                                }

                            }

                        }}
                        getCellClassName={(params) => {
                            if (params.row.name === "Gesamt") {
                                if (params.field === "actualPayout") {
                                    if (params.row.actualPayout !== params.row.plannedPayout) {
                                        return 'sum-row-red'
                                    }
                                }
                                else if (params.field === "plannedPayout") {
                                    if (params.row.plannedPayout !== props.totalPayoutAmount) {
                                        return 'sum-row-red'
                                    }
                                } else {
                                    return 'sum-row'
                                }

                            }

                        }}
                        components={{
                            Footer: CustomFooter
                        }}

                    />
                </Box>

                {/* </Space> */}
            </div>
        </div>

    )
}

export default RateCard