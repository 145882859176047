import React from 'react'
import { Upload, Button } from 'antd'
import { uploadFile } from '../util/APIUtils';

function UploadFiles(props) {
    const [loading, setLoading] = React.useState(false);

    const uploadFileNew = (info) => {
        // Only proceed if the file status is 'uploading' and it's the first chunk being sent
        if (info.file.status === 'uploading' && info.file.percent === 0) {
            let file = info.file.originFileObj;
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileName', file.name);
            formData.append('year', props.year);
            formData.append('itemId', props.projectId);
            formData.append('designation', props.projectNo || '');
            formData.append('description', '');
            formData.append('keywords', '');
            formData.append('mediaType', 1);
            formData.append('ownerId', props.ownerId);

            console.log({ formData });
            setLoading(true);

            const promise = uploadFile(formData, file.name);
            if (!promise) {
                setLoading(false);
                return;
            }

            promise.then(response => {
                console.log(response);
                setLoading(false);
                props.refreshPage();
            }).catch(error => {
                console.error(error);
                setLoading(false);
                props.refreshPage();
            });
        }
    };

    return (
        <div>
            <Upload.Dragger
                multiple
                onChange={uploadFileNew}
            >
                Datei ziehen
                Oder <br/>
                <Button loading={loading}>hochladen</Button>
            </Upload.Dragger>
        </div>
    );
}

export default UploadFiles;
