import React, { useState } from 'react';
import { Modal, Form, Input, Button, notification } from 'antd';
import { forgotPassword } from '../../util/APIUtils';

const PasswordRetrievalModal = () => {
    const [visible, setVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleSubmit = () => {
        if(!email) {
            return;
        }
        setIsLoading(true);
        let promise = forgotPassword(email);

        if (!promise) {
            return;
        }
        

        promise.then(response => {
            setIsLoading(false);
            if(response) {
                notification.success({
                message: 'Erfolg',
                description: 'Ein Link zur Passwortwiederherstellung wurde gesendet.',
              });
                setVisible(false);
              
            } else {
                notification.error({
                message: 'Fehler',
                description: 'Fehler beim Senden des Links zur Passwortwiederherstellung.',
              });
            }


        }).catch(error => {
            setIsLoading(false);
            setIsTokenValid(false);
            message.error("Failed")
        });

    };

    return (
        <>
            <a onClick={showModal} style={{ marginLeft: '10px', fontSize: '14px' }}>
                Passwort vergessen?
            </a>
            <Modal
                title="Passwort-Wiederherstellung"
                visible={visible}
                onCancel={handleCancel}
                footer={null}
                centered
            >
                <Form
                    onFinish={handleSubmit}
                    layout="vertical"
                >
                    <Form.Item
                        label="E-Mail"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Bitte geben Sie Ihre E-Mail-Adresse ein!',
                                type: 'email',
                            },
                        ]}
                    >
                        <Input
                            type="email"
                            placeholder="E-Mail-Adresse"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Item>
                    <br></br>
                    <Form.Item>
                        <Button loading={isLoading} type="primary" htmlType="submit" block>
                            Link senden
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default PasswordRetrievalModal;
