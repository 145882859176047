import React from 'react'
import { Comment, Avatar, Form, Button, List, Input, Card } from 'antd';
import moment from 'moment';
import { getProcessComments, addProcessComment, getUserForEmail } from '../../util/APIUtils';
import { getShortNameForFull,  } from '../../util/Helpers';
const { TextArea } = Input;

const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];
const classNames = [
    {
        avatar: {
            backgroundColor: '#f56a00',
        }
    },
    {
        orangeAvatar: {
            margin: 10,
            color: '#fff',
            backgroundColor: '#7265e6',
        }
    },
    {
        purpleAvatar: {
            margin: 10,
            color: '#fff',
            backgroundColor: '#ffbf00',
        }
    }]


const CommentList = ({ comments }) => (
    <List
        dataSource={comments}
        // header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
        itemLayout="horizontal"
        renderItem={props => <Comment {...props} />}
    />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
    <>
        <Form.Item>
            <TextArea rows={4} onChange={onChange} value={value} />
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                Add Comment
            </Button>
        </Form.Item>
    </>
);

class ProjectComments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            processId: props.processId,
            taskId: props.taskId,
            currentUser: props.currentUser,
            comments: [],
            loading: false,
            comments: [],
            submitting: false,
            value: '',
        };
    }

    formatComment(comments) {
        return comments.map(c => (
            {
                author: c.name,
                avatar: <Avatar style={{
                    margin: 10,
                    color: '#fff',
                    backgroundColor: '#7265e6'
                }}>{c.shortName}</Avatar>,
                content: c.fullMessage,
                datetime: moment(c.commentTime).fromNow()
            }
        ))
    }



    loadComments(processId) {
        this.setState({ isLoading: true });
        console.log("Load comments " + processId)
        getProcessComments(processId).then(response => {
            console.log(response);
            this.setState({
                comments: this.formatComment(response).reverse(),
                isLoading: false
            });
            console.log(this.state.comments)
        }).catch(error => {
            console.log(error);
            this.setState({ isLoading: false });
        });
    }

    handleSubmit = () => {
        console.log(this.state.value);
        if (!this.state.value) {
            return;
        }

        this.setState({
            submitting: true,
        });

        addProcessComment(this.state.processId, this.state.taskId, this.state.value).then(response => {
            console.log(response);
            this.setState({
                submitting: false,
                value: '',
            });
            this.loadComments(this.state.processId);
        }).catch(error => {
            console.log(error);
            this.setState({ submitting: false });
        });

    };

    handleChange = e => {
        this.setState({
            value: e.target.value,
        });
    };

    componentDidMount() {
        if(this.state.processId != null && this.state.taskId != null) {
            this.loadComments(this.state.processId);
        }
    }

    render() {
        const { comments, submitting, value } = this.state;

        return (
            <>
            <Card title={`${comments.length} ${comments.length > 1 ? 'Kommentare' : 'Kommentar'}`} >
                {comments.length > 0 && <CommentList comments={comments} />}
                <Comment
                    avatar={<Avatar style={{
                        margin: 10,
                        color: '#fff',
                        backgroundColor: '#7265e6'
                    }}>{this.state.currentUser.breederInfo.shortName}</Avatar>}
                    content={
                        <Editor
                            onChange={this.handleChange}
                            onSubmit={this.handleSubmit}
                            submitting={submitting}
                            value={value}
                        />
                    }
                />
                 </Card>
            </>
           
        );
    }
}

export default ProjectComments;