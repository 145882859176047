import { List, Avatar, Spin, Button, Modal, Form, Input, Popconfirm } from 'antd';
import { useQuery, useMutation } from 'react-query';
import React, { useState } from 'react';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { deleteComment, editComment, getCommentsForItem, postComment } from '../util/APIUtils';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'react-html-parser';
import moment from 'moment';



const CommentsList = ({ itemId, currentUser }) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const getComments = getCommentsForItem(itemId);
    // const { isLoading, error, data, refetch } = getComments(
    //     (jsonData) => jsonData,
    //     // {
    //     //     refetchInterval: 5000, // refresh every 30 seconds
    //     // }
    // );

    const [editingComment, setEditingComment] = useState(null);
    const [addCommentModalVisible, setAddCommentModalVisible] = useState(false);

    const [form] = Form.useForm();

    const showEditModal = comment => {
        setEditingComment(comment);
        form.setFieldsValue({ comment: comment.comment  });
    };

    const getCommentsForProject = React.useCallback(async () => {
        let promise;
        setLoading(true)
        promise = getCommentsForItem(itemId);
        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response)
            setData(response)
            setLoading(false)
            setEditingComment(false);
        }).catch(error => {
            setLoading(false)
        });
    }, [])

    React.useEffect(() => {
        getCommentsForProject();
    }, [getCommentsForProject]);

    const handleOk = async () => {
        try {
            const fields = await form.validateFields();
            const newComment = { ...editingComment, comment: fields.comment };
            let promise;
            setLoading(true)
            promise = editComment(newComment);
            if (!promise) {
                return;
            }

            promise.then(response => {
                console.log(response)
                setLoading(false)
                setEditingComment(false);
                getCommentsForProject()
            }).catch(error => {
                setLoading(false)
            });
        } catch (error) {
            console.error(error);
        }
        setEditingComment(false);
    };

    const handleCancel = () => {
        setEditingComment(null);
    };

    const handleDelete = (comment) => {
        let promise;
        setLoading(true)
        promise = deleteComment(comment.id);
        if (!promise) {
            return;
        }

        promise.then(response => {
            console.log(response)
            setLoading(false)
            getCommentsForProject()

        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
        getCommentsForProject()

    };

    //   if (error) {
    //     return <div>Error: {error.message}</div>;
    //   }

    //   if (isLoading) {
    //     return <Spin />;
    //   }

    const handleAddCommentOk = async () => {
        try {
            const fields = await form.validateFields();
            const newComment = {
                userId: currentUser.breederInfo.id,
                comment: fields.content,
                commentBy: currentUser.name,
                itemId: itemId,
                type: "project"
            };
            let promise;
            setLoading(true)
            promise = postComment(newComment);
            if (!promise) {
                return;
            }

            promise.then(response => {
                getCommentsForProject()
                setLoading(false)

            }).catch(error => {
                setLoading(false)
            });
            setAddCommentModalVisible(false);
        } catch (error) {
            console.error(error);
        }
    };
    const showAddCommentModal = () => {
        form.setFieldsValue({ content: "" });
        setAddCommentModalVisible(true);
    };

    if (loading) {
        return <p>Wird geladen .....</p>;
    }

    let locale = {
        emptyText: 'Noch keine Kommentare',
      };



    return (
        <>
            <List
                itemLayout="horizontal"
                dataSource={data}
                size="small"
                locale={locale}
                renderItem={comment => (
                    <List.Item
                        actions={[
                            currentUser && currentUser.breederInfo.id === comment.userId && (
                                <Button size='small' shape='circle' onClick={() => showEditModal(comment)} icon={<EditOutlined />}></Button>
                            ),
                            currentUser && currentUser.breederInfo.id === comment.userId && (
                                <Popconfirm
                                    title="Sind Sie sicher, dass Sie löschen möchten?"
                                    onConfirm={() => handleDelete(comment)}
                                    okText="Ok"
                                    cancelText="Abbrechen"
                                >
                                    <Button size='small' shape='circle' icon={<DeleteOutlined />} type="danger">
                                    </Button>
                                </Popconfirm>
                            ),
                        ]}
                    >
                        <List.Item.Meta
                            avatar={
                                <Avatar alt={comment.commentBy} />
                            }
                            title={<a href="#">{comment.commentBy}</a>}
                            description={parse(comment.comment +  moment(comment.commentTime).format("DD.MM.YYYY, HH:mm"))   }
                            

                        />
                    </List.Item>
                )}
            />
            {editingComment && (
                <Modal
                    title="Kommentar bearbeiten"
                    visible={!!editingComment}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okText="Speichern"
                    cancelText="Abbrechen"
                    style={{height: '400px'}}
                    width={800}
                >
                    <Form form={form} layout="vertical">
                    <div style={{ height: '220px' }}>
                        <Form.Item
                            name="comment"
                            rules={[{ required: true, message: 'Please enter comment content' }]}
                        >
                            
                            <ReactQuill rows={5} style={{ height: '200px' }} />
                            
                        </Form.Item>
                        </div>
                    </Form>

                </Modal>)}
            <Modal
                title="Einen Kommentar hinzufügen"
                visible={addCommentModalVisible}
                onOk={handleAddCommentOk}
                onCancel={() => setAddCommentModalVisible(false)}
                okText="Speichern"
                confirmLoading={loading}
                cancelText="Abbrechen"
                width={800}
                style={{height: '400px'}}
            >
                <Form form={form} layout="vertical">
                <div style={{ height: '220px' }} >
                    <Form.Item
                        name="content"
                        rules={[{ required: true, message: 'Please enter comment content' }]}
                    >
                            <ReactQuill  defaultValue={""} style={{ height: '200px' }}/>
                            
                    </Form.Item>
                    </div>
                </Form>
            </Modal>
            <Button
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                size="large"
                onClick={showAddCommentModal}
            />
            {/* {data.map((comment, index) => (
                <div key={comment.id}>
                    {index === data.length - 1 && (
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<PlusOutlined />}
                            size="large"
                            onClick={showAddCommentModal}
                        />
                    )}
                </div>
            ))} */}
        </>
    );
};

export default CommentsList
