import React, { useState } from 'react';
import moment from 'moment'
import { Form, Input, Select, DatePicker, Modal, Descriptions, notification, Row, Col } from 'antd';
import CropSelect from '../common/CropSelect';
import { addSorten } from '../util/APIUtils';

const { Option } = Select;
const { RangePicker } = DatePicker;

const AddSorten = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [startOpen, setStartOpen] = useState(false);
    const [endOpen, setEndOpen] = useState(false);
    const [cropId, setCropId] = useState(null);

    const sorten = props.sorten;
    console.log(sorten)
    const editing = sorten != null

   

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        setLoading(true)
        let sortenObj = {
            id: sorten? sorten.id: null,
            designation: values.designation,
            cropId: cropId,
            permit: moment.isMoment(values.permit) ? moment(values.permit).year() : null,
            extendedUntil: moment.isMoment(values.extendedUntil) ? moment(values.extendedUntil).year() : null,
        }
        let promise;
        console.log(sortenObj)
        promise = addSorten(sortenObj);
        if (!promise) {
            return;
        }
        promise.then(response => {
            setLoading(false)
            props.setVisible(false)
            notification.success({
                message: 'Kultursaat',
                description: "Erfolgreich gelöscht"
            });
            props.refetch()
        }).catch(error => {
            console.log(error)
        });

    };

   

    return (
        <Modal
            title="Add Record"
            visible={props.visible}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onFinish(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
            confirmLoading={loading}
            onCancel={() => {
                form.resetFields();
                props.onCancel();
            }}
        >
            <Form form={form} onFinish={onFinish} >
                <Descriptions
                    bordered
                    size={"small"}
                >
                    <Descriptions.Item span={3} label="Bezeichnung">
                        <Form.Item
                            name="designation"
                            rules={[{ required: true, message: 'Please input designation!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="Kulturart">
                        <Form.Item
                            name="cropName"
                        >
                            <CropSelect setItem={setCropId}/>
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="Zulassung">
                        <Form.Item name="permit" >
                            <DatePicker
                            picker="year"
                            />
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="Verlaengert bis">
                        <Form.Item name="extendedUntil" >
                            <DatePicker
                            picker="year"
                            />
                        </Form.Item>
                    </Descriptions.Item>
                    
                </Descriptions>
            </Form>
        </Modal>
    );
};

export default AddSorten;