import React from 'react'

import {
    SyncOutlined,
    AimOutlined
} from '@ant-design/icons';
import { Button, Input, InputNumber, Modal, Divider, Card, Space, Select, notification } from 'antd';
import { isFgLeader, isBoardMember } from '../../util/APIUtils';

export default function ActionButtons(props) {
    const [visible, setVisible] = React.useState(false);
    const [action, setAction] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [amount, setAmount] = React.useState(0);
    const [status, setStatus] = React.useState("");
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [fgLeader, setFgLeader] = React.useState(false);
    const [boardMember, setBoardMember] = React.useState(false);
    const task = props.task;
    const cropId = props.cropId;
    const breederId = props.breederId;
    

    const isUserFgLeader = () => {
        let promise;
        promise = isFgLeader(cropId);

        if (!promise) {
            return;
        }


        promise.then(response => {
            setFgLeader(response)

        }).catch(error => {
            console.log(error)
        });
    }

    const isUserBoardMember = () => {
        let promise;
        promise = isBoardMember();

        if (!promise) {
            return;
        }


        promise.then(response => {
            setBoardMember(response);

        }).catch(error => {
            console.log(error)
        });
    }

    React.useEffect(() => {
        isUserFgLeader();
        isUserBoardMember();
    }, [isUserFgLeader, isUserBoardMember]);


    const handleOk = () => {
        setConfirmLoading(true);
        switch (action) {
            case "project_submit":
                console.log("Submit prj")
                props.submitMyProject(description, false);
                break;
            case "breeder_review_submit":
                    console.log("Submit prj")
                    props.submitMyProject(description, true);
                    break;
            case "fg":
                if ([0, 1, -1, -2].includes(status) ) {
                    console.log("Submit FG")
                    props.approveProject(status, "fg", description, amount, false);

                } else {
                    notification.error("Status und Menge erforderlich!")
                }
                break;
            case "fg_re_review":
                console.log("fg re")
                if ([0, 1, -1].includes(status) ) {
                    console.log("Re Submit FG")
                    props.approveProject(status, "fg_re", description, amount, true);
                } else {
                    notification.error("Status und Menge erforderlich!")
                }
                break;
            case "board":
                console.log("Submit Board")
                if ([0, 1, -1].includes(status) ) {
                    props.approveProject(status, "board", description, amount);
                } else {
                    notification.error("Status und Menge erforderlich!")
                }
                break;
            case "report":
                console.log("Submit Report/Actual")
                if ([0, 1, -1].includes(status) ) {
                    props.approveProject(status, "report", description);
                } else {
                    notification.error("Status und Menge erforderlich!")
                }
                break;
        }
        setConfirmLoading(false);
        setVisible(false);


    };

    const handleCancel = () => {
        setVisible(false);
    };

    const approveClicked = (action) => {
        setVisible(true);
        setAction(action);
    }

    const handleStatusChange = (val) => {
        setStatus(val);
    }

    const getNextActionFromStatus = () => {
        let breederAccess = breederId === props.currentUser.breederInfo.id

        // props.submitMyProject
        switch (task.name) {
            case 'DRAFT':
                // return null
                return breederAccess ? <Button type="primary" style={{ display: 'inline-block', verticalAlign: 'middle' }} onClick={() => approveClicked("project_submit")} >Einreichen</Button>: ""
            case 'BREEDER_REVIEW':
                return breederAccess ? <Button type="primary" style={{ display: 'inline-block', verticalAlign: 'middle' }} onClick={() => approveClicked("breeder_review_submit")} >Einreichen</Button>: ""
            case 'FG_REVIEW':
                return fgLeader ? <Button type="primary" style={{ verticalAlign: 'middle' }} onClick={() => approveClicked("fg")} >FG Evaluation</Button> : ""
            case 'FG_RE_REVIEW':
                return fgLeader ? <Button type="primary" style={{ verticalAlign: 'middle' }} onClick={() => approveClicked("fg_re_review")} >FG Re Evaluation</Button> : ""
            case 'BOARD_APPEAL':
                return boardMember? <Button type="primary" style={{ display: 'inline-block', verticalAlign: 'middle' }} onClick={() => approveClicked("board")} >Board Evaluation</Button>: ""
            case 'CONTRACT':
                return <Button type="primary" style={{ display: 'inline-block', verticalAlign: 'middle' }} onClick={() => approveClicked("funder")} >Funder Evaluation</Button>
            case 'REPORT-ACTUAL':
                return <Button type="primary" style={{ display: 'inline-block', verticalAlign: 'middle' }} onClick={() => approveClicked("report")} >Funder Evaluation</Button>
        }
    }

    return (
        // <div style={{  verticalAlign: 'left',  }}>

        <div style={{ padding: '15px', background: '#ececec' }}>
            <Card bordered={false} >
                <Modal
                    title={action.toUpperCase() + " - Kommentar"}
                    visible={visible}
                    onOk={handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={handleCancel}
                // afterClose={onExit}
                >
                    {['FG_REVIEW','FG_RE_REVIEW', 'BOARD_APPEAL'].includes(task.name) ?
                        <span>
                            <Space>
                                Betrag
                                <InputNumber value={amount} onChange={setAmount} />
                                <br></br>
                                Status
                                <Select style={{ width: 120 }} onChange={handleStatusChange}>
                                    <Option value={1}>Ok</Option>
                                    {['FG_REVIEW'].includes(task.name)  && <Option value={-2}>Züchter Überarbeiten</Option>}
                                    <Option value={-1}>Zurückgestellt</Option>
                                    <Option value={0}>Abgelehnt</Option>
                                </Select>
                            </Space>
                            <Divider /></span> : ""}

                            Kommentar
                    <Input.TextArea value={description} onChange={e => setDescription(e.target.value)} rows={7} />
                </Modal>

                {getNextActionFromStatus()}
            </Card>
        </div>
        // </div>
    )
}
