import { Empty, List, Descriptions, Button, Space, notification, Form, Modal, Popconfirm } from 'antd';
import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Link, Prompt } from 'react-router-dom';
import moment from 'moment';
import { useTranslation, Trans } from 'react-i18next';
import EditLine from './EditLine';
import { deleteLine } from '../../util/APIUtils';

const LineDetail = (props) => {
    const [editing, _setEditing] = useState();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const dateFormat = 'MMM-DD-YYYY';
    const [form] = Form.useForm();
    const line = props.lines;

    const setEditing = () => {
        _setEditing(!editing)
    }

    useEffect(() => form.resetFields(), [props.line]);

    const onClose = () => {
        _setEditing(false);
        // notification.success({
        //     message: 'Kultursaat',
        //     description: "Line saved succesfully"
        // });
        props.fetchLines();
    }
    const onFinish = (values) => {
        form.submit();
    }

    const deleteLineDetail = () => {
        let promise;
        promise = deleteLine(line.id);
        if (!promise) {
            return;
        }
        promise.then(response => {
            setLoading(false)
            notification.success({
                message: 'Kultursaat',
                description: "Erfolgreich gelöscht"
            });
            props.fetchLines();
        }).catch(error => {
            console.log(error)
        });
    }

    const sortenStufeBlock = () => {

        if (line.varietyLevel >= 3) {
            return (
            <>
                <Descriptions.Item   span={2} label={"Menge vorh EliteSaatgut"}>{line.bulkEliteSeeds}</Descriptions.Item>
                <Descriptions.Item span={2} label={"keimfähigkeit"}>{line.germinationElite}  %</Descriptions.Item>

                <Descriptions.Item span={3} label={"Sortenbeschreibung"}>{line.varietyDescription}</Descriptions.Item>

                <Descriptions.Item span={2} label={"Insgesamt vorhandenes Saatgut für Versuchsanbau"}>{line.mixedSeeds}</Descriptions.Item>
                <Descriptions.Item span={2} label={"keimfähigkeit"}>{line.germinationVa}  %</Descriptions.Item>
            </>)
        }
    }

    return (
        <Fragment>
            {line ?
                <div>

                    <Descriptions
                        bordered
                        title={t("lines.title")}
                        size={"small"}
                        extra={<div> {props.isReportEditable && <Space>
                            <Button type="primary" onClick={setEditing}>Bearbeiten</Button>
                            <Popconfirm
                                title="Sicher löschen?"
                                onConfirm={deleteLineDetail}
                            >
                                <Button type="danger" >Löschen</Button>
                            </Popconfirm>
                        </Space>}</div>}
                    >
                        <Descriptions.Item span={3} label={t("lines.description")}>{line.description}</Descriptions.Item>
                        <Descriptions.Item span={3} label={t("lines.short_description")}>{line.briefDescription}</Descriptions.Item>
                        <Descriptions.Item span={3} label={t("lines.this_year_on_line")}>{line.task}</Descriptions.Item>
                        <Descriptions.Item span={3} label={t("lines.results")}>{line.results}</Descriptions.Item>
                        <Descriptions.Item span={2} label={t("lines.variety_level")}>{line.varietyLevel}</Descriptions.Item>
                        <Descriptions.Item span={2} label={t("lines.seed_carrier_number")}>{line.invitedSeedBearer}</Descriptions.Item>
                        <Descriptions.Item span={2} label={t("lines.readout_stock_size")}>{line.largeSelectionStock} {line.stockUnit}</Descriptions.Item>
                        <Descriptions.Item span={2} label={t("lines.blooming_seed_carriers")}>{line.bloomSeedBearer}</Descriptions.Item>
                        <Descriptions.Item span={2} label={t("lines.planting_date")}>{line.plantingDate ? moment(line.plantingDate).format(dateFormat) : ""}</Descriptions.Item>
                        <Descriptions.Item span={2} label={t("lines.sowing_date")}>{line.sowingDate ? moment(line.sowingDate).format('MMM-DD-YYYY') : ""}</Descriptions.Item>
                        <Descriptions.Item span={2} label={"Menge gew Saatgut"}>{line.amountOfSeeds}</Descriptions.Item>
                        <Descriptions.Item span={2} label={t("lines.germination")}>{line.germination} %</Descriptions.Item>
                        {sortenStufeBlock()}
                    </Descriptions>

                    <Modal width={800} forceRender visible={editing} onClose={onClose} onCancel={onClose}
                        footer={[
                        ]}
                    >
                        <EditLine line={line} onClose={onClose} />
                    </Modal>
                </div> :
                <Empty description={t("lines.select_item")}></Empty>}
        </Fragment>
    );
};

export default LineDetail;
